import { Icon, IconProps } from '@chakra-ui/react';

export default function SNXMainLooper({
	width = '1920px',
	height = '495px',
	...props
}: IconProps) {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 1870 495"
			fill="none"
			{...props}
		>
			<mask
				id="mask0_6353_87482"
				style={{
					maskType: 'alpha',
				}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="20"
				width="1920"
				height="518"
			>
				<rect
					y="20"
					width="1920"
					height="518"
					fill="url(#paint0_radial_6353_87482)"
				/>
			</mask>
			<g mask="url(#mask0_6353_87482)">
				<g clipPath="url(#clip0_6353_87482)">
					<path
						d="M486.746 65.3198C514.336 59.3598 529.836 49.4598 559.086 52.2598C589.856 55.1998 621.286 63.3598 652.176 63.4398C681.826 63.5198 710.636 53.6798 739.386 47.6998C768.736 41.5998 791.706 44.3398 820.976 47.4198C851.416 50.6298 882.016 45.3198 912.006 40.7598C941.745 36.2398 969.795 32.2098 999.785 37.3198C1034.01 43.1498 1067.07 54.1298 1100.94 61.5298C1150.82 72.4298 1200.45 54.9998 1249.41 73.3398C1276.49 83.4798 1301.32 96.9198 1330.6 99.1898C1358.49 101.35 1386.62 98.0098 1414.11 93.6098C1446.81 88.3798 1481.27 80.9498 1514.57 84.2898C1557.04 88.5398 1599.42 102.73 1641.27 110.74C1639.57 110.75 1637.88 110.84 1636.18 110.82C1695.74 111.45 1752.46 134.39 1794 176.94C1804.53 187.72 1813.55 198 1818.73 212.4C1821.29 219.53 1821.26 229.81 1825.51 235.9C1840.22 256.95 1885.86 262.8 1907.91 268.86C1938.4 277.25 1969.61 284.41 1999.02 296.19C2014.59 301.26 2028.49 309.18 2040.72 319.96C2046.6 329.28 2050.78 339.3 2053.27 350.03C2074.93 407.51 2129.69 435.23 2168.3 479.27C2041.36 477.64 1914.49 484.58 1787.58 483.56C1751.14 483.27 1714.2 478.85 1677.88 482.02C1620.98 486.98 1562.96 502.67 1505.7 500.18C1483.24 499.21 1463.02 492.54 1441.57 486.75C1413.7 479.23 1385.34 479.18 1356.66 480.35C1253.13 484.57 1150.69 501.22 1046.92 495.82C945.865 490.56 845.436 481.8 744.226 481.43C679.806 481.2 615.226 481.16 550.826 479.64C495.226 478.33 440.386 468.27 384.886 465.75C261.846 460.17 139.176 477.68 16.3066 480.03C33.8766 450.09 55.4666 429.02 80.9166 405.72C95.8065 392.08 112.767 377.79 122.156 359.39C124.816 347.21 130.796 336.93 140.096 328.54C154.706 317.94 170.576 309.72 187.686 303.88C217.586 292.12 248.776 283.91 279.296 273.99C304.136 265.91 333.846 258.94 348.576 235.64C354.256 226.65 359.336 217.37 365.426 208.63C378.476 189.88 393.796 173.07 408.696 155.81C434.736 125.65 459.936 94.8098 486.756 65.3298L486.746 65.3198Z"
						stroke="url(#paint1_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M22.3457 468.45C147.816 463.31 275.645 432.84 401.385 444.67C452.945 449.52 502.695 465.36 554.615 467.72C619.065 470.65 684.055 470.6 748.575 471.14C853.185 472.02 957.265 480.23 1061.79 483.98C1161 487.53 1258.54 467.17 1357.41 462.23C1384.93 460.85 1411.37 461.11 1437.6 470.19C1460.21 478.02 1480.81 487.52 1505.12 489.12C1559.75 492.73 1616.66 476.39 1670.87 471.44C1709.97 467.87 1749.4 473.89 1788.63 474.31C1912.07 475.61 2035.4 465.59 2158.88 467.29"
						stroke="url(#paint2_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M29.7168 457.44C95.3267 453.67 160.407 442.5 225.577 434.52C287.016 427 353.426 416.33 415.226 424.59C461.916 430.83 505.106 451.63 552.326 455.45C616.476 460.64 682.106 460.23 746.466 461.38C853.136 463.29 959.596 469.64 1066.25 472.03C1165 474.24 1261.1 449.98 1359.39 444.34C1386.7 442.77 1412.16 444.16 1437.33 455.48C1458.32 464.92 1477.03 476.01 1500.51 478.5C1555.09 484.28 1613.08 465.68 1667.44 461.2C1707.04 457.93 1746.59 465.21 1786.33 465.77C1906.64 467.49 2026.71 454.06 2147.05 455.59"
						stroke="url(#paint3_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M37.9355 447.15C105.575 442.73 172.495 429.05 239.515 419.47C299.575 410.88 366.545 398.44 427.135 407.62C473.235 414.61 513.135 440.25 560.265 443.74C621.395 448.27 683.315 450.04 744.595 451.96C852.595 455.34 960.874 459.57 1068.91 460.53C1165.19 461.38 1258.16 435.64 1353.81 428.29C1383.74 425.99 1410.33 427.54 1436.86 442.21C1457.63 453.7 1476.08 466.28 1500.48 468.9C1553.91 474.64 1611.01 455.72 1664.23 451.22C1702.16 448.01 1739.51 456.68 1777.5 457.67C1896.25 460.77 2014.55 442.96 2133.29 444.15"
						stroke="url(#paint4_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M47.0059 437.28C113.426 432.8 179.086 418.84 244.826 408.96C306.776 399.65 376.395 386.33 438.955 396.48C480.955 403.29 517.275 427.01 560.175 430.91C622.625 436.59 685.915 440.19 748.555 443.11C854.925 448.07 962.375 450.27 1068.82 449.83C1165.66 449.43 1258.93 421.06 1355.23 414.21C1383.55 412.2 1408.46 414.48 1433.14 429.17C1453.62 441.36 1470.82 455.33 1495.29 458.96C1518.06 462.33 1542.89 457.26 1565.37 454.11C1600.28 449.22 1635.49 442.66 1670.75 440.88C1707.12 439.04 1743.73 449.53 1780.36 450.39C1893.44 453.04 2005.52 432.06 2118.53 432.97"
						stroke="url(#paint5_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M56.8262 427.61C121.316 423.07 185.076 409.91 248.926 400.36C311.736 390.97 381.036 378.17 444.476 387.36C486.026 393.37 523.366 414.09 565.436 418.21C627.775 424.32 690.505 430.82 753.025 434.71C857.315 441.2 963.645 441.76 1068.04 440.17C1165.17 438.69 1258.8 408.15 1355.62 402.29C1382.1 400.69 1405.69 403.74 1428.63 417.59C1448.6 429.64 1465.73 444.61 1489.37 449.43C1511.97 454.04 1536.55 448.47 1558.99 445.34C1591.37 440.82 1623.6 435.01 1656.21 432.25C1694.92 428.96 1732.2 441.65 1770.94 443.19C1883.05 447.67 1993.39 421.26 2105.3 422"
						stroke="url(#paint6_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M66.8672 418.32C131.127 413.88 194.677 401.14 258.357 392.01C320.237 383.14 386.587 372.33 449.127 379.67C490.577 384.53 529.287 401.32 570.897 405.67C631.427 412 691.796 421.04 752.436 426.14C786.656 429.02 820.766 428.49 855.066 429.05C925.766 430.22 996.536 432.99 1067.25 431.28C1164.36 428.93 1257.95 396.45 1355.05 392.01C1381.24 390.81 1403.96 395.26 1426.35 409.03C1445.08 420.55 1461.77 434.55 1483.69 439.89C1509.2 446.11 1539.31 438.84 1564.8 434.95C1597.23 430.01 1630 423.54 1662.87 422.43C1697.87 421.24 1732.11 434.73 1767.38 436.41C1877.23 441.67 1984.31 410.73 2093.84 411.25"
						stroke="url(#paint7_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M76.8867 409.31C202.797 401.19 328.466 362.35 455.386 373.4C495.456 376.89 534.116 388.85 574.116 392.96C634.166 399.13 693.346 411.36 753.366 417.7C787.456 421.3 821.286 420.87 855.506 421.36C922.296 422.32 989.096 424.62 1055.9 423.27C1153.58 421.3 1247.41 388.59 1345.02 383.45C1372.05 382.03 1396.14 385.5 1419.78 399.07C1439.84 410.59 1457.71 425.73 1480.68 431.54C1504.05 437.45 1529.94 430.87 1553.29 427.36C1586.64 422.35 1619.9 415.82 1653.62 413.75C1691.66 411.42 1727.93 428.21 1766.22 430.04C1873.53 435.15 1977.03 400.39 2083.94 400.7"
						stroke="url(#paint8_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M86.6758 400.5C211.086 393.33 332.845 361.65 458.225 367.66C559.745 372.53 658.395 397.64 758.895 409.86C794.055 414.13 829.075 413.31 864.425 413.92C931.415 415.08 998.575 417.72 1065.55 415.1C1159.2 411.42 1249.24 378.49 1343.35 375.39C1369.82 374.52 1393.53 379.23 1416.72 392.07C1435.89 402.68 1453.47 416.44 1474.97 422.35C1498.04 428.69 1524.02 422.74 1547.08 419.08C1580.88 413.72 1614.7 407.03 1648.93 405.21C1686.12 403.23 1719.62 420.66 1756.75 423.29C1864.82 430.93 1967.98 390.21 2075.54 390.37"
						stroke="url(#paint9_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M95.9766 391.78C217.656 385.77 336.296 361.24 458.696 362.34C501.476 362.73 544.436 364.64 587.036 368.77C643.526 374.24 698.396 392.18 754.486 400.65C788.026 405.71 820.996 405.61 854.826 406.17C924.876 407.33 995.105 410.49 1065.16 407.52C1157.78 403.6 1246.54 369.08 1339.97 368.1C1364.74 367.84 1387.5 372.35 1409.72 383.35C1430.48 393.62 1449.57 408.01 1472.2 414.29C1495.82 420.85 1524.46 413.66 1547.92 409.78C1581.94 404.15 1616.61 396.32 1651.24 395.88C1687 395.43 1719.62 414.42 1755.75 416.97C1862.04 424.46 1962.55 380.07 2068.4 380.19"
						stroke="url(#paint10_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M104.566 383.08C163.776 380.65 222.596 371.63 281.496 365.6C342.746 359.33 403.426 358.67 464.866 357.13C505.866 356.1 547.106 353.29 588.076 356.66C646.186 361.44 702.436 383.4 759.946 392.75C794.696 398.4 829.046 397.95 864.146 398.68C930.785 400.05 997.635 403.06 1064.28 400C1154.17 395.87 1240.04 362.39 1330.89 361.01C1357.53 360.61 1382.6 365.71 1406.79 376.84C1426.81 386.05 1445.58 398.91 1466.91 405.12C1491 412.13 1517.89 405.61 1541.99 401.49C1576.62 395.58 1611.52 388.1 1646.74 387.34C1683.29 386.56 1715.48 407.71 1752.4 410.48C1858.03 418.41 1957.11 369.94 2062.44 370.16"
						stroke="url(#paint11_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M112.207 374.31C170.497 372.36 228.467 364.23 286.507 358.98C345.807 353.61 404.847 354.83 464.157 351.74C507.477 349.48 551.137 341.5 594.596 345.28C650.846 350.17 705.306 374.07 760.836 384.09C795.246 390.29 829.346 389.89 864.156 390.76C930.486 392.42 997.116 395.71 1063.44 392.32C1152.66 387.75 1237.5 353.18 1327.99 353.92C1380.98 354.35 1416.53 382.8 1464.89 397.03C1489.46 404.26 1519.26 396.24 1543.64 391.91C1574.74 386.39 1605.8 379.93 1637.41 378.49C1676.48 376.71 1707.6 399.87 1746.29 403.47C1852.29 413.34 1951.61 359.76 2057.52 360.28"
						stroke="url(#paint12_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M118.666 365.36C174.396 363.8 229.856 356.82 285.376 352.27C346.196 347.29 407.266 350.12 467.906 345.48C510.636 342.21 553.305 330.65 596.365 334.04C654.185 338.58 709.935 365.9 766.985 376.25C799.365 382.12 831.735 381.61 864.515 382.53C930.595 384.4 996.995 387.91 1063.08 384.5C1102.91 382.45 1139.08 371.32 1177.85 363.39C1226.1 353.51 1276.58 345.13 1325.99 346.31C1374.86 347.47 1413.99 370.89 1458.55 386.89C1482.44 395.47 1508.43 388.68 1532.71 384.45C1568.09 378.3 1604.51 368.68 1640.66 368.68C1678.01 368.68 1708.51 393.42 1746.28 396.59C1851.04 405.38 1948.41 349.5 2053.47 350.55"
						stroke="url(#paint13_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M123.717 356.17C178.627 354.73 233.307 348.42 288.047 344.36C348.427 339.88 409.536 343.68 469.626 338.32C512.736 334.48 555.486 319.51 599.086 323.28C656.586 328.25 711.516 356.73 768.236 367.35C800.476 373.38 832.906 372.77 865.566 373.84C931.176 376 997.166 379.73 1062.8 376.17C1102.56 374.01 1138.32 362.23 1176.96 354.17C1224.56 344.25 1274.66 336.23 1323.43 338.06C1371.24 339.85 1412.39 361.22 1456.09 377.69C1479.93 386.68 1505.8 379.71 1530.1 375.33C1565.51 368.93 1602.16 358.86 1638.41 359.08C1675.98 359.31 1705.62 385.71 1743.67 389.09C1797.86 393.91 1847.77 378.45 1899.02 363.91C1948.38 349.9 1998.62 340.02 2050.15 340.93"
						stroke="url(#paint14_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M127.117 346.62C181.887 345.03 236.437 339.08 291.067 335.28C350.027 331.19 409.617 334.83 468.337 330.1C512.037 326.58 555.077 308.68 599.257 312.76C656.737 318.06 710.946 347.14 767.616 357.84C799.786 363.91 832.116 363.26 864.716 364.5C930.346 367 996.426 371.05 1062.07 367.17C1102.47 364.78 1137.77 352.34 1177 344.06C1224.35 334.06 1274.51 326.09 1323.01 328.92C1371.58 331.75 1411.56 353.86 1456.4 369.02C1481.29 377.43 1508.84 369.68 1533.84 364.68C1565.58 358.33 1597.49 350.84 1629.92 349.24C1670.38 347.24 1699.43 376.45 1739.32 380.83C1793.7 386.81 1843.33 369.74 1894.44 354.61C1944.27 339.86 1995.3 329.92 2047.49 331.4"
						stroke="url(#paint15_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M131.547 337.36C186.347 335.7 240.927 329.57 295.597 325.72C354.677 321.56 414.557 325.57 473.356 320.44C517.236 316.61 560.326 299.36 604.756 303.73C660.866 309.25 713.226 337.75 768.386 348.76C800.536 355.18 833.296 354.66 865.936 355.85C931.096 358.23 996.696 361.02 1061.85 357.06C1102.38 354.6 1138.42 342.3 1177.77 334.12C1224.7 324.37 1274.41 317.15 1322.47 319.85C1371.26 322.6 1411.58 345.47 1456.69 360.25C1478.32 367.34 1503.12 361 1524.87 357.08C1560.39 350.69 1596.18 342.18 1632.41 341.74C1670.94 341.27 1701.23 367.84 1740.05 371.25C1792.55 375.87 1840.39 360.47 1889.75 345.72C1939.61 330.82 1990.52 320.94 2042.78 322.32"
						stroke="url(#paint16_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M139.836 328.73C194.526 326.99 248.976 320.65 303.526 316.72C362.706 312.46 422.636 316.54 481.536 311.34C525.906 307.42 569.375 290.79 614.325 295.6C668.775 301.43 718.895 329.68 772.285 340.99C804.085 347.72 836.615 347.47 868.985 348.43C933.205 350.34 997.985 351.7 1062.11 346.98C1102.66 344 1138.59 332.03 1178.11 324.09C1222.14 315.24 1268.05 309.39 1313.05 311.05C1361.88 312.85 1406.83 332.25 1451.77 349.12C1475.57 358.05 1503.57 351.52 1527.76 347.58C1562.37 341.93 1598.27 333.25 1633.58 334.58C1668.45 335.89 1697.16 358.66 1732.53 362.03C1786.06 367.13 1834.7 351.45 1884.98 336.4C1933.49 321.88 1983.04 312.68 2033.87 313.95"
						stroke="url(#paint17_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M151.447 320.66C206.247 318.81 260.777 312.19 315.437 308.17C373.357 303.91 431.777 307.74 489.457 303.02C533.547 299.42 577.037 283.94 621.607 287.72C677.787 292.48 728.457 323.76 783.197 335.53C812.696 341.88 843.116 341.59 873.156 342.13C936.396 343.27 1000.2 342.88 1063.22 337.18C1103.41 333.55 1139.65 321.54 1178.98 314C1222.08 305.74 1267.12 300.74 1311.05 303.05C1361.69 305.71 1406.18 326.57 1453.58 341.09C1475.44 347.78 1500.78 342.54 1522.66 339.5C1553.14 335.27 1583.72 329.85 1614.54 328.69C1654.38 327.19 1686.5 350.68 1725.97 353.87C1777.66 358.04 1824.56 343.64 1873.13 328.83C1921.45 314.1 1970.71 304.94 2021.42 306.18"
						stroke="url(#paint18_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M165.807 313.09C219.187 311.18 272.276 304.54 325.506 300.43C384.196 295.9 443.206 299.58 501.696 295.13C545.886 291.77 589.406 277.47 634.026 280.82C688.596 284.92 737.066 316.56 789.576 329.57C821.516 337.48 854.596 337.02 887.306 336.89C946.506 336.65 1006.13 334.26 1064.99 327.7C1104.64 323.28 1141.27 310.9 1180.31 303.97C1222.4 296.5 1266.36 292.29 1309.08 295.69C1358.81 299.65 1405.08 316.86 1452.03 331.57C1473.85 338.41 1499.92 333.59 1521.84 331.14C1551.68 327.8 1581.6 323.35 1611.67 323.25C1648.06 323.13 1678.79 343.6 1715.09 346.41C1765.23 350.29 1811.06 336.64 1858.11 321.98C1906.31 306.96 1955.33 297.76 2006.01 299"
						stroke="url(#paint19_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M182.346 305.95C234.206 303.98 285.756 297.3 337.456 293.12C395.195 288.44 452.855 291.33 510.495 288C558.125 285.25 605.125 270.53 653.075 274.95C706.325 279.86 751.995 312.82 803.175 325.87C832.835 333.43 863.045 333.1 893.475 332.38C951.345 331.02 1009.58 326.48 1066.89 318.37C1105.85 312.85 1142.6 300.27 1181.28 293.95C1220.08 287.61 1260.14 285.06 1299.37 288.09C1347.86 291.83 1399 304.01 1444.85 319.77C1495.64 337.23 1554.9 315.76 1607.53 318.54C1642.29 320.38 1672.97 338.21 1708.31 339.76C1756.22 341.87 1799.6 328.56 1844.53 314.44C1891.43 299.7 1938.98 291.03 1988.32 292.27"
						stroke="url(#paint20_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M200.506 299.17C252.586 296.9 304.296 290.02 356.216 285.74C412.816 281.08 469.216 283.83 525.755 280.88C573.685 278.37 621.195 265.01 669.385 269.19C721.075 273.67 763.835 306.95 812.635 321.51C844.435 331 876.905 330.23 909.745 328.24C963.245 325 1016.84 318.6 1069.64 309.36C1105.16 303.14 1139.18 291.13 1174.67 285.3C1214.52 278.75 1256.05 276.98 1296.23 281.29C1344.85 286.5 1396.45 295.06 1442.89 310.19C1489.66 325.43 1542.7 312.17 1591.09 314.03C1626.87 315.41 1658.85 331.96 1694.97 333.17C1742.39 334.76 1785.09 321.34 1829.53 307.19C1874.91 292.74 1921.2 284.49 1968.99 285.94"
						stroke="url(#paint21_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M219.717 292.69C270.177 290.38 320.257 283.51 370.547 279.15C427.827 274.19 484.766 276.89 542.006 274.2C590.256 271.93 638.196 259.9 686.656 263.86C738.776 268.12 779.676 303.69 828.296 319.19C857.516 328.5 886.806 327.88 917.066 325.16C969.156 320.47 1021.21 311.56 1072.31 300.57C1106.96 293.12 1140.61 280.68 1175.7 275.57C1211.94 270.29 1249.37 269.81 1285.75 274.09C1336.93 280.1 1391.64 285.41 1440.87 300.63C1487.46 315.04 1537.75 305.32 1585.67 310.01C1618.25 313.2 1647.79 326.26 1680.99 327.05C1726.5 328.12 1767.86 315.09 1810.44 301.23C1855.43 286.58 1901.11 278.43 1948.61 279.97"
						stroke="url(#paint22_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M239.426 286.45C288.576 284.1 337.336 277.16 386.316 272.75C442.746 267.67 498.685 269.77 555.145 267.95C605.835 266.31 656.455 254.4 707.275 259.13C758.195 263.87 796.145 300.7 843.125 316.9C869.845 326.11 896.195 326.08 923.965 322.76C974.895 316.67 1025.45 305.09 1075.02 292.16C1109.01 283.3 1141.97 270.16 1177 265.96C1209.94 262.01 1243.4 262.79 1276.27 267.16C1303.26 270.75 1329.18 276.32 1356.47 277.94C1385.88 279.69 1412.87 283.11 1441.09 291.8C1484.06 305.03 1529.94 300.18 1574.14 305.67C1606.83 309.73 1636.31 321.23 1669.74 321.03C1713.36 320.77 1752.66 308.02 1793.49 294.59C1837.26 280.2 1881.59 272.6 1927.82 274.23"
						stroke="url(#paint23_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M259.057 280.36C308.387 277.94 357.267 270.6 406.426 266.17C461.696 261.18 516.356 263.12 571.676 261.78C622.696 260.55 673.686 249.9 724.786 254.39C776.516 258.94 811.896 298.22 858.936 315.33C885.936 325.16 912.616 324.1 940.646 319.27C987.236 311.24 1033.12 298.02 1078.19 283.94C1111.26 273.61 1142.95 259.95 1177.7 256.57C1209.18 253.51 1241.35 255.78 1272.46 261.02C1327.56 270.29 1385.73 265.93 1439.29 282.28C1481.34 295.12 1525.19 294.35 1568.21 302.01C1597.87 307.29 1625.05 315.55 1655.6 314.94C1698.58 314.09 1737.18 301.1 1777.41 287.78C1820.05 273.67 1862.34 267.7 1907.29 268.67"
						stroke="url(#paint24_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M278.066 274.38C325.776 271.99 373.036 264.76 420.556 260.27C476.516 254.99 531.786 257.05 587.816 255.92C639.036 254.88 690.196 245.81 741.486 249.83C792.626 253.84 824.146 292.78 869.056 311.97C895.576 323.3 920.716 322.85 948.466 317.13C993.636 307.81 1037.66 291.91 1080.9 276.07C1110.92 265.07 1139.78 251.32 1171.93 248.11C1202.1 245.1 1232.78 248.41 1262.47 253.96C1289.25 258.97 1314.1 262.9 1341.4 262.92C1371.74 262.95 1401.12 262.92 1430.62 270.87C1471.35 281.83 1511.73 288.57 1553.15 296.53C1583.64 302.39 1610.76 309.99 1642.07 308.88C1684.34 307.38 1722.41 294.1 1762.05 281.02C1803.36 267.39 1844.16 262.14 1887.68 263.24"
						stroke="url(#paint25_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M295.867 268.42C342.337 265.78 388.327 258.86 434.617 254.38C491.787 248.85 548.167 251.24 605.407 250.12C656.907 249.12 708.647 240.96 760.157 245.56C810.127 250.03 838.947 290.07 882.157 309.82C945.526 338.79 1025.04 293.15 1083.18 268.53C1112.73 256.02 1140.74 241.48 1173.21 239.14C1202.4 237.04 1231.72 242.06 1260.13 248.25C1316.68 260.57 1375.81 245.87 1431.38 262.02C1495.82 280.75 1564.31 306.45 1632.34 302.28C1672.76 299.8 1709.17 286.89 1747.16 274.35C1787.31 261.09 1827.26 256.54 1869.54 257.79"
						stroke="url(#paint26_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M311.916 262.44C358.346 259.8 404.266 252.69 450.526 248.25C506.106 242.92 561.036 245.2 616.716 244.62C669.776 244.07 722.996 236.58 776.055 241.17C826.745 245.56 852.435 288.82 895.755 308.67C956.935 336.71 1031.64 286.99 1085.53 261.08C1114.2 247.29 1141.29 232.15 1173.66 230.6C1199.61 229.36 1225.26 234.94 1250.37 240.8C1309.01 254.5 1369.12 237.05 1427.1 252.22C1493.11 269.49 1553.42 300.97 1623.24 295.21C1661.43 292.06 1696.31 280.01 1732.38 268.13C1772.21 255 1811.66 250.89 1853.54 252.34"
						stroke="url(#paint27_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M325.627 256.35C370.637 253.83 415.127 246.93 459.947 242.56C516.357 237.06 572.027 239.44 628.547 239.08C681.307 238.74 734.156 232.43 786.906 236.5C837.536 240.41 860.206 281.91 901.626 303.96C962.466 336.35 1034.69 282.02 1087.07 253.92C1115.24 238.81 1141.9 223.25 1174.52 222.47C1199.74 221.87 1224.15 228.72 1248.4 234.8C1307.13 249.52 1367.23 227.9 1425.7 243.03C1461.61 252.33 1495.71 269.77 1531.2 280.91C1558.92 289.6 1584.81 290.45 1613.59 287.75C1651.46 284.2 1686.28 272.43 1722.2 260.88C1761.13 248.37 1799.53 245.08 1840.35 246.72"
						stroke="url(#paint28_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M336.457 250.09C381.267 247.68 425.557 240.76 470.187 236.5C524.617 231.31 578.307 233.55 632.827 233.64C686.367 233.73 740.007 227.82 793.517 231.58C846.356 235.29 865.166 275.73 907.116 299.81C967.676 334.57 1037.45 276.31 1088.13 246.83C1115.3 231.02 1142.38 214.51 1174.75 214.44C1199.05 214.39 1222.26 222.28 1245.56 228.14C1305.53 243.23 1367.25 217.67 1426.91 234.2C1462.55 244.08 1494.85 264.42 1530.03 275.84C1556.24 284.35 1583.52 282.01 1610.54 278.97C1646.14 274.97 1679.21 264.47 1713.19 253.85C1751.94 241.74 1790.06 239.03 1830.57 240.9"
						stroke="url(#paint29_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M343.836 243.6C402.036 240.66 459.526 230.33 517.736 227.45C588.956 223.93 660.686 227.48 732.046 225.79C763.555 225.05 802.485 220.62 832.395 232.34C859.475 242.95 875.525 267.8 897.335 285.49C971.155 345.35 1054.42 254.92 1119.45 221.76C1183.54 189.08 1244.14 231.01 1311.02 225.35C1353.03 221.8 1395.42 214.32 1436.41 228.48C1479.49 243.36 1517.14 275.29 1564.56 273.01C1610.86 270.79 1654.75 262.64 1698.93 249.06C1740.93 236.15 1780.84 232.49 1824.84 234.81"
						stroke="url(#paint30_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M347.887 236.87C406.147 234.13 463.717 224.04 521.997 221.54C593.606 218.47 665.726 221.96 737.466 220.63C769.006 220.05 808.086 215.36 837.616 228.45C863.896 240.09 879.406 265.02 901.556 282.07C974.746 338.43 1055.5 246.69 1118.99 215.16C1148.08 200.71 1174.74 197.34 1206.27 204.52C1239.84 212.16 1269.38 220.87 1304.41 218.05C1346.37 214.68 1388.67 205.43 1430.13 217.77C1474.23 230.89 1511.67 266.09 1559.54 263.45C1606.33 260.87 1651.41 254.36 1696.44 241.51C1738.93 229.39 1778.75 225.73 1823.05 228.53"
						stroke="url(#paint31_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M351.576 230.32C409.146 227.8 466.096 218.22 523.696 216.07C595.226 213.4 667.216 217.15 738.866 216.22C770.486 215.81 808.906 211.04 838.586 223.93C864.356 235.13 880.366 258.68 902.406 274.95C975.945 329.26 1054.72 240.19 1118.69 208.7C1147.91 194.31 1174.22 191.52 1205.74 198.78C1239.11 206.46 1268.94 214.61 1303.71 211.47C1345.31 207.71 1387.19 197.65 1428.48 209.54C1472.29 222.15 1509.67 256.62 1557.04 253.81C1603.94 251.03 1649.37 247.07 1694.85 235.04C1737.72 223.7 1777.17 219.46 1821.74 222.76"
						stroke="url(#paint32_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M355.316 224.04C412.486 221.72 469.096 212.58 526.316 210.88C598.366 208.73 670.866 212.73 743.016 212.41C774.876 212.27 812.666 207.61 842.396 221.21C864.086 231.13 879.486 249.41 898.276 263.44C926.376 284.43 955.076 290 988.106 276.53C1034.59 257.57 1073.45 224.1 1118.38 202.16C1149.95 186.75 1177.67 187.01 1210.91 195.46C1245.45 204.24 1276.26 209.45 1312.08 204.92C1348.24 200.34 1385.1 192.13 1421.38 200.69C1465.64 211.14 1503.56 246.51 1550.44 244.59C1598.24 242.63 1645.65 241.5 1692.23 229.93C1735.83 219.1 1775.07 213.82 1820.36 217.52"
						stroke="url(#paint33_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M359.107 218.02C415.177 215.95 470.747 207.38 526.877 206.05C598.907 204.34 671.337 208.86 743.437 209.2C775.477 209.35 812.637 204.88 842.857 217.41C864.157 226.24 880.157 242.73 899.037 255.38C927.607 274.53 956.137 279.59 988.637 266.73C1034.46 248.6 1073.3 216.72 1117.81 195.99C1149.75 181.11 1177.13 182.57 1210.24 191.49C1244.65 200.77 1275.34 205.57 1311.07 200.35C1346.69 195.15 1383 186 1419 193.9C1462.9 203.53 1500.17 237.12 1546.44 235.39C1594.66 233.59 1642.84 236.99 1690.07 225.72C1734.12 215.21 1773.07 208.86 1818.87 212.81"
						stroke="url(#paint34_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M362.947 212.22C418.277 210.37 473.147 202.31 528.537 201.44C596.787 200.36 665.297 205.26 733.577 206.28C766.627 206.77 804.127 202.55 836.177 211.65C861.437 218.82 880.287 236.17 902.777 248.61C979.747 291.19 1048.7 219.23 1117.05 190.1C1147.79 177 1173.39 177.79 1204.96 186.77C1238.95 196.44 1268.9 203.01 1304.53 197.51C1345.47 191.19 1385.88 178.66 1427.16 190.39C1467.91 201.98 1501.43 226.89 1545.04 226.26C1592.45 225.58 1640.27 233.32 1686.92 222.57C1731.78 212.23 1770.56 204.37 1817.3 208.49"
						stroke="url(#paint35_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M366.816 206.64C420.766 205.04 474.316 197.59 528.316 197.09C596.566 196.46 665.026 201.88 733.276 203.76C766.786 204.68 803.506 201.14 836.296 208.76C860.976 214.5 880.856 229.1 903.516 239.47C981.566 275.18 1046.45 211.44 1116.33 184.14C1147.54 171.95 1172.86 174.15 1204.19 183.94C1238.14 194.54 1267.64 201.01 1303.4 194.62C1343.58 187.44 1383.09 173.65 1424.04 184.43C1464.31 195.03 1497.23 217.74 1539.96 217.59C1588.17 217.41 1636.67 230.91 1684.28 220.04C1729.65 209.68 1768.13 200.39 1815.57 204.57"
						stroke="url(#paint36_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M370.717 201.24C423.697 199.85 476.317 192.96 529.357 192.9C598.257 192.83 667.306 198.88 736.146 201.64C770.086 203 806.216 200.07 839.606 206.71C863.566 211.48 884.486 223.48 907.406 231.21C984.356 257.15 1046.15 203.04 1115.65 178.13C1178.67 155.55 1232.54 205.26 1296.85 193.39C1336.3 186.11 1375.13 169.56 1415.89 177.77C1458.8 186.42 1492.96 209.34 1537.88 209.05C1586.05 208.74 1632.86 229.07 1680.67 218.3C1726.89 207.89 1765.19 196.73 1813.73 200.91"
						stroke="url(#paint37_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M374.646 196.02C426.106 195.21 477.216 188.59 528.736 188.87C597.716 189.25 666.696 195.96 735.546 199.62C769.966 201.45 805.426 199.46 839.586 204C863.206 207.14 885.116 215.88 908.166 221.35C935.086 227.74 962.296 229.58 989.246 222.12C1032.78 210.07 1071.82 185.89 1114.83 172.42C1178.77 152.39 1230.74 205.4 1295.66 191.68C1334.33 183.51 1372.14 165.65 1412.52 172.78C1434.06 176.58 1454.21 186.02 1474.85 192.86C1494.66 199.42 1511.59 200.72 1532.2 201.01C1581.36 201.7 1628.8 228.04 1677.7 216.9C1695.5 212.84 1712.51 205.97 1730.28 201.75C1757.27 195.34 1784.21 195.25 1811.73 197.53"
						stroke="url(#paint38_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M378.617 190.95C429.017 190.3 479.097 184.26 529.547 184.95C599.307 185.9 668.847 193.44 738.417 197.92C794.277 201.52 848.237 202.31 903.577 210.65C934.696 215.34 965.897 216.79 996.526 208.43C1036.62 197.49 1073.66 177.39 1113.89 166.97C1146.92 158.42 1172.36 165.16 1202.94 178.31C1237.34 193.1 1266.36 198.08 1303.07 188C1336.11 178.92 1369.3 163.6 1404.41 167.45C1445.66 171.96 1480.68 192.48 1522.96 193.17C1574.28 194.02 1622.72 227.56 1673.72 216.01C1691.92 211.89 1709.16 204.44 1727.19 199.72C1754.44 192.59 1781.6 192.09 1809.56 194.31"
						stroke="url(#paint39_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M382.596 186.02C431.446 185.52 480.006 180.1 528.906 181.08C598.785 182.48 668.235 190.72 737.865 196.07C793.245 200.32 848.815 198.84 904.295 201.39C935.195 202.81 966.855 203.96 997.145 196.86C1036.52 187.63 1073.46 169.85 1113.05 161.5C1146.57 154.42 1171.78 162.67 1202.05 176.73C1236.35 192.66 1264.83 197.82 1301.67 186.59C1333.97 176.75 1366.28 160.31 1401.01 163.18C1421.46 164.87 1440.98 172.62 1460.52 178.28C1480 183.93 1497.03 185.08 1517.08 185.7C1569.6 187.32 1618.27 227.19 1670.5 215.14C1688.95 210.88 1706.28 202.9 1724.46 197.73C1751.79 189.96 1779.01 189.08 1807.22 191.19"
						stroke="url(#paint40_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M386.596 181.19C434.426 180.8 481.976 175.94 529.856 177.25C600.605 179.19 670.625 188.39 741.045 194.44C797.205 199.26 852.225 195.07 908.335 192.11C935.185 190.7 962.855 191.37 989.425 187.07C1031.24 180.31 1070.58 163 1112.32 155.96C1148.97 149.78 1175.22 162.68 1207.35 178.23C1237.02 192.59 1263.14 196.81 1295.13 186.87C1332.19 175.35 1367.14 154.47 1407.32 160.17C1441.95 165.09 1472.26 177.57 1507.73 178.43C1543.19 179.29 1575.21 193.94 1607.53 206.84C1627.8 214.93 1644.88 219.41 1666.28 214.52C1685.13 210.21 1702.64 201.63 1721.06 195.94C1748.62 187.42 1776.01 186.09 1804.66 188.11"
						stroke="url(#paint41_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M390.607 176.46C437.007 176.14 483.127 171.83 529.567 173.37C600.467 175.71 670.347 185.59 740.847 192.38C798.877 197.97 851.867 191.02 909.117 182.98C936.047 179.2 963.237 179.38 990.097 175.91C1031.17 170.6 1070.42 155.74 1111.54 150.71C1148.61 146.17 1174.54 160.41 1206.46 176.78C1235.98 191.92 1261.76 196.32 1293.76 185.53C1330.05 173.3 1363.95 151.33 1403.76 155.96C1437.47 159.88 1467.75 170.58 1502.07 171.63C1538.27 172.74 1570.9 189.48 1603.19 204.01C1623.66 213.22 1640.83 218.73 1662.89 213.62C1681.97 209.2 1699.52 200.1 1718.04 193.96C1745.63 184.81 1773.02 183.1 1801.89 185.03"
						stroke="url(#paint42_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M394.627 171.8C436.637 171.54 478.467 167.9 520.497 169.1C590.897 171.12 660.137 180.78 729.967 188.84C766.417 193.05 803.126 196.54 839.526 190.51C861.466 186.88 882.686 180.54 904.316 175.53C935.246 168.37 965.796 168.49 997.066 164.64C1035.19 159.96 1072.34 148.59 1110.68 145.64C1145.96 142.93 1170.61 155.68 1200.64 172.37C1232.69 190.18 1258.85 195.91 1294 183.19C1327.48 171.08 1358.97 149.4 1395.99 151.44C1415.08 152.5 1433.55 158.43 1452.21 162.07C1468.45 165.24 1483.79 164.43 1500.08 165.58C1535.07 168.05 1565.99 184.78 1596.81 200.06C1619.4 211.25 1637.05 218.06 1661.98 211.75C1680.12 207.16 1696.77 198.21 1714.34 191.97C1742.09 182.11 1769.59 179.97 1798.86 181.84"
						stroke="url(#paint43_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M398.656 167.2C439.956 166.93 481.086 163.63 522.406 165.06C593.696 167.52 663.446 177.97 734.086 186.55C770.486 190.97 807.396 194.6 843.546 186.65C865.766 181.76 886.756 173.11 908.516 166.72C937.956 158.07 966.516 157.9 996.756 155.14C1034.7 151.68 1071.99 142.15 1110.11 140.49C1143.1 139.05 1167.1 150.98 1194.77 167.33C1228.65 187.35 1256.34 195.85 1294.23 180.71C1324.85 168.47 1354.4 147.59 1388.57 147.35C1407.26 147.22 1425.67 152.52 1443.89 155.9C1462.42 159.34 1480.07 157.83 1498.59 159.72C1555.51 165.53 1603.67 225.78 1661.18 209.55C1678.28 204.73 1693.97 196.04 1710.54 189.78C1738.43 179.25 1765.97 176.71 1795.61 178.54"
						stroke="url(#paint44_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M402.686 162.63C443.035 162.3 483.195 159.3 523.555 160.83C594.885 163.54 664.375 174.3 734.985 183.41C771.165 188.08 808.525 192.17 844.335 183.11C866.875 177.41 887.565 166.93 909.405 159.27C938.655 149.01 966.735 148.82 997.245 146.57C1034.73 143.81 1071.87 136.08 1109.53 135.53C1142.67 135.04 1166.37 147.82 1193.86 164.67C1227.36 185.21 1255.15 193.87 1292.83 178.2C1322.98 165.66 1351.85 144.15 1385.68 143.37C1403.94 142.95 1422.07 147.75 1439.93 150.76C1458.22 153.85 1475.84 152.25 1494.09 154.13C1527.74 157.59 1557.81 176.2 1586.59 192.4C1610.93 206.1 1630.04 215.35 1657.79 207.47C1674.97 202.59 1690.61 193.55 1707.15 187.01C1734.96 176 1762.36 173.19 1792.1 175.03"
						stroke="url(#paint45_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M406.717 158.08C446.737 157.65 486.537 154.83 526.567 156.49C598.867 159.48 668.656 170.96 740.196 180.28C772.176 184.45 805.866 188.25 837.726 181.17C864.786 175.16 888.126 160.79 913.816 151.17C941.236 140.9 967.796 141.18 996.686 139.27C1034.11 136.79 1071.4 130.46 1108.97 130.77C1175.47 131.32 1218.24 203.9 1286.54 177.15C1321.68 163.39 1352.53 137.52 1392.12 139.53C1423.67 141.13 1455.06 146.75 1486.67 148.67C1521.12 150.76 1551.71 170.38 1580.67 187.37C1605.17 201.75 1624.99 212.83 1653.54 204.99C1670.91 200.22 1686.57 190.83 1703.12 183.97C1730.97 172.42 1758.31 169.33 1788.31 171.23"
						stroke="url(#paint46_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M410.707 153.52C450.667 152.93 490.327 150.18 530.307 151.9C603.337 155.06 673.777 167.24 746.077 176.51C777.797 180.58 811.337 183.83 842.717 176.04C866.437 170.15 886.897 157.23 909.046 147.55C939.906 134.07 970.506 134.96 1003.47 132.54C1038.49 129.97 1073.54 125.43 1108.71 125.89C1142.81 126.34 1165.68 140.93 1193.42 158.56C1227.13 179.98 1255.89 186.52 1293.37 169.7C1323.36 156.24 1351.32 134.66 1385.58 135.03C1417.08 135.36 1448.3 143.01 1479.96 143.87C1520.03 144.95 1553.74 169.67 1587.04 189.03C1607.76 201.08 1625.42 207.87 1649.29 201.63C1666.8 197.05 1682.43 187.41 1698.94 180.33C1726.81 168.37 1754.03 165.08 1784.2 167.1"
						stroke="url(#paint47_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M414.426 149.07C454.126 148.32 493.476 145.39 533.196 147.11C605.926 150.27 676.215 162.41 748.245 171.51C779.705 175.49 813.115 179.08 844.175 170.95C867.825 164.77 888.105 151.55 910.105 141.53C940.815 127.53 970.695 128.02 1003.74 126.07C1038.6 124.02 1073.51 120.12 1108.48 121.09C1142.23 122.02 1165.04 136.49 1192.57 153.96C1226.17 175.29 1254.41 181.87 1291.8 165.2C1321.51 151.96 1349.5 130.5 1383.38 130.84C1414.66 131.15 1445.81 138.81 1477.25 139.78C1517.13 141 1550.75 165.39 1583.93 184.68C1604.57 196.67 1621.97 203.58 1645.73 197.25C1663.05 192.63 1678.54 183.08 1694.9 176.09C1722.66 164.23 1749.87 161.02 1779.9 162.96"
						stroke="url(#paint48_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M418.047 144.76C457.917 143.84 497.337 140.73 537.237 142.52C605.327 145.57 671.567 155.85 738.956 165.1C774.716 170.01 813.696 175.57 848.926 164.95C872.306 157.9 892.386 144.01 914.496 134.11C941.686 121.95 966.646 121.33 995.796 120.24C1033.21 118.84 1070.73 114.85 1108.18 116.58C1174.94 119.66 1215.85 191.47 1285.41 162.94C1315.21 150.72 1343.69 128.01 1377.1 126.83C1394.97 126.2 1413.08 130.86 1430.64 133.46C1446.61 135.83 1462.1 135.08 1478.06 136.31C1515.66 139.21 1546.93 160.57 1578.59 179.11C1599.78 191.52 1616.54 199.57 1641.18 193.14C1658.47 188.63 1673.91 179.14 1690.23 172.18C1718.07 160.31 1745.37 157.12 1775.47 159.01"
						stroke="url(#paint49_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M421.547 140.58C461.577 139.49 501.047 136.2 541.137 138.06C609.727 141.24 676.647 151.97 744.586 160.78C776.266 164.89 810.886 169.93 842.366 162.12C870.206 155.22 892.986 138.2 918.876 126.84C946.386 114.77 972.936 114.95 1002.46 114.06C1037.61 113 1072.9 110.23 1108.06 112.16C1171.02 115.61 1214.16 184.5 1279.05 160.58C1312.01 148.43 1341.17 124.29 1377.47 123.46C1393.23 123.1 1408.93 126.78 1424.42 129.18C1442.77 132.02 1460.62 130.93 1478.94 132.64C1513.57 135.87 1544.03 156.48 1573.17 173.61C1594.8 186.33 1611.53 195.45 1636.54 189.09C1653.79 184.71 1669.21 175.35 1685.46 168.4C1713.32 156.47 1740.83 153.42 1770.95 155.26"
						stroke="url(#paint50_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M424.936 136.55C464.656 135.3 503.735 131.84 543.515 133.7C611.795 136.89 678.555 147.55 746.225 156.05C777.625 160 812.185 165.26 843.345 157.31C871.145 150.22 893.845 133.04 919.635 121.42C947.075 109.06 973.075 108.98 1002.65 108.48C1037.66 107.88 1072.84 105.71 1107.82 108.08C1170.06 112.29 1213.46 179.66 1277.65 156.4C1310.34 144.55 1339.51 120.91 1375.4 120.09C1391.05 119.74 1406.67 123.41 1422.06 125.78C1440.31 128.58 1458.08 127.51 1476.3 129.23C1510.67 132.46 1540.94 152.65 1569.93 169.6C1591.41 182.16 1607.92 191.26 1632.7 184.91C1649.74 180.54 1665.01 171.35 1681.11 164.55C1708.87 152.82 1736.36 149.93 1766.3 151.76"
						stroke="url(#paint51_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M428.217 132.65C468.067 131.22 507.177 127.56 547.107 129.53C615.937 132.92 683.186 143.82 751.466 151.84C782.516 155.48 817.086 160.44 847.676 151.58C871.896 144.56 892.186 130.1 914.506 119.05C941.216 105.83 965.416 103.13 994.616 103.18C1032.2 103.24 1069.98 101.11 1107.49 104.25C1143.11 107.23 1167.12 124.01 1197.13 141.6C1226.82 159 1252.06 162.36 1284.38 149.17C1314.1 137.04 1342.49 116.18 1375.81 116.74C1406.93 117.26 1438.99 123.79 1470.14 125.7C1504.69 127.82 1535.41 147.39 1564.42 164.32C1585.46 176.59 1603.44 187.04 1627.91 180.98C1644.91 176.78 1660.11 167.66 1676.17 161.01C1704.09 149.44 1731.54 146.58 1761.58 148.42"
						stroke="url(#paint52_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M431.396 128.88C469.606 127.35 507.096 123.5 545.396 125.25C616.476 128.51 685.966 139.98 756.546 147.65C787.876 151.06 821.256 155.65 851.796 145.8C875.776 138.07 896.106 123.12 918.606 112.29C946.186 99.0102 970.786 97.8202 1001.17 98.0702C1036.53 98.3702 1072.1 97.2502 1107.36 100.49C1170.25 106.27 1212.74 171.19 1278.14 147.16C1307.94 136.21 1336.85 114.56 1369.67 113.62C1400.68 112.73 1432.71 121.33 1463.96 122.46C1498.89 123.72 1529.45 142.06 1558.85 159.11C1579.95 171.34 1598.19 182.97 1623.04 177.07C1639.98 173.05 1655.14 164.08 1671.13 157.54C1699.14 146.09 1726.68 143.36 1756.77 145.23"
						stroke="url(#paint53_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M434.477 125.25C472.327 123.57 509.376 119.53 547.336 121.29C618.036 124.56 687.376 135.82 757.646 143.12C788.796 146.35 821.966 151.04 852.306 141.2C876.296 133.42 896.656 118.45 919.146 107.51C946.686 94.1198 970.876 92.6698 1001.31 93.2698C1036.56 93.9698 1072.02 93.4098 1107.14 97.0298C1169.28 103.44 1212.2 166.38 1276.86 143.35C1306.47 132.8 1335.32 111.83 1367.77 110.93C1398.59 110.07 1430.42 118.52 1461.48 119.6C1496.07 120.81 1526.4 138.65 1555.61 155.4C1576.5 167.38 1594.53 178.83 1619.06 173.03C1635.78 169.08 1650.79 160.33 1666.62 154C1694.55 142.84 1722.06 140.35 1751.94 142.25"
						stroke="url(#paint54_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M437.447 121.75C473.557 119.99 508.977 115.78 545.207 117.26C613.067 120.04 679.927 130.22 747.347 137.49C778.627 140.87 813.977 147 844.927 138.95C870.037 132.42 891.017 117.78 913.717 105.99C940.157 92.2699 963.607 87.4299 993.197 88.4699C1031.04 89.7999 1069.12 89.4499 1106.78 93.8199C1139.33 97.5899 1162.69 111.46 1190.65 127.37C1220.44 144.32 1244.05 150.4 1277.18 138.96C1307.46 128.5 1335.43 109.28 1368.33 108.85C1398.98 108.45 1431.82 115.06 1462.51 117.33C1497.09 119.89 1526.21 137.31 1555.84 153.8C1576.9 165.53 1593.27 174.57 1617.45 168.37C1634.13 164.09 1649.31 155.35 1665.36 149.33C1692.09 139.3 1718.7 137.61 1747.01 139.47"
						stroke="url(#paint55_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M440.307 118.37C476.457 116.45 511.827 112.06 548.107 113.61C616.456 116.53 683.956 126.87 751.936 133.58C783.086 136.65 818.026 142.4 848.726 133.53C873.716 126.31 894.646 111.29 917.606 99.76C945.806 85.61 968.356 83.07 999.636 84.37C1035.29 85.85 1071.19 86.3 1106.64 90.7C1139.08 94.73 1162.47 108.32 1190.63 124.2C1220.22 140.88 1244.42 146.49 1277.53 134.94C1307.48 124.5 1336.16 105.53 1368.88 106.4C1382.23 106.75 1395.87 109.68 1409.04 111.59C1427.4 114.25 1445.27 113.24 1463.61 114.79C1497.4 117.64 1527.17 136.35 1556.13 152.28C1576.53 163.51 1592.69 170.34 1615.79 163.81C1631.12 159.48 1645.25 151.69 1660.17 146.22C1687.01 136.38 1713.71 134.88 1742.05 136.81"
						stroke="url(#paint56_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M443.076 115.13C478.836 113.07 513.726 108.49 549.636 110.04C617.636 112.97 684.926 123.12 752.626 129.35C783.586 132.2 818.346 137.91 848.836 129.19C873.876 122.03 894.916 107.1 917.916 95.5901C946.116 81.4801 968.416 78.7101 999.706 80.3201C1035.27 82.1501 1071.08 83.1001 1106.41 87.8401C1138.43 92.1301 1161.93 105.27 1189.96 120.71C1219.37 136.92 1243.47 142.48 1276.33 131.54C1306.12 121.62 1334.73 103.48 1367.07 104.36C1380.33 104.72 1393.88 107.58 1406.97 109.43C1425.22 112.01 1442.97 110.98 1461.2 112.44C1494.57 115.11 1524.04 133.2 1552.74 148.77C1572.89 159.7 1588.84 166.33 1611.59 160.02C1626.72 155.83 1640.72 148.3 1655.48 143.08C1682.25 133.6 1708.92 132.36 1737.07 134.37"
						stroke="url(#paint57_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M445.746 112C481.536 109.77 516.346 105.02 552.306 106.64C620.786 109.74 688.676 119.89 756.946 125.52C788.026 128.08 822.146 133.27 852.376 123.88C877.266 116.15 898.506 100.9 921.815 89.76C947.835 77.32 970.295 74.68 998.905 76.44C1034.65 78.65 1070.56 80.11 1106.05 85.18C1163.71 93.41 1210.3 147 1270.31 129.73C1300.22 121.12 1329.27 102.73 1361.18 102.29C1391.83 101.88 1424.43 108.61 1455.24 110.02C1488.75 111.55 1518.47 128.74 1547.25 144.19C1567.83 155.24 1583.22 162.64 1606.57 156.5C1622.94 152.2 1638.01 143.99 1654.11 138.87C1679.76 130.71 1705.43 130.06 1732.1 132.06"
						stroke="url(#paint58_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M448.316 109C484.156 106.61 518.836 101.68 554.866 103.39C623.826 106.66 692.296 116.71 761.126 121.73C790.346 123.86 821.496 128.4 850.036 120.27C873.746 113.51 894.056 100.01 915.866 89.0601C973.786 59.9901 1043.79 73.2001 1105.91 82.6501C1164.54 91.5601 1209.49 142.61 1270.76 125.9C1298.81 118.25 1325.8 102.38 1355.31 100.78C1386.13 99.1101 1418.18 107.35 1449.27 107.94C1483.03 108.58 1512.65 124.37 1541.73 139.75C1562.68 150.83 1578.01 158.87 1601.5 153.05C1617.79 149.02 1632.82 141.06 1648.84 136.15C1674.6 128.24 1700.38 127.81 1727.07 129.92"
						stroke="url(#paint59_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M450.787 106.11C486.217 103.58 520.357 98.47 555.997 100.18C624.627 103.47 692.817 113.2 761.377 117.7C790.447 119.61 821.417 124.1 849.817 116.2C873.597 109.59 894.067 96.26 915.987 85.42C974.307 56.57 1043.41 70.33 1105.69 80.31C1163.58 89.59 1209.2 138.17 1269.69 122.76C1297.59 115.65 1324.5 100.72 1353.69 99.27C1384.39 97.75 1416.08 105.66 1447.04 106.09C1480.33 106.56 1509.62 121.65 1538.44 136.58C1559.1 147.28 1574.24 154.97 1597.31 149.44C1613.38 145.59 1628.3 137.99 1644.14 133.36C1669.86 125.85 1695.6 125.69 1722.12 127.91"
						stroke="url(#paint60_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M453.166 103.35C488.636 100.66 522.626 95.3202 558.346 97.1702C622.456 100.5 686.216 109.07 750.306 113.1C781.656 115.07 816.496 120.82 847.396 112.93C873.416 106.29 895.826 91.6102 919.765 80.1202C975.965 53.1402 1045.96 68.4002 1105.31 78.1702C1163.06 87.6802 1209.88 134.55 1270.22 119.45C1300.3 111.92 1329.31 96.9002 1361.01 98.0902C1390.09 99.1802 1419.18 103.32 1448.3 104.68C1479.05 106.12 1505.99 118.74 1532.99 132.42C1553.38 142.75 1569.5 151.19 1592.27 146.11C1608.26 142.54 1623.15 135.2 1638.91 130.84C1664.72 123.69 1690.61 123.72 1717.15 126.06"
						stroke="url(#paint61_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M455.447 100.7C491.027 97.83 524.747 92.34 560.607 94.28C625.227 97.78 689.477 106.15 754.137 109.56C785.217 111.2 820.207 116.44 850.627 108.09C874.767 101.46 896.087 88.12 918.527 77.5C976.187 50.21 1044.36 65.47 1105.18 76.13C1159.59 85.66 1207.65 129.16 1264.3 117.64C1294.58 111.48 1323.85 96.6 1355.27 96.71C1386.29 96.82 1418.64 101.46 1449.68 102.99C1477.46 104.36 1503.05 116.3 1527.58 128.4C1547.68 138.32 1564.68 147.47 1587.26 142.82C1603.17 139.55 1618.04 132.51 1633.71 128.39C1659.65 121.57 1685.65 121.86 1712.22 124.33"
						stroke="url(#paint62_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M457.637 98.1702C492.797 95.1702 525.917 89.5002 561.387 91.4502C625.737 94.9802 689.666 103.07 754.106 105.91C785.046 107.27 819.816 112.37 850.126 104.37C874.376 97.9702 895.906 84.8702 918.496 74.4202C976.606 47.5302 1043.96 63.2102 1104.96 74.3002C1158.79 84.0902 1207.33 125.02 1263.36 114.77C1293.49 109.26 1322.67 95.5302 1353.75 95.7402C1384.69 95.9502 1416.57 100.26 1447.54 101.54C1474.88 102.67 1500.16 113.99 1524.43 125.63C1544.25 135.13 1561.01 143.77 1583.13 139.45C1598.82 136.38 1613.58 129.73 1629.08 125.94C1655 119.59 1680.94 120.17 1707.37 122.77"
						stroke="url(#paint63_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M459.736 95.7401C494.976 92.5701 527.876 86.7501 563.466 88.8001C628.356 92.5401 692.716 100.23 757.746 102.46C787.116 103.46 818.506 107.87 847.266 101.15C872.116 95.3501 894.126 82.6101 917.096 72.0401C976.066 44.9101 1042.9 61.4101 1104.59 72.6101C1162.04 83.0401 1210.39 122.44 1270.44 110.5C1296.23 105.37 1321.61 95.4801 1348.15 94.8901C1379.14 94.2001 1410.72 99.8801 1441.89 100.13C1473.69 100.39 1501.69 114.17 1529.99 127.01C1546.29 134.41 1560.33 139.45 1578.2 136.28C1593.81 133.51 1608.54 127.2 1623.97 123.67C1650.03 117.7 1676.08 118.55 1702.55 121.3"
						stroke="url(#paint64_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M461.746 93.4298C496.566 90.1298 528.836 84.1298 564.036 86.1898C628.686 89.9698 692.666 97.3198 757.506 98.9498C786.756 99.6898 817.936 103.95 846.606 97.5898C871.576 92.0498 893.816 79.6098 916.956 69.2598C976.465 42.6398 1042.42 59.5198 1104.35 71.0598C1161.12 81.6398 1210.22 118.4 1269.5 108.03C1295.17 103.54 1320.46 94.6098 1346.73 94.1898C1377.65 93.6898 1408.76 98.9698 1439.86 98.9698C1471.12 98.9698 1498.74 111.94 1526.75 124.23C1542.78 131.26 1556.66 135.95 1574.14 133.06C1589.54 130.51 1604.18 124.61 1619.43 121.4C1645.47 115.93 1671.48 117.08 1697.81 119.98"
						stroke="url(#paint65_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M463.666 91.2298C496.736 87.9298 527.316 81.6998 560.776 83.4898C627.726 87.0898 693.786 94.5298 760.956 95.5998C788.076 96.0298 816.876 99.9598 843.676 94.7298C871.056 89.3898 895.356 75.6098 920.786 64.9098C978.745 40.5298 1044.55 57.7298 1104.26 69.6298C1157.75 80.2898 1208.11 113.64 1263.78 106.35C1294.06 102.38 1323.41 92.4598 1354.27 93.8798C1381.24 95.1198 1407.26 98.6898 1434.3 98.0198C1465.86 97.2398 1493.2 108.57 1521.62 120.69C1537.68 127.54 1551.58 132.63 1569.29 130.03C1584.61 127.78 1599.24 122.24 1614.43 119.32C1640.63 114.27 1666.75 115.71 1693.13 118.76"
						stroke="url(#paint66_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M465.496 89.1302C498.156 85.7202 528.116 79.3002 561.196 81.1002C627.946 84.7402 693.546 91.8102 760.536 92.2602C787.566 92.4402 816.136 96.2302 842.876 91.3602C870.396 86.3402 894.986 72.9202 920.616 62.4802C979.055 38.6802 1044.14 56.1102 1104.04 68.3502C1157.02 79.1702 1207.87 109.85 1262.94 103.96C1293.05 100.74 1322.36 92.0202 1352.89 93.4302C1379.71 94.6702 1405.55 97.9802 1432.42 97.1102C1463.43 96.1002 1490.39 106.61 1518.53 118.14C1534.33 124.61 1548.07 129.28 1565.36 126.96C1580.48 124.93 1595.01 119.82 1610.04 117.23C1636.23 112.72 1662.31 114.45 1688.57 117.66"
						stroke="url(#paint67_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M467.246 87.1298C497.966 83.7698 526.496 77.1498 557.626 78.5798C621.756 81.5398 684.536 89.2998 748.916 89.0598C780.416 88.9398 814.776 93.4798 845.886 87.3798C869.596 82.7298 891.406 72.1098 913.586 62.9798C944.016 50.4498 967.635 45.8998 1000.07 50.0698C1034.81 54.5298 1069.31 60.4098 1103.66 67.1898C1157.39 77.7898 1208 106.15 1263.71 101.46C1291.71 99.0998 1319.24 92.1398 1347.49 92.9098C1376.51 93.7098 1404.89 96.5598 1433.99 96.3498C1462.53 96.1398 1487.43 104.61 1513.6 114.9C1531.16 121.81 1544.79 126.17 1563.72 123.63C1577.72 121.75 1591.28 117.48 1605.24 115.31C1631.59 111.21 1657.79 113.28 1684.12 116.65"
						stroke="url(#paint68_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M468.906 85.2399C499.216 81.7699 527.156 74.9699 557.906 76.4099C621.886 79.3999 684.176 86.9499 748.436 86.0399C779.876 85.5899 813.926 89.9399 844.996 84.2799C868.846 79.9399 890.906 69.6399 913.286 60.7699C943.776 48.6799 967.586 44.1099 999.926 48.4099C1034.66 53.0299 1069.1 59.2699 1103.44 66.1399C1156.7 76.8099 1207.83 102.57 1262.93 99.3099C1290.78 97.6599 1318.23 91.8299 1346.23 92.6899C1375.11 93.5799 1403.24 96.0799 1432.2 95.6099C1477.11 94.8699 1516.05 126.02 1559.91 120.77C1601.86 115.75 1636.72 109.97 1679.79 115.75"
						stroke="url(#paint69_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M470.486 83.4502C500.826 79.8302 528.506 72.8502 559.326 74.4002C593.636 76.1302 627.626 81.8002 661.906 83.5302C691.776 85.0302 721.726 83.7502 751.606 83.0402C781.166 82.3302 812.496 85.9102 841.676 81.5602C868.126 77.6102 892.306 66.3702 917.156 57.1202C945.296 46.6502 968.886 42.7602 998.946 46.8302C1033.95 51.5602 1068.86 57.7702 1103.39 65.2302C1156.29 76.6702 1208.99 99.5902 1263.76 97.2802C1289.52 96.1902 1315.09 91.9702 1340.93 92.4702C1372.32 93.0802 1402.5 95.4602 1433.94 94.6502C1477.7 93.5302 1515.18 123.03 1558.4 117.78C1599.22 112.81 1633.93 109.12 1675.59 114.97"
						stroke="url(#paint70_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M471.977 81.7498C501.917 78.0298 528.996 70.8698 559.446 72.4298C593.656 74.1798 627.556 80.0598 661.756 81.5898C691.486 82.9198 721.276 81.1998 750.996 80.1898C780.516 79.1798 811.526 82.5898 840.706 78.6498C867.306 75.0598 891.786 64.1898 916.846 55.2398C945.056 45.1698 968.806 41.3098 998.786 45.4598C1033.79 50.3098 1068.65 56.8398 1103.17 64.4098C1155.65 75.9198 1208.81 96.2398 1263.03 95.3698C1288.65 94.9598 1314.14 91.7798 1339.79 92.4398C1371.09 93.2498 1400.91 95.2198 1432.23 94.0798C1475.2 92.5198 1512.27 119.6 1554.73 115.14C1595.28 110.89 1630.37 108.22 1671.53 114.25"
						stroke="url(#paint71_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M473.396 80.16C503.425 76.28 530.165 68.97 560.755 70.64C593.015 72.41 624.985 77.78 657.235 79.5C689.495 81.22 721.855 78.66 754.085 77.28C781.685 76.1 809.995 79.2 837.365 76.27C866.515 73.15 893.365 61.14 920.895 52C947.295 43.24 970.125 40.15 997.545 44.03C1032.92 49.04 1067.82 56.45 1102.8 63.66C1153.78 74.18 1205.23 92.22 1257.55 93.48C1285.42 94.15 1313.22 91.57 1341.09 92.58C1370.3 93.64 1397.99 95.64 1427.16 93.79C1470.27 91.06 1508.04 116.22 1550.45 112.66C1591.03 109.25 1626.64 107.36 1667.62 113.61"
						stroke="url(#paint72_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M474.726 78.65C504.376 74.67 530.496 67.18 560.745 68.87C592.935 70.66 624.825 76.21 657.005 77.79C689.125 79.37 721.325 76.31 753.385 74.62C780.945 73.17 808.975 76.11 836.345 73.57C865.655 70.85 892.845 59.26 920.605 50.44C947.025 42.05 970.035 38.98 997.375 42.93C1032.76 48.04 1067.61 55.77 1102.59 63.04C1153.34 73.59 1204.96 89.1 1256.91 91.65C1312.32 94.37 1370.4 97.46 1425.61 93.43C1467.89 90.34 1505.35 113.01 1547.03 110.22C1587.37 107.51 1623.35 106.66 1663.89 113.08"
						stroke="url(#paint73_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M475.985 77.2399C505.275 73.1699 530.765 65.4999 560.685 67.1999C592.795 69.0199 624.625 74.7399 656.735 76.1799C688.725 77.6199 720.765 74.0699 752.655 72.0599C780.185 70.3299 807.935 73.1299 835.305 70.9899C864.785 68.6799 892.295 57.4999 920.305 48.9999C946.745 40.9699 969.945 37.9399 997.215 41.9499C1032.61 47.1499 1067.41 55.1999 1102.41 62.5099C1207.78 84.5299 1316.09 102.07 1424.13 93.1299C1465.57 89.6999 1502.8 109.91 1543.73 107.86C1583.85 105.85 1620.2 106.01 1660.34 112.6"
						stroke="url(#paint74_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M477.166 75.9198C506.636 71.6798 531.686 63.8598 561.836 65.7098C591.986 67.5698 621.856 72.8698 651.986 74.5698C684.046 76.3798 716.286 72.2798 748.216 69.9198C778.196 67.7098 808.586 70.8898 838.346 68.2098C863.866 65.9098 888.146 57.2998 912.506 49.9298C941.495 41.1598 966.056 36.6198 996.205 40.9998C1031.91 46.1898 1067.26 54.0698 1102.41 62.0898C1152.78 73.5798 1205.83 83.3698 1257.3 88.3698C1310 93.4898 1366.57 98.5498 1419.26 93.2998C1461.01 89.1398 1498.67 106.88 1539.84 105.63C1579.99 104.41 1616.93 105.4 1656.97 112.19"
						stroke="url(#paint75_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M478.265 74.6898C507.405 70.3498 531.805 62.3699 561.665 64.2399C591.755 66.1299 621.575 71.5798 651.664 73.1898C683.614 74.8998 715.714 70.2999 747.474 67.6399C777.514 65.1199 807.494 68.1999 837.324 65.9399C863.004 63.9899 887.554 55.7399 912.134 48.6499C941.154 40.2899 965.974 35.8299 996.034 40.2499C1031.75 45.4899 1067.06 53.6599 1102.23 61.7299C1152.47 73.2599 1205.56 80.6399 1256.75 86.8799C1309.24 93.2699 1365.29 98.8699 1417.94 93.1799C1458.86 88.7599 1496.36 104.06 1536.83 103.49C1576.82 102.93 1614.09 104.93 1653.81 111.88"
						stroke="url(#paint76_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M479.296 73.5401C508.116 69.1101 531.876 60.9601 561.456 62.8601C591.496 64.7901 621.276 70.3901 651.316 71.9101C683.156 73.5201 715.116 68.4401 746.726 65.4801C776.836 62.6501 806.396 65.6501 836.296 63.8001C862.135 62.1901 886.945 54.2901 911.755 47.5001C940.805 39.5401 965.875 35.1701 995.865 39.6001C1031.61 44.8901 1066.89 53.3401 1102.07 61.4501C1152.22 73.0101 1205.29 78.0401 1256.24 85.4801C1308.61 93.1201 1363.96 99.2401 1416.69 93.1101C1456.8 88.4501 1494.19 101.36 1533.97 101.44C1573.82 101.52 1611.41 104.5 1650.87 111.6"
						stroke="url(#paint77_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M480.256 72.4799C509.286 67.8799 532.616 59.6699 562.506 61.6599C592.916 63.6799 623.126 69.7599 653.566 70.8199C683.236 71.8499 712.916 67.0099 742.306 63.8099C773.016 60.4599 802.175 62.6899 832.745 61.7499C861.315 60.8699 888.565 52.2299 916.035 45.2299C942.565 38.4699 967.285 34.7199 994.555 38.7999C1030.72 44.1999 1066 53.6399 1101.72 61.1699C1152.84 71.9399 1205.76 75.7899 1257.33 84.4399C1310.95 93.4299 1364.56 98.2699 1418.75 92.7499C1456.76 88.8799 1492.76 98.7599 1530.57 99.4799C1570.6 100.25 1608.61 104.07 1648.17 111.37"
						stroke="url(#paint78_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M481.146 71.49C509.896 66.8 532.566 58.43 562.226 60.45C592.586 62.51 622.776 68.75 653.176 69.71C682.756 70.64 712.326 65.39 741.586 61.9C772.376 58.23 801.096 60.36 831.756 59.81C860.486 59.3 888.016 51.03 915.716 44.32C942.276 37.88 967.176 34.21 994.386 38.29C1030.58 43.72 1065.82 53.44 1101.57 60.98C1152.67 71.75 1205.49 73.38 1256.89 83.23C1310.52 93.51 1363.22 98.75 1417.64 92.73C1454.91 88.6 1490.9 96.32 1528.07 97.63C1568.02 99.04 1606.31 103.76 1645.72 111.19"
						stroke="url(#paint79_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M481.956 70.5899C510.436 65.7999 532.476 57.2899 561.906 59.3299C592.226 61.4299 622.396 67.8199 652.766 68.6899C682.276 69.5299 711.726 63.8699 740.866 60.1099C771.746 56.1199 800.016 58.1799 830.786 58.0099C859.666 57.8599 887.486 49.9499 915.406 43.5099C941.986 37.3799 967.066 33.7699 994.225 37.8699C1030.45 43.3199 1065.66 53.2999 1101.44 60.8499C1152.55 71.6299 1205.24 71.0999 1256.48 82.0999C1310.16 93.6299 1361.88 99.2599 1416.59 92.7399C1453.17 88.3799 1489.17 94.0899 1525.73 95.8699C1565.64 97.8199 1604.21 103.47 1643.51 111.03"
						stroke="url(#paint80_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M482.706 69.7698C510.936 64.8898 532.346 56.2398 561.576 58.3098C591.866 60.4498 622.026 66.9998 652.366 67.7798C681.806 68.5398 711.156 62.4798 740.176 58.4498C771.156 54.1498 798.976 56.1498 829.846 56.3598C858.876 56.5598 886.966 48.9998 915.096 42.8298C941.686 36.9998 966.935 33.4498 994.055 37.5398C1030.31 43.0098 1065.5 53.2398 1101.3 60.7898C1152.45 71.5698 1204.96 68.9698 1256.09 81.0898C1309.83 93.8298 1360.56 99.8098 1415.62 92.8098C1451.55 88.2498 1487.58 92.0398 1523.59 94.2598C1563.49 96.7198 1602.32 103.27 1641.56 110.95"
						stroke="url(#paint81_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M483.386 69.03C511.996 63.96 532.796 55.16 562.546 57.47C593.136 59.85 623.966 66.78 654.626 66.98C684.396 67.18 713.826 60.58 743.155 56.37C771.885 52.25 797.645 54.01 826.375 54.92C855.845 55.85 884.585 48.51 913.205 42.58C943.495 36.31 970.035 33.19 1000.85 38.38C1034.72 44.09 1067.96 53.09 1101.4 60.81C1150.59 72.18 1201.74 66.21 1251 78.9C1307.78 93.53 1359.65 100.71 1418.13 92.27C1490.17 81.87 1569.47 96.88 1639.9 110.87"
						stroke="url(#paint82_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M483.995 68.3502C512.405 63.1902 532.575 54.2502 562.175 56.6002C592.735 59.0302 623.595 66.1102 654.235 66.2302C683.965 66.3502 713.275 59.3702 742.515 54.9202C771.295 50.5402 796.725 52.2802 825.515 53.5002C855.125 54.7502 884.115 47.7302 912.935 42.0502C943.195 36.0802 969.984 32.9502 1000.69 38.1602C1034.6 43.9002 1067.81 53.1102 1101.29 60.8302C1150.56 72.1902 1201.52 64.3802 1250.7 77.9702C1307.63 93.7002 1358.37 101.3 1417.32 92.3902C1490.67 81.3002 1566.87 96.4502 1638.53 110.84"
						stroke="url(#paint83_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M484.546 67.7499C512.776 62.4999 532.326 53.4299 561.796 55.8199C592.326 58.2999 623.226 65.5199 653.846 65.5799C683.526 65.6299 712.756 58.2799 741.906 53.5999C770.736 48.9699 795.856 50.7199 824.695 52.2299C854.435 53.7899 883.665 47.0699 912.675 41.6099C942.895 35.9199 969.915 32.7699 1000.54 37.9799C1034.48 43.7499 1067.68 53.1599 1101.19 60.8599C1150.53 72.1899 1201.3 62.6699 1250.42 77.0999C1277.37 85.0099 1304.31 94.5499 1332.47 96.8399C1360.69 99.1299 1388.74 96.8799 1416.58 92.4999C1491.25 80.7799 1564.49 96.0299 1637.44 110.8"
						stroke="url(#paint84_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M485.026 67.2299C513.096 61.8899 532.046 52.6899 561.416 55.1299C591.936 57.6599 622.886 65.0299 653.496 65.0199C683.146 65.0199 712.286 57.2999 741.356 52.4099C770.246 47.5499 795.056 49.3099 823.956 51.1099C853.816 52.9699 883.276 46.5199 912.466 41.2599C942.636 35.8299 969.886 32.6399 1000.42 37.8399C1034.39 43.6299 1067.57 53.2499 1101.13 60.9199C1150.56 72.2299 1201.12 61.1299 1250.21 76.3299C1277.1 84.6599 1303.81 94.9399 1332.07 97.2699C1360.16 99.5899 1388.28 97.1199 1415.98 92.6399C1491.98 80.3399 1562.44 95.6699 1636.72 110.79"
						stroke="url(#paint85_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M485.447 66.76C513.377 61.33 531.757 52.02 561.037 54.49C591.557 57.07 622.557 64.58 653.167 64.53C682.797 64.49 711.857 56.42 740.847 51.33C769.797 46.24 794.307 48.06 823.267 50.12C853.247 52.25 882.917 46.05 912.267 40.97C942.397 35.76 969.827 32.53 1000.28 37.72C1034.29 43.52 1067.45 53.34 1101.05 60.99C1150.56 72.26 1200.94 59.73 1249.99 75.63C1276.84 84.34 1303.31 95.29 1331.68 97.66C1359.67 100 1387.84 97.34 1415.42 92.77C1492.75 79.97 1560.63 95.35 1636.29 110.78"
						stroke="url(#paint86_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M485.806 66.3702C513.626 60.8502 531.446 51.4202 560.666 53.9502C591.196 56.5902 622.245 64.2202 652.875 64.1502C682.485 64.0802 711.475 55.6802 740.405 50.4002C769.415 45.1002 793.645 47.0002 822.655 49.2902C852.735 51.6702 882.605 45.6902 912.105 40.7802C942.175 35.7702 969.785 32.4602 1000.16 37.6502C1034.19 43.4702 1067.34 53.4802 1100.98 61.1002C1150.57 72.3302 1200.77 58.5202 1249.8 75.0602C1276.63 84.1102 1302.85 95.6702 1331.34 98.0602C1359.25 100.4 1387.46 97.5702 1414.94 92.9502C1446.9 87.5802 1482.1 83.3702 1514.55 86.9002C1555.44 91.3402 1595.96 102.62 1636.17 110.81"
						stroke="url(#paint87_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M486.116 66.0401C513.836 60.4301 531.136 50.9001 560.316 53.4701C590.866 56.1601 621.986 63.9201 652.636 63.8401C682.236 63.7701 711.166 55.0401 740.046 49.5901C769.116 44.1001 793.066 46.1001 822.136 48.6101C852.316 51.2101 882.356 45.4301 912.006 40.6501C942.026 35.8001 969.776 32.4101 1000.07 37.5801C1034.15 43.4001 1067.28 53.6201 1100.96 61.2001C1150.63 72.3801 1200.67 57.4701 1249.68 74.5501C1276.51 83.9001 1302.47 96.0001 1331.1 98.4001C1358.96 100.74 1387.19 97.7501 1414.6 93.1101C1446.51 87.7101 1481.59 82.5201 1514.03 86.1201C1555.16 90.6801 1595.97 102.62 1636.45 110.83"
						stroke="url(#paint88_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M486.356 65.7699C514.016 60.0699 530.806 50.4399 559.976 53.0599C590.566 55.8099 621.746 63.6799 652.446 63.6099C682.046 63.5499 710.936 54.5199 739.766 48.9099C768.906 43.2399 792.596 45.3799 821.716 48.0699C851.976 50.8699 882.186 45.2599 911.946 40.5799C941.896 35.8699 969.776 32.3499 999.986 37.5199C1034.1 43.3499 1067.22 53.7599 1100.95 61.2999C1150.69 72.4199 1200.59 56.5799 1249.58 74.1199C1276.44 83.7399 1302.13 96.2899 1330.91 98.6899C1358.74 101.01 1386.97 97.8899 1414.35 93.2599C1446.3 87.8599 1481.25 81.8499 1513.76 85.4599C1555.19 90.0599 1596.32 102.62 1637.1 110.82"
						stroke="url(#paint89_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M486.537 65.5601C514.147 59.7801 530.467 50.0401 559.647 52.7201C590.277 55.5301 621.537 63.5101 652.287 63.4701C681.897 63.4301 710.747 54.1201 739.537 48.3701C768.737 42.5401 792.177 44.8401 821.357 47.6901C851.697 50.6601 882.047 45.1801 911.907 40.5701C941.797 35.9601 969.757 32.3001 999.896 37.4501C1034.05 43.2801 1067.15 53.8801 1100.92 61.3801C1150.72 72.4301 1200.5 55.8601 1249.48 73.7701C1276.38 83.6101 1301.8 96.5401 1330.74 98.9101C1358.56 101.19 1386.78 97.9701 1414.16 93.3801C1446.26 88.0001 1481.05 81.3401 1513.74 84.9201C1555.49 89.4901 1596.98 102.63 1638.1 110.8"
						stroke="url(#paint90_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M486.666 65.4198C514.256 59.5498 530.146 49.7298 559.346 52.4698C590.046 55.3498 621.386 63.4198 652.196 63.4298C681.826 63.4298 710.646 53.8498 739.406 47.9798C768.686 41.9998 791.876 44.5098 821.106 47.4898C851.506 50.5898 881.986 45.2198 911.926 40.6498C941.736 36.0998 969.765 32.2698 999.825 37.3998C1034.01 43.2298 1067.1 54.0198 1100.91 61.4698C1150.76 72.4498 1200.45 55.3498 1249.42 73.5198C1276.4 83.5298 1301.53 96.7598 1330.62 99.0898C1358.46 101.32 1386.65 98.0198 1414.06 93.5098C1446.41 88.1898 1481.03 81.0498 1513.98 84.5398C1556.07 88.9998 1597.98 102.68 1639.46 110.78"
						stroke="url(#paint91_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1624.95 107.61C1623.46 107.59 1621.96 107.63 1620.47 107.61C1680.35 110.17 1736.62 133.22 1778.58 176.02C1789.11 186.76 1798.5 196.77 1803.79 211.13C1806.48 218.44 1806.39 228.89 1810.79 235.16C1825.64 256.33 1870.99 262.39 1893.24 268.59C1923.59 277.05 1954.65 284.29 1983.94 296.08C1999.49 301.19 2013.39 309.11 2025.65 319.84C2031.67 329.21 2035.99 339.3 2038.62 350.12C2060.81 406.87 2115.08 435.18 2153.36 479.1"
						stroke="url(#paint92_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1610.27 104.65C1608.98 104.65 1607.66 104.61 1606.37 104.69C1666.97 110.86 1721.29 132.74 1764.53 176.19C1775.03 186.74 1784.79 196.26 1790.2 210.46C1793.04 217.9 1792.87 228.42 1797.37 234.85C1812.25 256.14 1857.13 262.45 1879.5 268.8C1909.62 277.35 1940.44 284.67 1969.51 296.47C1984.98 301.63 1998.82 309.55 2011.03 320.25C2017.11 329.68 2021.56 339.82 2024.41 350.67C2047.26 406.47 2101.08 435.19 2138.88 478.98"
						stroke="url(#paint93_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1597.06 101.86C1595.94 101.83 1594.82 101.85 1593.7 101.92C1653.24 114.72 1704.69 130.97 1749.3 175.01C1758.73 184.32 1769.12 192.57 1775.28 204.55C1779.95 213.64 1779.44 226.79 1785.08 234.94C1794.8 248.98 1824.48 256.53 1838.43 261.05C1877.25 273.66 1917.8 281.76 1955.64 297.35C1983.21 308.71 1995.43 317.69 2007.64 343.73C2017.66 365.1 2027.4 383.53 2043.44 401.39C2068.73 429.56 2100.09 450.04 2124.7 478.95"
						stroke="url(#paint94_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1585.2 99.2502C1584.19 99.1802 1583.19 99.2502 1582.2 99.4402C1603.42 106.06 1625.42 109.83 1646.46 117.83C1681.96 131.32 1710.57 150.52 1737.56 176.99C1747.84 187.07 1759.36 195.09 1765.55 208.54C1768.93 215.88 1768.53 225.7 1772.25 232.44C1784.69 254.95 1825.9 261.95 1847.58 268.48C1877.58 277.52 1908.24 285.1 1937.39 296.71C1953.37 302.05 1967.9 309.99 1980.99 320.51C1988.3 329.64 1993.39 339.88 1996.26 351.21C2020.11 405.68 2073.93 435.05 2110.8 478.98"
						stroke="url(#paint95_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1574.55 96.8198C1573.24 97.2498 1571.93 96.4498 1572.23 97.5398C1594.81 105.23 1617.83 110.7 1639.88 120.16C1675.1 135.27 1701.18 156.53 1729.14 181.98C1738.82 190.79 1749.14 197.21 1755.09 209.34C1758.69 216.68 1758.18 226.25 1761.9 233.16C1774.11 255.86 1814.53 263.06 1836.2 269.86C1865.74 279.13 1895.77 287.11 1924.65 298.38C1940.42 303.78 1954.73 311.75 1967.58 322.27C1974.71 331.51 1979.87 341.74 1983.05 352.98C2007.5 406 2061.1 435.32 2097.01 479.05"
						stroke="url(#paint96_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1564.98 94.5698C1561.03 97.6598 1573.02 99.3098 1577.41 100.8C1590.88 105.38 1604.28 110.09 1617.39 115.65C1653.09 130.78 1683.75 150.84 1711.56 177.84C1721.24 187.24 1734.79 194.36 1742.52 205.53C1746.65 211.5 1747.65 218 1750.25 224.51C1759.6 247.91 1774.62 253.59 1798.82 262.64C1836.18 276.61 1875.5 285.04 1912.37 300.41C1933.52 309.23 1952.84 317.96 1963.55 338.94C1975.99 363.3 1986.49 383.47 2005.86 403.66C2031.2 430.06 2060.27 450.43 2083.27 479.15"
						stroke="url(#paint97_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1556.36 92.5098C1554.25 96.2198 1564.7 98.2998 1570.06 100.43C1583.79 105.9 1597.51 111.36 1610.88 117.69C1645.7 134.17 1674.49 155.38 1702.28 181.71C1711.42 190.37 1724.17 196.05 1732.25 205.68C1739.06 213.8 1738.99 222.9 1742.76 232.21C1749.55 249.01 1767.2 255.61 1782.96 262.12C1819.65 277.25 1858.78 285.68 1895.52 300.7C1923.49 312.14 1938.84 321.67 1953.26 347.36C1965.11 368.46 1975.71 386.37 1992.98 404.04C2018.43 430.06 2046.96 450.39 2069.47 479.28"
						stroke="url(#paint98_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1548.53 90.6499C1546.35 93.3499 1597.66 116.31 1603.28 119.23C1637.66 137.07 1665.37 159.44 1693.4 185.68C1702.5 194.2 1715.3 198.64 1723.66 207.71C1730.82 215.48 1730.77 224.44 1734.44 233.71C1741.14 250.64 1757.88 257.37 1773.8 264.19C1809.69 279.57 1848.15 288.15 1884.14 303.3C1911.84 314.96 1926.31 324.68 1940.75 350.07C1952.66 371.02 1963.9 388.45 1981.32 405.76C2006.6 430.87 2033.95 450.8 2055.55 479.47"
						stroke="url(#paint99_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1541.36 88.9902C1538.88 94.0202 1590.31 117.57 1596.17 120.89C1629.98 140.03 1656.66 163.63 1684.87 189.71C1694.02 198.17 1706.77 201.33 1715.46 209.89C1722.97 217.29 1722.95 226.19 1726.52 235.41C1733.13 252.47 1748.93 259.31 1765 266.46C1800.07 282.08 1837.84 290.83 1873.06 306.1C1900.46 317.98 1914.07 327.89 1928.46 352.98C1957.65 403.9 2007.36 432.78 2041.34 479.66"
						stroke="url(#paint100_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1534.74 87.54C1532.76 95.25 1580.96 117.43 1587.88 121.65C1621.7 142.27 1647.84 166.65 1676.52 193.4C1686.29 202.51 1700.99 204.63 1709.77 214.36C1714.79 219.92 1716.12 227.2 1718.29 234.08C1726.2 259.15 1751.34 267.36 1774.35 276.28C1801.83 286.93 1830.2 295.36 1857.31 306.98C1873.19 312.92 1887.83 321.07 1901.22 331.42C1909.81 340.11 1915.69 350.34 1918.86 362.13C1943.36 410.12 1996.4 436.19 2026.83 479.89"
						stroke="url(#paint101_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1528.49 86.3101C1527.23 95.9301 1573.49 118.07 1581.37 123.25C1614.39 144.96 1639.69 170.5 1668.32 197.1C1678.22 206.29 1692.93 207.12 1702.06 216.52C1707.28 221.89 1708.69 229.22 1710.84 236.09C1718.73 261.21 1742.5 269.72 1765.67 279.04C1792.55 289.85 1820.35 298.42 1846.86 310.14C1862.43 316.16 1876.73 324.35 1889.76 334.73C1898.07 343.5 1903.81 353.7 1906.98 365.34C1930.97 411.91 1983.07 436.75 2011.84 480.16"
						stroke="url(#paint102_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1522.51 85.29C1522.39 97.09 1563.3 116.53 1573.37 123.59C1589.06 134.58 1604.37 146.15 1618.87 158.68C1633.82 171.6 1645.59 187.61 1660.09 200.66C1669.95 209.53 1683.35 209.19 1692.93 217.32C1698.75 222.26 1699.85 228.49 1702.69 235.04C1703.13 244 1706.71 251.57 1713.45 257.74C1724.88 266.84 1737.43 274.03 1751.09 279.31C1777.41 291.09 1805.01 299.83 1831.54 311.15C1863.49 324.79 1879.35 336.43 1894.28 366.64C1917.13 412.89 1968.94 436.89 1996.33 480.38"
						stroke="url(#paint103_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1516.64 84.5C1516.28 96.7 1558.33 118.33 1568.49 125.97C1599.48 149.26 1623.56 177.02 1651.58 203.1C1661.3 212.14 1673.31 211.12 1683.45 218.24C1693.13 225.03 1692.79 234.39 1696.72 244.49C1703.94 263.01 1719.35 270.96 1736.8 279.53C1766.7 294.21 1798.78 304 1828.94 318.15C1855.83 330.76 1869.31 341.76 1881.77 368.03C1903.51 413.83 1954.27 437.15 1980.17 480.68"
						stroke="url(#paint104_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1510.75 83.9399C1511.3 97.0999 1551.09 118.39 1561.8 126.91C1591.8 150.77 1615.27 179.1 1642.76 205.12C1652.69 214.51 1664.72 212.93 1675.23 220.02C1685.29 226.8 1685.02 236.4 1689 246.76C1696.12 265.35 1710.92 273.54 1728.39 282.41C1757.66 297.28 1789.22 307.23 1818.8 321.48C1845.3 334.25 1858.4 345.26 1870.37 371.41C1890.66 415.71 1939.49 438.13 1963.28 480.92"
						stroke="url(#paint105_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1504.71 83.6099C1506.61 97.6099 1545.19 119.4 1556.34 128.77C1584.82 152.7 1607.11 180.96 1633.45 206.55C1644.14 216.93 1658.77 214.42 1669.46 223.48C1676.1 229.11 1677.64 237.38 1680.21 245.25C1686.38 264.15 1697.22 272.88 1714.23 282.42C1746.92 300.76 1784.12 309.93 1816.65 328.54C1837.48 340.46 1848.7 350.48 1858.34 372.73C1877.24 416.35 1923.59 438.74 1945.57 481.18"
						stroke="url(#paint106_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1498.38 83.54C1503.96 99.03 1534.25 116.22 1547.2 127.41C1575.36 151.76 1597.36 180.26 1623.48 206.16C1633.95 216.54 1647.5 215.31 1658.69 223.46C1665.58 228.48 1668.32 236.04 1670.8 243.81C1679.64 271.47 1696 281.51 1722.53 294.01C1766 314.49 1826.62 326.03 1846.51 373.92C1864.22 416.55 1906.79 439.6 1926.86 481.5"
						stroke="url(#paint107_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1491.63 83.73C1494.37 97.17 1526.61 115.38 1536.84 124.18C1564.97 148.37 1586.25 176.9 1611.86 203.06C1622.63 214.05 1638.21 214.81 1649.36 224.76C1658.98 233.34 1659.49 243.18 1663.45 254.75C1670.38 274.95 1688.32 284.82 1706.4 294.32C1748.62 316.49 1812.82 327.26 1832.48 375.19C1849.53 416.74 1887.91 440.78 1906.28 482.02"
						stroke="url(#paint108_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1484.3 84.1699C1490.88 98.6299 1515.81 111.41 1528.26 121.95C1555.03 144.61 1575.29 170.91 1598.74 196.51C1610.21 209.03 1627.75 212.54 1639.25 224.87C1646.96 233.15 1649.61 242.06 1652.8 252.72C1659.2 274.1 1669.59 282.85 1689.29 294.38C1713.56 308.59 1740.45 317.94 1765.54 330.55C1781.03 337.46 1794.78 346.9 1806.77 358.84C1812.14 366.64 1815.98 375.07 1818.29 384.13C1835.66 420.58 1868.11 444.67 1883.9 482.55"
						stroke="url(#paint109_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1476.28 84.8701C1483.01 97.6201 1506.86 108.99 1518.47 118.52C1544.03 139.49 1562.52 163.87 1584.06 188.41C1595.76 201.73 1612.89 208.23 1624.9 221.14C1632.76 229.6 1637.76 239.42 1640.99 250.41C1648.46 275.84 1659.06 287.14 1682.68 300.23C1706.83 313.62 1732.57 323.74 1756.65 337.26C1779.29 349.98 1788.71 361.74 1799.22 384.6C1815.7 420.43 1845.59 445.78 1860.16 483.04"
						stroke="url(#paint110_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1467.41 85.8301C1475.09 96.0201 1494.21 104.15 1504.94 112.38C1530.09 131.69 1548.39 154.67 1568.41 178.86C1582.59 195.98 1603.03 206.48 1616.17 224.69C1624.69 236.5 1626.94 249.01 1631.34 262.58C1638.28 284 1654.4 295.36 1673.86 305.9C1697.62 318.77 1724.49 327.47 1746.6 343.12C1766.62 357.3 1772.54 369.93 1780.87 392.54C1786.61 408.12 1797.81 420.67 1807.87 433.52C1820.24 449.31 1828.58 464.4 1835.54 483.42"
						stroke="url(#paint111_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1457.56 87.0698C1467.08 93.6198 1480.31 99.2998 1490.54 106.44C1515.49 123.84 1532.56 145.88 1551.42 169.21C1566.02 187.27 1585.58 200.57 1599.4 219.35C1608.59 231.84 1613.41 244.47 1617.6 259.29C1624.14 282.41 1632.23 291.65 1653.39 304.71C1675.97 318.65 1701.68 326.94 1724 341.35C1747.54 356.55 1751.26 366.39 1759.65 391.01C1771.17 424.83 1798.81 449.06 1810.48 483.58"
						stroke="url(#paint112_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1446.61 88.5898C1483.98 95.9698 1511.64 131.57 1533.82 160.18C1549.47 180.35 1569.68 196.52 1584.23 217.62C1593.64 231.26 1599.16 245.01 1603.53 260.92C1609.81 283.76 1616.74 292.92 1637.63 305.93C1659.14 319.33 1683.81 327.01 1705.08 340.83C1727.84 355.62 1731.27 365.62 1738.98 389.69C1749.8 423.43 1774.96 449.03 1785.47 483.54"
						stroke="url(#paint113_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1434.4 90.4101C1467.17 85.4101 1499.65 130.97 1515.91 152.95C1532.62 175.55 1553.85 194.29 1568.96 218.19C1587.31 247.21 1586.1 283.19 1616.26 303.32C1636.73 316.98 1660.81 324.35 1682.2 336.38C1705.43 349.44 1711.08 360.18 1718.28 384.16C1728.78 419.12 1751.39 447.43 1760.96 483.14"
						stroke="url(#paint114_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1420.81 92.5299C1450.2 80.3199 1484.24 129.92 1497.32 147.99C1514.64 171.92 1536.13 192.37 1551.61 217.72C1569.33 246.73 1568.76 283.83 1599.21 302.86C1619.27 315.4 1642.72 321.6 1663.66 332.45C1686.39 344.23 1692.73 354.7 1699.33 378.05C1709.54 414.17 1729.1 445.35 1737.4 482.34"
						stroke="url(#paint115_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1405.71 94.96C1435.96 82.28 1465.06 127.49 1478.53 146.33C1496.09 170.89 1517.57 192.15 1533.15 218.23C1550.35 247.02 1550.17 284.07 1581.56 301.27C1601.73 312.33 1624.85 317.01 1645.81 326.32C1668.29 336.31 1676.06 346.11 1682.2 368.95C1692.43 406.97 1708.37 442.07 1715.26 481.08"
						stroke="url(#paint116_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1390.89 97.1198C1420.85 83.5698 1447.14 127.88 1460.2 146.42C1477.59 171.09 1499.2 192.49 1514.48 218.7C1522.27 232.06 1525.94 246.3 1531.49 260.45C1539.04 279.72 1545.49 287.55 1564.3 297.48C1596.47 314.45 1639.51 315.74 1651.33 355.71C1663.67 397.45 1679.3 438.35 1687.21 481.24"
						stroke="url(#paint117_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1376.31 98.5702C1403.65 80.8802 1430.82 127.99 1441.98 144.57C1456.94 166.8 1476.3 185.54 1490.11 208.65C1507.03 236.97 1506.54 270.79 1534.2 291.4C1549.78 303.01 1568.52 310.22 1583.41 322.84C1596.85 334.23 1600.14 346.73 1605.38 362.97C1618.57 403.9 1635.64 443.49 1644.79 485.66"
						stroke="url(#paint118_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1361.7 99.3901C1385.62 76.8101 1414.49 127.75 1423.55 141.62C1437.56 163.05 1456.61 180.53 1469 203.15C1483.61 229.84 1484.06 259.88 1501.63 285.04C1510.75 298.1 1522.69 308.9 1532.08 321.74C1545.88 340.61 1547.11 355.85 1553.29 377.82C1564.38 417.23 1586.14 451.96 1596.32 492"
						stroke="url(#paint119_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1347.04 99.6402C1368.01 73.5302 1397.63 126.84 1405.25 138.81C1417.72 158.39 1435.28 174.18 1446.21 194.87C1460.73 222.36 1459.77 254.18 1471.21 282.99C1476.28 295.76 1482.51 308.08 1487.55 320.86C1495.94 342.17 1497.2 362.21 1503.16 384.01C1514 423.67 1539.15 457.76 1550.5 497.77"
						stroke="url(#paint120_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1332.34 99.3602C1352.14 72.2302 1379.65 125.9 1386.61 137.21C1396.51 153.29 1410.86 166.08 1420.24 182.52C1435.39 209.09 1435.31 241.38 1442.21 270.53C1450.98 307.59 1457.31 345.79 1466.62 382.55C1477.05 423.72 1503.94 459.25 1515.95 500.42"
						stroke="url(#paint121_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1317.62 97.9C1333.36 71.21 1364.03 131.51 1368.28 138.75C1376.8 153.26 1389.63 164.6 1397.8 179.33C1411.84 204.65 1411.63 236.43 1419.33 263.9C1429.72 300.97 1437.94 337.77 1447.03 375.03C1451.88 394.91 1461.87 413.08 1470.84 431.28C1481.84 453.59 1488.55 475.15 1494.83 499.35"
						stroke="url(#paint122_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1302.87 94.28C1318.91 76.31 1346.42 137.87 1349.93 144.32C1357.61 158.43 1370.14 169.01 1377.12 183.56C1389.19 208.76 1388.95 238.93 1399.16 265.32C1404.37 278.79 1410.44 291.94 1415.55 305.45C1423.92 327.58 1425.45 348.86 1432.1 371.16C1437.81 390.32 1447.6 407.75 1456.12 425.73C1467.02 448.76 1472.35 471.73 1477.76 496.7"
						stroke="url(#paint123_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1288.08 89.1301C1308.3 86.8201 1325.14 140.81 1331.4 152.89C1337.61 164.87 1348.06 173.53 1353.88 185.68C1364.98 208.86 1364.73 235.36 1376.19 259.25C1383.73 274.97 1393.28 289.66 1400.84 305.37C1411.16 326.78 1412.7 345.67 1419.41 367.97C1423.67 382.11 1431.78 395.01 1438.45 408.04C1452.69 435.85 1457.66 462.37 1463.04 493.08"
						stroke="url(#paint124_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1273.29 83.1099C1294.08 103.87 1300.44 137.02 1312.9 162.46C1317.69 172.24 1325.86 178.9 1330.82 188.42C1334.62 195.7 1335.62 203.69 1336.96 211.67C1340.08 230.29 1344.98 243.11 1355.3 259.21C1366.17 276.17 1379.58 291.47 1389.48 309.07C1398.19 324.55 1400.57 340.76 1406.13 357.36C1411.68 373.93 1420.16 387.73 1428.13 403.15C1442.32 430.59 1445.22 458.64 1449.41 489"
						stroke="url(#paint125_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1258.46 76.9102C1270.36 108.05 1280.7 140.99 1294.28 171.3C1298.07 179.75 1304.43 184.6 1308.78 192.38C1313.16 200.23 1312.86 210.26 1314.41 218.71C1317.61 236.22 1326.6 249.61 1337.91 263.2C1360.87 290.79 1381.2 310.87 1391.17 346.13C1396.94 366.54 1406.76 378.56 1416.63 397C1431.14 424.09 1432.16 455.17 1435.41 485.1"
						stroke="url(#paint126_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1243.61 71.2002C1251.39 106.59 1261.91 145.24 1275.7 178.57C1278.88 186.26 1284.59 190.28 1288.01 197.31C1290.76 202.97 1290.75 209.56 1291.74 215.65C1294.46 232.25 1300.18 243.79 1311.38 256.18C1324.31 270.49 1340.09 281.99 1353.02 296.29C1368.89 313.83 1370.92 325.12 1377.72 346.74C1383.35 364.66 1395.48 376.38 1404.02 392.7C1418.15 419.68 1417.2 452.52 1419.72 481.96"
						stroke="url(#paint127_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1228.75 66.6499C1234.67 104.21 1242.38 147.9 1257.13 182.77C1259.85 189.2 1264.41 192.04 1267.18 197.69C1270.24 203.95 1269.72 212.88 1270.86 219.32C1273.86 236.29 1281.3 246.1 1293.89 257.84C1307.95 270.95 1324.49 281.29 1337.82 295.24C1354.26 312.44 1354.1 323.13 1361.05 344.43C1366.49 361.08 1379.87 373.07 1387.57 388.49C1401.23 415.87 1398.88 450.72 1400.94 480.13"
						stroke="url(#paint128_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1213.86 63.9399C1217.56 101.47 1224.92 146.74 1238.64 181.59C1241.1 187.83 1244.94 190.42 1247.66 195.81C1250.57 201.59 1249.95 210.62 1251 216.48C1253.95 232.86 1261.63 242.6 1273.68 253.96C1287.4 266.89 1303.46 277.26 1316.4 291.06C1332.45 308.18 1332.7 319.03 1339.29 340.27C1344.6 357.37 1358.04 369.87 1364.83 386.14C1377.05 415.39 1375.14 448.79 1376.69 479.77"
						stroke="url(#paint129_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1198.97 63.04C1201.76 98.87 1209.21 142.27 1221.09 175.82C1223.35 182.19 1227.26 186.01 1229.91 191.85C1232.66 197.92 1232.05 206.12 1233.17 212.36C1235.93 227.75 1243.12 238.14 1253.41 249.77C1265.26 263.17 1279.13 274.73 1290.17 288.87C1303.85 306.39 1305.04 318.41 1310.71 339.07C1315.54 356.65 1327.51 370.71 1333.66 387.65C1344.52 417.6 1342.38 449.68 1342.52 481.01"
						stroke="url(#paint130_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1184.07 63.3799C1188.29 97.2199 1194.97 134.01 1204.29 166.65C1206.47 174.27 1210.99 180.03 1213.48 187.34C1220 206.49 1219.1 224.69 1230.34 242.97C1239.35 257.62 1250.52 270.87 1259.28 285.67C1270.18 304.08 1272.32 317.83 1276.68 338.35C1280.7 357.27 1290.59 372.27 1296.22 390.54C1305.71 421.32 1303 451.91 1301.05 483.72"
						stroke="url(#paint131_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1169.17 64.3701C1177.05 94.7601 1179.79 126.5 1187.86 156.72C1190.27 165.76 1195.02 173.6 1197.7 182.49C1203.86 202.96 1203.49 223.51 1211.36 244.08C1223.78 276.56 1235.18 304.86 1240.24 339.59C1243.12 359.34 1250.72 375.99 1255.9 395.09C1264.47 426.75 1260.41 455.13 1255.84 487.25"
						stroke="url(#paint132_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1154.25 65.4302C1167.06 88.1302 1166.15 120.99 1171.49 146.01C1173.7 156.39 1178.26 165.7 1181.06 175.86C1187.42 198.91 1186.21 223.84 1190.58 247.35C1196.51 279.26 1201.07 309.97 1203.7 342.3C1205.36 362.69 1211.3 380.64 1215.43 400.52C1222.27 433.48 1217.97 458.28 1210.31 491.03"
						stroke="url(#paint133_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1139.33 65.9902C1153.14 75.8502 1152.04 121.61 1154.75 137.01C1156.93 149.38 1161.5 160.9 1164.42 173.07C1170.37 197.92 1168.2 223.46 1168.59 248.79C1169.1 282.13 1168.18 315.75 1169.43 349.04C1170.18 369.05 1174.9 387.82 1177.85 407.51C1182.85 440.9 1178.67 462.15 1168.06 494.47"
						stroke="url(#paint134_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1124.42 65.4702C1135.99 68.4902 1135.99 121.01 1137.29 130.78C1139.22 145.25 1143.68 159.01 1146.56 173.27C1151.96 199.99 1149.79 225.48 1147.16 252.5C1143.81 286.89 1138.78 321.83 1139.43 356.45C1139.8 375.81 1143.37 394.67 1145.46 413.86C1149.1 447.29 1145.46 465.33 1132.48 496.96"
						stroke="url(#paint135_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1109.34 63.29C1120.67 70.33 1117.62 116.22 1118.76 128.51C1122.99 174.02 1133.6 212.66 1126.27 259.18C1120.78 294.03 1113.75 328.38 1114.9 363.86C1115.5 382.29 1118.12 400.47 1119.77 418.81C1122.71 451.48 1119.66 467.21 1105.9 497.88"
						stroke="url(#paint136_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1091.86 59.3999C1101.84 64.7999 1098.07 117.56 1098.73 128.39C1101.38 172 1113.97 216.88 1103.31 260.17C1093.78 298.86 1081.38 332.46 1082.44 373.05C1082.84 388.49 1084.38 403.87 1085.38 419.28C1087.45 451.27 1085.17 466.54 1072.31 497.02"
						stroke="url(#paint137_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1072.05 54.3999C1083.23 64.3599 1077.03 115.43 1077.44 129.4C1078.87 178.2 1093.46 235.26 1070.67 280.35C1053.49 314.34 1039.57 341.46 1040.16 380.65C1040.4 396.52 1041 412.4 1041.01 428.27C1041.04 454.78 1038.7 468.42 1028.98 494.82"
						stroke="url(#paint138_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1050.87 48.9102C1053.08 51.7102 1053.31 56.2402 1053.91 60.4102C1057.23 83.4902 1055.33 107.78 1055.35 130.99C1055.37 157.96 1056.12 184.93 1056.43 211.89C1056.76 240.58 1060.44 265.07 1040.87 288.31C1015.47 318.47 993.696 336.66 991.956 378.46C990.326 417.53 991.056 454.16 979.886 491.81"
						stroke="url(#paint139_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1029.3 43.54C1032.12 47.45 1032.17 52.36 1032.69 57.3C1035.32 82.27 1033.69 107.97 1033.14 132.98C1032.47 163.55 1031.99 194.12 1031.4 224.69C1031.13 238.61 1032.82 256.18 1030.15 270.18C1027.38 284.67 1020.14 291.51 1008.22 299.86C975.566 322.73 946.346 334.06 942.126 377.53C938.526 414.63 936.946 452.07 929.106 488.46"
						stroke="url(#paint140_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M1008.27 38.8999C1011.33 43.0299 1011.28 48.3499 1011.77 53.4399C1014.37 80.3799 1012.25 108.24 1011.16 135.18C1009.68 171.59 1007.98 207.99 1006.42 244.4C1005.89 256.87 1006.72 270.4 1004.78 282.73C1001.89 301.12 987.996 305.42 971.586 311.75C956.936 317.4 941.746 321.98 927.616 328.92C903.206 340.91 897.636 353.23 894.306 378.61C889.626 414.2 885.316 449.72 880.556 485.27"
						stroke="url(#paint141_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M988.765 35.6001C992.055 40.1301 991.755 46.3901 992.115 51.9001C993.935 79.8701 991.655 108.55 990.025 136.45C987.805 174.49 985.275 212.51 982.975 250.54C982.175 263.81 984.705 286.15 980.165 298.29C975.085 311.88 959.845 314.09 947.355 317.12C927.265 321.99 906.375 325.12 886.895 332.32C860.295 342.15 856.555 354.22 852.655 379.72C847.315 414.59 840.575 447.27 838.325 482.73"
						stroke="url(#paint142_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M971.726 34.27C974.236 38.24 973.936 43.92 974.146 48.87C975.376 78.21 972.286 108.07 970.156 137.28C967.346 175.79 964.316 214.28 961.486 252.78C960.446 267 962.936 287.3 957.936 300.25C952.536 314.25 944.636 316.63 929.686 319.91C905.346 325.25 876.326 323.78 853.106 333.71C833.046 342.29 828.186 350.58 822.316 371.09C812.096 406.76 807.166 444.27 806.366 481.32"
						stroke="url(#paint143_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M956.656 34.9199C966.126 63.5599 955.046 108.69 952.126 138.2C948.486 175 945.866 211.91 942.956 248.78C941.696 264.8 941.586 281.32 939.076 297.21C936.136 315.77 921.606 316.63 904.266 319.83C879.446 324.42 850.336 324.15 826.806 334.37C807.366 342.81 804.676 353.33 799.766 372.81C790.666 408.88 783.786 444.08 782.946 481.4"
						stroke="url(#paint144_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M941.135 36.52C950.275 66.97 939.935 109.73 936.105 141.05C931.855 175.81 929.735 210.86 926.895 245.76C925.645 261.09 925.545 277.01 922.895 292.19C919.595 311.11 903.875 312.02 886.305 315.42C860.355 320.44 830.145 320.67 805.525 331.33C785.685 339.92 782.535 350.12 777.375 370.05C767.845 406.86 760.195 443.3 759.235 481.45"
						stroke="url(#paint145_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M925.185 38.7202C938.675 67.4502 925.945 115.26 921.665 145.29C917.135 177.12 915.485 209.37 912.865 241.39C911.705 255.59 911.715 270.53 908.965 284.55C905.205 303.74 887.355 304.95 869.835 308.54C843.385 313.96 809.465 314.37 784.745 326.31C764.515 336.09 760.525 344.51 754.775 365.79C744.555 403.65 736.175 442.01 735.165 481.36"
						stroke="url(#paint146_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M908.905 41.2402C927.205 68.7802 912.965 120.95 908.375 150.67C904.115 178.28 902.635 206.23 900.455 234.06C899.515 246.16 900.455 267.41 896.115 279.06C889.475 296.84 862.785 298.28 847.635 301.22C811.165 308.29 744.495 308.92 733.275 352.97C722.625 394.79 711.986 437.87 710.896 481.2"
						stroke="url(#paint147_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M892.394 43.7598C915.784 69.1198 900.634 127.6 895.714 156.42C891.644 180.26 890.494 204.35 888.724 228.44C887.944 239.06 888.724 258.03 884.744 268.16C877.574 286.4 848.025 288.47 832.675 291.66C794.925 299.51 723.475 301.35 711.085 346.66C699.245 389.97 687.775 435.88 686.595 480.93"
						stroke="url(#paint148_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M875.755 45.98C902.675 71.85 889.035 131.69 883.225 162.38C879.445 182.36 878.585 202.4 877.245 222.65C876.665 231.43 877.335 248.66 873.665 256.87C865.335 275.53 834.325 278.13 818.095 281.72C778.965 290.37 703.065 293.43 689.445 340C683.205 361.35 679.875 384.42 673.645 406.07C666.236 431.81 663.176 453.89 662.396 480.64"
						stroke="url(#paint149_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M859.086 47.6099C889.156 73.7999 876.996 136.14 870.366 168.41C866.806 185.75 866.456 202.95 865.236 220.55C864.706 228.15 865.176 237.17 862.316 244.26C854.626 263.35 829.136 265.86 810.886 270.22C767.496 280.6 682.056 284.66 668.396 336.21C655.856 383.51 640.136 431.2 638.446 480.36"
						stroke="url(#paint150_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M842.485 48.3301C874.285 76.9401 864.755 138.37 856.825 173.8C853.735 187.59 853.475 200.72 852.595 214.75C852.185 221.3 852.335 228.72 849.865 234.86C842.125 254.11 814.555 256.93 796.385 261.44C751.735 272.53 663.345 277.64 648.595 330.14C634.805 379.22 617.215 428.92 614.915 480.11"
						stroke="url(#paint151_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M826.044 47.8501C857.434 81.0901 852.294 138.46 842.044 178.26C839.214 189.24 839.084 198.86 838.394 210.04C838.044 215.76 837.914 222 835.754 227.37C827.944 246.72 799.304 249.71 781.154 254.35C751.394 261.95 721.134 268.06 691.794 277.24C663.235 286.17 638.825 295.24 630.075 324.92C615.095 375.72 595.155 426.76 591.935 479.9"
						stroke="url(#paint152_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M809.864 45.8599C838.734 84.7699 838.824 136.78 825.704 181.11C822.924 190.49 822.605 197.07 822.035 206.46C820.305 234.94 798.385 240.13 773.475 247.57C738.635 257.98 702.485 263.72 667.985 275.43C638.505 285.43 620.635 294.18 612.635 323.75C598.405 376.35 574.095 424.97 569.675 479.8"
						stroke="url(#paint153_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M794.754 43.0601C818.834 88.0701 823.444 133.74 807.354 182.43C804.434 191.28 804.394 196.9 803.494 205.56C800.624 233.14 780.124 238.15 755.514 245.59C721.135 255.98 685.475 261.85 651.455 273.59C621.815 283.81 605.425 292.38 597.025 321.74C581.705 375.31 555.285 423.81 548.095 479.56"
						stroke="url(#paint154_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M779.726 42.3301C800.546 89.9301 805.836 132.1 788.216 182.22C784.956 191.5 785.456 196.25 784.666 205.19C782.276 232.18 760.996 237.43 737.346 244.78C703.736 255.22 668.836 261.29 635.606 273.12C606.066 283.63 590.806 292.31 582.116 321.41C566.076 375.11 539.066 423.49 527.456 478.67"
						stroke="url(#paint155_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M764.154 43.4399C783.114 91.9699 786.764 131.17 768.394 181.31C764.794 191.14 766.194 195.53 765.864 205.13C764.854 234.8 736.035 239.82 711.235 246.9C682.925 254.99 654.105 261.76 626.275 271.47C597.675 281.44 577.805 290.84 568.295 319.4C550.415 373.1 524.115 422.99 507.675 477.27"
						stroke="url(#paint156_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M748.195 45.9302C766.585 93.8802 766.255 130.4 748.365 179.18C744.385 190.04 746.735 194.17 746.985 204.71C747.695 234.35 717.525 240.28 693.605 247.5C665.955 255.84 637.796 262.9 610.656 272.85C582.446 283.19 563.406 292.99 554.026 321.18C536.336 374.36 509.146 423.35 488.556 475.52"
						stroke="url(#paint157_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M731.965 49.3198C751.305 94.7298 744.885 130.59 728.355 176.11C723.765 188.74 726.665 191.75 727.925 203.79C730.755 230.74 705.195 238.46 683.776 246.16C652.516 257.4 619.846 264.52 589.036 277.17C560.896 288.73 547.636 298.28 539.076 326.24C523.286 377.83 493.086 425.1 469.886 473.53"
						stroke="url(#paint158_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M715.617 53.1499C735.997 95.5799 723.567 131.28 708.127 173.03C702.967 186.98 707.687 191.23 709.397 204.31C713.587 236.3 675.267 244.27 652.137 252.01C622.527 261.9 590.317 269.05 562.957 284.35C539.867 297.26 530.747 307.88 523.367 333.54C509.447 381.96 475.457 428.07 451.457 471.5"
						stroke="url(#paint159_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M699.286 56.9399C721.326 95.1599 701.976 133.02 688.036 169.78C682.586 184.16 687.666 189.09 690.386 202.52C696.046 230.42 669.086 240.42 647.576 248.71C619.836 259.4 591.106 267.43 563.737 279.13C534.677 291.56 519.927 302.55 509.417 331.55C491.247 381.69 461.087 423.89 433.057 469.51"
						stroke="url(#paint160_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M683.116 60.23C706.286 93.97 680.796 135.01 668.246 166.6C662.736 180.47 667.806 186.34 670.966 199.43C678.346 229.96 654.356 239.83 629.096 249.97C583.376 268.32 508.356 279.9 493.936 334.68C480.986 383.88 441.866 426.44 414.496 467.74"
						stroke="url(#paint161_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M667.237 62.5601C691.147 92.8301 659.787 136.2 648.707 163.8C642.707 178.75 648.977 185.56 652.667 199.33C660.207 227.51 638.427 238.91 615.657 248.76C568.437 269.19 495.357 279.89 477.737 334.82C461.807 384.48 425.297 423.8 395.547 466.32"
						stroke="url(#paint162_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M651.776 63.4399C675.166 91.8599 640.106 136.31 630.256 161.37C623.976 177.35 630.856 184.1 634.586 199.26C643.106 233.87 607.866 244.81 581.356 255.49C540.286 272.03 472.746 286.62 460.426 335.35C447.916 384.86 405.136 425.15 375.986 465.39"
						stroke="url(#paint163_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M636.606 62.3701C658.406 91.2601 623.016 135.35 612.046 159.83C604.646 176.35 613.496 184.77 617.026 200.48C623.176 227.81 599.156 239.16 577.236 248.94C532.956 268.69 454.156 283.24 441.126 337.6C437.806 351.47 434.306 361.26 425.986 372.92C403.576 404.32 378.576 434.05 355.656 465.1"
						stroke="url(#paint164_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M614.406 59.25C638.656 86.3 601.186 134.75 591.116 158.19C584.096 174.51 592.586 183.64 595.906 199.26C601.786 226.88 579.186 238.26 556.606 248.41C512.476 268.25 433.086 282.72 420.636 337.26C417.396 351.44 413.666 361.32 405.176 373.19C382.816 404.44 358.066 434.07 335.316 465.06"
						stroke="url(#paint165_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M595.516 56.6699C621.296 82.4499 581.616 134 572.686 156.75C566.526 172.44 572.506 181.05 575.876 196.37C583.716 231.95 549.556 243.23 521.956 254.6C481.176 271.4 412.456 286.62 400.826 335.5C389.066 384.92 344.027 425.47 314.977 465.25"
						stroke="url(#paint166_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M579.605 54.6099C605.395 80.2099 565.525 132.93 555.885 155.72C549.865 169.95 554.555 179.65 557.395 193.72C564.515 229 537.565 240.33 508.446 252.66C469.296 269.24 393.836 285.8 381.406 333.92C368.356 384.46 324.756 424.24 294.676 465.69"
						stroke="url(#paint167_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M566.376 53.0298C591.636 78.5298 550.816 132.48 541.156 154.86C534.827 169.52 539.056 180.05 541.116 194.78C545.386 225.29 526.707 237.18 501.207 248.69C453.657 270.14 379.277 281.94 361.457 337.35C345.757 386.14 304.227 425.11 274.467 466.33"
						stroke="url(#paint168_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M555.496 51.9102C579.296 77.2202 538.116 132.92 528.266 154.14C520.976 169.84 525.516 182.29 525.996 198.66C527.046 234.51 496.966 244.55 467.576 256.35C442.386 266.47 416.496 275.25 392.236 287.54C364.026 301.83 349.496 313.6 341.466 343.69C338.236 355.77 332.326 365.54 324.626 375.38C300.806 405.83 276.886 435.65 254.316 467.13"
						stroke="url(#paint169_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M546.676 51.2202C568.866 77.6902 526.806 131.86 516.386 153.78C509.276 168.74 511.586 180.7 511.386 196.57C510.966 231.64 487.866 242.34 456.936 255.06C431.146 265.67 404.676 274.6 379.326 286.28C350.026 299.79 331.956 312.16 323.176 343.05C319.366 356.45 313.466 366.01 304.786 376.79C280.536 406.89 256.786 436.5 234.326 468.07"
						stroke="url(#paint170_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M539.585 50.9199C559.255 78.1299 517.086 131.94 505.916 153.51C498.086 168.63 499.336 182.32 497.706 198.78C494.796 228.21 479.306 239.88 453.056 251.41C405.286 272.4 323.536 283.68 305.776 340.45C301.266 354.86 295.476 365.94 285.966 377.5C261.146 407.67 237.126 437.12 214.506 469.15"
						stroke="url(#paint171_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M533.917 50.9902C550.777 78.7802 508.557 132.01 496.597 153.33C488.687 167.43 487.587 181.25 485.167 196.79C483.927 204.72 482.707 213.57 479.277 220.96C468.457 244.28 441.827 251.57 419.237 260.22C374.657 277.3 305.807 289.41 288.347 339.59C281.777 358.48 273.707 370.63 260.757 385.77C237.257 413.25 215.687 440.55 194.877 470.29"
						stroke="url(#paint172_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M529.366 51.3999C543.146 80.1799 500.666 131.87 487.866 153.36C479.446 167.48 476.696 181.83 473.066 197.54C471.186 205.64 469.496 214.7 465.716 222.22C453.896 245.73 427.727 252.92 404.287 261.73C358.857 278.81 289.487 290.47 270.807 340.9C263.567 360.44 254.417 373.04 240.677 388.57C216.997 415.34 195.877 441.97 175.467 471.48"
						stroke="url(#paint173_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M525.606 52.1099C528.726 61.2199 523.996 72.4699 520.766 80.9299C510.776 107.09 494.536 129.99 479.686 153.48C470.696 167.7 466.286 182.48 461.436 198.37C458.916 206.63 456.746 215.89 452.586 223.56C439.756 247.24 413.996 254.36 389.726 263.31C343.436 280.37 273.496 291.6 253.586 342.25C245.646 362.44 235.406 375.51 220.866 391.43C197.036 417.52 176.326 443.39 156.326 472.7"
						stroke="url(#paint174_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M522.336 53.1001C524.216 62.4901 518.856 73.2501 515.196 81.5901C503.866 107.37 487.436 130.42 471.856 153.71C462.236 168.09 456.146 183.2 450.136 199.29C446.916 207.92 443.766 216.65 439.786 224.97C428.236 249.09 398.847 256.5 375.497 264.94C328.367 281.98 257.807 292.77 236.677 343.64C228.027 364.46 216.697 377.99 201.357 394.3C177.427 419.75 157.067 444.8 137.467 473.93"
						stroke="url(#paint175_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M519.237 54.3398C519.747 63.9298 513.907 74.1098 509.767 82.3898C497.097 107.74 480.427 130.9 464.157 153.99C453.857 168.6 446.227 183.94 439.037 200.23C435.177 208.97 431.537 217.86 427.207 226.38C414.857 250.71 385.547 258.04 361.507 266.56C313.577 283.56 242.337 293.93 220.067 345C210.717 366.44 198.317 380.42 182.207 397.11C158.207 421.97 138.127 446.17 118.957 475.11"
						stroke="url(#paint176_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M515.995 55.7998C514.755 65.8698 508.655 75.5098 503.735 84.1898C489.805 108.76 473.035 131.63 456.345 154.35C444.705 170.2 435.315 186.55 426.485 204.14C422.035 213.01 418.246 222.49 413.096 230.95C402.926 247.65 385.456 253.67 368.156 260.75C329.546 276.55 287.536 284.72 250.136 303.58C227.396 315.05 213.506 324.77 202.986 348.53C195.126 366.3 183.906 379.55 170.366 393.1C143.846 419.63 121.316 444.6 100.756 476.25"
						stroke="url(#paint177_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M512.296 57.4502C502.906 92.2202 469.546 127.15 448.276 154.68C437.036 169.23 426.926 184.16 417.866 200.16C411.466 211.47 406.496 225.09 398.836 235.51C386.906 251.73 366.036 258.07 348.036 264.76C313.576 277.57 278.056 287.06 244.116 301.31C215.446 313.35 198.816 323.86 186.076 351.9C177.926 369.84 165.116 382.98 151.166 396.57C124.877 422.18 102.787 446.15 82.9766 477.3"
						stroke="url(#paint178_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M507.835 59.25C493.125 93.81 462.805 126.18 439.685 155.01C427.825 169.81 416.575 184.79 406.595 200.93C399.455 212.48 393.945 225.75 386.135 236.77C374.455 253.23 352.415 259.6 334.565 266.14C299.635 278.94 263.635 288.37 229.196 302.56C200.216 314.5 183.366 324.93 170.196 353.03C161.606 371.37 147.946 384.92 133.496 398.74C107.166 423.93 85.0157 447.12 65.5957 478.22"
						stroke="url(#paint179_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M502.305 61.1899C482.695 94.7199 454.955 125.49 430.395 155.34C417.205 171.37 404.285 187.2 392.945 204.63C387.345 213.23 382.185 222.09 377.065 230.98C363.565 254.44 338.595 261.27 314.065 269.8C283.805 280.32 252.855 288.79 222.855 300.05C205.175 306.09 188.615 314.36 173.205 324.87C162.605 332.79 156.095 343.19 153.665 356.07C144.925 374.14 129.445 388.31 115.085 401.78C89.1255 426.13 67.3455 448.46 48.6855 479.01"
						stroke="url(#paint180_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M495.376 63.2202C471.726 94.9302 445.666 125.47 420.106 155.61C405.606 172.7 391.066 189.56 378.546 208.2C372.696 216.91 367.766 226.14 362.156 234.98C347.516 258.09 317.916 265.09 293.296 273.17C263.016 283.1 232.076 291.36 202.446 303.16C185.436 309.04 169.686 317.3 155.196 327.92C145.926 336.35 139.956 346.65 137.296 358.84C128.116 376.95 111.716 391.02 97.1263 404.49C71.5963 428.07 50.1563 449.62 32.2363 479.62"
						stroke="url(#paint181_linear_6353_87482)"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
				</g>
				<g filter="url(#filter0_f_6353_87482)">
					<rect
						x="-339.801"
						y="-39"
						width="1378.36"
						height="884"
						fill="#06061B"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_f_6353_87482"
					x="-839.801"
					y="-539"
					width="2378.36"
					height="1884"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="250"
						result="effect1_foregroundBlur_6353_87482"
					/>
				</filter>
				<radialGradient
					id="paint0_radial_6353_87482"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(960.639 249.61) rotate(90) scale(308.596 1143.83)"
				>
					<stop />
					<stop offset="1" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint1_linear_6353_87482"
					x1="16.3066"
					y1="272.643"
					x2="1171.5"
					y2="-800.437"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_6353_87482"
					x1="22.3457"
					y1="466.307"
					x2="48.37"
					y2="231.947"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_6353_87482"
					x1="29.7168"
					y1="451.217"
					x2="68.3552"
					y2="167.814"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_6353_87482"
					x1="37.9355"
					y1="437.88"
					x2="87.5708"
					y2="119.227"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_6353_87482"
					x1="47.0059"
					y1="427.17"
					x2="99.6772"
					y2="101.079"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_6353_87482"
					x1="56.8262"
					y1="418.402"
					x2="109.691"
					y2="93.5975"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_6353_87482"
					x1="66.8672"
					y1="410.469"
					x2="117.316"
					y2="94.6941"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_6353_87482"
					x1="76.8867"
					y1="403.338"
					x2="123.919"
					y2="99.7181"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_6353_87482"
					x1="86.6758"
					y1="396.642"
					x2="128.026"
					y2="112.865"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint10_linear_6353_87482"
					x1="95.9766"
					y1="390.653"
					x2="133.933"
					y2="119.681"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint11_linear_6353_87482"
					x1="104.566"
					y1="383.803"
					x2="143.866"
					y2="109.215"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint12_linear_6353_87482"
					x1="112.207"
					y1="375.124"
					x2="157.578"
					y2="81.5217"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint13_linear_6353_87482"
					x1="118.666"
					y1="366.158"
					x2="169.876"
					y2="55.5789"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint14_linear_6353_87482"
					x1="123.717"
					y1="357.048"
					x2="180.258"
					y2="31.8926"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint15_linear_6353_87482"
					x1="127.117"
					y1="347.851"
					x2="188.123"
					y2="11.055"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint16_linear_6353_87482"
					x1="131.547"
					y1="338.29"
					x2="191.412"
					y2="5.37567"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint17_linear_6353_87482"
					x1="139.836"
					y1="329.6"
					x2="198.895"
					y2="0.400291"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint18_linear_6353_87482"
					x1="151.447"
					y1="321.583"
					x2="209.856"
					y2="-3.70432"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint19_linear_6353_87482"
					x1="165.807"
					y1="314.407"
					x2="223.902"
					y2="-7.35865"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint20_linear_6353_87482"
					x1="182.346"
					y1="307.756"
					x2="239.922"
					y2="-9.52357"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint21_linear_6353_87482"
					x1="200.506"
					y1="301.533"
					x2="257.61"
					y2="-11.0808"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint22_linear_6353_87482"
					x1="219.717"
					y1="295.77"
					x2="276.545"
					y2="-12.4829"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint23_linear_6353_87482"
					x1="239.426"
					y1="292.12"
					x2="302.378"
					y2="-27.763"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint24_linear_6353_87482"
					x1="259.057"
					y1="288.812"
					x2="328.69"
					y2="-42.7355"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint25_linear_6353_87482"
					x1="278.066"
					y1="284.808"
					x2="358.33"
					y2="-65.5514"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint26_linear_6353_87482"
					x1="295.867"
					y1="279.75"
					x2="391.601"
					y2="-96.3994"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint27_linear_6353_87482"
					x1="311.916"
					y1="274.628"
					x2="423.577"
					y2="-124.961"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_6353_87482"
					x1="325.627"
					y1="269.165"
					x2="451.214"
					y2="-148.516"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint29_linear_6353_87482"
					x1="336.457"
					y1="263.514"
					x2="475.324"
					y2="-170.304"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint30_linear_6353_87482"
					x1="343.836"
					y1="258.355"
					x2="484.269"
					y2="-175.536"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint31_linear_6353_87482"
					x1="347.887"
					y1="251.489"
					x2="496.242"
					y2="-192.175"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint32_linear_6353_87482"
					x1="351.576"
					y1="244.554"
					x2="495.205"
					y2="-191.942"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint33_linear_6353_87482"
					x1="355.316"
					y1="237.773"
					x2="491.259"
					y2="-187.294"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint34_linear_6353_87482"
					x1="359.107"
					y1="230.256"
					x2="482.324"
					y2="-175.556"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint35_linear_6353_87482"
					x1="362.947"
					y1="221.811"
					x2="468.21"
					y2="-155.029"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint36_linear_6353_87482"
					x1="366.816"
					y1="213.715"
					x2="455.392"
					y2="-133.386"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint37_linear_6353_87482"
					x1="370.717"
					y1="205.826"
					x2="441.612"
					y2="-106.065"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint38_linear_6353_87482"
					x1="374.646"
					y1="198.037"
					x2="432.483"
					y2="-84.4005"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint39_linear_6353_87482"
					x1="378.617"
					y1="191.382"
					x2="429.598"
					y2="-73.8564"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint40_linear_6353_87482"
					x1="382.596"
					y1="188.783"
					x2="439.687"
					y2="-90.6344"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint41_linear_6353_87482"
					x1="386.596"
					y1="186.127"
					x2="451.034"
					y2="-109.214"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint42_linear_6353_87482"
					x1="390.607"
					y1="183.414"
					x2="462.539"
					y2="-126.976"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint43_linear_6353_87482"
					x1="394.627"
					y1="180.453"
					x2="473.621"
					y2="-143.099"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint44_linear_6353_87482"
					x1="398.656"
					y1="177.109"
					x2="484.764"
					y2="-158.858"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint45_linear_6353_87482"
					x1="402.686"
					y1="173.738"
					x2="495.857"
					y2="-173.786"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint46_linear_6353_87482"
					x1="406.717"
					y1="169.99"
					x2="505.001"
					y2="-185.156"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint47_linear_6353_87482"
					x1="410.707"
					y1="165.756"
					x2="512.622"
					y2="-194.234"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint48_linear_6353_87482"
					x1="414.426"
					y1="161.129"
					x2="518.246"
					y2="-200.79"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint49_linear_6353_87482"
					x1="418.047"
					y1="156.623"
					x2="523.731"
					y2="-207.091"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint50_linear_6353_87482"
					x1="421.547"
					y1="152.178"
					x2="528.796"
					y2="-212.815"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint51_linear_6353_87482"
					x1="424.936"
					y1="147.843"
					x2="533.511"
					y2="-218.013"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint52_linear_6353_87482"
					x1="428.217"
					y1="143.494"
					x2="537.923"
					y2="-222.897"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint53_linear_6353_87482"
					x1="431.396"
					y1="139.205"
					x2="542.63"
					y2="-228.291"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint54_linear_6353_87482"
					x1="434.477"
					y1="134.738"
					x2="548.34"
					y2="-235.46"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint55_linear_6353_87482"
					x1="437.447"
					y1="130.232"
					x2="553.486"
					y2="-241.916"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint56_linear_6353_87482"
					x1="440.307"
					y1="126.087"
					x2="557.524"
					y2="-246.534"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint57_linear_6353_87482"
					x1="443.076"
					y1="122.07"
					x2="561.586"
					y2="-251.168"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint58_linear_6353_87482"
					x1="445.746"
					y1="118.215"
					x2="565.387"
					y2="-255.398"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint59_linear_6353_87482"
					x1="448.316"
					y1="114.04"
					x2="570.977"
					y2="-262.534"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint60_linear_6353_87482"
					x1="450.787"
					y1="110.591"
					x2="572.579"
					y2="-263.581"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint61_linear_6353_87482"
					x1="453.166"
					y1="107.166"
					x2="574.298"
					y2="-264.905"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint62_linear_6353_87482"
					x1="455.447"
					y1="103.974"
					x2="575.127"
					y2="-264.925"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint63_linear_6353_87482"
					x1="457.637"
					y1="100.941"
					x2="575.49"
					y2="-264.292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint64_linear_6353_87482"
					x1="459.736"
					y1="98.1206"
					x2="575.275"
					y2="-262.773"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint65_linear_6353_87482"
					x1="461.746"
					y1="95.3352"
					x2="574.944"
					y2="-261.185"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint66_linear_6353_87482"
					x1="463.666"
					y1="92.4672"
					x2="575.559"
					y2="-261.155"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint67_linear_6353_87482"
					x1="465.496"
					y1="89.9204"
					x2="574.627"
					y2="-258.743"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint68_linear_6353_87482"
					x1="467.246"
					y1="87.2688"
					x2="573.925"
					y2="-256.876"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint69_linear_6353_87482"
					x1="468.906"
					y1="84.8895"
					x2="572.799"
					y2="-254.24"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint70_linear_6353_87482"
					x1="470.486"
					y1="82.6045"
					x2="571.288"
					y2="-251.037"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint71_linear_6353_87482"
					x1="471.977"
					y1="80.4896"
					x2="569.796"
					y2="-247.795"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint72_linear_6353_87482"
					x1="473.396"
					y1="78.7532"
					x2="570.096"
					y2="-247.024"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint73_linear_6353_87482"
					x1="474.726"
					y1="77.916"
					x2="573.346"
					y2="-250.032"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint74_linear_6353_87482"
					x1="475.985"
					y1="77.1636"
					x2="576.347"
					y2="-252.673"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint75_linear_6353_87482"
					x1="477.166"
					y1="76.525"
					x2="579.039"
					y2="-254.855"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint76_linear_6353_87482"
					x1="478.265"
					y1="75.9773"
					x2="581.69"
					y2="-257.016"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint77_linear_6353_87482"
					x1="479.296"
					y1="75.5016"
					x2="584.043"
					y2="-258.786"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint78_linear_6353_87482"
					x1="480.256"
					y1="75.0289"
					x2="586.559"
					y2="-260.907"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint79_linear_6353_87482"
					x1="481.146"
					y1="74.6759"
					x2="588.623"
					y2="-262.389"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint80_linear_6353_87482"
					x1="481.956"
					y1="74.3724"
					x2="590.428"
					y2="-263.608"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint81_linear_6353_87482"
					x1="482.706"
					y1="74.163"
					x2="592.079"
					y2="-264.637"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint82_linear_6353_87482"
					x1="483.386"
					y1="74.1156"
					x2="592.764"
					y2="-264.313"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint83_linear_6353_87482"
					x1="483.995"
					y1="73.9806"
					x2="594.092"
					y2="-265.121"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint84_linear_6353_87482"
					x1="484.546"
					y1="73.8686"
					x2="595.159"
					y2="-265.675"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint85_linear_6353_87482"
					x1="485.026"
					y1="73.7985"
					x2="596.058"
					y2="-266.123"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint86_linear_6353_87482"
					x1="485.447"
					y1="73.7392"
					x2="596.814"
					y2="-266.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint87_linear_6353_87482"
					x1="485.806"
					y1="73.7273"
					x2="597.437"
					y2="-266.796"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint88_linear_6353_87482"
					x1="486.116"
					y1="73.7182"
					x2="597.909"
					y2="-267.019"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint89_linear_6353_87482"
					x1="486.356"
					y1="73.6986"
					x2="598.168"
					y2="-267.13"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint90_linear_6353_87482"
					x1="486.537"
					y1="73.6785"
					x2="598.287"
					y2="-267.2"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint91_linear_6353_87482"
					x1="486.666"
					y1="73.6719"
					x2="598.244"
					y2="-267.176"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint92_linear_6353_87482"
					x1="1620.47"
					y1="297.304"
					x2="2112.49"
					y2="155.488"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint93_linear_6353_87482"
					x1="1606.37"
					y1="295.792"
					x2="2098.66"
					y2="155.071"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint94_linear_6353_87482"
					x1="1593.7"
					y1="294.41"
					x2="2085.34"
					y2="155.299"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint95_linear_6353_87482"
					x1="1582.2"
					y1="293.142"
					x2="2072.45"
					y2="156.017"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint96_linear_6353_87482"
					x1="1572.19"
					y1="292.001"
					x2="2059.89"
					y2="157.445"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint97_linear_6353_87482"
					x1="1564.2"
					y1="290.951"
					x2="2047.71"
					y2="159.817"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint98_linear_6353_87482"
					x1="1556.08"
					y1="290.009"
					x2="2035.38"
					y2="162.168"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint99_linear_6353_87482"
					x1="1548.47"
					y1="289.196"
					x2="2022.97"
					y2="164.849"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint100_linear_6353_87482"
					x1="1541.28"
					y1="288.481"
					x2="2010.31"
					y2="167.842"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint101_linear_6353_87482"
					x1="1534.68"
					y1="287.889"
					x2="1997.43"
					y2="171.248"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint102_linear_6353_87482"
					x1="1528.47"
					y1="287.425"
					x2="1984.13"
					y2="175.052"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint103_linear_6353_87482"
					x1="1522.51"
					y1="287.038"
					x2="1970.33"
					y2="179.124"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint104_linear_6353_87482"
					x1="1516.64"
					y1="286.805"
					x2="1955.9"
					y2="183.536"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint105_linear_6353_87482"
					x1="1510.75"
					y1="286.653"
					x2="1940.72"
					y2="188.166"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint106_linear_6353_87482"
					x1="1504.71"
					y1="286.624"
					x2="1924.72"
					y2="193.039"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint107_linear_6353_87482"
					x1="1498.38"
					y1="286.754"
					x2="1907.71"
					y2="198.198"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint108_linear_6353_87482"
					x1="1491.63"
					y1="287.112"
					x2="1888.9"
					y2="204.007"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint109_linear_6353_87482"
					x1="1484.3"
					y1="287.598"
					x2="1868.3"
					y2="210.201"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint110_linear_6353_87482"
					x1="1476.28"
					y1="288.191"
					x2="1846.28"
					y2="216.509"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint111_linear_6353_87482"
					x1="1467.41"
					y1="288.855"
					x2="1823.23"
					y2="222.656"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint112_linear_6353_87482"
					x1="1457.56"
					y1="289.543"
					x2="1799.55"
					y2="228.38"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint113_linear_6353_87482"
					x1="1446.61"
					y1="290.266"
					x2="1775.68"
					y2="233.533"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint114_linear_6353_87482"
					x1="1434.4"
					y1="290.767"
					x2="1752.11"
					y2="237.735"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint115_linear_6353_87482"
					x1="1420.81"
					y1="290.647"
					x2="1729.27"
					y2="240.554"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint116_linear_6353_87482"
					x1="1405.71"
					y1="291.046"
					x2="1707.52"
					y2="242.702"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint117_linear_6353_87482"
					x1="1390.89"
					y1="292.026"
					x2="1680.35"
					y2="247.452"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint118_linear_6353_87482"
					x1="1376.31"
					y1="294.32"
					x2="1639.78"
					y2="257.968"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint119_linear_6353_87482"
					x1="1361.7"
					y1="297.086"
					x2="1593.07"
					y2="269.699"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint120_linear_6353_87482"
					x1="1347.04"
					y1="299.461"
					x2="1548.45"
					y2="279.139"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint121_linear_6353_87482"
					x1="1332.34"
					y1="300.453"
					x2="1514.47"
					y2="284.009"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint122_linear_6353_87482"
					x1="1317.62"
					y1="299.581"
					x2="1493.5"
					y2="284.238"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint123_linear_6353_87482"
					x1="1302.87"
					y1="298.169"
					x2="1476.45"
					y2="283.132"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint124_linear_6353_87482"
					x1="1288.08"
					y1="295.367"
					x2="1461.73"
					y2="280.257"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint125_linear_6353_87482"
					x1="1273.29"
					y1="290.373"
					x2="1448.09"
					y2="275.131"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint126_linear_6353_87482"
					x1="1258.46"
					y1="285.348"
					x2="1434.08"
					y2="270.048"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint127_linear_6353_87482"
					x1="1243.61"
					y1="280.95"
					x2="1418.42"
					y2="265.89"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint128_linear_6353_87482"
					x1="1228.75"
					y1="277.789"
					x2="1399.75"
					y2="263.478"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint129_linear_6353_87482"
					x1="1213.86"
					y1="276.279"
					x2="1375.68"
					y2="263.545"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint130_linear_6353_87482"
					x1="1198.97"
					y1="276.472"
					x2="1341.92"
					y2="266.6"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint131_linear_6353_87482"
					x1="1184.07"
					y1="278.022"
					x2="1302.58"
					y2="271.286"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint132_linear_6353_87482"
					x1="1169.17"
					y1="280.309"
					x2="1260.74"
					y2="276.316"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint133_linear_6353_87482"
					x1="1154.25"
					y1="282.758"
					x2="1218.8"
					y2="280.789"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint134_linear_6353_87482"
					x1="1139.33"
					y1="284.789"
					x2="1179.94"
					y2="284.015"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint135_linear_6353_87482"
					x1="1124.42"
					y1="285.806"
					x2="1149.9"
					y2="285.503"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint136_linear_6353_87482"
					x1="1105.9"
					y1="285.208"
					x2="1128.78"
					y2="284.966"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint137_linear_6353_87482"
					x1="1072.31"
					y1="282.865"
					x2="1107.51"
					y2="282.296"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint138_linear_6353_87482"
					x1="1028.98"
					y1="279.295"
					x2="1083.31"
					y2="277.947"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint139_linear_6353_87482"
					x1="979.886"
					y1="275.072"
					x2="1056.82"
					y2="272.384"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint140_linear_6353_87482"
					x1="929.106"
					y1="270.733"
					x2="1033.91"
					y2="265.762"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint141_linear_6353_87482"
					x1="880.556"
					y1="266.834"
					x2="1012.54"
					y2="258.964"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint142_linear_6353_87482"
					x1="838.325"
					y1="263.922"
					x2="992.046"
					y2="253.251"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint143_linear_6353_87482"
					x1="806.366"
					y1="262.551"
					x2="973.473"
					y2="249.928"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint144_linear_6353_87482"
					x1="782.946"
					y1="262.91"
					x2="959.286"
					y2="248.826"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint145_linear_6353_87482"
					x1="759.235"
					y1="263.718"
					x2="943.512"
					y2="248.275"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint146_linear_6353_87482"
					x1="735.165"
					y1="264.749"
					x2="929.679"
					y2="247.437"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint147_linear_6353_87482"
					x1="710.896"
					y1="265.901"
					x2="916.155"
					y2="246.486"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint148_linear_6353_87482"
					x1="686.595"
					y1="266.995"
					x2="902.908"
					y2="245.272"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint149_linear_6353_87482"
					x1="662.396"
					y1="267.934"
					x2="889.315"
					y2="243.862"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint150_linear_6353_87482"
					x1="638.446"
					y1="268.589"
					x2="875.545"
					y2="242.162"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint151_linear_6353_87482"
					x1="614.915"
					y1="268.813"
					x2="861.283"
					y2="240.185"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint152_linear_6353_87482"
					x1="591.935"
					y1="268.471"
					x2="846.272"
					y2="237.953"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint153_linear_6353_87482"
					x1="569.675"
					y1="267.446"
					x2="830.205"
					y2="235.544"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint154_linear_6353_87482"
					x1="548.095"
					y1="265.954"
					x2="812.758"
					y2="233.215"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint155_linear_6353_87482"
					x1="527.456"
					y1="265.142"
					x2="794.67"
					y2="231.746"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint156_linear_6353_87482"
					x1="507.675"
					y1="264.97"
					x2="776.144"
					y2="231.054"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint157_linear_6353_87482"
					x1="488.556"
					y1="265.295"
					x2="757.49"
					y2="230.912"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint158_linear_6353_87482"
					x1="469.886"
					y1="265.938"
					x2="739.331"
					y2="230.969"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint159_linear_6353_87482"
					x1="451.457"
					y1="266.775"
					x2="721.568"
					y2="231.121"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint160_linear_6353_87482"
					x1="433.057"
					y1="267.614"
					x2="704.357"
					y2="231.117"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint161_linear_6353_87482"
					x1="414.496"
					y1="268.32"
					x2="687.372"
					y2="230.914"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint162_linear_6353_87482"
					x1="395.547"
					y1="268.735"
					x2="670.648"
					y2="230.338"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint163_linear_6353_87482"
					x1="375.986"
					y1="268.691"
					x2="654.08"
					y2="229.252"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint164_linear_6353_87482"
					x1="355.656"
					y1="268.019"
					x2="637.745"
					y2="227.497"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint165_linear_6353_87482"
					x1="335.316"
					y1="266.472"
					x2="616.746"
					y2="226.462"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint166_linear_6353_87482"
					x1="314.977"
					y1="265.306"
					x2="598.367"
					y2="225.012"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint167_linear_6353_87482"
					x1="294.676"
					y1="264.523"
					x2="582.237"
					y2="223.271"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint168_linear_6353_87482"
					x1="274.467"
					y1="264.077"
					x2="568.251"
					y2="221.222"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint169_linear_6353_87482"
					x1="254.316"
					y1="263.937"
					x2="556.07"
					y2="218.891"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint170_linear_6353_87482"
					x1="234.326"
					y1="264.08"
					x2="545.699"
					y2="216.241"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint171_linear_6353_87482"
					x1="214.506"
					y1="264.484"
					x2="536.632"
					y2="213.375"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint172_linear_6353_87482"
					x1="194.877"
					y1="265.101"
					x2="528.818"
					y2="210.216"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint173_linear_6353_87482"
					x1="175.467"
					y1="265.909"
					x2="522"
					y2="206.797"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint174_linear_6353_87482"
					x1="156.326"
					y1="266.879"
					x2="515.389"
					y2="203.356"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint175_linear_6353_87482"
					x1="137.467"
					y1="267.992"
					x2="510.115"
					y2="199.442"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint176_linear_6353_87482"
					x1="118.957"
					y1="269.201"
					x2="505.154"
					y2="195.372"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint177_linear_6353_87482"
					x1="100.756"
					y1="270.498"
					x2="500.262"
					y2="191.216"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint178_linear_6353_87482"
					x1="82.9766"
					y1="271.842"
					x2="494.905"
					y2="187.202"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint179_linear_6353_87482"
					x1="65.5957"
					y1="273.192"
					x2="488.797"
					y2="183.431"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint180_linear_6353_87482"
					x1="48.6855"
					y1="274.545"
					x2="481.697"
					y2="180.081"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint181_linear_6353_87482"
					x1="32.2363"
					y1="275.85"
					x2="473.343"
					y2="177.265"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<clipPath id="clip0_6353_87482">
					<rect
						width="2093.2"
						height="494.53"
						fill="white"
						transform="translate(50.5801 34)"
					/>
				</clipPath>
			</defs>
		</Icon>
	);
}
