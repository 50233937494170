import { Icon, IconProps } from '@chakra-ui/react';

const LooperGroup = ({
	width = '835',
	height = '714',
	...props
}: IconProps) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 835 714"
			fill="none"
			{...props}
		>
			<path
				opacity="0.01"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M560.498 352.643C571.929 422.522 629.819 503.986 584.732 558.482C541 611.34 452.877 557.011 385.026 564.732C321.979 571.907 263.846 619.9 203.018 601.918C138.073 582.719 84.9948 529.535 56.6826 467.667C28.9235 407.007 30.5767 335.399 52.0953 272.12C71.5424 214.933 124.578 180.782 168.332 139.346C210.447 99.4612 244.5 36.5379 302.252 35.2121C360.11 33.8839 396.122 96.0534 440.707 133.093C474.287 160.99 509.133 186.076 530.095 224.495C551.616 263.938 553.23 308.216 560.498 352.643Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.02"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M563.549 350.68C576.157 420.116 635.264 500.289 591.281 555.378C548.62 608.812 459.856 556.205 392.373 565.079C329.669 573.325 272.571 622.161 211.641 605.3C146.586 587.297 92.7649 535.222 63.4738 474.062C34.7549 414.097 35.1563 342.71 55.4995 279.279C73.8845 221.953 126.143 187 169.024 144.947C210.3 104.47 243.141 41.1743 300.67 38.849C358.305 36.5195 395.275 97.8455 440.352 133.981C474.301 161.196 509.463 185.589 531.021 223.509C553.154 262.44 555.533 306.535 563.549 350.68Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.03"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M566.533 348.676C580.306 417.648 640.603 496.513 597.731 552.173C556.147 606.161 466.775 555.283 399.68 565.299C337.337 574.606 281.286 624.264 220.273 608.521C155.131 591.713 100.59 540.756 70.3358 480.32C40.6729 421.065 39.8308 349.921 58.9998 286.358C76.3236 228.914 127.793 193.175 169.794 150.524C210.223 109.471 241.848 45.8263 299.138 42.5089C356.533 39.1853 394.443 99.6534 439.991 134.878C474.297 161.408 509.761 185.103 531.905 222.516C554.639 260.926 557.777 304.825 566.533 348.676Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.04"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M569.449 346.63C584.374 415.121 645.834 492.659 604.079 548.87C563.579 603.391 473.632 554.246 406.945 565.393C344.979 575.752 289.988 626.21 228.913 611.583C163.704 595.967 108.465 546.137 77.265 486.44C46.6741 427.909 44.5971 357.03 62.5936 293.358C78.8578 235.814 129.526 199.306 170.64 156.075C210.214 114.463 240.622 50.4926 297.656 46.1906C354.794 41.8807 393.623 101.477 439.627 135.786C474.274 161.625 510.028 184.621 532.746 221.517C556.069 259.396 559.96 303.086 569.449 346.63Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.05"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M572.297 344.546C588.361 412.536 650.957 488.732 610.325 545.47C570.915 600.503 480.426 553.095 414.165 565.363C352.596 576.762 298.673 627.999 237.556 614.486C172.303 600.058 116.39 551.365 84.2597 492.421C52.757 434.629 49.454 364.036 66.2801 300.276C81.4865 242.653 131.343 205.392 171.562 161.599C210.274 119.445 239.463 55.1719 296.223 49.8933C353.087 44.6051 392.818 103.316 439.258 136.703C474.235 161.849 510.265 184.142 533.545 220.513C557.446 257.853 562.084 301.321 572.297 344.546Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.06"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M575.076 342.425C592.267 409.894 655.97 484.732 616.467 541.975C578.152 597.499 487.154 551.833 421.338 565.209C360.182 577.639 307.34 629.632 246.201 617.229C180.925 603.988 124.36 556.439 91.3168 498.263C58.9187 441.223 54.3992 370.938 70.0571 307.111C84.2079 249.427 133.242 211.431 172.558 167.094C210.402 124.417 238.371 59.8632 294.841 53.6162C351.414 47.3578 392.028 105.17 438.887 137.631C474.179 162.08 510.47 183.667 534.302 219.504C558.769 256.296 564.147 299.53 575.076 342.425Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.07"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M577.785 340.267C596.089 407.198 660.87 480.662 622.502 538.389C585.287 594.381 493.814 550.46 428.461 564.934C367.736 578.383 315.983 631.11 254.843 619.815C189.565 607.755 132.372 561.36 98.4319 503.965C65.1551 447.691 59.4286 377.734 73.9213 313.861C87.019 256.136 135.221 217.423 173.627 172.559C210.596 129.376 237.344 64.5652 293.507 57.3584C349.773 50.1383 391.25 107.038 438.51 138.57C474.103 162.318 510.643 183.198 535.015 218.493C560.036 254.729 566.147 297.715 577.785 340.267Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.08"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M580.422 338.076C599.827 404.449 665.658 476.525 628.429 534.713C592.319 591.153 500.403 548.98 435.532 564.538C375.255 578.995 324.601 632.434 263.479 622.243C198.221 611.362 140.422 566.126 105.603 509.526C71.4642 454.031 64.5409 384.424 77.8716 320.526C89.9191 262.78 137.278 223.366 174.769 177.994C210.856 134.321 236.383 69.2768 292.223 61.1189C348.166 52.9461 390.487 108.922 438.129 139.519C474.01 162.564 510.785 182.733 535.685 217.479C561.248 253.15 568.086 295.877 580.422 338.076Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.09"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M582.989 335.851C603.48 401.65 670.332 472.322 634.247 530.95C599.245 587.817 506.921 547.392 442.549 564.023C382.736 579.477 333.19 633.606 272.107 624.514C206.89 614.807 148.507 570.738 112.827 514.946C77.8433 460.243 69.7337 391.005 81.9059 327.104C92.9064 269.355 139.412 229.258 175.982 183.397C211.183 139.253 235.488 73.9967 290.989 64.8969C346.592 55.7803 389.738 110.82 437.744 140.479C473.899 162.817 510.895 182.275 536.312 216.463C562.405 251.563 569.962 294.018 582.989 335.851Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.1"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M585.484 333.595C607.047 398.801 674.892 468.057 639.954 527.102C606.066 584.373 513.365 545.7 449.51 563.391C390.177 579.829 341.748 634.626 280.723 626.629C215.568 618.091 156.626 575.196 120.101 520.224C84.2899 466.327 75.0047 397.478 86.0223 333.595C95.9795 275.861 141.624 235.099 177.266 188.767C211.574 144.169 234.658 78.724 289.805 68.6915C345.053 58.6406 389.003 112.732 437.355 141.45C473.771 163.079 510.974 181.824 536.895 215.447C563.508 249.967 571.776 292.138 585.484 333.595Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.11"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M587.907 331.309C610.527 395.905 679.336 463.732 645.549 523.172C612.777 580.827 519.733 543.905 456.412 562.643C397.576 580.054 350.272 635.495 289.325 628.589C224.252 621.214 164.773 579.499 127.422 525.361C90.8007 472.28 80.3511 403.84 90.2186 339.996C99.1362 282.297 143.909 240.888 178.62 194.102C212.03 149.068 233.894 83.4574 288.67 72.5018C343.547 61.5262 388.282 114.659 436.962 142.432C473.625 163.35 511.022 181.38 537.436 214.432C564.554 248.364 573.526 290.24 587.907 331.309Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.12"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M590.257 328.994C613.92 392.964 683.663 459.349 651.03 519.162C619.378 577.178 526.022 542.008 463.254 561.78C404.929 580.152 358.758 636.215 297.908 630.394C232.939 624.178 172.946 583.648 134.787 530.355C97.3728 478.103 85.7701 410.092 94.4921 346.307C102.375 288.661 146.268 246.623 180.041 199.402C212.549 153.95 233.194 88.1958 287.585 76.327C342.076 64.4365 387.575 116.599 436.564 143.426C473.461 163.63 511.038 180.945 537.933 213.417C565.544 246.755 575.213 288.324 590.257 328.994Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.13"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M592.532 326.653C617.223 389.979 687.871 454.911 656.395 515.075C625.864 573.431 532.231 540.012 470.031 560.804C412.234 580.124 367.203 636.788 306.469 632.046C241.624 626.983 181.141 587.643 142.191 535.207C104.002 483.795 91.2584 416.233 98.8399 352.526C105.692 294.952 148.697 252.303 181.528 204.666C213.13 158.813 232.558 92.9382 286.548 80.1664C340.637 67.3711 386.881 118.553 436.161 144.43C473.277 163.92 511.021 180.518 538.385 212.405C566.477 245.142 576.834 286.392 592.532 326.653Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.14"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M594.734 324.286C620.437 386.953 691.961 450.419 661.644 510.912C632.238 569.588 538.36 537.919 476.744 559.717C419.491 579.972 375.606 637.213 315.007 633.545C250.307 629.628 189.357 591.484 149.635 539.916C110.689 489.355 96.8155 422.26 103.262 358.654C109.088 301.17 151.198 257.928 183.083 209.893C213.774 163.656 231.986 97.6833 285.561 84.0187C339.233 70.3291 386.202 120.521 435.756 145.447C473.077 164.219 510.975 180.101 538.794 211.396C567.355 243.525 578.392 284.445 594.734 324.286Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.15"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M596.861 321.896C623.561 383.887 695.931 445.878 666.775 506.678C638.495 565.65 544.404 535.73 483.388 558.521C426.693 579.698 383.962 637.494 323.518 634.893C258.982 632.116 197.588 595.171 157.112 544.482C117.427 494.783 102.437 428.174 107.754 364.688C112.56 307.312 153.766 263.495 184.701 215.081C214.478 168.479 231.478 102.43 284.622 87.8835C337.863 73.3102 385.535 122.502 435.344 146.474C472.858 164.53 510.896 179.694 539.159 210.391C568.176 241.906 579.885 282.485 596.861 321.896Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.16"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M598.912 319.485C626.594 380.784 699.781 441.289 671.787 502.373C644.634 561.622 550.362 533.448 489.964 557.217C433.842 579.303 392.27 637.63 331.998 636.091C267.647 634.446 205.832 598.705 164.621 548.905C124.215 500.079 108.121 433.975 112.316 370.628C116.106 313.379 156.402 269.006 186.384 220.23C215.243 173.281 231.033 107.178 283.732 91.7599C336.528 76.3137 384.883 124.496 434.929 147.514C472.621 164.851 510.785 179.298 539.48 209.391C568.941 240.286 581.313 280.512 598.912 319.485Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.17"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M600.889 317.052C629.536 377.645 703.51 436.654 676.679 498.001C650.655 557.505 556.235 531.075 496.468 555.807C440.933 578.788 400.526 637.624 340.446 637.139C276.299 636.62 214.087 602.085 172.159 553.186C131.05 505.242 113.866 439.66 116.944 376.473C119.725 319.368 159.104 274.457 188.129 225.339C216.067 178.06 230.651 111.925 282.891 95.6469C335.227 79.339 384.245 126.503 434.51 148.566C472.367 165.183 510.643 178.913 539.758 208.397C569.649 238.666 582.675 278.529 600.889 317.052Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.18"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M602.789 314.601C632.386 374.472 707.117 431.977 681.45 493.565C656.555 553.302 562.018 528.612 502.899 554.293C447.965 578.155 408.728 637.478 348.858 638.04C284.935 638.639 222.348 605.313 179.723 557.324C137.93 510.271 119.668 445.23 121.636 382.222C123.415 325.28 161.87 279.849 189.935 230.407C216.95 182.816 230.331 116.671 282.098 99.544C333.961 82.3856 383.62 128.523 434.086 149.63C472.095 165.528 510.471 178.541 539.992 207.409C570.3 237.047 583.973 276.537 602.789 314.601Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.19"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M604.613 312.132C635.143 371.267 710.601 427.259 686.098 489.066C662.332 549.016 567.711 526.062 509.253 552.676C454.935 577.406 416.872 637.192 357.23 638.793C293.551 640.503 230.613 608.389 187.309 561.319C144.851 515.167 125.524 450.685 126.39 387.875C127.172 331.112 164.697 285.18 191.801 235.433C217.89 187.548 230.072 121.415 281.353 103.45C332.728 85.4528 383.009 130.556 433.657 150.707C471.804 165.884 510.266 178.182 540.182 206.43C570.895 235.43 585.203 274.536 604.613 312.132Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.2"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M606.361 309.648C637.807 368.033 713.962 422.504 690.624 484.508C667.987 544.649 573.313 523.426 515.532 550.959C461.842 576.543 424.957 636.769 365.561 639.402C302.145 642.213 238.879 611.314 194.915 565.171C151.81 519.93 131.434 456.023 131.205 393.431C130.997 336.865 167.586 290.45 193.726 240.416C218.887 192.255 229.876 126.155 280.657 107.365C331.531 88.54 382.411 132.601 433.225 151.796C471.496 166.253 510.031 177.836 540.328 205.459C571.433 233.817 586.369 272.529 606.361 309.648Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.21"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M608.033 307.15C640.378 364.77 717.2 417.714 695.025 479.893C673.517 540.204 578.821 520.708 521.731 549.143C468.683 575.566 432.981 636.21 373.849 639.867C310.714 643.771 247.144 614.087 202.539 568.881C158.806 524.559 137.394 461.244 136.077 398.889C134.888 342.537 170.536 295.658 195.71 245.355C219.942 196.936 229.741 130.89 280.009 111.287C330.369 91.6469 381.828 134.658 432.789 152.898C471.17 166.636 509.765 177.505 540.431 204.497C571.915 232.209 587.469 270.517 608.033 307.15Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.22"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M609.628 304.64C642.855 361.482 720.314 412.891 699.302 475.224C678.921 535.684 584.235 517.908 527.85 547.231C475.456 574.478 440.939 635.517 382.089 640.189C319.254 645.177 255.402 616.711 210.177 572.45C165.835 529.053 143.4 466.347 141.005 404.249C138.841 348.127 173.543 300.804 197.75 250.25C221.051 201.59 229.666 135.621 279.408 115.215C329.241 94.7728 381.259 136.728 432.349 154.013C470.827 167.031 509.469 177.188 540.491 203.547C572.34 230.607 588.503 268.501 609.628 304.64Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.23"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M611.144 302.119C645.236 358.17 723.303 408.038 703.451 470.503C684.196 531.091 589.552 515.03 533.884 545.224C482.158 573.281 448.83 634.692 390.278 640.371C327.762 646.433 263.652 619.185 217.824 575.876C172.892 533.414 149.451 471.334 145.985 409.509C142.854 353.636 176.605 305.886 199.844 255.1C222.213 206.217 229.65 140.345 278.853 119.15C328.147 97.9172 380.701 138.81 431.903 155.141C470.465 167.441 509.141 176.888 540.506 202.607C572.708 229.012 589.469 266.483 611.144 302.119Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.24"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M612.584 299.589C647.523 354.835 726.167 403.158 707.474 465.734C689.343 526.429 594.771 512.075 539.835 543.125C488.789 571.976 456.651 633.736 398.415 640.412C336.237 647.54 271.891 621.51 225.48 579.162C179.977 537.641 155.544 476.202 151.017 414.67C146.926 359.061 179.722 310.903 201.993 259.904C223.429 210.815 229.694 145.061 278.346 123.091C327.088 101.079 380.158 140.904 431.453 156.283C470.086 167.865 508.783 176.603 540.479 201.68C573.019 227.426 590.37 264.464 612.584 299.589Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.25"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M613.946 297.051C649.716 351.482 728.906 398.253 711.371 460.918C694.362 521.7 599.893 509.046 545.7 540.935C495.345 570.566 464.4 632.652 406.497 640.317C344.675 648.5 280.116 623.687 233.143 582.307C187.087 541.734 161.678 480.952 156.098 419.731C151.056 364.403 182.893 315.856 204.195 264.662C224.698 215.385 229.797 149.77 277.887 127.035C326.065 104.259 379.629 143.01 431 157.438C469.691 168.305 508.395 176.336 540.409 200.767C573.276 225.849 591.205 262.446 613.946 297.051Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.26"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M615.231 294.508C651.812 348.11 731.519 393.326 715.138 456.059C699.249 516.907 604.913 505.945 551.477 538.656C501.825 569.051 472.075 631.442 414.521 640.085C353.072 649.313 288.323 625.717 240.806 585.311C194.218 545.694 167.848 485.584 161.225 424.692C155.241 369.661 186.115 320.743 206.447 269.372C226.018 219.925 229.958 154.47 277.473 130.984C325.075 107.455 379.112 145.127 430.542 158.607C469.277 168.759 507.976 176.087 540.295 199.868C573.475 224.283 591.973 260.429 615.231 294.508Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.27"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M616.437 291.96C653.813 344.722 734.007 388.38 718.777 451.16C704.005 512.053 609.832 502.775 557.165 536.292C508.227 567.435 479.672 630.106 422.485 639.718C361.426 649.981 296.51 627.602 248.47 588.176C201.368 549.52 174.052 490.097 166.397 429.552C159.478 374.834 189.387 325.565 208.75 274.035C227.389 224.435 230.176 159.159 277.105 134.936C324.12 110.668 378.609 147.257 430.081 159.79C468.847 169.229 507.528 175.855 540.138 198.984C573.618 222.729 592.675 258.416 616.437 291.96Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.28"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M617.566 289.41C655.718 341.321 736.368 383.416 722.287 446.222C708.628 507.141 614.648 499.536 562.762 533.842C514.55 565.719 487.191 628.649 430.385 639.219C369.735 650.505 304.675 629.342 256.13 590.902C208.534 553.213 180.288 494.492 171.61 434.31C163.767 379.922 192.707 330.32 211.103 278.649C228.809 228.914 230.452 163.838 276.784 138.89C323.2 113.897 378.119 149.397 429.615 160.987C468.399 169.716 507.049 175.643 539.939 198.116C573.705 221.188 593.311 256.407 617.566 289.41Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.29"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M618.618 286.86C657.527 337.907 738.604 378.438 725.666 441.249C713.117 502.173 619.361 496.233 568.266 531.311C520.79 563.905 494.628 627.07 438.221 638.589C377.995 650.888 312.813 630.938 263.784 593.49C215.713 556.774 186.554 498.769 176.863 438.968C168.105 384.924 196.074 335.007 213.503 283.214C230.278 233.361 230.784 168.505 276.507 142.846C322.314 117.14 377.643 151.55 429.145 162.199C467.934 170.219 506.542 175.451 539.698 197.266C573.737 219.662 593.88 254.405 618.618 286.86Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.3"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M619.591 284.309C659.239 334.484 740.713 373.448 728.915 436.243C717.472 497.152 623.968 492.867 573.677 528.7C526.946 561.995 501.98 625.374 445.987 637.83C386.204 651.13 320.922 632.391 271.429 595.94C222.902 560.201 192.846 502.926 182.153 443.524C172.489 389.839 199.486 339.628 215.948 287.73C231.794 237.775 231.172 173.16 276.276 146.804C321.462 120.399 377.179 153.713 428.671 163.425C467.453 170.739 506.005 175.279 539.414 196.433C573.713 218.15 594.383 252.41 619.591 284.309Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.31"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M620.487 281.762C660.856 331.053 742.696 368.449 732.034 431.208C721.693 492.081 628.47 489.441 578.992 526.011C533.018 559.992 509.248 623.561 453.684 636.944C394.36 651.233 329.001 633.703 279.062 598.254C230.1 563.497 199.163 506.966 187.479 447.978C176.92 394.668 202.941 344.18 218.439 292.195C233.358 242.157 231.615 177.802 276.09 150.761C320.646 123.672 376.729 155.888 428.194 164.666C466.955 171.277 505.439 175.128 539.088 195.619C573.635 216.656 594.821 250.424 620.487 281.762Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.32"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M621.304 279.218C662.376 327.617 744.553 363.444 735.022 426.146C725.777 486.964 632.864 485.956 584.211 523.247C539.002 557.897 516.427 621.634 461.308 635.933C402.458 651.199 337.045 634.874 286.681 600.431C237.302 566.662 205.502 510.887 192.838 452.33C181.393 399.41 206.438 348.664 220.974 296.61C234.966 246.506 232.113 182.429 275.948 154.719C319.863 126.958 376.292 158.073 427.712 165.922C466.44 171.832 504.844 174.999 538.721 194.825C573.5 215.179 595.193 248.448 621.304 279.218Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.33"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M622.045 276.681C663.799 324.176 746.284 358.434 737.878 421.059C729.725 481.802 637.151 482.416 589.332 520.409C544.898 555.712 523.517 619.595 468.857 634.798C410.498 651.03 345.052 635.907 294.283 602.474C244.507 569.695 211.86 514.69 198.227 456.58C185.907 404.065 209.975 353.079 223.552 300.974C236.62 250.82 232.664 187.041 275.85 158.675C319.115 130.258 375.868 160.27 427.228 167.193C465.909 172.407 504.221 174.893 538.312 194.052C573.312 213.722 595.499 246.484 622.045 276.681Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.34"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M622.709 274.15C665.128 320.734 747.889 353.423 740.604 415.951C733.537 476.6 641.33 478.823 594.354 517.501C550.705 553.439 530.515 617.447 476.33 633.542C418.477 650.727 353.02 636.802 301.866 604.383C251.712 572.598 218.236 518.375 203.646 460.729C190.462 408.632 213.551 357.425 226.171 305.286C238.318 255.1 233.27 191.638 275.797 162.631C318.402 133.57 375.457 162.478 426.74 168.48C465.363 173 503.571 174.809 537.864 193.3C573.07 212.285 595.741 244.533 622.709 274.15Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.35"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M623.294 271.629C666.358 317.293 749.367 348.413 743.196 410.824C737.209 471.359 645.397 475.179 599.275 514.524C556.418 551.082 537.418 615.191 483.721 632.167C426.391 650.292 360.944 637.561 309.425 606.159C258.913 575.371 224.624 521.942 209.09 464.775C195.052 413.111 217.163 361.702 228.828 309.547C240.057 259.345 233.926 196.218 275.785 166.584C317.721 136.895 375.058 164.697 426.247 169.782C464.8 173.612 502.891 174.749 537.373 192.572C572.773 210.869 595.916 242.597 623.294 271.629Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.36"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M623.804 269.118C667.493 313.853 750.721 343.406 745.657 405.68C740.745 466.083 649.355 471.486 604.095 511.48C562.039 548.642 544.226 612.83 491.032 630.675C434.238 649.727 368.825 638.185 316.96 607.803C266.109 578.015 231.025 525.393 214.559 468.72C199.678 417.502 220.811 365.909 231.525 313.755C241.839 263.554 234.636 200.781 275.818 170.534C317.076 140.231 374.673 166.926 425.752 171.1C464.222 174.244 502.185 174.714 536.842 191.866C572.423 209.475 596.027 240.676 623.804 269.118Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.37"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.236 266.619C668.533 310.418 751.95 338.406 747.987 400.524C744.143 460.775 653.202 467.748 608.812 508.373C567.566 546.12 550.936 610.367 498.259 629.068C442.017 649.034 376.658 638.676 324.468 609.317C273.297 580.531 237.436 528.726 220.05 472.562C204.338 421.805 224.492 370.047 234.26 317.911C243.661 267.728 235.397 205.327 275.893 174.481C316.464 143.579 374.301 169.166 425.253 172.435C463.628 174.897 501.452 174.703 536.272 191.185C572.02 208.105 596.074 238.773 624.236 266.619Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.38"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.593 264.134C669.476 306.99 753.054 333.415 750.185 395.357C747.402 455.437 656.937 463.966 613.426 505.204C572.996 543.521 557.547 607.804 505.401 627.348C449.725 648.215 384.441 639.036 331.945 610.701C280.474 582.919 243.855 531.943 225.561 476.303C209.029 426.019 228.206 374.115 237.03 322.014C245.524 271.865 236.208 209.854 276.011 178.425C315.887 146.938 373.942 171.417 424.752 173.786C463.019 175.57 500.692 174.719 535.662 190.529C571.565 206.76 596.057 236.888 624.593 264.134Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.39"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.873 261.665C670.325 303.569 754.033 328.435 752.251 390.182C750.522 450.073 660.561 460.143 617.936 501.975C578.329 540.845 564.057 605.143 512.455 625.517C457.36 647.271 392.172 639.265 339.39 611.956C287.639 585.181 250.278 535.044 231.09 479.942C213.75 430.145 231.951 378.112 239.836 326.064C247.425 275.965 237.07 214.361 276.171 182.364C315.343 150.307 373.596 173.679 424.248 175.153C462.396 176.264 499.906 174.76 535.014 189.899C571.057 205.441 595.977 235.023 624.873 261.665Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.4"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M625.079 259.213C671.079 300.159 754.889 323.469 754.186 385.001C753.503 444.685 664.071 456.282 622.34 498.69C583.565 538.095 570.464 602.386 519.419 623.579C464.918 646.205 399.849 639.366 346.8 613.085C294.788 587.316 256.704 538.029 236.635 483.481C218.498 434.183 235.724 382.039 242.675 330.061C249.365 280.028 237.98 218.849 276.372 186.298C314.834 153.687 373.263 175.951 423.741 176.538C461.759 176.98 499.095 174.829 534.327 189.296C570.498 204.149 595.833 233.18 625.079 259.213Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.41"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M625.208 256.779C671.737 296.762 755.621 318.519 755.988 379.819C756.344 439.277 667.467 452.385 626.637 495.35C588.699 535.274 576.766 599.537 526.291 621.534C472.398 645.02 407.467 639.341 354.172 614.087C301.918 589.328 263.129 540.899 242.192 486.918C223.272 438.132 239.524 385.896 245.545 334.003C251.34 284.054 238.939 223.317 276.614 190.226C314.357 157.075 372.942 178.234 423.231 177.939C461.106 177.717 498.257 174.925 533.601 188.721C569.887 202.885 595.626 231.359 625.208 256.779Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.42"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M625.262 254.365C672.3 293.378 756.229 313.588 757.659 374.636C759.046 433.85 670.748 448.454 630.827 491.959C593.732 532.383 582.962 596.598 533.068 619.385C479.798 643.716 415.026 639.19 361.504 614.966C309.027 591.215 269.551 543.656 247.761 490.254C228.068 441.993 243.35 389.683 248.446 337.893C253.351 288.042 239.944 227.763 276.895 194.149C313.914 160.473 372.634 180.527 422.717 179.358C460.438 178.477 497.394 175.049 532.837 188.175C569.225 201.65 595.356 229.562 625.262 254.365Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.43"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M625.242 251.974C672.77 290.011 756.716 308.678 759.199 369.457C761.609 428.409 673.916 444.492 634.909 488.518C598.663 529.426 589.051 593.57 539.752 617.136C487.115 642.296 422.523 638.916 368.793 615.721C316.114 592.979 275.969 546.298 253.339 493.49C232.887 445.766 247.2 393.399 251.377 341.728C255.397 291.991 240.998 232.188 277.218 198.065C313.505 163.88 372.339 182.83 422.203 180.794C459.758 179.26 496.507 175.202 532.037 187.658C568.514 200.446 595.025 227.791 625.242 251.974Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.44"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M625.148 249.606C673.147 286.662 757.08 303.792 760.609 364.283C764.032 422.957 676.969 440.501 638.882 485.029C603.491 526.404 595.031 590.458 546.337 614.787C494.348 640.762 429.955 638.521 376.038 616.355C323.175 594.622 282.381 548.828 258.924 496.627C237.726 449.45 251.073 397.045 254.336 345.509C257.477 295.902 242.097 236.591 277.58 201.974C313.129 167.294 372.056 185.144 421.685 182.248C459.064 180.066 495.596 175.385 531.2 187.171C567.753 199.273 594.632 226.046 625.148 249.606Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.45"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.981 247.262C673.43 283.333 757.324 298.932 761.888 359.118C766.315 417.495 679.907 436.485 642.745 481.496C608.215 523.32 600.9 587.263 552.824 612.341C501.495 639.118 437.321 638.007 383.236 616.869C330.208 596.144 288.782 551.246 264.514 499.664C242.582 453.047 254.966 400.621 257.322 349.236C259.589 299.775 243.242 240.97 277.982 205.876C312.786 170.717 371.786 187.469 421.166 183.72C458.357 180.896 494.66 175.597 530.326 186.716C566.942 198.132 594.179 224.329 624.981 247.262Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.46"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.741 244.945C673.621 280.026 757.447 294.101 763.038 353.963C768.46 412.027 682.73 432.445 646.499 477.921C612.834 520.177 606.658 583.988 559.211 609.802C508.553 637.363 444.62 637.375 390.386 617.265C337.211 597.547 295.174 553.552 270.108 502.601C247.456 456.555 258.88 404.126 260.334 352.908C261.734 303.608 244.432 245.327 278.423 209.77C312.476 174.147 371.53 189.803 420.645 185.209C457.637 181.749 493.702 175.84 529.417 186.293C566.084 197.025 593.665 222.642 624.741 244.945Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.47"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.429 242.656C673.72 276.744 757.451 289.301 764.058 348.823C770.466 406.556 685.437 428.383 650.142 474.306C617.346 516.976 612.302 580.635 565.496 607.171C515.521 635.502 451.846 636.627 397.483 617.543C344.181 598.832 301.551 555.748 275.702 505.441C252.342 459.977 262.81 407.562 263.37 356.527C263.909 307.403 245.666 249.66 278.902 213.655C312.199 177.584 371.285 192.149 420.122 186.718C456.904 182.628 492.721 176.114 528.473 185.903C565.178 195.952 593.092 220.985 624.429 242.656Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.48"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M624.046 240.397C673.727 273.487 757.336 284.535 764.949 343.699C772.333 401.084 688.028 424.304 653.673 470.653C621.751 513.721 617.833 577.208 571.677 604.45C522.397 633.537 459 635.766 404.527 617.707C351.117 600 307.912 557.834 281.296 508.182C257.242 463.311 266.758 410.927 266.43 360.091C266.114 311.158 246.943 253.968 279.419 217.531C311.955 181.028 371.054 194.504 419.598 188.245C456.16 183.531 491.718 176.42 527.495 185.546C564.224 194.915 592.46 219.36 624.046 240.397Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.49"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M623.59 238.169C673.643 270.258 757.103 279.805 765.711 338.593C774.061 395.615 690.503 420.208 657.092 466.966C626.048 510.413 623.247 573.709 577.752 601.643C529.178 631.469 466.079 634.793 411.514 617.756C358.015 601.053 314.254 559.811 286.885 510.827C262.151 466.558 270.719 414.223 269.51 363.6C268.346 314.873 248.261 258.252 279.973 221.398C311.743 184.478 370.834 196.869 419.072 189.79C455.403 184.459 490.692 176.758 526.481 185.224C563.224 193.915 591.768 217.767 623.59 238.169Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.5"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M623.065 235.973C673.469 267.058 756.753 275.113 766.346 333.51C775.651 390.152 692.862 416.099 660.399 463.247C630.236 507.056 628.545 570.14 583.721 598.752C535.863 629.301 473.08 633.711 418.443 617.694C364.874 601.991 320.577 561.681 292.47 513.375C267.069 469.718 274.694 417.449 272.611 367.056C270.607 318.549 249.621 262.51 280.564 225.256C311.563 187.934 370.627 199.245 418.545 191.355C454.634 185.413 489.646 177.129 525.435 184.937C562.178 192.952 591.019 216.21 623.065 235.973Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.51"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M622.47 233.811C673.207 263.89 756.287 270.461 766.854 328.45C777.103 384.697 695.105 411.979 663.594 459.498C634.315 503.652 633.725 566.505 589.582 595.78C542.452 627.036 480.003 632.521 425.313 617.522C371.691 602.816 326.877 563.444 298.048 515.827C271.994 472.792 278.68 420.606 275.732 370.457C272.894 322.185 251.023 266.742 281.192 229.103C311.416 191.395 370.434 201.631 418.017 192.939C453.855 186.393 488.579 177.534 524.356 184.686C561.087 192.028 590.213 214.687 622.47 233.811Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.52"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M621.808 231.685C672.857 260.756 755.708 265.852 767.238 323.418C778.42 379.253 697.234 407.85 666.678 455.721C638.285 500.202 638.789 562.806 595.336 592.728C548.942 624.676 486.847 631.226 432.122 617.241C378.467 603.53 333.155 565.102 303.619 518.183C276.925 475.781 282.679 423.694 278.873 373.804C275.209 325.781 252.466 270.949 281.858 232.94C311.303 194.862 370.254 204.028 417.49 194.543C453.066 187.4 487.493 177.972 523.247 184.471C559.953 191.144 589.352 213.202 621.808 231.685Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.53"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M621.075 229.595C672.417 257.656 755.013 261.289 767.493 318.414C779.599 373.823 699.245 403.715 669.646 451.92C642.143 496.711 643.731 559.045 600.977 589.6C555.33 622.224 493.606 629.827 438.866 616.854C385.195 604.135 339.404 566.655 309.176 520.446C281.858 478.684 286.684 426.714 282.028 377.097C277.547 329.338 253.947 275.129 282.557 236.766C311.219 198.333 370.085 206.434 416.961 196.166C452.266 188.433 486.386 178.445 522.104 184.294C558.774 190.3 588.434 211.755 621.075 229.595Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.54"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M620.275 227.544C671.89 254.594 754.206 256.773 767.625 313.443C780.641 368.41 701.14 399.577 672.501 448.096C645.89 493.179 648.552 555.226 606.507 586.399C561.615 619.681 500.28 628.328 445.543 616.362C391.875 604.631 345.625 568.105 314.721 522.614C286.792 481.503 290.697 429.665 285.199 380.336C279.908 332.854 255.466 279.282 283.291 240.581C311.167 201.809 369.929 208.851 416.431 197.809C451.455 189.493 485.26 178.953 520.931 184.156C557.552 189.498 587.46 210.346 620.275 227.544Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.55"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M619.411 225.533C671.279 251.571 753.288 252.307 767.634 308.506C781.549 363.016 702.922 395.438 675.244 444.252C649.526 489.61 653.254 551.352 611.925 583.126C567.799 617.051 506.87 626.73 452.154 615.768C398.507 605.02 351.817 569.452 320.253 524.69C291.728 484.237 294.717 432.548 288.387 383.521C282.294 336.33 257.025 283.408 284.062 244.384C311.149 205.289 369.787 211.278 415.903 199.472C450.636 190.581 484.117 179.496 519.729 184.056C556.29 188.738 586.435 208.978 619.411 225.533Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.56"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M618.479 223.562C670.582 248.588 752.259 247.892 767.52 303.606C782.322 357.645 704.587 391.3 677.872 440.391C653.049 486.007 657.832 547.424 617.228 579.785C573.876 614.336 513.371 625.036 458.695 615.072C405.087 605.304 357.975 570.699 325.767 526.674C296.66 486.888 298.74 435.364 291.586 386.653C284.7 339.766 258.619 287.506 284.866 248.175C311.161 208.772 369.656 213.715 415.374 201.156C449.807 191.697 482.955 180.075 518.496 183.996C554.985 188.022 585.354 207.652 618.479 223.562Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.57"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M617.484 221.635C669.801 245.647 751.121 243.532 767.284 298.745C782.961 352.299 706.138 387.166 680.388 436.516C656.461 482.372 662.289 543.447 622.417 576.378C579.847 611.539 519.783 623.247 465.164 614.278C411.613 605.484 364.1 571.846 331.264 528.568C301.59 489.456 302.767 438.112 294.798 389.731C287.128 343.162 260.25 291.577 285.704 251.954C311.205 212.259 369.539 216.162 414.847 202.86C448.97 192.842 481.777 180.691 517.236 183.977C553.641 187.35 584.223 206.368 617.484 221.635Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.58"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M616.424 219.751C668.936 242.751 749.875 239.229 766.928 293.926C783.467 346.981 707.574 383.038 682.789 432.628C659.758 478.707 666.621 539.422 627.49 572.909C585.71 608.662 526.103 621.367 471.56 613.387C418.083 605.563 370.188 572.894 336.741 530.371C306.513 491.941 306.795 440.795 298.021 392.757C289.575 346.518 261.916 295.619 286.575 255.721C311.279 215.749 369.435 218.62 414.319 204.585C448.124 194.015 480.581 181.344 515.948 183.998C552.257 186.723 583.039 205.128 616.424 219.751Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.59"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M615.302 217.912C667.989 239.9 748.524 234.983 766.452 289.152C783.842 341.693 708.897 378.919 685.077 428.73C662.944 475.014 670.831 535.352 632.447 569.378C591.465 605.708 532.332 619.397 477.883 612.401C424.497 605.541 376.239 573.845 342.196 532.085C311.431 494.345 310.824 443.41 301.254 395.729C292.042 349.834 263.618 299.632 287.48 259.474C311.386 219.242 369.344 221.088 413.794 206.331C447.272 195.216 479.371 182.035 514.633 184.061C550.835 186.142 581.805 203.933 615.302 217.912Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.6"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M614.117 216.12C666.96 237.097 747.066 230.798 765.857 284.424C784.084 336.438 710.105 374.811 687.251 424.825C666.015 471.297 674.914 531.241 637.285 565.791C597.108 602.679 538.465 617.339 484.127 611.322C430.85 605.421 382.248 574.7 347.627 533.711C316.338 496.668 314.85 445.961 304.494 398.649C294.525 353.11 265.352 303.617 288.416 263.214C311.522 222.738 369.264 223.566 413.268 208.098C446.41 196.447 478.144 182.763 513.29 184.167C549.374 185.608 580.521 202.784 614.117 216.12Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.61"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M612.872 214.376C665.853 234.343 745.505 226.676 765.146 279.745C784.197 331.22 711.201 370.717 689.312 420.915C668.973 467.559 678.875 527.091 642.005 562.148C602.64 599.578 544.503 615.198 490.294 610.152C437.144 605.205 388.217 575.461 353.033 535.25C321.236 498.91 318.875 448.446 307.743 401.517C297.027 356.346 267.121 307.573 289.385 266.941C311.689 226.235 369.198 226.054 412.746 209.886C445.544 197.708 476.904 183.529 511.924 184.315C547.877 185.122 579.188 201.682 612.872 214.376Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.62"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M611.566 212.681C664.665 231.64 743.841 222.618 764.318 275.118C784.18 326.04 712.183 366.638 691.259 417.002C671.817 463.8 682.708 522.905 646.606 558.453C608.06 596.409 550.444 612.973 496.381 608.893C443.373 604.893 394.142 576.129 358.412 536.704C326.122 501.073 322.896 450.867 310.997 404.333C299.544 359.542 268.921 311.5 290.384 270.654C311.886 229.734 369.145 228.553 412.224 211.695C444.669 198.999 475.648 184.335 510.531 184.507C546.344 184.684 577.807 200.628 611.566 212.681Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.63"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M610.203 211.037C663.401 228.989 742.078 218.628 763.377 270.544C784.036 320.901 713.055 362.579 693.095 413.09C674.548 460.025 686.418 518.685 651.088 554.709C613.367 593.172 556.288 610.669 502.388 607.548C449.54 604.488 400.023 576.706 363.764 538.072C330.995 503.158 326.912 453.223 314.258 407.098C302.078 362.699 270.754 315.397 291.416 274.354C312.115 233.236 369.105 231.062 411.706 213.527C443.791 200.32 474.381 185.179 509.116 184.744C544.777 184.296 576.381 199.624 610.203 211.037Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.64"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M608.78 209.444C662.059 226.392 740.214 214.705 762.321 266.027C783.763 315.806 713.814 358.539 694.817 409.18C677.165 456.235 690 514.435 655.449 550.919C618.559 589.872 562.031 608.288 508.311 606.119C455.64 603.992 405.856 577.193 369.085 539.357C335.853 505.164 330.921 455.517 317.522 409.811C304.625 365.817 272.616 319.264 292.477 278.039C312.373 236.738 369.078 233.581 411.189 215.38C442.906 201.671 473.101 186.064 507.677 185.025C543.175 183.959 574.908 198.669 608.78 209.444Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.65"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M607.302 207.904C660.642 223.851 738.254 210.854 761.154 261.567C783.367 310.757 714.463 354.523 696.428 405.275C679.669 452.433 693.457 510.157 659.69 547.084C623.636 586.511 567.675 605.831 514.151 604.607C461.673 603.407 411.642 577.591 374.374 540.56C340.695 507.094 334.924 457.748 320.79 412.474C307.186 368.895 274.51 323.102 293.569 281.709C312.662 240.241 369.064 236.111 410.676 217.255C442.017 203.054 471.809 186.988 506.217 185.352C541.542 183.672 573.391 197.766 607.302 207.904Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.66"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M605.767 206.418C659.149 221.366 736.196 207.075 759.875 257.169C782.843 305.757 715.001 350.533 697.924 401.377C682.057 448.622 696.786 505.854 663.807 543.209C628.595 583.092 573.214 603.302 519.904 603.015C467.636 602.734 417.375 577.901 379.629 541.681C345.517 508.947 338.915 459.917 324.059 415.086C309.758 371.934 276.431 326.909 294.688 285.365C312.979 243.745 369.062 238.651 410.165 219.152C441.121 204.467 470.505 187.953 504.733 185.724C539.874 183.436 571.828 196.915 605.767 206.418Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.67"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M604.179 204.988C657.585 218.941 734.045 203.37 758.489 252.833C782.198 300.809 715.431 346.57 699.312 397.49C684.334 444.804 699.991 501.529 667.805 539.295C633.44 579.617 578.653 600.703 525.572 601.346C473.529 601.976 423.059 578.127 384.851 542.722C350.321 510.726 342.898 462.025 327.33 417.648C312.344 374.934 278.382 330.687 295.839 289.006C313.327 247.25 369.075 241.201 409.659 221.072C440.224 205.912 469.192 188.958 503.231 186.143C538.177 183.254 570.224 196.117 604.179 204.988Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.68"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M602.537 203.613C655.949 216.575 731.801 199.741 756.995 248.561C781.431 295.914 715.753 342.637 700.588 393.614C686.497 440.981 703.068 497.185 671.679 535.345C638.166 576.089 583.987 598.036 531.152 599.6C479.35 601.134 428.69 578.269 390.037 543.685C355.104 512.43 346.87 464.072 330.6 420.161C314.94 377.895 280.361 334.434 297.017 292.632C313.704 250.755 369.101 243.762 409.156 223.015C439.323 207.389 467.869 190.005 501.709 186.61C536.45 183.124 568.579 195.373 602.537 203.613Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.69"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M600.843 202.297C654.243 214.272 729.466 196.191 755.394 244.357C780.544 291.076 715.968 338.736 701.754 389.753C688.547 437.157 706.02 492.825 675.432 531.363C642.774 572.51 589.216 595.304 536.643 597.782C485.098 600.211 434.266 578.329 395.184 544.57C359.865 514.061 350.828 466.059 333.87 422.626C317.546 380.819 282.367 338.15 298.224 296.243C314.11 254.26 369.14 246.333 408.657 224.98C438.42 208.897 466.537 191.093 500.168 187.124C534.695 183.049 566.893 194.684 600.843 202.297Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.7"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M599.099 201.039C652.468 212.031 727.042 192.72 753.69 240.222C779.538 286.296 716.076 334.87 702.811 385.909C690.485 433.333 708.845 488.45 679.062 527.351C647.263 568.885 594.34 592.511 542.044 595.893C490.771 599.208 439.786 578.308 400.294 545.379C364.603 515.62 354.774 467.988 337.137 425.042C320.161 383.703 284.399 341.837 299.459 299.839C314.546 257.764 369.193 248.914 408.164 226.967C437.514 210.438 465.197 192.223 498.609 187.685C532.912 183.027 565.168 194.05 599.099 201.039Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.71"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M597.304 199.84C650.625 209.854 724.529 189.331 751.882 236.158C778.413 281.578 716.079 331.041 703.758 382.084C692.309 429.512 711.543 484.065 682.569 523.311C651.633 565.215 599.356 589.657 547.354 593.934C496.367 598.128 445.249 578.208 405.362 546.113C369.315 517.107 358.704 469.858 340.401 427.41C322.785 386.551 286.456 345.492 300.72 303.419C315.01 261.268 369.259 251.506 407.674 228.978C436.607 212.011 463.85 193.395 497.033 188.296C531.101 183.061 563.404 193.474 597.304 199.84Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.72"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M595.461 198.703C648.716 207.744 721.932 186.026 749.974 232.167C777.173 276.923 715.977 327.251 704.597 378.28C694.022 425.696 714.116 479.671 685.952 519.247C655.882 561.502 604.265 586.746 552.571 591.91C501.886 596.973 450.652 578.031 410.388 546.774C374 518.525 362.618 471.671 343.662 429.73C325.415 389.36 288.539 349.117 302.009 306.983C315.503 264.771 369.338 254.108 407.19 231.012C435.698 213.617 462.496 194.609 495.441 188.955C529.265 183.151 561.603 192.955 595.461 198.703Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.73"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M593.572 197.627C646.743 205.7 719.251 182.805 747.966 228.252C775.819 272.333 715.772 323.502 705.329 374.501C695.624 421.889 716.563 475.273 689.213 515.162C660.012 557.751 609.066 583.781 557.695 589.821C507.328 595.744 455.997 577.779 415.373 547.362C378.659 519.873 366.516 473.427 346.918 432.004C328.054 392.133 290.646 352.712 303.324 310.531C316.025 268.274 369.432 256.721 406.712 233.069C434.79 215.256 461.137 195.867 493.835 189.665C527.405 183.297 559.767 192.494 593.572 197.627Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.74"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M591.636 196.614C644.706 203.725 716.487 179.67 745.861 224.413C774.351 267.812 715.465 319.796 705.953 370.748C697.115 418.092 718.885 470.871 692.351 511.057C664.021 553.964 613.757 580.763 562.724 587.671C512.689 594.443 461.279 577.453 420.312 547.88C383.288 521.154 370.394 475.128 350.167 434.232C330.697 394.868 292.775 356.275 304.664 314.064C316.575 271.775 369.539 259.345 406.24 235.15C433.881 216.928 459.772 197.167 492.214 190.424C525.521 183.5 557.896 192.092 591.636 196.614Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.75"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M589.656 195.664C642.607 201.82 713.644 176.624 743.659 220.654C772.773 263.361 715.056 316.135 706.472 367.023C698.495 414.308 721.081 466.47 695.365 506.937C667.909 550.143 618.339 577.696 567.659 585.461C517.969 593.074 466.499 577.055 425.206 548.329C387.888 522.368 374.254 476.774 353.41 436.414C333.346 397.567 294.928 359.808 306.031 317.58C317.154 275.275 369.66 261.978 405.774 237.254C432.973 218.633 458.403 198.511 490.581 191.233C523.616 183.761 555.992 191.751 589.656 195.664Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.76"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M587.633 194.78C640.448 199.986 710.722 173.667 741.364 216.975C771.084 258.982 714.548 312.522 706.885 363.329C699.765 410.538 723.152 462.071 698.256 502.802C671.675 546.291 622.81 574.582 572.497 583.194C523.167 591.638 471.655 576.587 430.053 548.71C392.455 523.516 378.093 478.365 356.645 438.552C335.999 400.229 297.102 363.31 307.421 321.08C317.76 278.773 369.795 264.623 405.314 239.382C432.066 220.372 457.03 199.898 488.934 192.093C521.688 184.08 554.055 191.47 587.633 194.78Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.77"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M585.568 193.961C638.231 198.224 707.725 170.802 738.977 213.38C769.289 254.678 713.942 308.959 707.196 359.668C700.927 406.786 725.1 457.678 701.025 498.657C675.321 542.411 627.172 571.424 577.239 580.872C528.283 590.136 476.747 576.051 434.853 549.024C396.991 524.599 381.912 479.904 359.872 440.645C338.656 402.856 299.298 366.782 308.837 324.564C318.394 282.269 369.945 267.278 404.863 241.533C431.161 222.144 455.656 201.329 487.278 193.004C519.742 184.457 552.088 191.25 585.568 193.961Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.78"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M583.464 193.208C635.956 196.535 704.654 168.029 736.5 209.869C767.389 250.451 713.238 305.447 707.403 356.042C701.981 403.055 726.925 453.293 703.672 494.505C678.845 538.506 631.422 568.224 581.885 578.499C533.315 588.572 481.773 575.448 439.604 549.274C401.494 525.62 385.709 481.391 363.09 442.694C341.317 405.447 301.515 370.223 310.278 328.032C319.057 285.764 370.109 269.943 404.419 243.709C430.26 223.95 454.28 202.803 485.611 193.966C517.777 184.894 550.091 191.093 583.464 193.208Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.79"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M581.32 192.523C633.626 194.921 701.51 165.35 733.934 206.444C765.383 246.303 712.438 301.989 707.508 352.454C702.926 399.345 728.626 448.919 706.196 490.347C682.247 534.578 635.56 564.986 586.431 576.075C538.261 586.948 486.732 574.781 444.304 549.461C405.961 526.578 389.482 482.826 366.296 444.701C343.979 408.003 303.751 373.633 311.741 331.483C319.746 289.256 370.287 272.619 403.982 245.908C429.36 225.79 452.902 204.322 483.934 194.981C515.794 185.39 548.065 190.998 581.32 192.523Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.8"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M579.138 191.906C631.242 193.383 698.296 162.767 731.281 203.107C763.275 242.235 711.543 298.587 707.511 348.905C703.764 395.66 730.204 444.559 708.597 486.186C685.528 530.63 639.586 561.711 590.878 573.604C543.122 585.265 491.622 574.051 448.953 549.585C410.392 527.475 393.231 484.212 369.492 446.665C346.642 410.525 306.005 377.013 313.227 334.918C320.463 292.745 370.479 275.306 403.554 248.131C428.464 227.665 451.524 205.886 482.249 196.047C513.794 185.946 546.012 190.967 579.138 191.906Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.81"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M576.921 191.358C628.806 191.922 695.015 160.28 728.545 199.86C761.068 238.25 710.556 295.242 707.416 345.398C704.498 392.002 731.662 440.215 710.878 482.025C688.688 526.666 643.501 558.402 595.228 571.088C547.898 583.526 496.444 573.26 453.551 549.65C414.787 528.313 396.956 485.548 372.677 448.588C349.307 413.012 308.28 380.363 314.738 338.336C321.208 296.233 370.687 278.004 403.135 250.379C427.574 229.573 450.147 207.494 480.558 197.166C511.78 186.562 543.934 191 576.921 191.358Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.82"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M574.668 190.88C626.319 190.538 691.667 157.891 725.726 196.704C758.762 234.35 709.476 291.956 707.221 341.934C705.125 388.373 732.997 435.889 713.037 477.867C691.726 522.687 647.302 555.062 599.476 568.529C552.585 581.733 501.195 572.41 458.095 549.656C419.143 529.092 400.655 486.836 375.849 450.47C351.971 415.466 310.571 383.681 316.269 341.738C321.978 299.717 370.908 280.712 402.725 252.65C426.688 231.516 448.772 209.146 478.86 198.337C509.751 187.24 541.83 191.097 574.668 190.88Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.83"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M572.383 190.472C623.783 189.233 688.256 155.601 722.827 193.641C756.36 230.537 708.307 288.732 706.929 338.516C705.649 384.775 734.214 431.585 715.076 473.715C694.643 518.697 650.991 551.694 603.625 565.93C557.185 579.888 505.877 571.502 462.585 549.604C423.461 529.814 404.327 488.077 379.007 452.312C354.636 417.886 312.88 386.97 317.824 345.123C322.777 303.198 371.146 283.43 402.325 254.946C425.807 233.494 447.399 210.844 477.158 199.562C507.709 187.978 539.704 191.259 572.383 190.472Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.84"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M570.066 190.135C621.2 188.008 684.783 153.412 719.85 190.672C753.863 226.813 707.048 285.571 706.541 335.146C706.069 381.211 735.31 427.305 716.994 469.571C697.439 514.697 654.567 548.3 607.673 563.293C561.695 577.994 510.486 570.54 467.02 549.498C427.739 530.481 407.971 489.271 382.152 454.114C357.299 420.274 315.205 390.229 319.399 348.491C323.602 306.677 371.397 286.16 401.934 257.267C424.933 235.506 446.029 212.586 475.451 200.839C505.656 188.779 537.556 191.488 570.066 190.135Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.85"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M567.719 189.871C618.572 186.864 681.251 151.324 716.797 187.799C751.275 223.178 705.703 282.475 706.058 331.826C706.388 377.683 736.289 423.052 718.794 465.437C700.115 510.692 658.03 544.882 611.621 560.621C566.118 576.053 515.023 569.524 471.4 549.337C431.976 531.092 411.588 490.421 385.282 455.878C359.961 422.629 317.546 393.457 320.997 351.843C324.453 310.152 371.665 288.9 401.555 259.611C424.066 237.552 444.664 214.374 473.741 202.17C503.593 189.641 535.387 191.782 567.719 189.871Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.86"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M565.342 189.678C615.9 185.801 677.662 149.339 713.67 185.024C748.596 219.636 704.271 279.446 705.481 328.558C706.605 374.193 737.15 418.827 720.474 461.317C702.67 506.683 661.38 541.444 615.466 557.916C570.449 574.066 519.487 568.457 475.722 549.124C436.171 531.651 415.175 491.526 388.396 457.604C362.619 424.952 319.902 396.656 322.614 355.178C325.331 313.623 371.947 291.651 401.185 261.98C423.206 239.634 443.303 216.207 472.029 203.554C501.52 190.565 533.199 192.143 565.342 189.678Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.87"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M562.94 189.56C613.186 184.822 674.018 147.458 710.471 182.347C745.83 216.188 702.757 276.485 704.813 325.344C706.723 370.742 737.895 414.634 722.037 457.213C705.105 502.674 664.618 537.988 619.211 555.18C574.692 572.037 523.877 567.341 479.989 548.86C440.325 532.158 418.734 492.588 391.495 459.293C365.276 427.244 322.274 399.825 324.253 358.496C326.236 317.091 372.246 294.412 400.829 264.374C422.356 241.75 441.949 218.084 470.317 204.993C499.441 191.552 530.994 192.572 562.94 189.56Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.88"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M560.511 189.514C610.432 183.925 670.32 145.682 707.203 179.771C742.977 212.836 701.159 273.595 704.053 322.185C706.741 367.335 738.524 410.476 723.482 453.127C707.421 498.666 667.743 534.516 622.854 552.416C578.842 569.967 528.193 566.177 484.196 548.548C444.434 532.615 422.261 493.609 394.577 460.945C367.928 429.505 324.659 402.964 325.911 361.798C327.166 320.556 372.56 297.184 400.483 266.792C421.513 243.902 440.601 220.008 468.604 206.485C497.354 192.602 528.772 193.068 560.511 189.514Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.89"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M558.058 189.544C607.64 183.113 666.573 144.011 703.868 177.296C740.042 209.581 699.482 270.777 703.204 319.084C706.663 363.971 739.04 406.353 724.811 449.062C709.619 494.663 670.756 531.032 626.396 549.627C582.903 567.859 532.434 564.968 488.345 548.188C448.5 533.023 425.759 494.588 397.642 462.562C370.578 431.736 327.058 406.074 327.59 365.083C328.122 324.016 372.89 299.967 400.15 269.234C420.681 246.088 439.261 221.976 466.894 208.031C495.262 193.714 526.536 193.632 558.058 189.544Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.9"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M555.583 189.648C604.81 182.386 662.777 142.447 700.467 174.923C737.025 206.424 697.724 268.032 702.267 316.042C706.488 360.654 739.443 402.269 726.024 445.021C711.697 490.667 673.655 527.537 629.835 546.814C586.871 565.715 536.598 563.715 492.434 547.782C452.521 533.382 429.223 495.528 400.688 464.145C373.221 433.937 329.469 409.155 329.287 368.351C329.104 327.472 373.236 302.76 399.829 271.701C419.858 248.309 437.929 223.99 465.184 209.631C493.166 194.89 524.286 194.264 555.583 189.648Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.91"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M553.087 189.827C601.946 181.744 658.935 140.99 697.004 172.655C733.928 203.368 695.891 265.362 701.244 313.062C706.219 357.385 739.734 398.227 727.123 441.006C713.659 486.68 676.443 524.034 633.173 543.98C590.748 563.537 540.687 562.421 496.463 547.332C456.496 533.696 432.656 496.43 403.716 465.694C375.86 436.109 331.893 412.207 331.003 371.603C330.112 330.925 373.598 305.564 399.522 274.193C419.046 250.565 436.607 226.049 463.479 211.286C491.067 196.129 522.023 194.966 553.087 189.827Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.92"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M550.572 190.082C599.05 181.189 655.051 139.642 693.481 170.491C730.757 200.414 693.982 262.769 700.138 310.145C705.857 354.167 739.915 394.228 728.109 437.019C715.504 482.706 679.121 520.526 636.41 541.128C594.534 561.327 544.701 561.087 500.433 546.84C460.427 533.965 436.057 497.293 406.727 467.209C378.495 438.252 334.329 415.23 332.74 374.839C331.147 334.373 373.978 308.379 399.229 276.709C418.247 252.856 435.296 228.154 461.779 212.995C488.968 197.432 519.751 195.736 550.572 190.082Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.93"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M548.039 190.414C596.122 180.721 651.124 138.403 689.899 168.434C727.509 197.563 691.999 260.254 698.947 307.293C705.402 351 739.986 390.275 728.982 433.063C717.232 478.747 681.685 517.015 639.544 538.259C598.226 559.088 548.636 559.715 504.34 546.307C464.309 534.19 439.424 498.12 409.717 468.693C381.122 440.367 336.776 418.225 334.493 378.057C332.206 337.816 374.373 311.205 398.95 279.249C417.459 255.182 433.994 230.304 460.083 214.758C486.868 198.799 517.469 196.576 548.039 190.414Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.94"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M545.489 190.822C593.165 180.341 647.159 137.273 686.262 166.484C724.19 194.817 689.946 257.818 697.675 304.507C704.856 347.889 739.95 386.371 729.743 429.141C718.845 474.806 684.139 513.505 642.577 535.377C601.826 556.822 552.494 558.308 508.187 545.736C468.144 534.373 442.757 498.912 412.687 470.145C383.743 442.454 339.233 421.192 336.265 381.26C333.29 341.255 374.786 314.041 398.685 281.814C416.685 257.543 432.706 232.499 458.395 216.576C484.769 200.229 515.179 197.485 545.489 190.822Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.95"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M542.925 191.307C590.181 180.049 643.156 136.254 682.571 164.642C720.801 192.177 687.821 255.464 696.322 301.789C704.221 344.833 739.807 382.516 730.394 425.254C720.343 470.884 686.483 509.996 645.508 532.483C605.334 554.531 556.274 556.867 511.97 545.127C471.931 534.516 446.055 499.67 415.636 471.566C386.356 444.515 341.701 424.13 338.053 384.446C334.4 344.689 375.215 316.887 398.435 284.404C415.924 259.939 431.43 234.739 456.714 218.448C482.672 201.723 512.882 198.464 542.925 191.307Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.96"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M540.348 191.869C587.17 179.846 639.119 135.347 678.828 162.91C717.345 189.645 685.63 253.193 694.892 299.141C703.498 341.836 739.56 378.715 730.935 421.405C721.727 466.985 688.716 506.492 648.338 529.581C608.748 552.218 559.976 555.395 515.691 544.482C475.669 534.619 449.319 500.395 418.565 472.958C388.963 446.549 344.177 427.041 339.86 387.616C335.534 348.118 375.662 319.744 398.201 287.018C415.177 262.37 430.167 237.025 455.042 220.375C480.579 203.281 510.58 199.513 540.348 191.869Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.97"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M537.761 192.509C584.137 179.732 635.051 134.551 675.038 161.287C713.824 187.221 683.372 251.005 693.385 296.565C702.69 338.898 739.209 374.968 731.37 417.597C722.999 463.111 690.841 502.996 651.068 526.671C612.071 549.884 563.601 553.893 519.35 543.803C479.359 534.685 452.547 501.088 421.473 474.322C391.561 448.557 346.664 429.924 341.684 390.769C336.694 351.542 376.126 322.612 397.984 289.656C414.446 264.835 428.92 239.355 453.38 222.356C478.491 204.903 508.276 200.632 537.761 192.509Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.98"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M535.163 193.227C581.082 179.708 630.953 133.867 671.201 159.776C710.24 184.906 681.051 248.902 691.804 294.061C701.796 336.023 738.757 371.278 731.697 413.831C724.16 459.264 692.857 499.509 653.697 523.758C615.301 547.533 567.147 552.363 522.945 543.093C482.999 534.714 455.74 501.75 424.358 475.657C394.152 450.541 349.159 432.78 343.524 393.906C337.878 354.962 376.608 325.49 397.782 292.319C413.73 267.335 427.688 241.731 451.729 224.391C476.41 206.588 505.969 201.822 535.163 193.227Z"
				stroke="#402FC8"
			/>
			<path
				opacity="0.99"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M532.557 194.023C578.007 179.774 626.828 133.296 667.321 158.376C706.597 182.703 678.667 246.886 690.15 291.632C700.82 333.21 738.204 367.648 731.919 410.11C725.209 455.447 694.765 496.034 656.226 520.842C618.439 545.165 570.614 550.808 526.477 542.351C486.589 534.709 458.897 502.383 427.222 476.965C396.733 452.499 351.661 435.609 345.38 397.027C339.088 358.376 377.108 328.379 397.598 295.005C413.03 269.87 426.473 244.151 450.09 226.48C474.336 208.338 503.661 203.082 532.557 194.023Z"
				stroke="#402FC8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M529.946 194.897C574.916 179.93 622.678 132.838 663.399 157.089C702.897 180.612 676.225 244.957 688.426 289.279C699.763 330.463 737.553 364.078 732.038 406.436C726.15 451.662 696.567 492.574 658.656 517.927C621.485 542.784 574.003 549.229 529.946 541.581C490.129 534.67 462.018 502.988 430.064 478.247C399.307 454.434 354.173 438.411 347.254 400.133C340.322 361.784 377.627 331.278 397.432 297.716C412.349 272.439 425.276 246.616 448.465 228.624C472.271 210.152 501.355 204.413 529.946 194.897Z"
				stroke="#402FC8"
			/>
		</Icon>
	);
};

export default LooperGroup;
