import { Icon, IconProps } from '@chakra-ui/react';

const SuperSynthyIcon = ({
	width = '75px',
	height = '66px',
	...props
}: IconProps) => {
	return (
		<Icon width={width} height={height} viewBox="0 0 75 66" fill="none">
			<path
				d="M45.2266 44.0621C37.2396 46.6518 29.6069 47.7464 23.7252 47.4258C20.784 47.2655 18.2866 46.7516 16.4029 45.8984C14.5193 45.0452 13.2601 43.8581 12.7709 42.3556C12.2817 40.8531 12.6012 39.1542 13.6225 37.3591C14.6439 35.564 16.3617 33.6833 18.6467 31.8284C23.2163 28.1191 30.036 24.5276 38.0229 21.9379C46.0099 19.3482 53.6426 18.2536 59.5243 18.5742C62.4655 18.7345 64.963 19.2484 66.8466 20.1016C68.7303 20.9548 69.9894 22.1419 70.4786 23.6444C70.9678 25.1469 70.6484 26.8458 69.627 28.6409C68.6056 30.436 66.8879 32.3167 64.6028 34.1716C60.0332 37.8809 53.2136 41.4724 45.2266 44.0621Z"
				stroke="url(#paint0_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M44.744 44.1808C36.6713 46.4908 29.005 47.3189 23.1381 46.7936C20.2042 46.531 17.7263 45.9304 15.8736 45.0121C14.021 44.0939 12.8041 42.8637 12.3677 41.3451C11.9314 39.8264 12.3101 38.1397 13.3936 36.3813C14.4771 34.6228 16.2596 32.8031 18.6081 31.0289C23.3046 27.481 30.2458 24.1292 38.3184 21.8192C46.3911 19.5092 54.0574 18.6812 59.9243 19.2064C62.8582 19.4691 65.3361 20.0696 67.1888 20.9879C69.0414 21.9062 70.2583 23.1363 70.6947 24.655C71.131 26.1736 70.7523 27.8604 69.6688 29.6188C68.5853 31.3773 66.8028 33.1969 64.4542 34.9711C59.7578 38.5191 52.8166 41.8709 44.744 44.1808Z"
				stroke="url(#paint1_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M44.2564 44.2858C36.1078 46.3133 28.4172 46.8738 22.5723 46.1446C19.6494 45.7799 17.194 45.0934 15.3745 44.1112C13.5552 43.1289 12.3821 41.8572 11.9991 40.3242C11.6161 38.7913 12.0535 37.1188 13.1979 35.3992C14.3422 33.6795 16.1873 31.923 18.5965 30.2317C23.4142 26.8495 30.4683 23.7415 38.6168 21.7141C46.7654 19.6866 54.456 19.1261 60.301 19.8553C63.2238 20.22 65.6792 20.9065 67.4987 21.8887C69.3181 22.871 70.4912 24.1428 70.8742 25.6757C71.2572 27.2086 70.8197 28.8811 69.6754 30.6007C68.531 32.3204 66.6859 34.0769 64.2768 35.7682C59.4591 39.1504 52.405 42.2584 44.2564 44.2858Z"
				stroke="url(#paint2_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M43.7657 44.3773C35.5512 46.1197 27.8457 46.412 22.0298 45.4797C19.1215 45.0134 16.6915 44.2418 14.9075 43.1968C13.1236 42.1518 11.9957 40.84 11.6666 39.2946C11.3374 37.7493 11.8331 36.0931 13.0369 34.4144C14.2407 32.7356 16.146 31.0444 18.6129 29.438C23.546 26.2257 30.7045 23.3652 38.919 21.6228C47.1335 19.8804 54.839 19.588 60.6549 20.5204C63.5632 20.9866 65.9931 21.7582 67.7771 22.8033C69.5611 23.8482 70.689 25.1601 71.0181 26.7054C71.3473 28.2507 70.8516 29.907 69.6478 31.5857C68.444 33.2645 66.5386 34.9556 64.0718 36.562C59.1387 39.7744 51.9802 42.6349 43.7657 44.3773Z"
				stroke="url(#paint3_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M43.2726 44.4547C35.0022 45.91 27.2912 45.9338 21.5114 44.7995C18.6212 44.2322 16.2197 43.3765 14.4733 42.27C12.727 41.1635 11.6457 39.8131 11.3708 38.2573C11.0959 36.7015 11.6492 35.0635 12.911 33.4277C14.1728 31.7919 16.1361 30.1681 18.6576 28.6486C23.7 25.61 30.9542 23.0006 39.2247 21.5454C47.4951 20.0901 55.2061 20.0663 60.9859 21.2006C63.8761 21.7678 66.2776 22.6236 68.0239 23.7301C69.7702 24.8366 70.8516 26.1869 71.1265 27.7428C71.4014 29.2986 70.8481 30.9366 69.5863 32.5723C68.3245 34.2082 66.3612 35.8319 63.8397 37.3514C58.7973 40.39 51.5431 42.9995 43.2726 44.4547Z"
				stroke="url(#paint4_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M42.7765 44.5182C34.4602 45.6845 26.753 45.4398 21.0165 44.1049C18.1478 43.4373 15.7777 42.4985 14.0712 41.3318C12.3646 40.1652 11.3312 38.778 11.1108 37.2135C10.8905 35.6491 11.5008 34.0314 12.819 32.4405C14.1372 30.8496 16.1561 29.2952 18.7293 27.8645C23.8749 25.0034 31.2159 22.6482 39.5322 21.4818C47.8485 20.3155 55.5556 20.5602 61.2922 21.8951C64.1609 22.5627 66.5309 23.5016 68.2375 24.6682C69.9441 25.8348 70.9775 27.222 71.1978 28.7865C71.4182 30.3509 70.8079 31.9686 69.4897 33.5595C68.1715 35.1504 66.1525 36.7048 63.5794 38.1355C58.4338 40.9967 51.0928 43.3519 42.7765 44.5182Z"
				stroke="url(#paint5_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M42.2784 44.5677C33.9264 45.4437 26.2325 44.9307 20.5461 43.3968C17.7026 42.6297 15.3668 41.6089 13.702 40.3835C12.0373 39.1582 11.053 37.7358 10.8875 36.1647C10.7221 34.5935 11.3885 32.998 12.7615 31.4541C14.1346 29.91 16.2067 28.4269 18.8283 27.0866C24.0708 24.4064 31.4897 22.3083 39.8417 21.4323C48.1937 20.5563 55.8877 21.0693 61.5741 22.6032C64.4176 23.3703 66.7534 24.3911 68.4182 25.6165C70.0829 26.8419 71.0672 28.2642 71.2326 29.8354C71.3981 31.4065 70.7317 33.002 69.3586 34.546C67.9856 36.09 65.9135 37.5731 63.2919 38.9134C58.0494 41.5936 50.6305 43.6917 42.2784 44.5677Z"
				stroke="url(#paint6_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M41.7796 44.6031C33.4021 45.1877 25.7308 44.4071 20.1015 42.676C17.2865 41.8104 14.9878 40.7089 13.3669 39.4263C11.7461 38.1437 10.8122 36.6879 10.7017 35.112C10.5913 33.536 11.3131 31.9647 12.7393 30.4695C14.1655 28.9742 16.2882 27.5642 18.9551 26.316C24.2882 23.82 31.7759 21.9816 40.1535 21.397C48.5311 20.8124 56.2024 21.593 61.8316 23.3241C64.6466 24.1897 66.9453 25.2912 68.5662 26.5738C70.187 27.8564 71.121 29.3122 71.2314 30.8881C71.3419 32.4641 70.62 34.0354 69.1938 35.5306C67.7676 37.0259 65.6449 38.4359 62.978 39.6841C57.645 42.1801 50.1572 44.0185 41.7796 44.6031Z"
				stroke="url(#paint7_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M41.2797 44.6243C32.8868 44.9168 25.2475 43.8695 19.6822 41.9434C16.8992 40.9803 14.6404 39.7994 13.0654 38.4611C11.4904 37.1228 10.6079 35.6355 10.5527 34.0566C10.4974 32.4777 11.2738 30.9326 12.7514 29.4879C14.229 28.0433 16.3997 26.708 19.1086 25.5535C24.5257 23.2447 32.0732 21.6682 40.4662 21.3757C48.8591 21.0832 56.4984 22.1306 62.0637 24.0566C64.8467 25.0197 67.1054 26.2006 68.6805 27.5389C70.2555 28.8772 71.138 30.3645 71.1932 31.9434C71.2485 33.5223 70.4721 35.0675 68.9945 36.5121C67.5168 37.9568 65.3461 39.292 62.6372 40.4466C57.2201 42.7553 49.6726 44.3318 41.2797 44.6243Z"
				stroke="url(#paint8_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M40.7773 44.6314C32.3793 44.6314 24.7812 43.3186 19.2867 41.2C16.539 40.1405 14.323 38.8816 12.7957 37.4893C11.2685 36.097 10.4385 34.5798 10.4385 33C10.4385 31.4202 11.2685 29.903 12.7957 28.5107C14.323 27.1184 16.539 25.8595 19.2867 24.8C24.7812 22.6814 32.3793 21.3686 40.7773 21.3686C49.1753 21.3686 56.7734 22.6814 62.2679 24.8C65.0155 25.8595 67.2316 27.1184 68.7589 28.5107C70.2861 29.903 71.116 31.4202 71.116 33C71.116 34.5798 70.2861 36.097 68.7589 37.4893C67.2316 38.8816 65.0155 40.1405 62.2679 41.2C56.7734 43.3186 49.1753 44.6314 40.7773 44.6314Z"
				stroke="url(#paint9_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M40.2773 44.6243C31.8844 44.3318 24.3369 42.7553 18.9198 40.4465C16.2109 39.292 14.0402 37.9567 12.5625 36.5121C11.0849 35.0674 10.3085 33.5223 10.3638 31.9434C10.419 30.3645 11.3015 28.8772 12.8765 27.5389C14.4516 26.2006 16.7103 25.0197 19.4933 24.0566C25.0586 22.1305 32.6979 21.0832 41.0908 21.3757C49.4838 21.6682 57.0313 23.2447 62.4484 25.5534C65.1573 26.708 67.328 28.0432 68.8056 29.4879C70.2832 30.9325 71.0596 32.4777 71.0043 34.0566C70.9491 35.6355 70.0666 37.1228 68.4916 38.4611C66.9166 39.7994 64.6578 40.9803 61.8748 41.9434C56.3095 43.8694 48.6702 44.9168 40.2773 44.6243Z"
				stroke="url(#paint10_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M39.7768 44.603C31.3992 44.0184 23.9115 42.18 18.5784 39.684C15.9115 38.4359 13.7888 37.0258 12.3626 35.5305C10.9364 34.0353 10.2146 32.4641 10.325 30.8881C10.4354 29.3121 11.3694 27.8564 12.9902 26.5738C14.6111 25.2912 16.9098 24.1896 19.7248 23.324C25.3541 21.593 33.0254 20.8124 41.4029 21.3969C49.7805 21.9815 57.2682 23.82 62.6013 26.316C65.2682 27.5641 67.3909 28.9742 68.8171 30.4694C70.2433 31.9646 70.9651 33.5359 70.8547 35.1119C70.7443 36.6879 69.8103 38.1436 68.1895 39.4262C66.5686 40.7088 64.2699 41.8104 61.4549 42.676C55.8257 44.407 48.1544 45.1876 39.7768 44.603Z"
				stroke="url(#paint11_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M39.2772 44.5676C30.9252 43.6916 23.5063 41.5935 18.2637 38.9133C15.6421 37.573 13.5701 36.0899 12.197 34.5459C10.824 33.0019 10.1575 31.4065 10.323 29.8353C10.4885 28.2641 11.4728 26.8418 13.1375 25.6164C14.8022 24.3911 17.138 23.3702 19.9816 22.6032C25.668 21.0693 33.3619 20.5563 41.7139 21.4323C50.0659 22.3083 57.4848 24.4064 62.7274 27.0866C65.349 28.4268 67.421 29.91 68.7941 31.454C70.1671 32.9979 70.8336 34.5934 70.6681 36.1646C70.5026 37.7358 69.5183 39.1581 67.8536 40.3834C66.1889 41.6088 63.8531 42.6296 61.0095 43.3967C55.3231 44.9306 47.6292 45.4436 39.2772 44.5676Z"
				stroke="url(#paint12_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M38.7789 44.5182C30.4626 43.3518 23.1216 40.9967 17.976 38.1355C15.4029 36.7048 13.3839 35.1504 12.0657 33.5595C10.7475 31.9686 10.1372 30.3509 10.3576 28.7865C10.5779 27.222 11.6114 25.8348 13.3179 24.6682C15.0245 23.5015 17.3946 22.5627 20.2632 21.8951C25.9998 20.5602 33.7069 20.3155 42.0232 21.4818C50.3395 22.6482 57.6805 25.0033 62.8262 27.8645C65.3993 29.2952 67.4182 30.8496 68.7365 32.4405C70.0546 34.0314 70.6649 35.6491 70.4446 37.2135C70.2242 38.778 69.1908 40.1652 67.4843 41.3318C65.7777 42.4985 63.4076 43.4373 60.5389 44.1049C54.8024 45.4398 47.0952 45.6845 38.7789 44.5182Z"
				stroke="url(#paint13_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M38.2828 44.4547C30.0123 42.9995 22.7581 40.39 17.7157 37.3514C15.1942 35.8319 13.2309 34.2082 11.9691 32.5723C10.7073 30.9366 10.154 29.2986 10.4289 27.7428C10.7038 26.1869 11.7852 24.8366 13.5315 23.7301C15.2778 22.6236 17.6793 21.7678 20.5695 21.2006C26.3493 20.0663 34.0603 20.0901 42.3307 21.5453C50.6012 23.0006 57.8554 25.61 62.8978 28.6486C65.4193 30.1681 67.3826 31.7919 68.6444 33.4277C69.9062 35.0635 70.4595 36.7015 70.1846 38.2573C69.9097 39.8131 68.8283 41.1635 67.082 42.27C65.3357 43.3765 62.9342 44.2322 60.044 44.7995C54.2642 45.9338 46.5532 45.91 38.2828 44.4547Z"
				stroke="url(#paint14_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M37.7895 44.3772C29.575 42.6348 22.4165 39.7744 17.4834 36.562C15.0166 34.9556 13.1112 33.2645 11.9074 31.5857C10.7036 29.907 10.2079 28.2507 10.5371 26.7054C10.8663 25.1601 11.9942 23.8482 13.7781 22.8032C15.5621 21.7582 17.992 20.9866 20.9003 20.5204C26.7162 19.588 34.4217 19.8804 42.6362 21.6228C50.8507 23.3652 58.0092 26.2257 62.9423 29.438C65.4092 31.0444 67.3145 32.7356 68.5183 34.4144C69.7221 36.0931 70.2178 37.7493 69.8886 39.2946C69.5595 40.84 68.4316 42.1518 66.6477 43.1968C64.8637 44.2418 62.4337 45.0134 59.5254 45.4797C53.7095 46.412 46.004 46.1196 37.7895 44.3772Z"
				stroke="url(#paint15_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M37.2989 44.2859C29.1504 42.2584 22.0963 39.1504 17.2786 35.7682C14.8694 34.0769 13.0243 32.3204 11.88 30.6007C10.7356 28.8811 10.2982 27.2086 10.6812 25.6757C11.0642 24.1428 12.2373 22.871 14.0566 21.8888C15.8761 20.9065 18.3315 20.22 21.2544 19.8554C27.0993 19.1261 34.7899 19.6867 42.9385 21.7141C51.087 23.7415 58.1411 26.8495 62.9589 30.2317C65.368 31.9231 67.2131 33.6795 68.3575 35.3992C69.5018 37.1188 69.9393 38.7913 69.5563 40.3243C69.1733 41.8572 68.0002 43.1289 66.1808 44.1112C64.3613 45.0934 61.9059 45.7799 58.983 46.1446C53.1381 46.8738 45.4475 46.3133 37.2989 44.2859Z"
				stroke="url(#paint16_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M36.8117 44.1808C28.739 41.8708 21.7979 38.519 17.1014 34.9711C14.7529 33.1969 12.9704 31.3772 11.8868 29.6187C10.8033 27.8603 10.4246 26.1736 10.861 24.6549C11.2974 23.1363 12.5142 21.9062 14.3669 20.9879C16.2195 20.0696 18.6975 19.469 21.6313 19.2064C27.4982 18.6811 35.1645 19.5092 43.2372 21.8192C51.3099 24.1291 58.251 27.481 62.9475 31.0289C65.2961 32.8031 67.0786 34.6227 68.1621 36.3812C69.2456 38.1396 69.6243 39.8264 69.1879 41.345C68.7516 42.8637 67.5347 44.0938 65.6821 45.0121C63.8294 45.9304 61.3514 46.5309 58.4176 46.7936C52.5507 47.3188 44.8844 46.4908 36.8117 44.1808Z"
				stroke="url(#paint17_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M36.3289 44.0621C28.342 41.4724 21.5223 37.8809 16.9527 34.1716C14.6677 32.3167 12.9499 30.436 11.9285 28.6409C10.9072 26.8458 10.5877 25.1469 11.0769 23.6444C11.5661 22.1418 12.8253 20.9548 14.7089 20.1016C16.5926 19.2484 19.09 18.7345 22.0312 18.5742C27.9129 18.2536 35.5456 19.3482 43.5326 21.9379C51.5196 24.5276 58.3392 28.1191 62.9088 31.8284C65.1938 33.6833 66.9116 35.5639 67.933 37.3591C68.9543 39.1542 69.2738 40.8531 68.7846 42.3556C68.2954 43.8581 67.0362 45.0452 65.1526 45.8983C63.2689 46.7516 60.7715 47.2654 57.8303 47.4258C51.9486 47.7464 44.3159 46.6518 36.3289 44.0621Z"
				stroke="url(#paint18_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M35.8492 43.93C27.9577 41.0637 21.2678 37.2368 16.8308 33.3706C14.6119 31.4373 12.961 29.498 12.003 27.6683C11.0451 25.8388 10.7852 24.1298 11.3267 22.6452C11.8681 21.1606 13.168 20.0182 15.0803 19.2311C16.9927 18.444 19.5066 18.0174 22.4516 17.9596C28.341 17.8441 35.9307 19.2038 43.8222 22.0701C51.7138 24.9364 58.4037 28.7632 62.8407 32.6294C65.0595 34.5627 66.7105 36.502 67.6685 38.3317C68.6264 40.1612 68.8863 41.8702 68.3448 43.3548C67.8034 44.8394 66.5035 45.9818 64.5912 46.7689C62.6788 47.556 60.1649 47.9826 57.2199 48.0404C51.3305 48.1559 43.7408 46.7962 35.8492 43.93Z"
				stroke="url(#paint19_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M35.3766 43.7844C27.5901 40.645 21.0381 36.5876 16.739 32.5692C14.5891 30.5597 13.007 28.5641 12.1136 26.7022C11.2202 24.8404 11.0203 23.1234 11.6133 21.6586C12.2064 20.1938 13.5454 19.0973 15.4841 18.3773C17.4228 17.6573 19.9501 17.3185 22.8954 17.3633C28.7852 17.4529 36.3227 19.0761 44.1092 22.2155C51.8957 25.3549 58.4477 29.4124 62.7468 33.4307C64.8967 35.4402 66.4788 37.4358 67.3722 39.2977C68.2656 41.1595 68.4655 42.8765 67.8725 44.3413C67.2794 45.8062 65.9403 46.9026 64.0017 47.6226C62.0629 48.3426 59.5357 48.6814 56.5904 48.6366C50.7006 48.547 43.1631 46.9238 35.3766 43.7844Z"
				stroke="url(#paint20_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M34.9075 43.6259C27.2355 40.2172 20.8294 35.934 16.6735 31.7684C14.5952 29.6853 13.0838 27.6358 12.2561 25.7439C11.4284 23.8521 11.2886 22.1292 11.9325 20.6859C12.5765 19.2426 13.953 18.1935 15.9157 17.5414C17.8784 16.8894 20.416 16.6388 23.3579 16.7862C29.241 17.0808 36.7172 18.9656 44.3891 22.3742C52.0611 25.7828 58.4672 30.066 62.6232 34.2317C64.7014 36.3148 66.2128 38.3643 67.0405 40.2561C67.8682 42.1479 68.008 43.8709 67.3641 45.3141C66.7202 46.7574 65.3436 47.8066 63.3809 48.4586C61.4182 49.1107 58.8806 49.3612 55.9387 49.2139C50.0557 48.9192 42.5794 47.0345 34.9075 43.6259Z"
				stroke="url(#paint21_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M34.4453 43.4542C26.8972 39.7805 20.6449 35.2768 16.6371 30.9689C14.633 28.8147 13.1942 26.7138 12.4331 24.7943C11.6721 22.8749 11.5926 21.1481 12.2866 19.7281C12.9806 18.3081 14.3931 17.3075 16.3774 16.7243C18.3617 16.141 20.9065 15.9789 23.8415 16.2287C29.7106 16.728 37.1163 18.872 44.6644 22.5457C52.2125 26.2195 58.4649 30.7232 62.4727 35.031C64.4768 37.1852 65.9156 39.2861 66.6766 41.2057C67.4377 43.1251 67.5171 44.8519 66.8231 46.2718C66.1291 47.6918 64.7166 48.6924 62.7324 49.2757C60.748 49.859 58.2032 50.021 55.2683 49.7713C49.3991 49.2719 41.9934 47.128 34.4453 43.4542Z"
				stroke="url(#paint22_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M33.989 43.2699C26.574 39.3355 20.4829 34.6168 16.6282 30.172C14.7006 27.9493 13.3362 25.7996 12.6427 23.8547C11.9493 21.9099 11.9303 20.1815 12.6735 18.7865C13.4168 17.3916 14.8634 16.4408 16.8668 15.927C18.8704 15.4131 21.4193 15.3398 24.3437 15.6916C30.1918 16.3951 37.5181 18.7956 44.9331 22.73C52.3481 26.6644 58.4391 31.3831 62.2938 35.8279C64.2214 38.0506 65.5858 40.2003 66.2793 42.1452C66.9727 44.09 66.9918 45.8184 66.2485 47.2134C65.5052 48.6083 64.0586 49.5591 62.0552 50.073C60.0517 50.5868 57.5028 50.6601 54.5783 50.3083C48.7302 49.6048 41.404 47.2043 33.989 43.2699Z"
				stroke="url(#paint23_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M33.5368 43.0731C26.2639 38.8829 20.3415 33.955 16.6447 29.3786C14.796 27.0902 13.5076 24.8942 12.8826 22.9264C12.2575 20.9586 12.299 19.2306 13.0906 17.8623C13.8822 16.4941 15.3611 15.5943 17.3813 15.1505C19.4016 14.7068 21.9515 14.7223 24.8619 15.1757C30.6818 16.0824 37.9197 18.7367 45.1925 22.9269C52.4654 27.1171 58.3878 32.0451 62.0846 36.6214C63.9333 38.9099 65.2217 41.1058 65.8467 43.0736C66.4718 45.0414 66.4303 46.7695 65.6387 48.1377C64.8471 49.5059 63.3682 50.4058 61.348 50.8495C59.3277 51.2932 56.7778 51.2777 53.8674 50.8243C48.0475 49.9176 40.8097 47.2634 33.5368 43.0731Z"
				stroke="url(#paint24_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M33.0958 42.864C25.9739 38.423 20.2275 33.2918 16.6929 28.5895C14.9254 26.2381 13.7146 23.9986 13.1588 22.0102C12.6029 20.0219 12.7048 18.2963 13.5438 16.9565C14.3827 15.6166 15.8923 14.7688 17.9268 14.3957C19.9613 14.0226 22.5091 14.1269 25.4019 14.6814C31.1866 15.7903 38.3271 18.695 45.449 23.1359C52.5709 27.5769 58.3173 32.7081 61.8519 37.4104C63.6194 39.7618 64.8303 42.0013 65.3861 43.9897C65.9419 45.978 65.8401 47.7036 65.0011 49.0434C64.1621 50.3833 62.6526 51.2311 60.6181 51.6042C58.5835 51.9773 56.0357 51.873 53.143 51.3185C47.3583 50.2096 40.2177 47.3049 33.0958 42.864Z"
				stroke="url(#paint25_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M32.6599 42.6429C25.6977 37.9566 20.1342 32.6284 16.7663 27.8058C15.0821 25.3943 13.9503 23.114 13.4643 21.1075C12.9784 19.101 13.1405 17.38 14.0258 16.0702C14.9112 14.7604 16.4494 13.9657 18.4957 13.6637C20.5421 13.3616 23.0847 13.5546 25.9563 14.2095C31.6987 15.5192 38.7333 18.6707 45.6956 23.357C52.6578 28.0433 58.2213 33.3715 61.5892 38.1941C63.2734 40.6056 64.4052 42.8859 64.8912 44.8924C65.3771 46.8989 65.215 48.6199 64.3297 49.9297C63.4443 51.2395 61.9061 52.0342 59.8598 52.3362C57.8134 52.6383 55.2708 52.4453 52.3992 51.7904C46.6568 50.4807 39.6222 47.3292 32.6599 42.6429Z"
				stroke="url(#paint26_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M32.2322 42.4101C25.438 37.4841 20.0643 31.9654 16.8671 27.0286C15.2683 24.5598 14.2169 22.2415 13.8014 20.2193C13.386 18.1971 13.6082 16.4828 14.5388 15.2046C15.4694 13.9264 17.0345 13.1858 19.0901 12.9552C21.1458 12.7246 23.6801 13.006 26.527 13.7606C32.2201 15.2694 39.1402 18.6641 45.9344 23.59C52.7285 28.5159 58.1022 34.0346 61.2994 38.9715C62.8983 41.4402 63.9496 43.7585 64.3651 45.7808C64.7806 47.8029 64.5584 49.5172 63.6278 50.7954C62.6972 52.0736 61.132 52.8142 59.0764 53.0448C57.0207 53.2754 54.4864 52.994 51.6395 52.2395C45.9464 50.7306 39.0263 47.336 32.2322 42.4101Z"
				stroke="url(#paint27_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.8136 42.1657C25.1959 37.0062 20.0185 31.3037 16.9958 26.2585C15.4843 23.7355 14.5147 21.382 14.1702 19.3465C13.8257 17.3111 14.1077 15.6056 15.0824 14.3606C16.0572 13.1156 17.6473 12.4299 19.7097 12.2711C21.7722 12.1123 24.2951 12.4817 27.1139 13.3349C32.7507 15.0411 39.5479 18.6747 46.1657 23.8343C52.7834 28.9938 57.9608 34.6963 60.9834 39.7415C62.495 42.2644 63.4646 44.6179 63.8091 46.6534C64.1536 48.6888 63.8716 50.3943 62.8968 51.6393C61.9221 52.8843 60.332 53.57 58.2696 53.7288C56.2071 53.8877 53.6841 53.5182 50.8654 52.665C45.2285 50.9588 38.4313 47.3252 31.8136 42.1657Z"
				stroke="url(#paint28_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.4028 41.9102C24.9695 36.5234 19.9947 30.644 17.1503 25.4966C15.728 22.9226 14.8413 20.5367 14.5681 18.4905C14.295 16.4443 14.6365 14.7497 15.6542 13.5394C16.6719 12.3291 18.285 11.6992 20.3517 11.6123C22.4185 11.5254 24.927 11.9825 27.7142 12.9333C33.288 14.8348 39.954 18.7029 46.3872 24.0898C52.8204 29.4766 57.7953 35.3559 60.6396 40.5033C62.062 43.0774 62.9487 45.4632 63.2218 47.5094C63.4949 49.5556 63.1534 51.2503 62.1357 52.4606C61.118 53.6709 59.5049 54.3008 57.4382 54.3877C55.3714 54.4746 52.863 54.0175 50.0757 53.0666C44.502 51.1652 37.836 47.2971 31.4028 41.9102Z"
				stroke="url(#paint29_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.0016 41.6438C24.7607 36.0362 19.9945 29.9872 17.3319 24.7439C16.0004 22.1219 15.1977 19.7066 14.9963 17.6521C14.7949 15.5977 15.1954 13.916 16.2548 12.7418C17.3142 11.5677 18.9484 10.9944 21.0169 10.9795C23.0855 10.9646 25.5764 11.5088 28.3287 12.5562C33.8325 14.6506 40.3592 18.7485 46.6001 24.3561C52.841 29.9637 57.6072 36.0127 60.2698 41.256C61.6013 43.8781 62.4041 46.2933 62.6054 48.3478C62.8068 50.4022 62.4063 52.084 61.3469 53.2581C60.2875 54.4322 58.6533 55.0056 56.5848 55.0204C54.5162 55.0353 52.0253 54.4911 49.273 53.4438C43.7692 51.3494 37.2425 47.2515 31.0016 41.6438Z"
				stroke="url(#paint30_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M30.6109 41.367C24.5699 35.5455 20.0182 29.3342 17.5406 24.0013C16.3016 21.3345 15.5838 18.8928 15.4544 16.8325C15.325 14.7723 15.7841 13.1055 16.8839 11.969C17.9838 10.8325 19.637 10.3164 21.7047 10.3736C23.7726 10.4308 26.243 11.0614 28.957 12.2039C34.3842 14.4888 40.7635 18.8115 46.8046 24.633C52.8456 30.4546 57.3973 36.6659 59.8749 41.9987C61.1139 44.6655 61.8317 47.1073 61.9611 49.1675C62.0905 51.2277 61.6314 52.8945 60.5315 54.031C59.4317 55.1675 57.7785 55.6836 55.7107 55.6264C53.6429 55.5693 51.1725 54.9386 48.4585 53.7961C43.0313 51.5113 36.6519 47.1886 30.6109 41.367Z"
				stroke="url(#paint31_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M30.2282 41.0799C24.3945 35.0515 20.0627 28.6855 17.7731 23.2696C16.6282 20.5613 15.9962 18.0961 15.9389 16.0325C15.8817 13.9691 16.3988 12.3193 17.5377 11.2218C18.6766 10.1243 20.3469 9.66609 22.4114 9.79523C24.476 9.92437 26.9228 10.6407 29.5952 11.877C34.9392 14.3495 41.1635 18.8917 46.9972 24.9201C52.8309 30.9485 57.1627 37.3145 59.4523 42.7304C60.5972 45.4387 61.2292 47.904 61.2865 49.9675C61.3437 52.0309 60.8266 53.6807 59.6877 54.7782C58.5488 55.8758 56.8785 56.3339 54.814 56.2048C52.7495 56.0757 50.3026 55.3594 47.6302 54.123C42.2862 51.6506 36.0619 47.1083 30.2282 41.0799Z"
				stroke="url(#paint32_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M29.8581 40.783C24.2387 34.5551 20.1323 28.0421 18.0335 22.5497C16.9839 19.8032 16.4386 17.3175 16.4535 15.2532C16.4684 13.189 17.0429 11.5583 18.2195 10.5011C19.3961 9.44385 21.0814 9.04413 23.1401 9.24509C25.1989 9.44605 27.6193 10.2471 30.2467 11.5758C35.501 14.2329 41.5627 18.9891 47.182 25.217C52.8014 31.4449 56.9078 37.9579 59.0066 43.4502C60.0562 46.1968 60.6015 48.6825 60.5866 50.7468C60.5717 52.8109 59.9972 54.4417 58.8206 55.4989C57.644 56.5561 55.9587 56.9558 53.9 56.7549C51.8412 56.5539 49.4209 55.7529 46.7934 54.4242C41.5391 51.7671 35.4774 47.0109 29.8581 40.783Z"
				stroke="url(#paint33_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M29.4954 40.4766C24.0973 34.0568 20.2211 27.4048 18.3157 21.8427C17.3628 19.0613 16.9047 16.558 16.9918 14.4956C17.0789 12.4332 17.7101 10.8234 18.923 9.8078C20.1359 8.7922 21.8341 8.45142 23.8846 8.72396C25.9351 8.9965 28.3259 9.88137 30.9053 11.3008C36.0635 14.1392 41.9551 19.1036 47.3532 25.5234C52.7513 31.9432 56.6275 38.5953 58.533 44.1574C59.4858 46.9388 59.9439 49.442 59.8568 51.5045C59.7697 53.5669 59.1385 55.1766 57.9256 56.1922C56.7127 57.2078 55.0145 57.5486 52.9641 57.2761C50.9135 57.0035 48.5227 56.1187 45.9433 54.6993C40.7851 51.8609 34.8935 46.8964 29.4954 40.4766Z"
				stroke="url(#paint34_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M29.1452 40.1611C23.9749 33.5572 20.3337 26.7742 18.624 21.1492C17.769 18.3363 17.3987 15.8186 17.5579 13.7604C17.7171 11.7023 18.4042 10.1155 19.6519 9.14277C20.8995 8.17003 22.6086 7.8886 24.6483 8.23238C26.688 8.57617 29.0464 9.54376 31.5747 11.0521C36.6304 14.0684 42.3448 19.2351 47.5151 25.8389C52.6854 32.4428 56.3266 39.2258 58.0364 44.8509C58.8914 47.6638 59.2616 50.1814 59.1024 52.2396C58.9433 54.2977 58.2562 55.8845 57.0085 56.8573C55.7609 57.83 54.0517 58.1114 52.0121 57.7677C49.9723 57.4239 47.6139 56.4563 45.0857 54.9479C40.03 51.9316 34.3156 46.765 29.1452 40.1611Z"
				stroke="url(#paint35_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.8062 39.8368C23.87 33.0569 20.4683 26.1512 18.9563 20.4701C18.2002 17.6291 17.9182 15.1001 18.1493 13.0487C18.3803 10.9974 19.1225 9.43548 20.4034 8.50678C21.6844 7.57808 23.4023 7.35635 25.4287 7.77095C27.4551 8.18557 29.7783 9.23471 32.2522 10.8302C37.1994 14.0208 42.7296 19.3832 47.6658 26.1632C52.602 32.9431 56.0038 39.8487 57.5158 45.5299C58.2718 48.3709 58.5538 50.8999 58.3228 52.9513C58.0917 55.0026 57.3495 56.5645 56.0686 57.4932C54.7877 58.4219 53.0698 58.6436 51.0434 58.229C49.0169 57.8144 46.6938 56.7653 44.2199 55.1698C39.2727 51.9792 33.7424 46.6168 28.8062 39.8368Z"
				stroke="url(#paint36_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.4799 39.5042C23.7838 32.5565 20.6256 25.5366 19.3132 19.8062C18.6569 16.9407 18.4636 14.4034 18.7662 12.3613C19.0689 10.3193 19.8653 8.78416 21.1779 7.90063C22.4905 7.0171 24.2152 6.85534 26.2258 7.34026C28.2365 7.8252 30.5216 8.9546 32.9382 10.6353C37.7708 13.9962 43.1101 19.548 47.8062 26.4957C52.5023 33.4434 55.6604 40.4633 56.9728 46.1937C57.6291 49.0592 57.8225 51.5965 57.5198 53.6386C57.2171 55.6806 56.4208 57.2158 55.1082 58.0993C53.7955 58.9828 52.0709 59.1446 50.0602 58.6597C48.0495 58.1747 45.7645 57.0453 43.3479 55.3647C38.5153 52.0037 33.176 46.4519 28.4799 39.5042Z"
				stroke="url(#paint37_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.1637 39.1637C23.7135 32.0567 20.8027 24.9311 19.6916 19.1585C19.1359 16.2718 19.0314 13.7293 19.4053 11.699C19.7792 9.66882 20.6287 8.16236 21.9715 7.32508C23.3142 6.4878 25.0435 6.3862 27.0359 6.94085C29.0285 7.49552 31.2726 8.70381 33.629 10.4676C38.3411 13.9948 43.483 19.7292 47.9332 26.8362C52.3835 33.9432 55.2942 41.0688 56.4054 46.8414C56.961 49.7281 57.0656 52.2706 56.6917 54.3009C56.3178 56.3311 55.4682 57.8375 54.1255 58.6748C52.7828 59.5121 51.0535 59.6137 49.061 59.0591C47.0685 58.5044 44.8243 57.2961 42.468 55.5323C37.7559 52.0051 32.614 46.2708 28.1637 39.1637Z"
				stroke="url(#paint38_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.8602 38.8158C23.6612 31.5581 21.0015 24.3354 20.0928 18.5277C19.6385 15.6234 19.6229 13.0788 20.0676 11.0628C20.5123 9.04682 21.414 7.57086 22.7852 6.78085C24.1564 5.99083 25.8882 5.94952 27.86 6.57322C29.8319 7.19695 32.0325 8.48267 34.3257 10.3275C38.9116 14.0166 43.8498 19.9265 48.0488 27.1842C52.2478 34.4419 54.9075 41.6646 55.8161 47.4723C56.2705 50.3766 56.2861 52.9211 55.8414 54.9372C55.3967 56.9531 54.495 58.4291 53.1238 59.2191C51.7526 60.0091 50.0208 60.0504 48.049 59.4267C46.077 58.803 43.8765 57.5173 41.5833 55.6725C36.9974 51.9834 32.0592 46.0734 27.8602 38.8158Z"
				stroke="url(#paint39_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.5672 38.4607C23.6246 31.0612 21.2191 23.7503 20.5141 17.9144C20.1616 14.9961 20.2351 12.4526 20.75 10.4532C21.2649 8.45397 22.2177 7.01031 23.6157 6.26853C25.0137 5.52675 26.7458 5.54578 28.6947 6.23777C30.6436 6.9298 32.7978 8.29136 35.0251 10.2149C39.4792 14.0615 44.2077 20.1398 48.1503 27.5393C52.0929 34.9388 54.4985 42.2497 55.2034 48.0856C55.5559 51.0039 55.4825 53.5474 54.9676 55.5468C54.4527 57.546 53.4999 58.9897 52.1019 59.7314C50.7039 60.4732 48.9717 60.4542 47.0229 59.7622C45.0739 59.0702 42.9197 57.7086 40.6924 55.7851C36.2384 51.9385 31.5098 45.8602 27.5672 38.4607Z"
				stroke="url(#paint40_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.2895 38.0989C23.6081 30.5666 21.4597 23.1764 20.9592 17.3195C20.709 14.3907 20.8714 11.8513 21.4559 9.8711C22.0404 7.891 23.0431 6.4814 24.4662 5.78876C25.8893 5.09611 27.6197 5.17545 29.5432 5.9349C31.4667 6.69438 33.572 8.13013 35.7307 10.1301C40.0475 14.1294 44.5606 20.3687 48.242 27.901C51.9234 35.4333 54.0718 42.8235 54.5723 48.6804C54.8225 51.6092 54.6601 54.1486 54.0756 56.1288C53.4911 58.1089 52.4884 59.5185 51.0653 60.2112C49.6422 60.9038 47.9118 60.8245 45.9883 60.065C44.0648 59.3055 41.9595 57.8698 39.8008 55.8698C35.484 51.8705 30.9709 45.6312 27.2895 38.0989Z"
				stroke="url(#paint41_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.0239 37.7309C23.6081 30.075 21.7195 22.6145 21.4242 16.7438C21.2766 13.808 21.5276 11.2758 22.1811 9.31718C22.8344 7.35865 23.8858 5.98482 25.3323 5.34216C26.7788 4.69949 28.5054 4.83905 30.4011 5.66502C32.2969 6.49103 34.3507 7.99923 36.4381 10.0731C40.6124 14.2204 44.9045 20.6131 48.3203 28.269C51.7361 35.9249 53.6247 43.3854 53.92 49.2561C54.0677 52.1919 53.8166 54.7241 53.1632 56.6827C52.5098 58.6413 51.4584 60.0151 50.0119 60.6578C48.5654 61.3004 46.8388 61.1609 44.9431 60.3349C43.0473 59.5089 40.9935 58.0007 38.9061 55.9268C34.7318 51.7795 30.4397 45.3868 27.0239 37.7309Z"
				stroke="url(#paint42_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.7697 37.3572C23.6238 29.587 21.9972 22.0652 21.9074 16.1878C21.8625 13.2487 22.202 10.7268 22.9235 8.7921C23.645 6.85752 24.7438 5.52114 26.2118 4.92924C27.6799 4.33734 29.4006 4.53695 31.2663 5.42844C33.132 6.31996 35.1318 7.89877 37.1454 10.0441C41.1722 14.3342 45.2381 20.8725 48.384 28.6427C51.53 36.4129 53.1565 43.9346 53.2463 49.8121C53.2912 52.7512 52.9517 55.2731 52.2302 57.2078C51.5087 59.1424 50.4099 60.4787 48.9419 61.0706C47.4738 61.6625 45.7531 61.4629 43.8875 60.5714C42.0217 59.6799 40.0219 58.1011 38.0083 55.9558C33.9815 51.6657 29.9156 45.1274 26.7697 37.3572Z"
				stroke="url(#paint43_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.5314 36.9783C23.6591 29.1032 22.2966 21.5294 22.4124 15.6524C22.4703 12.7136 22.8978 10.205 23.6865 8.29661C24.4752 6.38833 25.6201 5.09103 27.108 4.55062C28.5958 4.01021 30.3085 4.26963 32.1419 5.22554C33.9753 6.1815 35.9186 7.829 37.856 10.0431C41.7303 14.4709 45.5651 21.1468 48.4373 29.0218C51.3096 36.8968 52.6721 44.4706 52.5563 50.3476C52.4984 53.2865 52.0709 55.7951 51.2822 57.7034C50.4935 59.6117 49.3486 60.909 47.8608 61.4494C46.3729 61.9898 44.6602 61.7304 42.8269 60.7745C40.9934 59.8185 39.0501 58.171 37.1127 55.9569C33.2385 51.5292 29.4037 44.8533 26.5314 36.9783Z"
				stroke="url(#paint44_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.3033 36.5943C23.7082 28.6241 22.6114 21.0074 22.9326 15.1381C23.0933 12.203 23.6083 9.71083 24.4632 7.8311C25.3182 5.95146 26.5077 4.69482 28.0136 4.20655C29.5195 3.71828 31.222 4.03719 33.0208 5.05637C34.8197 6.0756 36.7043 7.78977 38.563 10.07C42.2801 14.63 45.8791 21.4354 48.4742 29.4056C51.0693 37.3759 52.1661 44.9926 51.8449 50.8619C51.6842 53.797 51.1692 56.2892 50.3143 58.1689C49.4593 60.0485 48.2698 61.3052 46.7639 61.7934C45.258 62.2817 43.5555 61.9628 41.7566 60.9436C39.9578 59.9244 38.0732 58.2102 36.2145 55.9299C32.4974 51.37 28.8984 44.5646 26.3033 36.5943Z"
				stroke="url(#paint45_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.0909 36.2061C23.7761 28.1503 22.9463 20.5001 23.4727 14.6455C23.7359 11.7178 24.3377 9.2451 25.2579 7.39629C26.178 5.54757 27.4108 4.33311 28.9328 3.89759C30.4549 3.46206 32.1452 3.84007 33.9073 4.92128C35.6695 6.00254 37.4929 7.78129 39.2708 10.1249C42.8261 14.8115 46.1849 21.7381 48.4997 29.7939C50.8145 37.8497 51.6442 45.4999 51.1179 51.3545C50.8547 54.2821 50.2529 56.7549 49.3327 58.6037C48.4125 60.4524 47.1798 61.6669 45.6577 62.1024C44.1357 62.5379 42.4453 62.1599 40.6832 61.0787C38.9211 59.9974 37.0977 58.2187 35.3198 55.8751C31.7645 51.1884 28.4057 44.2619 26.0909 36.2061Z"
				stroke="url(#paint46_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.8912 35.8139C23.8596 27.6824 23.2978 20.0079 24.0286 14.1753C24.394 11.2585 25.0819 8.80826 26.0662 6.99263C27.0505 5.17708 28.325 4.0063 29.8613 3.62405C31.3977 3.24179 33.0738 3.67844 34.797 4.82036C36.5202 5.96233 38.2804 7.8035 39.9752 10.2076C43.3644 15.0152 46.479 22.0545 48.5106 30.186C50.5422 38.3175 51.104 45.992 50.3732 51.8247C50.0078 54.7414 49.3199 57.1916 48.3356 59.0073C47.3513 60.8228 46.0768 61.9936 44.5405 62.3759C43.0041 62.7581 41.328 62.3215 39.6048 61.1795C37.8816 60.0376 36.1214 58.1964 34.4266 55.7923C31.0374 50.9847 27.9229 43.9454 25.8912 35.8139Z"
				stroke="url(#paint47_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.7048 35.4183C23.9587 27.221 23.6657 19.5317 24.6 13.728C25.0672 10.8258 25.8404 8.40099 26.8876 6.62074C27.9348 4.84057 29.2495 3.71489 30.7982 3.38637C32.347 3.05786 34.0068 3.55261 35.689 4.75385C37.3713 5.95514 39.066 7.85649 40.6757 10.3182C43.8947 15.2409 46.7612 22.3844 48.5072 30.5816C50.2532 38.7789 50.5462 46.4683 49.6119 52.272C49.1447 55.1742 48.3715 57.599 47.3243 59.3792C46.2772 61.1594 44.9625 62.2851 43.4137 62.6136C41.8649 62.9421 40.2051 62.4474 38.5229 61.2461C36.8406 60.0448 35.146 58.1435 33.5363 55.6818C30.3172 50.7591 27.4508 43.6156 25.7048 35.4183Z"
				stroke="url(#paint48_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.5339 35.0198C24.0756 26.7667 24.0517 19.0718 25.1884 13.3042C25.7569 10.4201 26.6144 8.02364 27.7232 6.28096C28.832 4.53835 30.1852 3.45913 31.7445 3.18475C33.3039 2.91037 34.9454 3.46264 36.5845 4.72172C38.2238 5.98087 39.8509 7.94008 41.3736 10.4563C44.4185 15.4881 47.0333 22.7271 48.4916 30.9802C49.9499 39.2333 49.9738 46.9282 48.8371 52.6958C48.2687 55.58 47.4112 57.9764 46.3023 59.7191C45.1936 61.4617 43.8403 62.5409 42.281 62.8153C40.7217 63.0896 39.0802 62.5374 37.441 61.2783C35.8018 60.0191 34.1746 58.0599 32.652 55.5437C29.6071 50.5119 26.9922 43.2729 25.5339 35.0198Z"
				stroke="url(#paint49_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.3761 34.6188C24.2074 26.3199 24.4526 18.6289 25.7903 12.9044C26.4592 10.0418 27.4 7.67672 28.5691 5.9737C29.7382 4.27077 31.1283 3.23934 32.6963 3.01944C34.2643 2.79953 35.8855 3.40863 37.4796 4.72403C39.0738 6.0395 40.6314 8.05419 42.0652 10.6219C44.9323 15.7567 47.2924 23.0823 48.4611 31.3812C49.6299 39.6801 49.3847 47.3711 48.0469 53.0956C47.378 55.9582 46.4372 58.3233 45.2681 60.0263C44.0991 61.7292 42.7089 62.7606 41.1409 62.9806C39.5729 63.2005 37.9518 62.5914 36.3576 61.276C34.7634 59.9605 33.2058 57.9458 31.7721 55.3781C28.905 50.2433 26.5449 42.9177 25.3761 34.6188Z"
				stroke="url(#paint50_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.2327 34.2158C24.3549 25.8813 24.8689 18.2035 26.406 12.5291C27.1747 9.69148 28.1976 7.3606 29.4256 5.69934C30.6535 4.03816 32.0788 3.05577 33.6536 2.89061C35.2283 2.72544 36.8272 3.39063 38.3743 4.76075C39.9216 6.13094 41.4078 8.19865 42.7508 10.8147C45.4366 16.0463 47.5391 23.4496 48.4169 31.7841C49.2947 40.1186 48.7806 47.7964 47.2435 53.4708C46.4749 56.3084 45.452 58.6393 44.224 60.3006C42.9961 61.9617 41.5708 62.9441 39.996 63.1093C38.4213 63.2745 36.8224 62.6093 35.2753 61.2391C33.728 59.869 32.2418 57.8013 30.8988 55.1852C28.213 49.9536 26.1105 42.5503 25.2327 34.2158Z"
				stroke="url(#paint51_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.1035 33.8113C24.5177 25.4513 25.2999 17.7961 27.0345 12.1787C27.902 9.3696 29.0058 7.07577 30.2911 5.45829C31.5763 3.84088 33.0352 2.90873 34.6147 2.79851C36.1943 2.68829 37.7689 3.40876 39.2672 4.83193C40.7656 6.25516 42.1786 8.37337 43.4293 11.0346C45.9305 16.3565 47.7728 23.8286 48.3586 32.1886C48.9444 40.5486 48.1621 48.2038 46.4275 53.8212C45.5601 56.6303 44.4563 58.9241 43.171 60.5416C41.8858 62.159 40.4269 63.0912 38.8473 63.2014C37.2678 63.3116 35.6931 62.5911 34.1948 61.168C32.6965 59.7447 31.2835 57.6265 30.0327 54.9653C27.5316 49.6434 25.6893 42.1713 25.1035 33.8113Z"
				stroke="url(#paint52_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.9887 33.4059C24.6956 25.0306 25.7451 17.4073 27.6752 11.8537C28.6403 9.07655 29.8237 6.82256 31.1647 5.25082C32.5058 3.67916 33.9963 2.79839 35.5788 2.74324C37.1612 2.6881 38.7097 3.46297 40.1573 4.93745C41.605 6.412 42.9431 8.57813 44.1 11.2813C46.4135 16.6871 47.9933 24.2187 48.2864 32.5941C48.5795 40.9694 47.53 48.5927 45.6 54.1463C44.6348 56.9234 43.4515 59.1774 42.1104 60.7492C40.7694 62.3208 39.2788 63.2016 37.6964 63.2567C36.1139 63.3119 34.5654 62.537 33.1178 61.0625C31.6701 59.588 30.3321 57.4219 29.1752 54.7186C26.8616 49.3129 25.2818 41.7812 24.9887 33.4059Z"
				stroke="url(#paint53_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.8862 33C24.8862 24.6196 26.2017 17.0375 28.3247 11.5545C29.3864 8.81262 30.6479 6.60121 32.0431 5.07714C33.4383 3.55314 34.9587 2.72481 36.5421 2.72481C38.1255 2.72481 39.646 3.55314 41.0412 5.07714C42.4364 6.60121 43.6979 8.81262 44.7595 11.5545C46.8826 17.0375 48.1981 24.6196 48.1981 33C48.1981 41.3804 46.8826 48.9625 44.7595 54.4455C43.6979 57.1874 42.4364 59.3988 41.0412 60.9229C39.646 62.4469 38.1255 63.2752 36.5421 63.2752C34.9587 63.2752 33.4383 62.4469 32.0431 60.9229C30.6479 59.3988 29.3864 57.1874 28.3247 54.4455C26.2017 48.9625 24.8862 41.3804 24.8862 33Z"
				stroke="url(#paint54_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.7985 32.594C25.0916 24.2187 26.6714 16.6871 28.985 11.2813C30.1419 8.57812 31.4799 6.41199 32.9276 4.93744C34.3752 3.46296 35.9237 2.68809 37.5062 2.74323C39.0886 2.79838 40.5792 3.67915 41.9202 5.25081C43.2613 6.82255 44.4446 9.07654 45.4098 11.8537C47.3398 17.4073 48.3893 25.0306 48.0962 33.4059C47.8031 41.7812 46.2233 49.3129 43.9098 54.7186C42.7529 57.4218 41.4148 59.588 39.9671 61.0625C38.5195 62.537 36.971 63.3119 35.3886 63.2567C33.8061 63.2016 32.3156 62.3208 30.9745 60.7491C29.6335 59.1774 28.4501 56.9234 27.485 54.1463C25.5549 48.5927 24.5054 40.9694 24.7985 32.594Z"
				stroke="url(#paint55_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.7264 32.1887C25.3122 23.8287 27.1544 16.3566 29.6556 11.0347C30.9063 8.37346 32.3193 6.25525 33.8177 4.83202C35.316 3.40885 36.8907 2.68838 38.4702 2.7986C40.0497 2.90882 41.5086 3.84097 42.7938 5.45838C44.0791 7.07586 45.183 9.36969 46.0504 12.1788C47.785 17.7962 48.5673 25.4514 47.9814 33.8114C47.3956 42.1714 45.5534 49.6435 43.0522 54.9654C41.8015 57.6266 40.3885 59.7448 38.8901 61.1681C37.3918 62.5912 35.8171 63.3117 34.2376 63.2015C32.6581 63.0913 31.1992 62.1591 29.914 60.5417C28.6287 58.9242 27.5248 56.6304 26.6574 53.8213C24.9228 48.2039 24.1405 40.5487 24.7264 32.1887Z"
				stroke="url(#paint56_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.6684 31.7842C25.5462 23.4497 27.6486 16.0463 30.3344 10.8148C31.6775 8.1987 33.1637 6.13099 34.7109 4.76081C36.2581 3.39068 37.857 2.7255 39.4317 2.89066C41.0064 3.05582 42.4318 4.03821 43.6597 5.69939C44.8876 7.36066 45.9105 9.69153 46.6792 12.5291C48.2163 18.2035 48.7303 25.8813 47.8525 34.2158C46.9747 42.5503 44.8722 49.9537 42.1865 55.1852C40.8434 57.8013 39.3572 59.869 37.81 61.2392C36.2628 62.6093 34.6639 63.2745 33.0892 63.1093C31.5145 62.9442 30.0891 61.9618 28.8612 60.3006C27.6333 58.6394 26.6103 56.3085 25.8417 53.4709C24.3046 47.7965 23.7905 40.1187 24.6684 31.7842Z"
				stroke="url(#paint57_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.6231 31.3812C25.7919 23.0823 28.152 15.7567 31.0191 10.6219C32.4528 8.05419 34.0104 6.03951 35.6046 4.72404C37.1988 3.40863 38.82 2.79954 40.3879 3.01944C41.9559 3.23935 43.3461 4.27078 44.5151 5.97371C45.6842 7.67672 46.625 10.0418 47.2939 12.9044C48.6317 18.6289 48.8769 26.3199 47.7081 34.6188C46.5394 42.9177 44.1793 50.2433 41.3122 55.3781C39.8785 57.9458 38.3208 59.9605 36.7266 61.276C35.1325 62.5914 33.5113 63.2005 31.9433 62.9806C30.3753 62.7607 28.9852 61.7292 27.8162 60.0263C26.6471 58.3233 25.7063 55.9582 25.0373 53.0956C23.6996 47.3711 23.4544 39.6801 24.6231 31.3812Z"
				stroke="url(#paint58_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.5932 30.9802C26.0515 22.7271 28.6663 15.4881 31.7112 10.4563C33.2339 7.94007 34.861 5.98085 36.5003 4.72171C38.1394 3.46262 39.7809 2.91036 41.3403 3.18474C42.8996 3.45912 44.2528 4.53833 45.3616 6.28094C46.4704 8.02363 47.3279 10.42 47.8964 13.3042C49.0331 19.0718 49.0092 26.7667 47.5509 35.0198C46.0926 43.2729 43.4777 50.5119 40.4328 55.5437C38.9102 58.0599 37.283 60.0191 35.6438 61.2783C34.0047 62.5374 32.3631 63.0896 30.8038 62.8152C29.2445 62.5409 27.8912 61.4616 26.7825 59.719C25.6737 57.9763 24.8161 55.5799 24.2477 52.6958C23.111 46.9282 23.1349 39.2333 24.5932 30.9802Z"
				stroke="url(#paint59_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.5771 30.5817C26.3231 22.3844 29.1896 15.2409 32.4086 10.3182C34.0183 7.85654 35.713 5.95518 37.3953 4.75389C39.0774 3.55266 40.7373 3.0579 42.286 3.38642C43.8348 3.71494 45.1495 4.84062 46.1967 6.62078C47.2439 8.40103 48.017 10.8258 48.4842 13.7281C49.4185 19.5317 49.1256 27.2211 47.3795 35.4184C45.6335 43.6157 42.7671 50.7592 39.548 55.6819C37.9383 58.1435 36.2437 60.0449 34.5614 61.2462C32.8792 62.4474 31.2194 62.9422 29.6706 62.6137C28.1218 62.2851 26.8071 61.1595 25.76 59.3793C24.7128 57.599 23.9396 55.1742 23.4724 52.272C22.5381 46.4683 22.8311 38.779 24.5771 30.5817Z"
				stroke="url(#paint60_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.5739 30.186C26.6055 22.0545 29.72 15.0152 33.1093 10.2076C34.8041 7.80352 36.5642 5.96234 38.2875 4.82037C40.0107 3.67845 41.6868 3.24181 43.2231 3.62406C44.7595 4.00632 46.034 5.17709 47.0182 6.99265C48.0025 8.80828 48.6904 11.2585 49.0559 14.1753C49.7866 20.0079 49.2249 27.6824 47.1933 35.8139C45.1616 43.9454 42.0471 50.9847 38.6579 55.7923C36.963 58.1964 35.2029 60.0376 33.4797 61.1795C31.7565 62.3215 30.0804 62.7581 28.544 62.3759C27.0077 61.9936 25.7332 60.8228 24.7489 59.0073C23.7646 57.1916 23.0767 54.7414 22.7113 51.8247C21.9805 45.992 22.5422 38.3175 24.5739 30.186Z"
				stroke="url(#paint61_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.585 29.7939C26.8998 21.7381 30.2586 14.8116 33.8139 10.1249C35.5917 7.7813 37.4152 6.00254 39.1773 4.92128C40.9394 3.84007 42.6298 3.46207 44.1518 3.89759C45.6738 4.33312 46.9066 5.54757 47.8268 7.39629C48.747 9.2451 49.3488 11.7178 49.612 14.6455C50.1383 20.5001 49.3086 28.1503 46.9938 36.2061C44.679 44.2619 41.3202 51.1884 37.7649 55.8751C35.987 58.2187 34.1635 59.9974 32.4014 61.0787C30.6393 62.1599 28.949 62.5379 27.4269 62.1024C25.9049 61.6669 24.6721 60.4524 23.752 58.6037C22.8317 56.7549 22.2299 54.2821 21.9667 51.3545C21.4404 45.4999 22.2702 37.8497 24.585 29.7939Z"
				stroke="url(#paint62_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.6104 29.4057C27.2055 21.4354 30.8045 14.6301 34.5215 10.0701C36.3803 7.78983 38.2648 6.07567 40.0637 5.05644C41.8625 4.03725 43.565 3.71834 45.0709 4.20661C46.5768 4.69488 47.7663 5.95152 48.6213 7.83117C49.4763 9.71089 49.9912 12.2031 50.1519 15.1381C50.4732 21.0075 49.3763 28.6242 46.7812 36.5944C44.1861 44.5647 40.5871 51.37 36.8701 55.93C35.0113 58.2103 33.1268 59.9244 31.3279 60.9437C29.5291 61.9628 27.8265 62.2818 26.3207 61.7935C24.8148 61.3052 23.6252 60.0486 22.7703 58.1689C21.9153 56.2892 21.4003 53.797 21.2397 50.862C20.9184 44.9926 22.0152 37.376 24.6104 29.4057Z"
				stroke="url(#paint63_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.6475 29.0218C27.5198 21.1467 31.3546 14.4709 35.2288 10.0431C37.1662 7.82898 39.1095 6.18149 40.943 5.22553C42.7763 4.26961 44.489 4.0102 45.9769 4.55061C47.4647 5.09102 48.6096 6.38832 49.3983 8.2966C50.187 10.205 50.6145 12.7136 50.6724 15.6524C50.7882 21.5294 49.4257 29.1032 46.5534 36.9782C43.6812 44.8533 39.8464 51.5291 35.9721 55.9569C34.0348 58.171 32.0914 59.8185 30.258 60.7745C28.4246 61.7304 26.712 61.9898 25.2241 61.4494C23.7362 60.909 22.5913 59.6117 21.8026 57.7034C21.0139 55.7951 20.5864 53.2865 20.5285 50.3476C20.4127 44.4706 21.7752 36.8968 24.6475 29.0218Z"
				stroke="url(#paint64_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.7002 28.6428C27.8462 20.8726 31.9121 14.3343 35.9388 10.0442C37.9525 7.89886 39.9523 6.32005 41.818 5.42853C43.6837 4.53704 45.4044 4.33743 46.8724 4.92933C48.3405 5.52123 49.4393 6.85761 50.1608 8.79219C50.8823 10.7269 51.2218 13.2488 51.1769 16.1879C51.0871 22.0653 49.4605 29.5871 46.3146 37.3573C43.1686 45.1275 39.1027 51.6658 35.076 55.9559C33.0623 58.1012 31.0626 59.68 29.1968 60.5715C27.3311 61.463 25.6104 61.6626 24.1424 61.0707C22.6743 60.4788 21.5755 59.1424 20.854 57.2079C20.1325 55.2732 19.793 52.7512 19.8379 49.8121C19.9277 43.9347 21.5543 36.413 24.7002 28.6428Z"
				stroke="url(#paint65_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.765 28.269C28.1808 20.6131 32.4729 14.2205 36.6473 10.0732C38.7347 7.99931 40.7885 6.49111 42.6843 5.66511C44.58 4.83914 46.3066 4.69958 47.7531 5.34224C49.1995 5.9849 50.2509 7.35873 50.9043 9.31726C51.5577 11.2759 51.8088 13.8081 51.6611 16.7439C51.3658 22.6146 49.4772 30.0751 46.0614 37.731C42.6457 45.3869 38.3536 51.7796 34.1792 55.9269C32.0918 58.0008 30.038 59.509 28.1422 60.335C26.2465 61.1609 24.5199 61.3005 23.0734 60.6578C21.627 60.0152 20.5756 58.6413 19.9222 56.6828C19.2688 54.7242 19.0177 52.1919 19.1654 49.2562C19.4607 43.3855 21.3493 35.9249 24.765 28.269Z"
				stroke="url(#paint66_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.8428 27.9011C28.5242 20.3688 33.0373 14.1295 37.3541 10.1301C39.5128 8.13018 41.6181 6.69442 43.5416 5.93494C45.4651 5.1755 47.1955 5.09616 48.6186 5.7888C50.0417 6.48144 51.0444 7.89105 51.6289 9.87114C52.2134 11.8513 52.3758 14.3908 52.1255 17.3196C51.6251 23.1764 49.4767 30.5667 45.7953 38.0989C42.1139 45.6312 37.6008 51.8705 33.284 55.8699C31.1253 57.8698 29.02 59.3056 27.0965 60.0651C25.173 60.8245 23.4426 60.9038 22.0195 60.2112C20.5964 59.5186 19.5937 58.1089 19.0092 56.1289C18.4247 54.1487 18.2623 51.6092 18.5125 48.6804C19.013 42.8236 21.1614 35.4333 24.8428 27.9011Z"
				stroke="url(#paint67_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M24.9338 27.5393C28.8764 20.1398 33.605 14.0615 38.059 10.2149C40.2863 8.29136 42.4405 6.9298 44.3895 6.23778C46.3383 5.54578 48.0705 5.52675 49.4685 6.26854C50.8665 7.01032 51.8193 8.45398 52.3342 10.4532C52.8491 12.4526 52.9225 14.9961 52.57 17.9144C51.8651 23.7503 49.4595 31.0612 45.5169 38.4607C41.5743 45.8602 36.8458 51.9385 32.3917 55.7851C30.1644 57.7086 28.0102 59.0702 26.0613 59.7622C24.1124 60.4542 22.3803 60.4732 20.9823 59.7314C19.5843 58.9897 18.6314 57.546 18.1166 55.5467C17.6017 53.5474 17.5282 51.0038 17.8807 48.0855C18.5857 42.2497 20.9912 34.9388 24.9338 27.5393Z"
				stroke="url(#paint68_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.0351 27.1842C29.234 19.9265 34.1723 14.0166 38.7582 10.3274C41.0514 8.48263 43.2519 7.19692 45.2238 6.57319C47.1957 5.94949 48.9274 5.9908 50.2986 6.78082C51.6699 7.57083 52.5716 9.04679 53.0162 11.0627C53.4609 13.0788 53.4454 15.6234 52.991 18.5276C52.0824 24.3354 49.4226 31.5581 45.2236 38.8157C41.0247 46.0734 36.0864 51.9833 31.5006 55.6725C29.2073 57.5173 27.0068 58.803 25.0349 59.4267C23.063 60.0504 21.3313 60.0091 19.9601 59.2191C18.5889 58.4291 17.6871 56.9531 17.2425 54.9371C16.7978 52.9211 16.8133 50.3765 17.2677 47.4723C18.1763 41.6645 20.8361 34.4418 25.0351 27.1842Z"
				stroke="url(#paint69_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.1527 26.8362C29.603 19.7292 34.7449 13.9948 39.457 10.4677C41.8133 8.70386 44.0575 7.49557 46.05 6.94089C48.0425 6.38624 49.7717 6.48784 51.1145 7.32513C52.4572 8.16241 53.3068 9.66887 53.6807 11.6991C54.0546 13.7294 53.95 16.2719 53.3944 19.1585C52.2832 24.9311 49.3725 32.0568 44.9222 39.1638C40.472 46.2708 35.3301 52.0051 30.618 55.5323C28.2616 57.2961 26.0175 58.5044 24.0249 59.0591C22.0325 59.6137 20.3032 59.5121 18.9605 58.6749C17.6177 57.8376 16.7682 56.3311 16.3943 54.3009C16.0204 52.2706 16.1249 49.7281 16.6806 46.8414C17.7918 41.0689 20.7025 33.9432 25.1527 26.8362Z"
				stroke="url(#paint70_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.2798 26.4957C29.9759 19.548 35.3152 13.9962 40.1478 10.6353C42.5644 8.95465 44.8495 7.82525 46.8602 7.34031C48.8708 6.85539 50.5955 7.01715 51.9081 7.90068C53.2207 8.78421 54.0171 10.3193 54.3197 12.3613C54.6224 14.4034 54.429 16.9407 53.7728 19.8063C52.4604 25.5366 49.3022 32.5566 44.6061 39.5043C39.9101 46.452 34.5707 52.0038 29.7381 55.3647C27.3215 57.0454 25.0365 58.1748 23.0258 58.6597C21.0151 59.1446 19.2905 58.9829 17.9779 58.0993C16.6652 57.2158 15.8689 55.6807 15.5662 53.6387C15.2636 51.5966 15.4569 49.0593 16.1132 46.1937C17.4256 40.4634 20.5837 33.4434 25.2798 26.4957Z"
				stroke="url(#paint71_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.4186 26.1632C30.3548 19.3832 35.8851 14.0208 40.8322 10.8302C43.3062 9.23471 45.6293 8.18558 47.6558 7.77096C49.6821 7.35636 51.4001 7.57809 52.681 8.50679C53.9619 9.43549 54.7041 10.9974 54.9352 13.0487C55.1662 15.1001 54.8842 17.6291 54.1281 20.4701C52.6162 26.1512 49.2144 33.0569 44.2782 39.8368C39.342 46.6167 33.8118 51.9792 28.8646 55.1698C26.3907 56.7653 24.0675 57.8144 22.0411 58.229C20.0147 58.6436 18.2968 58.4219 17.0158 57.4932C15.7349 56.5645 14.9927 55.0026 14.7617 52.9513C14.5306 50.8999 14.8126 48.3709 15.5687 45.5299C17.0807 39.8487 20.4824 32.9431 25.4186 26.1632Z"
				stroke="url(#paint72_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.5685 25.8389C30.7388 19.235 36.4532 14.0684 41.5089 11.0521C44.0371 9.54375 46.3955 8.57616 48.4353 8.23237C50.4749 7.88859 52.1841 8.17002 53.4317 9.14276C54.6794 10.1155 55.3665 11.7023 55.5257 13.7604C55.6848 15.8186 55.3146 18.3363 54.4596 21.1491C52.7498 26.7742 49.1087 33.5572 43.9383 40.1611C38.768 46.765 33.0536 51.9316 27.9979 54.9479C25.4697 56.4562 23.1113 57.4238 21.0715 57.7676C19.0319 58.1114 17.3227 57.83 16.0751 56.8572C14.8274 55.8845 14.1403 54.2977 13.9812 52.2396C13.822 50.1814 14.1922 47.6637 15.0472 44.8508C16.757 39.2258 20.3982 32.4428 25.5685 25.8389Z"
				stroke="url(#paint73_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.7307 25.5234C31.1288 19.1036 37.0204 14.1392 42.1785 11.3008C44.758 9.88138 47.1488 8.99651 49.1993 8.72397C51.2497 8.45143 52.948 8.79222 54.1608 9.80781C55.3737 10.8234 56.0049 12.4332 56.092 14.4956C56.1791 16.558 55.721 19.0613 54.7682 21.8427C52.8627 27.4048 48.9866 34.0568 43.5884 40.4766C38.1903 46.8964 32.2987 51.8608 27.1405 54.6993C24.5611 56.1187 22.1703 57.0035 20.1198 57.2761C18.0694 57.5486 16.3711 57.2078 15.1583 56.1922C13.9454 55.1766 13.3142 53.5669 13.2271 51.5045C13.14 49.442 13.5981 46.9388 14.5509 44.1574C16.4564 38.5953 20.3325 31.9432 25.7307 25.5234Z"
				stroke="url(#paint74_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M25.9026 25.217C31.5219 18.9891 37.5836 14.2329 42.8378 11.5759C45.4653 10.2472 47.8856 9.4461 49.9444 9.24513C52.0032 9.04418 53.6884 9.44389 54.865 10.5011C56.0416 11.5583 56.6162 13.1891 56.6311 15.2532C56.646 17.3175 56.1007 19.8033 55.0511 22.5498C52.9523 28.0421 48.8459 34.5551 43.2265 40.783C37.6072 47.0109 31.5455 51.7671 26.2912 54.4242C23.6637 55.7529 21.2434 56.5539 19.1846 56.7549C17.1259 56.9559 15.4406 56.5562 14.264 55.4989C13.0874 54.4417 12.5129 52.811 12.498 50.7468C12.4831 48.6825 13.0284 46.1968 14.078 43.4503C16.1768 37.9579 20.2832 31.4449 25.9026 25.217Z"
				stroke="url(#paint75_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.0865 24.92C31.9202 18.8916 38.1445 14.3494 43.4885 11.877C46.1609 10.6406 48.6077 9.9243 50.6723 9.79516C52.7368 9.66602 54.4071 10.1242 55.546 11.2217C56.6849 12.3193 57.202 13.969 57.1448 16.0325C57.0875 18.096 56.4555 20.5612 55.3106 23.2695C53.021 28.6854 48.6892 35.0515 42.8555 41.0799C37.0218 47.1083 30.7975 51.6505 25.4535 54.1229C22.7811 55.3593 20.3343 56.0756 18.2697 56.2048C16.2052 56.3339 14.5349 55.8757 13.396 54.7782C12.2571 53.6807 11.74 52.0309 11.7972 49.9674C11.8545 47.9039 12.4865 45.4387 13.6314 42.7304C15.921 37.3145 20.2528 30.9484 26.0865 24.92Z"
				stroke="url(#paint76_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.2808 24.633C32.3218 18.8115 38.7012 14.4887 44.1284 12.2039C46.8424 11.0614 49.3128 10.4307 51.3806 10.3736C53.4484 10.3164 55.1016 10.8325 56.2014 11.969C57.3012 13.1055 57.7604 14.7723 57.6309 16.8325C57.5015 18.8928 56.7838 21.3345 55.5448 24.0013C53.0672 29.3341 48.5154 35.5454 42.4744 41.367C36.4334 47.1885 30.0541 51.5112 24.6268 53.7961C21.9129 54.9386 19.4425 55.5693 17.3746 55.6264C15.3069 55.6836 13.6536 55.1675 12.5538 54.031C11.454 52.8945 10.9949 51.2277 11.1243 49.1675C11.2537 47.1072 11.9715 44.6655 13.2104 41.9987C15.6881 36.6659 20.2398 30.4546 26.2808 24.633Z"
				stroke="url(#paint77_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.4829 24.3561C32.7238 18.7485 39.2505 14.6506 44.7543 12.5562C47.5066 11.5088 49.9975 10.9646 52.0661 10.9795C54.1346 10.9944 55.7688 11.5677 56.8282 12.7418C57.8876 13.916 58.2881 15.5977 58.0868 17.6521C57.8854 19.7066 57.0826 22.1219 55.7512 24.7439C53.0885 29.9872 48.3223 36.0362 42.0814 41.6438C35.8405 47.2515 29.3139 51.3494 23.81 53.4438C21.0577 54.4911 18.5668 55.0353 16.4982 55.0205C14.4297 55.0056 12.7955 54.4322 11.7361 53.2581C10.6767 52.084 10.2762 50.4022 10.4776 48.3478C10.6789 46.2933 11.4817 43.8781 12.8132 41.256C15.4758 36.0127 20.242 29.9637 26.4829 24.3561Z"
				stroke="url(#paint78_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.6975 24.0897C33.1307 18.7029 39.7967 14.8348 45.3705 12.9333C48.1577 11.9825 50.6662 11.5253 52.733 11.6123C54.7997 11.6992 56.4128 12.3291 57.4305 13.5394C58.4482 14.7497 58.7897 16.4443 58.5166 18.4905C58.2435 20.5367 57.3567 22.9226 55.9344 25.4966C53.09 30.644 48.1152 36.5233 41.6819 41.9102C35.2487 47.2971 28.5827 51.1652 23.009 53.0666C20.2217 54.0175 17.7133 54.4746 15.6465 54.3877C13.5797 54.3008 11.9666 53.6709 10.949 52.4606C9.93126 51.2503 9.58976 49.5556 9.86287 47.5095C10.136 45.4632 11.0227 43.0774 12.4451 40.5033C15.2894 35.3559 20.2643 29.4766 26.6975 24.0897Z"
				stroke="url(#paint79_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M26.9182 23.8343C33.5359 18.6747 40.3331 15.0411 45.9699 13.3349C48.7887 12.4817 51.3116 12.1122 53.3741 12.2711C55.4366 12.4299 57.0267 13.1156 58.0014 14.3606C58.9761 15.6056 59.2582 17.3111 58.9137 19.3465C58.5691 21.382 57.5995 23.7355 56.088 26.2585C53.0654 31.3037 47.8879 37.0062 41.2702 42.1657C34.6525 47.3252 27.8553 50.9588 22.2185 52.665C19.3997 53.5182 16.8767 53.8877 14.8142 53.7289C12.7518 53.57 11.1617 52.8843 10.187 51.6393C9.21225 50.3943 8.93022 48.6888 9.27472 46.6534C9.61923 44.6179 10.5889 42.2644 12.1004 39.7415C15.123 34.6963 20.3005 28.9938 26.9182 23.8343Z"
				stroke="url(#paint80_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.1506 23.59C33.9447 18.6641 40.8648 15.2694 46.5579 13.7606C49.4048 13.0061 51.9392 12.7247 53.9949 12.9552C56.0505 13.1858 57.6156 13.9265 58.5462 15.2046C59.4768 16.4828 59.699 18.1971 59.2835 20.2193C58.868 22.2415 57.8167 24.5598 56.2179 27.0286C53.0206 31.9655 47.6469 37.4842 40.8528 42.4101C34.0587 47.336 27.1385 50.7307 21.4454 52.2395C18.5985 52.994 16.0642 53.2754 14.0085 53.0448C11.9529 52.8143 10.3878 52.0736 9.45718 50.7954C8.52658 49.5173 8.30437 47.803 8.71984 45.7808C9.13533 43.7585 10.1867 41.4402 11.7855 38.9715C14.9828 34.0346 20.3565 28.5159 27.1506 23.59Z"
				stroke="url(#paint81_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.3898 23.3571C34.352 18.6708 41.3867 15.5192 47.1291 14.2095C50.0006 13.5546 52.5433 13.3617 54.5896 13.6637C56.6359 13.9657 58.1742 14.7604 59.0595 16.0702C59.9449 17.38 60.107 19.101 59.621 21.1075C59.1351 23.114 58.0033 25.3943 56.3191 27.8059C52.9511 32.6284 47.3877 37.9566 40.4255 42.6429C33.4632 47.3292 26.4285 50.4808 20.6862 51.7904C17.8146 52.4453 15.272 52.6383 13.2256 52.3363C11.1793 52.0343 9.64103 51.2396 8.7557 49.9298C7.87038 48.6199 7.70826 46.899 8.1942 44.8925C8.68016 42.8859 9.81193 40.6057 11.4961 38.1941C14.8641 33.3716 20.4275 28.0434 27.3898 23.3571Z"
				stroke="url(#paint82_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.6357 23.1359C34.7576 18.695 41.8982 15.7903 47.6829 14.6814C50.5756 14.1269 53.1234 14.0226 55.158 14.3957C57.1925 14.7688 58.702 15.6166 59.541 16.9565C60.3799 18.2963 60.4818 20.0219 59.926 22.0102C59.3701 23.9986 58.1593 26.2381 56.3918 28.5895C52.8572 33.2918 47.1108 38.423 39.9889 42.864C32.867 47.305 25.7264 50.2096 19.9417 51.3185C17.049 51.873 14.5012 51.9773 12.4666 51.6042C10.4321 51.2311 8.9226 50.3833 8.08362 49.0435C7.24465 47.7036 7.14281 45.9781 7.69863 43.9897C8.25446 42.0013 9.46529 39.7619 11.2328 37.4104C14.7674 32.7081 20.5138 27.5769 27.6357 23.1359Z"
				stroke="url(#paint83_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M27.8911 22.9268C35.164 18.7366 42.4018 16.0824 48.2218 15.1756C51.1321 14.7222 53.682 14.7067 55.7023 15.1505C57.7225 15.5942 59.2015 16.494 59.9931 17.8623C60.7847 19.2305 60.8261 20.9586 60.2011 22.9263C59.5761 24.8942 58.2876 27.0901 56.439 29.3786C52.7421 33.9549 46.8198 38.8828 39.5469 43.0731C32.274 47.2633 25.0362 49.9175 19.2162 50.8243C16.3059 51.2777 13.756 51.2932 11.7357 50.8494C9.71547 50.4057 8.2365 49.5059 7.4449 48.1376C6.6533 46.7694 6.61187 45.0413 7.23689 43.0736C7.86192 41.1057 9.15034 38.9098 10.999 36.6213C14.6959 32.045 20.6182 27.1171 27.8911 22.9268Z"
				stroke="url(#paint84_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.1526 22.73C35.5676 18.7956 42.8938 16.3951 48.742 15.6916C51.6664 15.3398 54.2153 15.4131 56.2188 15.927C58.2223 16.4408 59.6689 17.3916 60.4121 18.7866C61.1554 20.1815 61.1364 21.91 60.4429 23.8548C59.7495 25.7996 58.385 27.9494 56.4574 30.172C52.6028 34.6168 46.5117 39.3355 39.0967 43.2699C31.6817 47.2043 24.3555 49.6049 18.5073 50.3083C15.5829 50.6601 13.034 50.5868 11.0305 50.073C9.02702 49.5592 7.58043 48.6084 6.83716 47.2134C6.0939 45.8184 6.11293 44.09 6.80638 42.1452C7.49986 40.2003 8.86428 38.0506 10.7919 35.8279C14.6465 31.3831 20.7376 26.6644 28.1526 22.73Z"
				stroke="url(#paint85_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.4211 22.5457C35.9692 18.8719 43.3749 16.728 49.2441 16.2286C52.179 15.9789 54.7238 16.1409 56.7082 16.7242C58.6924 17.3075 60.1049 18.3081 60.7989 19.7281C61.4929 21.1481 61.4135 22.8748 60.6524 24.7943C59.8914 26.7138 58.4526 28.8147 56.4484 30.9689C52.4407 35.2767 46.1883 39.7804 38.6402 43.4542C31.0921 47.1279 23.6864 49.2719 17.8172 49.7713C14.8823 50.021 12.3375 49.8589 10.3531 49.2756C8.36888 48.6924 6.95642 47.6918 6.2624 46.2718C5.56838 44.8518 5.64784 43.1251 6.40888 41.2056C7.16996 39.2861 8.60873 37.1852 10.6129 35.031C14.6206 30.7231 20.873 26.2194 28.4211 22.5457Z"
				stroke="url(#paint86_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.6951 22.3741C36.3671 18.9655 43.8433 17.0808 49.7264 16.7861C52.6683 16.6387 55.2059 16.8893 57.1686 17.5414C59.1312 18.1934 60.5078 19.2426 61.1518 20.6859C61.7957 22.1291 61.6559 23.8521 60.8282 25.7439C60.0005 27.6357 58.4891 29.6852 56.4108 31.7683C52.2548 35.934 45.8488 40.2172 38.1768 43.6258C30.5048 47.0344 23.0286 48.9191 17.1456 49.2138C14.2037 49.3612 11.6661 49.1106 9.70336 48.4586C7.7407 47.8065 6.3641 46.7574 5.72017 45.3141C5.07623 43.8708 5.21603 42.1479 6.04374 40.2561C6.87148 38.3642 8.38285 36.3147 10.4611 34.2316C14.6171 30.066 21.0232 25.7828 28.6951 22.3741Z"
				stroke="url(#paint87_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M28.9762 22.2155C36.7627 19.0762 44.3003 17.453 50.19 17.3634C53.1353 17.3185 55.6626 17.6573 57.6013 18.3773C59.54 19.0973 60.879 20.1938 61.4721 21.6586C62.0652 23.1235 61.8652 24.8405 60.9718 26.7023C60.0784 28.5641 58.4963 30.5598 56.3465 32.5692C52.0473 36.5876 45.4953 40.6451 37.7088 43.7845C29.9223 46.9238 22.3848 48.547 16.495 48.6366C13.5497 48.6814 11.0225 48.3427 9.08373 47.6226C7.14507 46.9027 5.80601 45.8062 5.21294 44.3413C4.61988 42.8765 4.81984 41.1595 5.71321 39.2977C6.60661 37.4358 8.18874 35.4402 10.3386 33.4308C14.6377 29.4124 21.1897 25.3549 28.9762 22.2155Z"
				stroke="url(#paint88_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M29.2624 22.07C37.1539 19.2037 44.7436 17.844 50.633 17.9596C53.578 18.0174 56.0919 18.444 58.0043 19.2311C59.9166 20.0181 61.2165 21.1606 61.7579 22.6452C62.2994 24.1297 62.0395 25.8387 61.0816 27.6683C60.1236 29.4979 58.4727 31.4372 56.2538 33.3706C51.8168 37.2368 45.1269 41.0636 37.2354 43.9299C29.3438 46.7962 21.7541 48.1559 15.8647 48.0403C12.9197 47.9825 10.4058 47.5559 8.49344 46.7688C6.58114 45.9818 5.28124 44.8393 4.73977 43.3547C4.19829 41.8702 4.45818 40.1612 5.41612 38.3316C6.37409 36.502 8.02505 34.5627 10.2439 32.6293C14.6809 28.7631 21.3708 24.9363 29.2624 22.07Z"
				stroke="url(#paint89_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M29.5532 21.9378C37.5402 19.3481 45.1728 18.2536 51.0545 18.5742C53.9958 18.7345 56.4932 19.2484 58.3769 20.1016C60.2605 20.9548 61.5196 22.1418 62.0089 23.6443C62.4981 25.1469 62.1786 26.8458 61.1573 28.6408C60.1359 30.436 58.4181 32.3166 56.133 34.1715C51.5635 37.8809 44.7438 41.4724 36.7568 44.0621C28.7699 46.6518 21.1372 47.7463 15.2555 47.4257C12.3143 47.2654 9.81682 46.7515 7.93314 45.8983C6.04953 45.0451 4.79038 43.8581 4.30116 42.3556C3.81194 40.853 4.13143 39.1542 5.15277 37.3591C6.17414 35.5639 7.89192 33.6833 10.177 31.8284C14.7466 28.1191 21.5662 24.5275 29.5532 21.9378Z"
				stroke="url(#paint90_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M29.8483 21.8191C37.921 19.5092 45.5873 18.6811 51.4542 19.2063C54.3881 19.469 56.866 20.0696 58.7187 20.9879C60.5713 21.9061 61.7882 23.1363 62.2246 24.6549C62.6609 26.1736 62.2822 27.8603 61.1987 29.6187C60.1152 31.3772 58.3327 33.1969 55.9841 34.9711C51.2876 38.519 44.3465 41.8708 36.2738 44.1808C28.2012 46.4907 20.5349 47.3188 14.668 46.7936C11.7341 46.5309 9.25618 45.9303 7.40349 45.012C5.55085 44.0938 4.33399 42.8636 3.89762 41.345C3.46124 39.8263 3.83995 38.1396 4.92345 36.3812C6.00698 34.6227 7.78948 32.803 10.138 31.0288C14.8345 27.4809 21.7756 24.1291 29.8483 21.8191Z"
				stroke="url(#paint91_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M30.1459 21.7141C38.2945 19.6867 45.9851 19.1262 51.8301 19.8554C54.7529 20.2201 57.2083 20.9066 59.0278 21.8888C60.8472 22.8711 62.0203 24.1428 62.4033 25.6757C62.7863 27.2087 62.3488 28.8812 61.2045 30.6008C60.0601 32.3205 58.2151 34.0769 55.8059 35.7683C50.9882 39.1505 43.9341 42.2585 35.7855 44.2859C27.6369 46.3133 19.9464 46.8739 14.1014 46.1447C11.1785 45.78 8.7231 45.0935 6.90366 44.1112C5.08427 43.129 3.91117 41.8572 3.52817 40.3243C3.14517 38.7914 3.58264 37.1189 4.72697 35.3993C5.87134 33.6796 7.7164 31.9231 10.1256 30.2318C14.9433 26.8496 21.9974 23.7416 30.1459 21.7141Z"
				stroke="url(#paint92_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M30.4482 21.6228C38.6627 19.8804 46.3682 19.588 52.1841 20.5204C55.0924 20.9866 57.5223 21.7582 59.3063 22.8032C61.0903 23.8482 62.2182 25.1601 62.5473 26.7054C62.8765 28.2507 62.3808 29.9069 61.177 31.5857C59.9732 33.2644 58.0678 34.9556 55.601 36.562C50.6679 39.7743 43.5094 42.6348 35.2949 44.3772C27.0804 46.1196 19.3749 46.412 13.559 45.4796C10.6507 45.0134 8.22073 44.2418 6.43674 43.1968C4.65281 42.1518 3.52491 40.8399 3.19576 39.2946C2.8666 37.7493 3.36229 36.093 4.56607 34.4143C5.76988 32.7355 7.67524 31.0444 10.1421 29.438C15.0752 26.2257 22.2337 23.3652 30.4482 21.6228Z"
				stroke="url(#paint93_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M30.7527 21.5453C39.0232 20.09 46.7342 20.0662 52.514 21.2005C55.4042 21.7678 57.8057 22.6235 59.552 23.7301C61.2983 24.8365 62.3797 26.1869 62.6546 27.7427C62.9295 29.2985 62.3762 30.9365 61.1144 32.5723C59.8526 34.2081 57.8893 35.8319 55.3678 37.3514C50.3254 40.39 43.0712 42.9994 34.8007 44.4547C26.5303 45.9099 18.8193 45.9338 13.0395 44.7994C10.1493 44.2322 7.7478 43.3764 6.00145 42.2699C4.25515 41.1634 3.17382 39.8131 2.89891 38.2573C2.624 36.7014 3.17731 35.0635 4.43906 33.4277C5.70085 31.7918 7.66419 30.1681 10.1857 28.6486C15.2281 25.61 22.4823 23.0006 30.7527 21.5453Z"
				stroke="url(#paint94_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.0614 21.4818C39.3777 20.3155 47.0849 20.5602 52.8214 21.8951C55.6901 22.5627 58.0601 23.5016 59.7667 24.6682C61.4733 25.8349 62.5067 27.222 62.7271 28.7865C62.9474 30.3509 62.3371 31.9686 61.0189 33.5595C59.7007 35.1504 57.6818 36.7048 55.1086 38.1355C49.963 40.9967 42.622 43.3519 34.3057 44.5182C25.9894 45.6845 18.2823 45.4398 12.5457 44.1049C9.67704 43.4373 7.30697 42.4985 5.60038 41.3318C3.89384 40.1652 2.86039 38.778 2.64006 37.2135C2.41973 35.6491 3.02999 34.0314 4.34818 32.4405C5.66641 30.8496 7.68534 29.2952 10.2585 27.8645C15.4041 25.0034 22.7451 22.6482 31.0614 21.4818Z"
				stroke="url(#paint95_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.371 21.4324C39.723 20.5564 47.4169 21.0694 53.1033 22.6033C55.9468 23.3703 58.2826 24.3911 59.9474 25.6165C61.6121 26.8419 62.5964 28.2642 62.7619 29.8354C62.9274 31.4066 62.2609 33.002 60.8879 34.546C59.5148 36.09 57.4427 37.5731 54.8211 38.9134C49.5786 41.5936 42.1597 43.6917 33.8077 44.5677C25.4557 45.4437 17.7617 44.9307 12.0754 43.3968C9.2318 42.6297 6.89601 41.6089 5.23126 40.3835C3.56655 39.1582 2.58225 37.7359 2.41677 36.1647C2.25128 34.5935 2.91774 32.998 4.29077 31.4541C5.66383 29.9101 7.7359 28.4269 10.3575 27.0867C15.6 24.4065 23.019 22.3084 31.371 21.4324Z"
				stroke="url(#paint96_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.6818 21.3969C40.0594 20.8124 47.7307 21.593 53.3599 23.324C56.1749 24.1896 58.4736 25.2912 60.0945 26.5738C61.7153 27.8564 62.6493 29.3121 62.7597 30.8881C62.8701 32.4641 62.1483 34.0353 60.7221 35.5306C59.2959 37.0258 57.1732 38.4359 54.5063 39.684C49.1733 42.18 41.6855 44.0184 33.3079 44.603C24.9304 45.1876 17.2591 44.407 11.6298 42.676C8.81478 41.8104 6.51611 40.7088 4.89523 39.4262C3.27439 38.1436 2.34043 36.6879 2.23 35.1119C2.11956 33.5359 2.84141 31.9646 4.2676 30.4694C5.69383 28.9742 7.8165 27.5641 10.4834 26.316C15.8165 23.82 23.3042 21.9815 31.6818 21.3969Z"
				stroke="url(#paint97_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M31.9942 21.3757C40.3871 21.0832 48.0264 22.1306 53.5917 24.0566C56.3748 25.0197 58.6335 26.2006 60.2085 27.5389C61.7835 28.8772 62.666 30.3645 62.7213 31.9434C62.7765 33.5223 62.0002 35.0675 60.5226 36.5121C59.0449 37.9568 56.8742 39.292 54.1653 40.4466C48.7482 42.7553 41.2007 44.3318 32.8078 44.6243C24.4149 44.9168 16.7755 43.8695 11.2102 41.9434C8.42723 40.9803 6.16848 39.7994 4.59344 38.4611C3.01845 37.1228 2.13597 35.6355 2.08072 34.0566C2.02547 32.4777 2.80183 30.9326 4.27944 29.4879C5.75709 28.0433 7.92778 26.708 10.6367 25.5535C16.0538 23.2447 23.6013 21.6682 31.9942 21.3757Z"
				stroke="url(#paint98_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<path
				d="M32.3067 21.3686C40.7047 21.3686 48.3028 22.6813 53.7973 24.8C56.545 25.8594 58.761 27.1183 60.2883 28.5106C61.8155 29.9029 62.6455 31.4201 62.6455 33C62.6455 34.5798 61.8155 36.097 60.2883 37.4893C58.761 38.8816 56.545 40.1405 53.7973 41.2C48.3028 43.3186 40.7047 44.6313 32.3067 44.6313C23.9087 44.6313 16.3106 43.3186 10.8161 41.2C8.06844 40.1405 5.85237 38.8816 4.3251 37.4893C2.79787 36.097 1.96794 34.5798 1.96794 33C1.96794 31.4201 2.79787 29.9029 4.3251 28.5107C5.85237 27.1183 8.06844 25.8594 10.8161 24.8C16.3106 22.6813 23.9087 21.3686 32.3067 21.3686Z"
				stroke="url(#paint99_linear_2312_85736)"
				strokeWidth="0.218108"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2312_85736"
					x1="70.5824"
					y1="23.6107"
					x2="12.6903"
					y2="42.4604"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2312_85736"
					x1="70.7995"
					y1="24.625"
					x2="12.2815"
					y2="41.4399"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2312_85736"
					x1="70.98"
					y1="25.6493"
					x2="11.9077"
					y2="40.4085"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_2312_85736"
					x1="71.1248"
					y1="26.6828"
					x2="11.5707"
					y2="39.3679"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_2312_85736"
					x1="71.2339"
					y1="27.7239"
					x2="11.271"
					y2="38.3191"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_2312_85736"
					x1="71.3058"
					y1="28.7713"
					x2="11.0077"
					y2="37.2634"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_2312_85736"
					x1="71.3411"
					y1="29.824"
					x2="10.7818"
					y2="36.2023"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_2312_85736"
					x1="71.3402"
					y1="30.8806"
					x2="10.5942"
					y2="35.1372"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_2312_85736"
					x1="71.3022"
					y1="31.9396"
					x2="10.444"
					y2="34.0693"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_2312_85736"
					x1="71.2251"
					y1="33"
					x2="10.3295"
					y2="33"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint10_linear_2312_85736"
					x1="71.1133"
					y1="34.0604"
					x2="10.2551"
					y2="31.9307"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint11_linear_2312_85736"
					x1="70.9635"
					y1="35.1195"
					x2="10.2174"
					y2="30.8628"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint12_linear_2312_85736"
					x1="70.7766"
					y1="36.1759"
					x2="10.2173"
					y2="29.7976"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint13_linear_2312_85736"
					x1="70.5526"
					y1="37.2287"
					x2="10.2545"
					y2="28.7366"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint14_linear_2312_85736"
					x1="70.292"
					y1="38.2762"
					x2="10.3291"
					y2="27.681"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint15_linear_2312_85736"
					x1="69.9953"
					y1="39.3173"
					x2="10.4412"
					y2="26.6322"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint16_linear_2312_85736"
					x1="69.6621"
					y1="40.3506"
					x2="10.5898"
					y2="25.5914"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint17_linear_2312_85736"
					x1="69.2928"
					y1="41.375"
					x2="10.7748"
					y2="24.5601"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint18_linear_2312_85736"
					x1="68.8883"
					y1="42.3892"
					x2="10.9963"
					y2="23.5396"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint19_linear_2312_85736"
					x1="68.4473"
					y1="43.392"
					x2="11.2522"
					y2="22.5312"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint20_linear_2312_85736"
					x1="67.9736"
					y1="44.3821"
					x2="11.5453"
					y2="21.5359"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint21_linear_2312_85736"
					x1="67.4638"
					y1="45.3584"
					x2="11.8714"
					y2="20.5553"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint22_linear_2312_85736"
					x1="66.9212"
					y1="46.3196"
					x2="12.2326"
					y2="19.5903"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint23_linear_2312_85736"
					x1="66.3448"
					y1="47.2645"
					x2="12.6268"
					y2="18.6423"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint24_linear_2312_85736"
					x1="65.7332"
					y1="48.1921"
					x2="13.0512"
					y2="17.7126"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint25_linear_2312_85736"
					x1="65.0936"
					y1="49.1011"
					x2="13.5119"
					y2="16.8019"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint26_linear_2312_85736"
					x1="64.4201"
					y1="49.9906"
					x2="14.0014"
					y2="15.9116"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint27_linear_2312_85736"
					x1="63.7161"
					y1="50.8594"
					x2="14.5217"
					y2="15.0428"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_2312_85736"
					x1="62.9828"
					y1="51.7064"
					x2="15.0725"
					y2="14.1964"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint29_linear_2312_85736"
					x1="62.2193"
					y1="52.5306"
					x2="15.6512"
					y2="13.3735"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint30_linear_2312_85736"
					x1="61.428"
					y1="53.331"
					x2="16.2584"
					y2="12.5751"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint31_linear_2312_85736"
					x1="60.6101"
					y1="54.1067"
					x2="16.8938"
					y2="11.802"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint32_linear_2312_85736"
					x1="59.7635"
					y1="54.8566"
					x2="17.5534"
					y2="11.0553"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint33_linear_2312_85736"
					x1="58.8936"
					y1="55.5799"
					x2="18.2405"
					y2="10.3356"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint34_linear_2312_85736"
					x1="57.9958"
					y1="56.2757"
					x2="18.9488"
					y2="9.64397"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint35_linear_2312_85736"
					x1="57.0757"
					y1="56.9431"
					x2="19.6819"
					y2="8.98107"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint36_linear_2312_85736"
					x1="56.1328"
					y1="57.5814"
					x2="20.4371"
					y2="8.34767"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint37_linear_2312_85736"
					x1="55.1692"
					y1="58.1897"
					x2="21.2146"
					y2="7.74449"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint38_linear_2312_85736"
					x1="54.1834"
					y1="58.7673"
					x2="22.0105"
					y2="7.17222"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint39_linear_2312_85736"
					x1="53.1784"
					y1="59.3135"
					x2="22.8259"
					y2="6.63152"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint40_linear_2312_85736"
					x1="52.1531"
					y1="59.8277"
					x2="23.6574"
					y2="6.12292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint41_linear_2312_85736"
					x1="51.1132"
					y1="60.3091"
					x2="24.5083"
					y2="5.64696"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint42_linear_2312_85736"
					x1="50.0563"
					y1="60.7573"
					x2="25.3741"
					y2="5.20423"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint43_linear_2312_85736"
					x1="48.9828"
					y1="61.1717"
					x2="26.2527"
					y2="4.79516"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint44_linear_2312_85736"
					x1="47.8981"
					y1="61.5519"
					x2="27.1473"
					y2="4.42029"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint45_linear_2312_85736"
					x1="46.7976"
					y1="61.8971"
					x2="28.0509"
					y2="4.07983"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint46_linear_2312_85736"
					x1="45.6878"
					y1="62.2072"
					x2="28.9674"
					y2="3.77426"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint47_linear_2312_85736"
					x1="44.5669"
					y1="62.4817"
					x2="29.8928"
					y2="3.50385"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint48_linear_2312_85736"
					x1="43.4364"
					y1="62.7203"
					x2="30.8261"
					y2="3.26899"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint49_linear_2312_85736"
					x1="42.3"
					y1="62.9226"
					x2="31.7683"
					y2="3.06983"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint50_linear_2312_85736"
					x1="41.1561"
					y1="63.0885"
					x2="32.7158"
					y2="2.90658"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint51_linear_2312_85736"
					x1="40.0075"
					y1="63.2177"
					x2="33.6684"
					y2="2.7794"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint52_linear_2312_85736"
					x1="38.855"
					y1="63.3102"
					x2="34.6247"
					y2="2.68849"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint53_linear_2312_85736"
					x1="37.7002"
					y1="63.3657"
					x2="35.5838"
					y2="2.63395"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint54_linear_2312_85736"
					x1="36.5421"
					y1="63.3842"
					x2="36.5421"
					y2="2.61576"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint55_linear_2312_85736"
					x1="35.3848"
					y1="63.3657"
					x2="37.5011"
					y2="2.63394"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint56_linear_2312_85736"
					x1="34.23"
					y1="63.3103"
					x2="38.4602"
					y2="2.68858"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint57_linear_2312_85736"
					x1="33.0778"
					y1="63.2178"
					x2="39.4168"
					y2="2.77945"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint58_linear_2312_85736"
					x1="31.9281"
					y1="63.0886"
					x2="40.3685"
					y2="2.90659"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint59_linear_2312_85736"
					x1="30.7848"
					y1="62.9226"
					x2="41.3165"
					y2="3.06982"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint60_linear_2312_85736"
					x1="29.6479"
					y1="62.7203"
					x2="42.2582"
					y2="3.26904"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint61_linear_2312_85736"
					x1="28.5176"
					y1="62.4817"
					x2="43.1917"
					y2="3.50386"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint62_linear_2312_85736"
					x1="27.3968"
					y1="62.2072"
					x2="44.1172"
					y2="3.77426"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint63_linear_2312_85736"
					x1="26.2869"
					y1="61.8972"
					x2="45.0337"
					y2="4.07989"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint64_linear_2312_85736"
					x1="25.1867"
					y1="61.5519"
					x2="45.9375"
					y2="4.42028"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint65_linear_2312_85736"
					x1="24.1015"
					y1="61.1718"
					x2="46.8315"
					y2="4.79525"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint66_linear_2312_85736"
					x1="23.029"
					y1="60.7574"
					x2="47.7112"
					y2="5.20431"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint67_linear_2312_85736"
					x1="21.9716"
					y1="60.3092"
					x2="48.5765"
					y2="5.64701"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint68_linear_2312_85736"
					x1="20.931"
					y1="59.8277"
					x2="49.4267"
					y2="6.12292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint69_linear_2312_85736"
					x1="19.9055"
					y1="59.3135"
					x2="50.2579"
					y2="6.63149"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint70_linear_2312_85736"
					x1="18.9026"
					y1="58.7673"
					x2="51.0754"
					y2="7.17226"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint71_linear_2312_85736"
					x1="17.9168"
					y1="58.1897"
					x2="51.8714"
					y2="7.74454"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint72_linear_2312_85736"
					x1="16.9516"
					y1="57.5814"
					x2="52.6473"
					y2="8.34768"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint73_linear_2312_85736"
					x1="16.0079"
					y1="56.9431"
					x2="53.4017"
					y2="8.98107"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint74_linear_2312_85736"
					x1="15.0881"
					y1="56.2757"
					x2="54.135"
					y2="9.64398"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint75_linear_2312_85736"
					x1="14.191"
					y1="55.5799"
					x2="54.8441"
					y2="10.3356"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint76_linear_2312_85736"
					x1="13.3202"
					y1="54.8566"
					x2="55.5303"
					y2="11.0552"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint77_linear_2312_85736"
					x1="12.4753"
					y1="54.1067"
					x2="56.1916"
					y2="11.802"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint78_linear_2312_85736"
					x1="11.655"
					y1="53.331"
					x2="56.8246"
					y2="12.5751"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint79_linear_2312_85736"
					x1="10.8653"
					y1="52.5306"
					x2="57.4335"
					y2="13.3735"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint80_linear_2312_85736"
					x1="10.101"
					y1="51.7064"
					x2="58.0113"
					y2="14.1964"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint81_linear_2312_85736"
					x1="9.36889"
					y1="50.8595"
					x2="58.5633"
					y2="15.0428"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint82_linear_2312_85736"
					x1="8.66524"
					y1="49.9906"
					x2="59.084"
					y2="15.9116"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint83_linear_2312_85736"
					x1="7.99109"
					y1="49.1012"
					x2="59.5728"
					y2="16.8019"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint84_linear_2312_85736"
					x1="7.35041"
					y1="48.1921"
					x2="60.0324"
					y2="17.7125"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint85_linear_2312_85736"
					x1="6.74083"
					y1="47.2645"
					x2="60.4589"
					y2="18.6424"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint86_linear_2312_85736"
					x1="6.16434"
					y1="46.3195"
					x2="60.853"
					y2="19.5903"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint87_linear_2312_85736"
					x1="5.62051"
					y1="45.3583"
					x2="61.2129"
					y2="20.5552"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint88_linear_2312_85736"
					x1="5.1118"
					y1="44.3821"
					x2="61.5401"
					y2="21.5359"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint89_linear_2312_85736"
					x1="4.63727"
					y1="43.392"
					x2="61.8324"
					y2="22.5311"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint90_linear_2312_85736"
					x1="4.19742"
					y1="42.3892"
					x2="62.0895"
					y2="23.5396"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint91_linear_2312_85736"
					x1="3.79277"
					y1="41.375"
					x2="62.3108"
					y2="24.5601"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint92_linear_2312_85736"
					x1="3.42234"
					y1="40.3506"
					x2="62.4946"
					y2="25.5915"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint93_linear_2312_85736"
					x1="3.08908"
					y1="39.3172"
					x2="62.6432"
					y2="26.6321"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint94_linear_2312_85736"
					x1="2.7915"
					y1="38.2762"
					x2="62.7544"
					y2="27.681"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint95_linear_2312_85736"
					x1="2.53206"
					y1="37.2287"
					x2="62.8302"
					y2="28.7366"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint96_linear_2312_85736"
					x1="2.30831"
					y1="36.1761"
					x2="62.8676"
					y2="29.7977"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint97_linear_2312_85736"
					x1="2.12121"
					y1="35.1195"
					x2="62.8673"
					y2="30.8628"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint98_linear_2312_85736"
					x1="1.97173"
					y1="34.0604"
					x2="62.8299"
					y2="31.9307"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint99_linear_2312_85736"
					x1="1.85889"
					y1="33"
					x2="62.7545"
					y2="33"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
			</defs>
		</Icon>
	);
};

export default SuperSynthyIcon;
