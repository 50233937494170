import { Icon, IconProps } from '@chakra-ui/react';

export const StakingLooper = ({
	width = '387px',
	height = '264px',
	...props
}: IconProps) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 387 264"
			fill="none"
			{...props}
		>
			<path
				d="M39.4443 194.735C18.8333 131.301 10.1125 70.6545 12.667 23.887C13.9443 0.502434 18.0399 -19.3986 24.8674 -34.4403C31.6947 -49.4819 41.2413 -59.6398 53.4113 -63.5941C65.5813 -67.5484 79.2754 -64.9418 93.64 -56.7859C108.005 -48.63 123.016 -34.937 137.794 -16.7693C167.35 19.5649 195.942 73.7547 216.553 137.189C237.164 200.623 245.885 261.269 243.33 308.037C242.053 331.421 237.957 351.322 231.13 366.364C224.302 381.406 214.756 391.564 202.586 395.518C190.416 399.472 176.722 396.866 162.357 388.71C147.992 380.554 132.981 366.861 118.203 348.693C88.647 312.359 60.0553 258.169 39.4443 194.735Z"
				stroke="url(#paint0_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M38.4952 190.879C20.1106 126.764 13.5116 65.8502 17.6967 19.2004C19.7893 -4.12535 24.577 -23.8714 31.9252 -38.6656C39.2733 -53.4598 49.1686 -63.2783 61.4692 -66.8054C73.7698 -70.3326 87.3645 -67.2497 101.436 -58.5975C115.507 -49.9452 130.031 -35.7367 144.167 -17.0643C172.436 20.2793 199.119 75.4339 217.504 139.549C235.889 203.663 242.488 264.577 238.303 311.227C236.21 334.553 231.422 354.299 224.074 369.093C216.726 383.887 206.831 393.706 194.53 397.233C182.229 400.76 168.635 397.677 154.564 389.025C140.492 380.373 125.968 366.164 111.833 347.492C83.5629 310.148 56.8798 254.993 38.4952 190.879Z"
				stroke="url(#paint1_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M37.6542 186.994C21.5183 122.276 17.0492 61.1696 22.8598 14.6943C25.7652 -8.54424 31.2391 -28.1111 39.0992 -42.64C46.9591 -57.1686 57.191 -66.6358 69.6073 -69.7315C82.0235 -72.8273 95.5023 -69.2718 109.263 -60.1338C123.024 -50.9956 137.043 -36.289 150.518 -17.1346C177.468 21.1728 202.21 77.2251 218.345 141.942C234.481 206.66 238.95 267.767 233.14 314.242C230.234 337.481 224.76 357.047 216.9 371.576C209.04 386.105 198.809 395.572 186.392 398.668C173.976 401.764 160.497 398.208 146.737 389.07C132.976 379.932 118.956 365.225 105.481 346.071C78.5319 307.763 53.79 251.711 37.6542 186.994Z"
				stroke="url(#paint2_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M36.9232 183.08C23.0557 117.839 20.7219 56.6132 28.151 10.369C31.8656 -12.754 38.0191 -32.1179 46.3814 -46.3636C54.7436 -60.6091 65.2997 -69.7135 77.8164 -72.374C90.333 -75.0345 103.68 -71.0108 117.113 -61.3981C130.547 -51.7853 144.044 -36.5983 156.843 -16.9853C182.439 22.2393 205.209 79.1209 219.077 144.362C232.944 209.603 235.278 270.829 227.849 317.073C224.134 340.196 217.981 359.56 209.618 373.805C201.256 388.051 190.7 397.155 178.183 399.816C165.667 402.476 152.32 398.453 138.887 388.84C125.453 379.227 111.956 364.04 99.1572 344.427C73.5612 305.203 50.7906 248.321 36.9232 183.08Z"
				stroke="url(#paint3_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M36.3037 179.142C24.7216 113.457 24.5259 52.187 33.5644 6.23015C38.0837 -16.7491 44.9092 -35.8865 53.7636 -49.8316C62.6179 -63.7766 73.4853 -72.507 86.0872 -74.7291C98.6891 -76.9512 111.887 -72.4641 124.977 -62.3885C138.067 -52.3127 151.026 -36.6639 163.132 -16.6162C187.344 23.4778 208.115 81.1194 219.697 146.805C231.28 212.49 231.475 273.76 222.437 319.717C217.917 342.696 211.092 361.833 202.237 375.778C193.383 389.723 182.516 398.454 169.914 400.676C157.312 402.898 144.114 398.411 131.024 388.335C117.934 378.259 104.975 362.611 92.8689 342.563C68.6574 302.469 47.8858 244.827 36.3037 179.142Z"
				stroke="url(#paint4_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M35.795 175.185C26.5124 109.136 28.4551 47.8963 39.0919 2.28291C44.4105 -20.5246 51.8997 -39.4121 61.2354 -53.0398C70.5709 -66.6673 81.7364 -75.0131 94.4082 -76.794C107.08 -78.5749 120.113 -73.63 132.843 -63.1036C145.574 -52.5772 157.979 -36.4856 169.378 -16.0276C192.176 24.8869 210.923 83.2183 220.206 149.268C229.488 215.317 227.545 276.557 216.909 322.17C211.59 344.977 204.101 363.865 194.765 377.493C185.43 391.12 174.264 399.466 161.592 401.247C148.921 403.028 135.887 398.083 123.157 387.556C110.427 377.03 98.0216 360.938 86.6225 340.48C63.825 299.566 45.0777 241.234 35.795 175.185Z"
				stroke="url(#paint5_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M35.398 171.213C28.4261 104.879 32.5049 43.7452 44.7271 -1.46916C50.8384 -24.0772 58.9822 -42.6918 68.7878 -55.9854C78.5933 -69.2787 90.0432 -77.2298 102.769 -78.5674C115.496 -79.905 128.348 -74.5082 140.703 -63.544C153.059 -52.5797 164.895 -36.065 175.573 -15.2216C196.929 26.4636 213.629 85.4138 220.601 151.747C227.573 218.08 223.494 279.214 211.272 324.429C205.16 347.037 197.017 365.651 187.211 378.945C177.405 392.238 165.956 400.189 153.229 401.527C140.503 402.864 127.65 397.468 115.295 386.504C102.94 375.539 91.104 359.025 80.4258 338.181C59.0701 296.496 42.3699 237.546 35.398 171.213Z"
				stroke="url(#paint6_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M35.1151 167.228C30.4625 100.692 36.6723 39.7372 50.465 -5.0231C57.3616 -27.4041 66.1501 -45.7232 76.4137 -58.6664C86.6771 -71.6095 98.3975 -79.1561 111.163 -80.0487C123.928 -80.9413 136.584 -75.0993 148.549 -63.7106C160.514 -52.3217 171.767 -35.4041 181.711 -14.2007C201.599 28.2044 216.232 87.7015 220.884 154.238C225.537 220.774 219.327 281.728 205.534 326.489C198.638 348.87 189.849 367.189 179.586 380.132C169.322 393.075 157.602 400.622 144.837 401.514C132.072 402.407 119.415 396.565 107.45 385.176C95.4851 373.787 84.2327 356.87 74.2883 335.666C54.4004 293.261 39.7678 233.764 35.1151 167.228Z"
				stroke="url(#paint7_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M34.9466 163.236C32.6189 96.5779 40.9522 35.8772 56.2986 -8.3745C63.9721 -30.5012 73.3946 -48.5024 84.1036 -61.0795C94.8125 -73.6565 106.789 -80.7895 119.578 -81.2361C132.366 -81.6827 144.811 -75.4025 156.371 -63.6031C167.932 -51.8037 178.587 -34.5036 187.785 -12.9661C206.181 30.1072 218.728 90.0788 221.056 156.737C223.384 223.395 215.05 284.095 199.704 328.347C192.031 350.474 182.608 368.475 171.899 381.052C161.19 393.629 149.214 400.762 136.425 401.209C123.636 401.655 111.191 395.375 99.6313 383.576C88.071 371.776 77.4158 354.476 68.2175 332.939C49.8217 289.865 37.2744 229.894 34.9466 163.236Z"
				stroke="url(#paint8_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M34.8865 159.24C34.8865 92.5414 45.3332 32.1685 62.2146 -11.5206C70.6557 -33.366 80.7006 -51.0274 91.842 -63.2231C102.983 -75.4187 115.202 -82.1294 127.998 -82.1294C140.794 -82.1294 153.013 -75.4187 164.154 -63.2231C175.295 -51.0274 185.34 -33.366 193.781 -11.5206C210.663 32.1685 221.109 92.5414 221.109 159.24C221.109 225.939 210.663 286.311 193.781 330.001C185.34 351.846 175.295 369.507 164.154 381.703C153.013 393.899 140.794 400.609 127.998 400.609C115.202 400.609 102.983 393.899 91.842 381.703C80.7006 369.507 70.6557 351.846 62.2146 330.001C45.3332 286.311 34.8865 225.939 34.8865 159.24Z"
				stroke="url(#paint9_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M34.9462 155.243C37.2739 88.5847 49.8212 28.6132 68.2171 -14.4602C77.4154 -35.9977 88.0706 -53.2977 99.6308 -65.0972C111.191 -76.8966 123.636 -83.1767 136.425 -82.7302C149.213 -82.2836 161.19 -75.1506 171.899 -62.5736C182.608 -49.9964 192.03 -31.9952 199.704 -9.86858C215.05 34.3831 223.383 95.0838 221.056 161.742C218.728 228.4 206.181 288.371 187.785 331.445C178.586 352.982 167.931 370.282 156.371 382.082C144.811 393.881 132.366 400.161 119.577 399.715C106.789 399.268 94.812 392.135 84.1031 379.558C73.3941 366.981 63.9717 348.98 56.2982 326.853C40.9518 282.601 32.6184 221.901 34.9462 155.243Z"
				stroke="url(#paint10_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M35.1152 151.251C39.7678 84.7144 54.4005 25.2173 74.2884 -17.1878C84.2327 -38.3911 95.4852 -55.3088 107.45 -66.6976C119.415 -78.0864 132.072 -83.9284 144.837 -83.0357C157.602 -82.1431 169.322 -74.5965 179.586 -61.6534C189.849 -48.7102 198.638 -30.3911 205.535 -8.01014C219.327 36.7501 225.537 97.7047 220.884 164.241C216.232 230.777 201.599 290.274 181.711 332.679C171.767 353.882 160.514 370.8 148.549 382.189C136.585 393.578 123.928 399.42 111.163 398.527C98.3976 397.634 86.6771 390.088 76.4137 377.145C66.1502 364.202 57.3617 345.882 50.4651 323.502C36.6724 278.741 30.4625 217.787 35.1152 151.251Z"
				stroke="url(#paint11_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M35.3965 147.265C42.3684 80.932 59.0686 21.9819 80.4243 -19.7033C91.1026 -40.5467 102.939 -57.0614 115.294 -68.0257C127.649 -78.9899 140.502 -84.3867 153.228 -83.0491C165.954 -81.7115 177.404 -73.7605 187.209 -60.4671C197.015 -47.1736 205.159 -28.5589 211.27 -5.95089C223.492 39.2635 227.571 100.398 220.599 166.731C213.627 233.064 196.927 292.014 175.572 333.699C164.893 354.543 153.057 371.057 140.702 382.022C128.347 392.986 115.494 398.383 102.768 397.045C90.0417 395.708 78.5918 387.757 68.7863 374.463C58.9807 361.17 50.8369 342.555 44.7256 319.947C32.5034 274.733 28.4246 213.598 35.3965 147.265Z"
				stroke="url(#paint12_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M35.795 143.294C45.0776 77.2442 63.8249 18.9128 86.6224 -22.0017C98.0216 -42.4597 110.427 -58.5513 123.157 -69.0778C135.887 -79.6041 148.921 -84.549 161.592 -82.7681C174.264 -80.9872 185.43 -72.6414 194.765 -59.0139C204.101 -45.3863 211.59 -26.4987 216.909 -3.6912C227.545 41.9222 229.488 103.161 220.206 169.211C210.923 235.26 192.176 293.592 169.378 334.506C157.979 354.964 145.574 371.056 132.843 381.582C120.113 392.109 107.08 397.054 94.4081 395.273C81.7363 393.492 70.5709 385.146 61.2353 371.518C51.8996 357.891 44.4104 339.003 39.0918 316.196C28.4551 270.582 26.5123 209.343 35.795 143.294Z"
				stroke="url(#paint13_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M36.3032 139.336C47.8853 73.6504 68.6569 16.0088 92.8684 -24.0852C104.975 -44.1329 117.934 -59.7817 131.024 -69.8575C144.114 -79.9331 157.311 -84.4202 169.913 -82.1981C182.515 -79.9761 193.383 -71.2457 202.237 -57.3006C211.091 -43.3555 217.917 -24.2181 222.436 -1.23886C231.475 44.7179 231.279 105.988 219.697 171.673C208.115 237.358 187.343 295 163.132 335.094C151.025 355.142 138.066 370.79 124.976 380.866C111.887 390.942 98.6886 395.429 86.0867 393.207C73.4848 390.985 62.6174 382.254 53.7631 368.309C44.9087 354.364 38.0832 335.227 33.5638 312.248C24.5254 266.291 24.7211 205.021 36.3032 139.336Z"
				stroke="url(#paint14_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M36.9234 135.399C50.7908 70.1584 73.5615 13.2768 99.1575 -25.9478C111.956 -45.5608 125.453 -60.7478 138.887 -70.3606C152.32 -79.9733 165.667 -83.997 178.184 -81.3365C190.7 -78.676 201.256 -69.5716 209.619 -55.3261C217.981 -41.0804 224.134 -21.7165 227.849 1.40648C235.278 47.6507 232.944 108.876 219.077 174.117C205.209 239.358 182.439 296.24 156.843 335.465C144.044 355.078 130.547 370.265 117.113 379.877C103.68 389.49 90.3333 393.514 77.8166 390.853C65.3 388.193 54.7439 379.088 46.3817 364.843C38.0193 350.597 31.8659 331.233 28.1513 308.11C20.7222 261.866 23.056 200.641 36.9234 135.399Z"
				stroke="url(#paint15_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M37.654 131.485C53.7898 66.7676 78.5317 10.7153 105.481 -27.5921C118.956 -46.7465 132.975 -61.4532 146.736 -70.5913C160.497 -79.7293 173.976 -83.2848 186.392 -80.1891C198.808 -77.0933 209.04 -67.6261 216.9 -53.0975C224.76 -38.5686 230.234 -19.0018 233.14 4.23678C238.95 50.7121 234.481 111.819 218.345 176.536C202.209 241.254 177.467 297.306 150.518 335.613C137.043 354.768 123.024 369.474 109.263 378.613C95.5021 387.751 82.0233 391.306 69.607 388.21C57.1908 385.115 46.9589 375.647 39.099 361.119C31.2389 346.59 25.765 327.023 22.8596 303.784C17.049 257.309 21.5181 196.202 37.654 131.485Z"
				stroke="url(#paint16_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M38.4966 127.598C56.8812 63.4837 83.5642 8.32905 111.834 -29.0145C125.969 -47.6869 140.493 -61.8954 154.565 -70.5477C168.636 -79.1999 182.231 -82.2828 194.531 -78.7557C206.832 -75.2285 216.727 -65.41 224.075 -50.6159C231.424 -35.8216 236.211 -16.0756 238.304 7.2502C242.489 53.9 235.89 114.814 217.505 178.928C199.121 243.043 172.438 298.198 144.168 335.541C130.033 354.214 115.509 368.422 101.437 377.075C87.3659 385.727 73.7712 388.81 61.4706 385.283C49.17 381.755 39.2747 371.937 31.9266 357.143C24.5784 342.349 19.7907 322.603 17.6981 299.277C13.513 252.627 20.112 191.713 38.4966 127.598Z"
				stroke="url(#paint17_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M39.4452 123.743C60.0562 60.3091 88.6479 6.11923 118.204 -30.2149C132.982 -48.3827 147.993 -62.0756 162.358 -70.2316C176.723 -78.3875 190.417 -80.994 202.587 -77.0397C214.757 -73.0855 224.303 -62.9276 231.131 -47.886C237.958 -32.8443 242.054 -12.9432 243.331 10.4414C245.886 57.2088 237.165 117.855 216.554 181.289C195.943 244.723 167.351 298.913 137.795 335.247C123.017 353.415 108.006 367.108 93.6409 375.264C79.2762 383.42 65.5822 386.026 53.4122 382.072C41.2422 378.118 31.6956 367.96 24.8682 352.918C18.0408 337.877 13.9452 317.976 12.6679 294.591C10.1134 247.824 18.8342 187.177 39.4452 123.743Z"
				stroke="url(#paint18_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M40.5033 119.924C63.3156 57.2474 93.781 4.08845 124.587 -31.1921C139.99 -48.833 155.47 -61.9938 170.111 -69.6434C184.751 -77.293 198.528 -79.42 210.553 -75.0434C222.577 -70.6669 231.764 -60.182 238.062 -44.9113C244.36 -29.6405 247.759 -9.6086 248.219 13.8063C249.14 60.6345 238.308 120.939 215.496 183.616C192.683 246.292 162.218 299.451 131.412 334.731C116.008 352.372 100.529 365.533 85.888 373.183C71.2475 380.832 57.4708 382.959 45.4463 378.583C33.4217 374.206 24.2354 363.721 17.9371 348.45C11.6388 333.18 8.2402 313.148 7.77979 289.733C6.859 242.905 17.6911 182.6 40.5033 119.924Z"
				stroke="url(#paint19_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M41.6684 116.143C66.6541 54.3011 98.9562 2.23769 130.975 -31.9463C146.984 -49.0389 162.914 -61.6513 177.813 -68.7854C192.711 -75.9194 206.554 -77.5643 218.418 -72.7707C230.283 -67.9772 239.098 -57.1781 244.859 -41.6969C250.621 -26.2156 253.318 -6.07728 252.961 17.3395C252.247 64.1712 239.317 124.061 214.331 185.903C189.345 247.745 157.043 299.808 125.025 333.992C109.015 351.085 93.0856 363.697 78.1868 370.832C63.2882 377.965 49.4457 379.61 37.5812 374.817C25.7167 370.023 16.9019 359.224 11.1404 343.743C5.37891 328.262 2.68147 308.123 3.03851 284.707C3.75256 237.875 16.6826 177.985 41.6684 116.143Z"
				stroke="url(#paint20_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M42.9377 112.404C70.0664 51.4717 104.166 0.567352 137.358 -32.4783C153.954 -49.0018 170.314 -61.0507 185.453 -67.6604C200.592 -74.2701 214.483 -75.4309 226.173 -70.2262C237.863 -65.0215 246.296 -53.9214 251.513 -38.2485C256.731 -22.5755 258.724 -2.35539 257.55 21.0346C255.202 67.8129 240.189 127.215 213.061 188.148C185.932 249.08 151.833 299.984 118.641 333.03C102.044 349.553 85.684 361.602 70.5454 368.212C55.4069 374.821 41.5155 375.982 29.8254 370.778C18.1354 365.573 9.70292 354.473 4.48525 338.8C-0.73249 323.127 -2.72547 302.907 -1.55142 279.517C0.7966 232.738 15.8089 173.336 42.9377 112.404Z"
				stroke="url(#paint21_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M44.3124 108.713C73.5511 48.7644 109.406 -0.918898 143.731 -32.7861C160.894 -48.7203 177.665 -60.1908 193.025 -66.2682C208.385 -72.3455 222.309 -73.0209 233.81 -67.4113C245.311 -61.8018 253.351 -50.4142 258.019 -34.5688C262.686 -18.7233 263.972 1.55413 261.983 24.8889C258.003 71.5568 240.927 130.399 211.688 190.347C182.45 250.296 146.595 299.979 112.27 331.846C95.1064 347.78 78.3358 359.251 62.9757 365.328C47.6158 371.406 33.6923 372.081 22.191 366.471C10.6898 360.862 2.6498 349.474 -2.01773 333.629C-6.68531 317.783 -7.9714 297.506 -5.98176 274.171C-2.00264 227.503 15.0737 168.661 44.3124 108.713Z"
				stroke="url(#paint22_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M45.7877 105.069C77.1008 46.1779 114.668 -2.22381 150.084 -32.8737C167.793 -48.1992 184.954 -59.0774 200.517 -64.6151C216.079 -70.1526 230.018 -70.3416 241.316 -64.3341C252.615 -58.3266 260.252 -46.6653 264.364 -30.6667C268.476 -14.6679 269.053 5.64199 266.251 28.8931C260.645 75.3937 241.526 133.604 210.213 192.496C178.9 251.387 141.332 299.789 105.916 330.438C88.2074 345.764 71.0467 356.642 55.4838 362.18C39.9212 367.717 25.9826 367.906 14.6841 361.899C3.38564 355.891 -4.25203 344.23 -8.36371 328.231C-12.4754 312.233 -13.0531 291.923 -10.2503 268.672C-4.6449 222.171 14.4746 163.961 45.7877 105.069Z"
				stroke="url(#paint23_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M47.3624 101.48C80.7117 43.7172 119.945 -3.34388 156.41 -32.7391C174.642 -47.4372 192.172 -57.7099 207.919 -62.701C223.665 -67.6921 237.602 -67.3946 248.684 -60.9964C259.766 -54.5983 266.992 -42.6775 270.543 -26.5451C274.094 -10.4126 273.962 9.9051 270.35 33.0443C263.125 79.3208 241.985 136.829 208.636 194.591C175.287 252.354 136.053 299.415 99.589 328.81C81.3562 343.508 63.8263 353.781 48.0797 358.772C32.3332 363.763 18.3965 363.466 7.3146 357.068C-3.76733 350.669 -10.9934 338.749 -14.5442 322.616C-18.0951 306.484 -17.9636 286.166 -14.351 263.027C-7.12621 216.75 14.0131 159.243 47.3624 101.48Z"
				stroke="url(#paint24_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M49.0356 97.9466C84.3804 41.383 125.232 -4.28024 162.7 -32.3849C181.435 -46.4378 199.313 -56.0925 215.224 -60.531C231.135 -64.9695 245.053 -64.1857 255.905 -57.4047C266.757 -50.6237 273.562 -38.458 276.548 -22.2116C279.534 -5.96496 278.693 14.3358 274.275 37.3348C265.44 83.331 242.306 140.066 206.962 196.63C171.617 253.193 130.765 298.856 93.2966 326.961C74.5619 341.014 56.6842 350.669 40.773 355.107C24.862 359.546 10.9441 358.762 0.0922588 351.981C-10.7596 345.2 -17.5652 333.034 -20.5509 316.788C-23.5366 300.541 -22.6961 280.24 -18.2782 257.241C-9.44275 211.245 13.6907 154.51 49.0356 97.9466Z"
				stroke="url(#paint25_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M50.804 94.4757C88.1014 39.18 130.522 -5.02966 168.948 -31.8096C188.162 -45.2001 206.366 -54.225 222.422 -58.1055C238.478 -61.986 252.36 -60.717 262.969 -53.5614C273.578 -46.4058 279.954 -34.01 282.371 -17.6692C284.788 -1.32831 283.24 18.9307 278.022 41.7615C267.587 87.4214 242.487 143.315 205.19 198.61C167.892 253.906 125.472 298.115 87.0456 324.895C67.8319 338.286 49.6281 347.311 33.5717 351.191C17.5154 355.072 3.63344 353.803 -6.97519 346.647C-17.5838 339.492 -23.9607 327.096 -26.3775 310.755C-28.7944 294.414 -27.2459 274.155 -22.0281 251.324C-11.5928 205.664 13.5066 149.771 50.804 94.4757Z"
				stroke="url(#paint26_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M52.6704 91.0653C91.8748 37.105 135.813 -5.59725 175.15 -31.0198C194.819 -43.7316 213.327 -52.1157 229.509 -55.4335C245.691 -58.7513 259.52 -56.9986 269.872 -49.4771C280.225 -41.9556 286.165 -29.3448 288.01 -12.9297C289.855 3.48564 287.601 23.6783 281.589 46.3131C269.567 91.581 242.532 146.564 203.328 200.524C164.123 254.485 120.186 297.187 80.8483 322.61C61.179 335.321 42.6714 343.705 26.4893 347.023C10.3074 350.341 -3.52184 348.588 -13.8743 341.067C-24.2267 333.545 -30.1671 320.935 -32.0122 304.519C-33.8573 288.104 -31.6027 267.911 -25.5913 245.277C-13.5688 200.009 13.4659 145.026 52.6704 91.0653Z"
				stroke="url(#paint27_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M54.6276 87.722C95.6913 35.1628 141.093 -5.9801 181.293 -30.0144C201.394 -42.0319 220.183 -49.765 236.471 -52.516C252.759 -55.2671 266.519 -53.0328 276.602 -45.1546C286.686 -37.2764 292.183 -24.466 293.454 -7.99644C294.725 8.47328 291.767 28.5749 284.969 50.9861C271.374 95.8069 242.437 149.813 201.373 202.372C160.309 254.931 114.908 296.074 74.7076 320.109C54.6066 332.126 35.8177 339.859 19.5297 342.61C3.24183 345.361 -10.5178 343.127 -20.6014 335.249C-30.6851 327.371 -36.1817 314.56 -37.4528 298.091C-38.7239 281.621 -35.766 261.519 -28.9683 239.108C-15.3733 194.287 13.5638 140.281 54.6276 87.722Z"
				stroke="url(#paint28_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M56.6724 84.4507C99.5455 33.3566 146.355 -6.17673 187.37 -28.7934C207.878 -40.1021 226.925 -47.1748 243.299 -49.3557C259.673 -51.5366 273.347 -48.8234 283.149 -40.5981C292.952 -32.3728 297.998 -19.3784 298.693 -2.87452C299.389 13.6295 295.731 33.6157 288.156 55.776C273.005 100.095 242.2 153.058 199.327 204.152C156.454 255.246 109.645 294.78 68.6301 317.396C48.1219 328.705 29.0745 335.778 12.7004 337.959C-3.67349 340.14 -17.3468 337.427 -27.1493 329.201C-36.9519 320.976 -41.9981 307.981 -42.6936 291.478C-43.3892 274.974 -39.7315 254.987 -32.1558 232.827C-17.0049 188.508 13.7994 135.545 56.6724 84.4507Z"
				stroke="url(#paint29_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M58.8037 81.2511C103.434 31.6844 151.594 -6.19121 193.374 -27.3627C214.264 -37.9488 233.547 -44.3524 249.987 -45.9605C266.427 -47.5687 279.997 -44.38 289.507 -35.8176C299.016 -27.2552 303.606 -14.0926 303.725 2.42552C303.844 18.9438 299.491 38.7902 291.147 60.6726C274.458 104.436 241.824 156.292 197.194 205.859C152.564 255.425 104.403 293.301 62.6243 314.472C41.734 325.058 22.4514 331.462 6.01113 333.07C-10.4289 334.678 -23.9992 331.49 -33.5087 322.927C-43.0182 314.365 -47.6079 301.202 -47.727 284.684C-47.8462 268.166 -43.4932 248.319 -35.1487 226.437C-18.4603 182.674 14.1736 130.818 58.8037 81.2511Z"
				stroke="url(#paint30_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M61.02 78.1274C107.353 30.1484 156.806 -6.02331 199.299 -25.7238C220.546 -35.5744 240.04 -41.3012 256.526 -42.3346C273.012 -43.368 286.463 -39.7077 295.668 -30.8186C304.873 -21.9295 309 -8.61473 308.543 7.89745C308.086 24.4098 303.043 44.0922 293.94 65.67C275.734 108.824 241.31 159.51 194.978 207.489C148.645 255.468 99.1916 291.639 56.6991 311.34C35.452 321.19 15.9576 326.917 -0.528698 327.951C-17.0149 328.984 -30.4656 325.324 -39.6705 316.435C-48.8754 307.546 -53.0029 294.231 -52.5455 277.719C-52.0881 261.206 -47.0452 241.524 -37.942 219.946C-19.7365 176.792 14.6873 126.106 61.02 78.1274Z"
				stroke="url(#paint31_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M63.3203 75.0822C111.299 28.7495 161.985 -5.67433 205.139 -23.8799C226.717 -32.983 246.399 -38.0259 262.912 -38.4833C279.424 -38.9407 292.738 -34.8132 301.628 -25.6083C310.517 -16.4034 314.177 -2.95267 313.144 13.5335C312.11 30.0198 306.383 49.5142 296.533 70.7612C276.832 113.254 240.661 162.707 192.682 209.04C144.703 255.372 94.0171 289.796 50.863 308.002C29.2851 317.105 9.60276 322.148 -6.90961 322.605C-23.4218 323.063 -36.7366 318.935 -45.6256 309.73C-54.5147 300.525 -58.175 287.075 -57.1416 270.588C-56.1082 254.102 -50.3815 234.608 -40.5309 213.361C-20.8304 170.868 15.3414 121.415 63.3203 75.0822Z"
				stroke="url(#paint32_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M65.6975 72.1188C115.264 27.4888 167.12 -5.14516 210.883 -21.8335C232.766 -30.178 252.612 -34.531 269.131 -34.4118C285.649 -34.2927 298.811 -29.703 307.374 -20.1935C315.936 -10.684 319.125 2.88627 317.517 19.3263C315.908 35.7666 309.505 55.0492 298.919 75.9395C277.747 117.719 239.872 165.879 190.305 210.509C140.738 255.139 88.8822 287.773 45.119 304.462C23.2366 312.806 3.39024 317.159 -13.128 317.04C-29.6461 316.921 -42.8087 312.331 -51.3711 302.822C-59.9335 293.312 -63.1222 279.742 -61.5141 263.302C-59.9059 246.862 -53.5024 227.579 -42.9162 206.689C-21.7448 164.91 16.1308 116.749 65.6975 72.1188Z"
				stroke="url(#paint33_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M68.1487 69.2391C119.243 26.366 172.206 -4.43828 216.525 -19.5892C238.685 -27.1649 258.672 -30.8226 275.176 -30.127C291.679 -29.4315 304.674 -24.3853 312.899 -14.5827C321.125 -4.78017 323.838 8.89313 321.657 25.267C319.476 41.6411 312.403 60.6886 301.094 81.1967C278.478 122.211 238.944 169.021 187.85 211.894C136.756 254.767 83.793 285.571 39.474 300.722C17.3137 308.298 -2.67248 311.956 -19.1765 311.26C-35.6804 310.565 -48.6748 305.518 -56.9001 295.716C-65.1255 285.913 -67.8386 272.24 -65.6577 255.866C-63.4768 239.492 -56.4041 220.445 -45.0954 199.936C-22.4787 158.922 17.0546 112.112 68.1487 69.2391Z"
				stroke="url(#paint34_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M70.6726 66.4471C123.232 25.3834 177.238 -3.55378 222.059 -17.1487C244.47 -23.9465 264.572 -26.9044 281.041 -25.6333C297.511 -24.3622 310.321 -18.8655 318.199 -8.7819C326.078 1.30173 328.312 15.0614 325.561 31.3492C322.81 47.6372 315.077 66.4262 303.059 86.5272C279.025 126.728 237.882 172.129 185.323 213.193C132.764 254.256 78.7576 283.194 33.9368 296.789C11.5256 303.586 -8.57605 306.544 -25.0458 305.273C-41.5153 304.002 -54.3257 298.505 -62.2039 288.422C-70.0821 278.338 -72.3164 264.578 -69.5654 248.291C-66.8143 232.003 -59.0813 213.214 -47.0637 193.113C-23.0294 152.912 18.1134 107.511 70.6726 66.4471Z"
				stroke="url(#paint35_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M73.2705 63.745C127.231 24.5405 182.214 -2.49421 227.482 -14.5167C250.117 -20.5281 270.309 -22.7827 286.725 -20.9376C303.14 -19.0925 315.751 -13.1521 323.272 -2.79966C330.793 7.55277 332.546 21.382 329.228 37.5639C325.911 53.746 317.527 72.2536 304.815 91.923C279.392 131.26 236.69 175.198 182.73 214.402C128.769 253.607 73.7862 280.642 28.5183 292.664C5.8835 298.676 -14.3091 300.93 -30.7245 299.085C-47.1396 297.24 -59.7504 291.299 -67.2719 280.947C-74.7934 270.595 -76.5461 256.765 -73.2283 240.583C-69.9105 224.401 -61.5264 205.894 -48.8146 186.224C-23.392 146.887 19.3102 102.949 73.2705 63.745Z"
				stroke="url(#paint36_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M75.9337 61.133C131.229 23.8356 187.122 -1.26375 232.782 -11.6991C255.613 -16.9169 275.872 -18.4654 292.213 -16.0485C308.554 -13.6317 320.95 -7.25479 328.105 3.35383C335.261 13.9625 336.53 27.8444 332.649 43.9007C328.769 59.9571 319.744 78.1609 306.353 97.3746C279.574 135.801 235.364 178.221 180.068 215.519C124.773 252.816 68.8795 277.916 23.2196 288.351C0.388786 293.569 -19.8702 295.117 -36.2112 292.7C-52.5519 290.283 -64.9477 283.907 -72.1033 273.298C-79.2589 262.689 -80.5279 248.807 -76.6474 232.751C-72.7669 216.695 -63.742 198.491 -50.3515 179.277C-23.5716 140.851 20.6381 98.4304 75.9337 61.133Z"
				stroke="url(#paint37_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M78.6577 58.6162C135.221 23.2714 191.956 0.137924 237.953 -8.69752C260.952 -13.1154 281.252 -13.9559 297.499 -10.9702C313.745 -7.98456 325.911 -1.17896 332.692 9.67293C339.473 20.5248 340.257 34.4426 335.818 50.3537C331.38 66.2649 321.725 84.1426 307.672 102.877C279.568 140.345 233.904 181.197 177.341 216.542C120.777 251.887 64.0421 275.021 18.0458 283.856C-4.95316 288.274 -25.2539 289.114 -41.5005 286.129C-57.747 283.143 -69.9126 276.337 -76.6937 265.486C-83.4747 254.634 -84.2584 240.716 -79.8199 224.805C-75.3814 208.894 -65.7267 191.016 -51.6738 172.281C-23.5692 134.813 22.094 93.9611 78.6577 58.6162Z"
				stroke="url(#paint38_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M81.4437 56.1952C139.206 22.8459 196.714 1.70656 242.991 -5.51826C266.13 -9.1308 286.448 -9.26232 302.58 -5.71144C318.713 -2.1606 330.633 5.06543 337.031 16.1474C343.43 27.2293 343.727 41.166 338.736 56.9124C333.745 72.6591 323.472 90.1889 308.774 108.422C279.379 144.886 232.318 184.12 174.555 217.469C116.793 250.818 59.2847 271.958 13.0081 279.182C-10.1311 282.795 -30.4488 282.926 -46.5813 279.376C-62.7137 275.825 -74.6344 268.599 -81.0326 257.517C-87.4307 246.435 -87.7283 232.498 -82.7372 216.752C-77.7461 201.005 -67.4734 183.475 -52.7752 165.242C-23.38 128.778 23.6811 89.5445 81.4437 56.1952Z"
				stroke="url(#paint39_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M84.2857 53.8729C143.177 22.5598 201.388 3.44037 247.888 -2.16501C271.139 -4.96781 291.449 -4.39017 307.448 -0.278438C323.447 3.83325 335.108 11.4709 341.115 22.7694C347.123 34.0679 346.934 48.0064 341.396 63.5691C335.859 79.132 324.98 96.2927 309.655 114.001C279.005 149.418 230.603 186.985 171.712 218.298C112.821 249.611 54.6102 268.731 8.10964 274.336C-15.1415 277.139 -35.4514 276.561 -51.4502 272.449C-67.4488 268.338 -79.1101 260.7 -85.1176 249.402C-91.1251 238.103 -90.9361 224.164 -85.3985 208.602C-79.8609 193.039 -68.9827 175.878 -53.6572 158.169C-23.0073 122.753 25.3944 85.186 84.2857 53.8729Z"
				stroke="url(#paint40_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M87.1815 51.6502C147.13 22.4115 205.972 5.33521 252.64 1.35609C275.975 -0.633549 296.252 0.652543 312.098 5.32012C327.943 9.98765 339.331 18.0276 344.94 29.5289C350.55 41.0301 349.874 54.9536 343.797 70.3136C337.72 85.6737 326.249 102.444 310.315 119.607C278.448 153.932 228.765 189.788 168.816 219.026C108.868 248.265 50.0256 265.341 3.35777 269.32C-19.977 271.31 -40.2544 270.024 -56.0999 265.356C-71.9453 260.689 -83.333 252.649 -88.9425 241.148C-94.552 229.646 -93.8767 215.723 -87.7994 200.363C-81.722 185.003 -70.2514 168.232 -54.3172 151.069C-22.4501 116.744 27.2332 80.889 87.1815 51.6502Z"
				stroke="url(#paint41_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M90.1291 49.5303C151.061 22.4016 210.464 7.38924 257.242 5.04123C280.632 3.86717 300.852 5.86015 316.525 11.0779C332.198 16.2956 343.298 24.7281 348.503 36.4181C353.708 48.1081 352.547 61.9995 345.937 77.138C339.327 92.2767 327.278 108.637 310.755 125.233C277.709 158.425 226.805 192.525 165.873 219.653C104.941 246.782 45.5381 261.794 -1.24017 264.142C-24.6302 265.317 -44.8503 263.324 -60.5233 258.106C-76.1961 252.888 -87.2963 244.456 -92.501 232.766C-97.7057 221.076 -96.5449 207.184 -89.9352 192.046C-83.3254 176.907 -71.2766 160.547 -54.7531 143.95C-21.7074 110.758 29.1969 76.6591 90.1291 49.5303Z"
				stroke="url(#paint42_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M93.1209 47.5131C154.963 22.5273 214.853 9.59728 261.685 8.88323C285.101 8.52619 305.24 11.2236 320.721 16.9852C336.202 22.7466 347.001 31.5614 351.795 43.4259C356.588 55.2905 354.943 69.1329 347.809 84.0315C340.675 98.9303 328.063 114.86 310.97 130.87C276.786 162.888 224.723 195.19 162.881 220.176C101.039 245.162 41.1491 258.092 -5.68261 258.806C-29.0994 259.163 -49.2376 256.465 -64.719 250.704C-80.2001 244.942 -90.9992 236.128 -95.7928 224.263C-100.586 212.399 -98.9414 198.556 -91.8075 183.657C-84.6734 168.759 -72.0609 152.829 -54.9683 136.819C-20.7844 104.801 31.279 72.4988 93.1209 47.5131Z"
				stroke="url(#paint43_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M96.1533 45.6019C158.829 22.7896 219.134 11.9576 265.963 12.8784C289.377 13.3388 309.409 16.7374 324.68 23.0357C339.951 29.334 350.436 38.5202 354.812 50.5448C359.189 62.5694 357.062 76.3461 349.412 90.9866C341.763 105.627 328.602 121.107 310.961 136.511C275.68 167.316 222.521 197.782 159.845 220.594C97.1691 243.406 36.8641 254.238 -9.96398 253.318C-33.3789 252.857 -53.4108 249.459 -68.6816 243.16C-83.9523 236.862 -94.4372 227.676 -98.8138 215.651C-103.19 203.627 -101.063 189.85 -93.4137 175.209C-85.7641 160.569 -72.6034 145.089 -54.9624 129.685C-19.6819 98.8796 33.4771 68.4142 96.1533 45.6019Z"
				stroke="url(#paint44_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M99.227 43.7959C162.661 23.1849 223.307 14.4641 270.075 17.0186C293.459 18.2959 313.361 22.3915 328.402 29.2189C343.444 36.0463 353.602 45.5929 357.556 57.7629C361.51 69.9329 358.904 83.6269 350.748 97.9916C342.592 112.356 328.899 127.367 310.731 142.146C274.397 171.702 220.207 200.293 156.773 220.904C93.339 241.515 32.6927 250.236 -14.0748 247.682C-37.4594 246.404 -57.3604 242.309 -72.4021 235.481C-87.4437 228.654 -97.6016 219.107 -101.556 206.937C-105.51 194.767 -102.904 181.073 -94.7477 166.709C-86.5918 152.344 -72.8988 137.333 -54.7311 122.554C-18.3969 92.9986 35.7929 64.4069 99.227 43.7959Z"
				stroke="url(#paint45_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M102.333 42.0997C166.448 23.7151 227.362 17.1161 274.011 21.3012C297.337 23.3938 317.083 28.1815 331.878 35.5297C346.672 42.8778 356.49 52.7731 360.017 65.0737C363.544 77.3743 360.462 90.969 351.809 105.04C343.157 119.112 328.949 133.636 310.276 147.771C272.933 176.041 217.778 202.724 153.663 221.109C89.5484 239.493 28.6348 246.092 -18.0151 241.907C-41.3409 239.814 -61.0868 235.027 -75.8811 227.679C-90.6753 220.33 -100.494 210.435 -104.021 198.135C-107.548 185.834 -104.465 172.239 -95.813 158.168C-87.1607 144.097 -72.9522 129.573 -54.2797 115.437C-16.9362 87.1673 38.2184 60.4843 102.333 42.0997Z"
				stroke="url(#paint46_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M105.473 40.5106C170.19 24.3748 231.297 19.9056 277.773 25.7162C301.011 28.6217 320.578 34.0956 335.107 41.9556C349.635 49.8156 359.103 60.0475 362.198 72.4637C365.294 84.8799 361.739 98.3587 352.601 112.119C343.462 125.88 328.756 139.9 309.601 153.375C271.294 180.324 215.242 205.066 150.524 221.202C85.807 237.338 24.7002 241.807 -21.7752 235.996C-45.0137 233.091 -64.5806 227.617 -79.1094 219.757C-93.6381 211.897 -103.105 201.665 -106.201 189.249C-109.297 176.833 -105.741 163.354 -96.6033 149.593C-87.4651 135.832 -72.7585 121.813 -53.604 108.338C-15.2967 81.3884 40.7556 56.6465 105.473 40.5106Z"
				stroke="url(#paint47_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M108.642 39.0345C173.883 25.1671 235.108 22.8333 281.353 30.2623C304.476 33.977 323.84 40.1304 338.085 48.4927C352.331 56.8549 361.435 67.411 364.096 79.9277C366.756 92.4444 362.732 105.791 353.12 119.224C343.507 132.658 328.32 146.155 308.707 158.954C269.482 184.55 212.601 207.321 147.36 221.188C82.1187 235.055 20.8931 237.389 -25.3512 229.96C-48.4742 226.245 -67.8381 220.092 -82.0838 211.73C-96.3293 203.367 -105.434 192.811 -108.094 180.295C-110.755 167.778 -106.731 154.432 -97.1182 140.998C-87.5054 127.564 -72.3184 114.067 -52.7054 101.269C-13.4809 75.6725 43.4007 52.9019 108.642 39.0345Z"
				stroke="url(#paint48_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M111.83 37.667C177.515 26.0849 238.785 25.8892 284.742 34.9276C307.721 39.447 326.858 46.2725 340.803 55.1269C354.748 63.9812 363.479 74.8486 365.701 87.4505C367.923 100.052 363.436 113.25 353.36 126.34C343.284 139.43 327.636 152.389 307.588 164.495C267.494 188.707 209.852 209.479 144.167 221.061C78.4817 232.643 17.2119 232.838 -28.7449 223.8C-51.7242 219.281 -70.8615 212.455 -84.8067 203.601C-98.7517 194.746 -107.482 183.879 -109.704 171.277C-111.926 158.675 -107.439 145.477 -97.3635 132.388C-87.2877 119.298 -71.6389 106.338 -51.5912 94.2322C-11.4972 70.0207 46.1444 49.2491 111.83 37.667Z"
				stroke="url(#paint49_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M115.041 36.4112C181.09 27.1286 242.329 29.0713 287.943 39.7081C310.75 45.0267 329.638 52.5159 343.265 61.8516C356.893 71.1871 365.239 82.3526 367.02 95.0244C368.8 107.696 363.856 120.73 353.329 133.46C342.803 146.19 326.711 158.595 306.253 169.994C265.339 192.792 207.007 211.539 140.958 220.822C74.9083 230.104 13.6691 228.162 -31.9443 217.525C-54.7519 212.206 -73.6394 204.717 -87.267 195.381C-100.895 186.046 -109.24 174.88 -111.021 162.209C-112.802 149.537 -107.857 136.504 -97.3309 123.773C-86.8044 111.043 -70.7129 98.6379 -50.2549 87.2387C-9.34036 64.4412 48.9911 45.6939 115.041 36.4112Z"
				stroke="url(#paint50_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M118.269 35.2681C184.602 28.2962 245.736 32.375 290.951 44.5972C313.559 50.7085 332.173 58.8523 345.467 68.6579C358.76 78.4634 366.711 89.9133 368.049 102.64C369.386 115.366 363.99 128.219 353.025 140.574C342.061 152.929 325.546 164.765 304.703 175.443C263.018 196.799 204.068 213.499 137.734 220.471C71.4013 227.443 10.2671 223.364 -34.9472 211.142C-57.5553 205.03 -76.1699 196.887 -89.4634 187.081C-102.757 177.276 -110.708 165.826 -112.045 153.099C-113.383 140.373 -107.986 127.52 -97.0221 115.165C-86.0577 102.81 -69.5431 90.9741 -48.6997 80.2959C-7.0145 58.9402 51.9357 42.24 118.269 35.2681Z"
				stroke="url(#paint51_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M121.506 34.2372C188.042 29.5845 248.996 35.7944 293.756 49.5871C316.137 56.4837 334.457 65.2722 347.4 75.5357C360.343 85.7992 367.889 97.5196 368.782 110.285C369.675 123.05 363.833 135.707 352.444 147.671C341.055 159.636 324.137 170.889 302.934 180.833C260.529 200.721 201.032 215.354 134.496 220.006C67.9597 224.659 7.00513 218.449 -37.7552 204.657C-60.1361 197.76 -78.4552 188.971 -91.3984 178.708C-104.342 168.444 -111.888 156.724 -112.781 143.959C-113.673 131.194 -107.831 118.537 -96.4426 106.572C-85.0538 94.6072 -68.1361 83.3547 -46.9328 73.4104C-4.52767 53.5225 54.9694 38.8899 121.506 34.2372Z"
				stroke="url(#paint52_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M124.75 33.3206C191.407 30.9929 252.108 39.3262 296.36 54.6727C318.487 62.3461 336.488 71.7686 349.065 82.4776C361.642 93.1865 368.775 105.163 369.221 117.952C369.668 130.74 363.388 143.185 351.589 154.745C339.789 166.306 322.489 176.961 300.951 186.159C257.878 204.555 197.907 217.102 131.249 219.43C64.5907 221.758 3.88997 213.424 -40.3617 198.078C-62.4883 190.405 -80.4895 180.982 -93.0667 170.273C-105.644 159.564 -112.777 147.588 -113.223 134.799C-113.67 122.011 -107.39 109.565 -95.5903 98.0053C-83.7908 86.4451 -66.4908 75.7898 -44.9533 66.5916C-1.87994 48.1957 58.0916 35.6484 124.75 33.3206Z"
				stroke="url(#paint53_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M128 32.5174C194.699 32.5174 255.072 42.9641 298.761 59.8455C320.606 68.2865 338.268 78.3315 350.463 89.4729C362.659 100.614 369.37 112.832 369.37 125.629C369.37 138.425 362.659 150.643 350.463 161.785C338.268 172.926 320.606 182.971 298.761 191.412C255.072 208.294 194.699 218.74 128 218.74C61.3017 218.74 0.928764 208.294 -42.7604 191.412C-64.6057 182.971 -82.2671 172.926 -94.4629 161.785C-106.658 150.643 -113.369 138.425 -113.369 125.629C-113.369 112.832 -106.658 100.614 -94.4629 89.4729C-82.2671 78.3315 -64.6057 68.2865 -42.7604 59.8455C0.928764 42.9641 61.3017 32.5174 128 32.5174Z"
				stroke="url(#paint54_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M131.25 31.828C197.907 34.1557 257.879 46.7031 300.952 65.0989C322.49 74.2972 339.79 84.9524 351.589 96.5127C363.389 108.073 369.669 120.518 369.222 133.306C368.776 146.095 361.643 158.072 349.066 168.78C336.489 179.489 318.487 188.912 296.361 196.585C252.109 211.932 191.408 220.265 124.75 217.937C58.0925 215.61 -1.87903 203.062 -44.9524 184.666C-66.4899 175.468 -83.7899 164.813 -95.5894 153.253C-107.389 141.693 -113.669 129.248 -113.222 116.459C-112.776 103.671 -105.643 91.6939 -93.0658 80.985C-80.4886 70.276 -62.4874 60.8535 -40.3608 53.18C3.89088 37.8336 64.5916 29.5003 131.25 31.828Z"
				stroke="url(#paint55_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M134.494 31.2509C201.03 35.9036 260.527 50.5362 302.932 70.4241C324.135 80.3684 341.053 91.6209 352.442 103.586C363.831 115.551 369.673 128.207 368.78 140.973C367.887 153.738 360.341 165.458 347.398 175.722C334.454 185.985 316.135 194.774 293.754 201.67C248.994 215.463 188.039 221.673 121.503 217.02C54.9672 212.368 -4.52988 197.735 -46.935 177.847C-68.1383 167.903 -85.056 156.65 -96.4449 144.685C-107.834 132.72 -113.676 120.064 -112.783 107.298C-111.89 94.5333 -104.344 82.8129 -91.4006 72.5495C-78.4574 62.2859 -60.1383 53.4974 -37.7574 46.6008C7.00292 32.8081 67.9575 26.5983 134.494 31.2509Z"
				stroke="url(#paint56_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M137.731 30.7862C204.064 37.7581 263.014 54.4583 304.699 75.814C325.543 86.4922 342.057 98.3282 353.022 110.683C363.986 123.038 369.383 135.891 368.045 148.618C366.708 161.344 358.757 172.794 345.463 182.599C332.17 192.405 313.555 200.549 290.947 206.66C245.733 218.882 184.598 222.961 118.265 215.989C51.9321 209.017 -7.01813 192.317 -48.7033 170.961C-69.5467 160.283 -86.0614 148.447 -97.0257 136.092C-107.99 123.737 -113.387 110.884 -112.049 98.1576C-110.712 85.4314 -102.76 73.9815 -89.4671 64.176C-76.1735 54.3704 -57.5589 46.2266 -34.9509 40.1153C10.2635 27.8931 71.3977 23.8143 137.731 30.7862Z"
				stroke="url(#paint57_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M140.958 30.4366C207.008 39.7192 265.339 58.4665 306.254 81.264C326.712 92.6632 342.803 105.068 353.33 117.799C363.856 130.529 368.801 143.562 367.02 156.234C365.239 168.906 356.893 180.071 343.266 189.407C329.638 198.742 310.751 206.232 287.943 211.55C242.33 222.187 181.091 224.13 115.041 214.847C48.9916 205.564 -9.33989 186.817 -50.2544 164.02C-70.7124 152.621 -86.8039 140.215 -97.3304 127.485C-107.857 114.755 -112.802 101.721 -111.021 89.0497C-109.24 76.3779 -100.894 65.2125 -87.2665 55.8769C-73.6389 46.5412 -54.7514 39.052 -31.9439 33.7334C13.6695 23.0967 74.9088 21.1539 140.958 30.4366Z"
				stroke="url(#paint58_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M144.167 30.1967C209.852 41.7788 267.494 62.5504 307.588 86.7619C327.636 98.8681 343.285 111.827 353.36 124.917C363.436 138.007 367.923 151.205 365.701 163.807C363.479 176.409 354.749 187.276 340.804 196.131C326.858 204.985 307.721 211.81 284.742 216.33C238.785 225.368 177.515 225.173 111.83 213.59C46.1447 202.008 -11.497 181.237 -51.5909 157.025C-71.6387 144.919 -87.2875 131.96 -97.3632 118.87C-107.439 105.78 -111.926 92.5821 -109.704 79.9802C-107.482 67.3783 -98.7514 56.5109 -84.8064 47.6566C-70.8613 38.8023 -51.7239 31.9768 -28.7446 27.4574C17.2122 18.419 78.4819 18.6146 144.167 30.1967Z"
				stroke="url(#paint59_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M147.357 30.0709C212.598 43.9383 269.48 66.709 308.705 92.305C328.318 105.103 343.505 118.601 353.117 132.034C362.73 145.468 366.754 158.814 364.093 171.331C361.433 183.848 352.328 194.404 338.083 202.766C323.837 211.128 304.473 217.282 281.35 220.997C235.106 228.426 173.881 226.092 108.639 212.224C43.3984 198.357 -13.4832 175.586 -52.7078 149.99C-72.3208 137.192 -87.5078 123.694 -97.1206 110.261C-106.733 96.8273 -110.757 83.4808 -108.096 70.9641C-105.436 58.4474 -96.3316 47.8913 -82.0861 39.5291C-67.8405 31.1668 -48.4765 25.0134 -25.3535 21.2987C20.8907 13.8697 82.1163 16.2035 147.357 30.0709Z"
				stroke="url(#paint60_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M150.527 30.0553C215.244 46.1912 271.296 70.9331 309.604 97.8824C328.758 111.358 343.465 125.377 352.603 139.138C361.741 152.898 365.296 166.377 362.201 178.793C359.105 191.21 349.638 201.442 335.109 209.302C320.58 217.162 301.013 222.636 277.775 225.541C231.3 231.352 170.193 226.882 105.475 210.747C40.758 194.611 -15.2942 169.869 -53.6016 142.919C-72.756 129.444 -87.4627 115.425 -96.6008 101.664C-105.739 87.9034 -109.294 74.4246 -106.199 62.0084C-103.103 49.5922 -93.6357 39.3603 -79.107 31.5003C-64.5782 23.6403 -45.0113 18.1664 -21.7728 15.261C24.7026 9.45034 85.8094 13.9195 150.527 30.0553Z"
				stroke="url(#paint61_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M153.664 30.1499C217.779 48.5345 272.934 75.2176 310.277 103.487C328.95 117.623 343.158 132.147 351.81 146.218C360.463 160.289 363.546 173.884 360.018 186.185C356.491 198.485 346.673 208.381 331.879 215.729C317.084 223.077 297.338 227.865 274.012 229.957C227.363 234.142 166.449 227.543 102.334 209.159C38.2194 190.774 -16.9352 164.091 -54.2787 135.821C-72.9512 121.686 -87.1597 107.162 -95.812 93.0905C-104.464 79.0192 -107.547 65.4245 -104.02 53.1239C-100.493 40.8233 -90.6743 30.9281 -75.8801 23.5799C-61.0858 16.2317 -41.3399 11.444 -18.0141 9.35139C28.6358 5.16627 89.5494 11.7653 153.664 30.1499Z"
				stroke="url(#paint62_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M156.772 30.3524C220.207 50.9634 274.396 79.5551 310.731 109.111C328.898 123.889 342.591 138.9 350.747 153.265C358.903 167.63 361.51 181.324 357.555 193.494C353.601 205.664 343.443 215.211 328.402 222.038C313.36 228.865 293.459 232.961 270.074 234.238C223.307 236.793 162.661 228.072 99.2264 207.461C35.7923 186.85 -18.3975 158.258 -54.7317 128.702C-72.8995 113.924 -86.5924 98.9129 -94.7484 84.5481C-102.904 70.1835 -105.511 56.4894 -101.556 44.3194C-97.6022 32.1494 -87.4443 22.6028 -72.4028 15.7755C-57.361 8.94804 -37.46 4.8524 -14.0754 3.5751C32.6921 1.02059 93.3383 9.74144 156.772 30.3524Z"
				stroke="url(#paint63_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M159.847 30.6635C222.523 53.4757 275.682 83.9412 310.963 114.747C328.604 130.151 341.764 145.63 349.414 160.271C357.064 174.912 359.191 188.688 354.814 200.713C350.437 212.737 339.952 221.924 324.682 228.222C309.411 234.52 289.379 237.919 265.964 238.379C219.136 239.3 158.831 228.468 96.1549 205.656C33.4788 182.843 -19.6802 152.378 -54.9608 121.572C-72.6017 106.169 -85.7624 90.6889 -93.4121 76.0482C-101.062 61.4076 -103.189 47.631 -98.8121 35.6064C-94.4355 23.5818 -83.9506 14.3955 -68.68 8.09729C-53.4091 1.79897 -33.3773 -1.59965 -9.96233 -2.06006C36.8658 -2.98085 97.1708 7.85122 159.847 30.6635Z"
				stroke="url(#paint64_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M162.88 31.0814C224.721 56.0672 276.785 88.3693 310.969 120.388C328.061 136.397 340.674 152.327 347.808 167.226C354.942 182.124 356.587 195.967 351.793 207.831C347 219.696 336.201 228.511 320.719 234.272C305.238 240.034 285.1 242.731 261.683 242.374C214.851 241.66 154.961 228.73 93.1192 203.744C31.2774 178.759 -20.786 146.456 -54.9699 114.438C-72.0625 98.4282 -84.675 82.4986 -91.8091 67.5999C-98.943 52.7013 -100.588 38.8588 -95.7944 26.9943C-91.0008 15.1298 -80.2017 6.31501 -64.7206 0.553534C-49.2392 -5.208 -29.101 -7.90545 -5.68421 -7.5484C41.1475 -6.83436 101.038 6.09572 162.88 31.0814Z"
				stroke="url(#paint65_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M165.873 31.6047C226.805 58.7334 277.709 92.8328 310.755 126.025C327.278 142.621 339.327 158.981 345.937 174.12C352.546 189.259 353.707 203.15 348.503 214.84C343.298 226.53 332.198 234.962 316.525 240.18C300.852 245.398 280.632 247.391 257.242 246.217C210.463 243.869 151.061 228.856 90.1288 201.728C29.1967 174.599 -21.7077 140.5 -54.7534 107.308C-71.2768 90.7111 -83.3257 74.351 -89.9354 59.2124C-96.5451 44.0739 -97.706 30.1824 -92.5012 18.4924C-87.2965 6.80243 -76.1964 -1.63009 -60.5236 -6.84776C-44.8506 -12.0655 -24.6304 -14.0585 -1.2404 -12.8844C45.5379 -10.5364 104.94 4.47593 165.873 31.6047Z"
				stroke="url(#paint66_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M168.819 32.2304C228.767 61.4691 278.45 97.3243 310.317 131.649C326.252 148.812 337.722 165.583 343.799 180.943C349.877 196.303 350.552 210.227 344.943 221.728C339.333 233.229 327.945 241.269 312.1 245.936C296.255 250.604 275.977 251.89 252.642 249.901C205.974 245.921 147.132 228.845 87.1839 199.606C27.2356 170.368 -22.4477 134.512 -54.3149 100.187C-70.2491 83.0244 -81.7196 66.2538 -87.797 50.8937C-93.8743 35.5337 -94.5497 21.6102 -88.9401 10.109C-83.3306 -1.39225 -71.943 -9.43223 -56.0976 -14.0998C-40.252 -18.7673 -19.9747 -20.0534 3.36013 -18.0638C50.028 -14.0847 108.87 2.99164 168.819 32.2304Z"
				stroke="url(#paint67_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M171.713 32.9595C230.604 64.2726 279.006 101.84 309.656 137.256C324.981 154.965 335.86 172.126 341.397 187.688C346.935 203.251 347.124 217.19 341.116 228.488C335.109 239.787 323.448 247.424 307.449 251.536C291.45 255.648 271.14 256.225 247.889 253.423C201.389 247.817 143.178 228.698 84.2867 197.385C25.3953 166.071 -23.0064 128.504 -53.6562 93.088C-68.9817 75.3793 -79.86 58.2186 -85.3976 42.6557C-90.9352 27.093 -91.1242 13.1545 -85.1167 1.85599C-79.1092 -9.44249 -67.4479 -17.0802 -51.4493 -21.1918C-35.4505 -25.3036 -15.1406 -25.8812 8.11057 -23.0784C54.6111 -17.473 112.822 1.64645 171.713 32.9595Z"
				stroke="url(#paint68_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M174.555 33.7892C232.318 67.1385 279.379 106.372 308.774 142.836C323.472 161.069 333.745 178.599 338.736 194.346C343.727 210.092 343.43 224.029 337.032 235.111C330.633 246.193 318.713 253.419 302.58 256.97C286.448 260.52 266.13 260.389 242.991 256.776C196.714 249.552 139.206 228.412 81.4438 195.063C23.6812 161.714 -23.3799 122.48 -52.7751 86.0158C-67.4733 67.7829 -77.746 50.253 -82.7371 34.5064C-87.7282 18.76 -87.4306 4.82328 -81.0325 -6.25865C-74.6343 -17.3406 -62.7136 -24.5666 -46.5812 -28.1175C-30.4487 -31.6683 -10.131 -31.5368 13.0082 -27.9243C59.2848 -20.6995 116.793 0.43989 174.555 33.7892Z"
				stroke="url(#paint69_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M177.342 34.7162C233.905 70.0611 279.568 110.913 307.673 148.381C321.726 167.116 331.381 184.994 335.819 200.905C340.258 216.816 339.474 230.734 332.693 241.585C325.912 252.437 313.746 259.243 297.5 262.229C281.253 265.214 260.952 264.374 237.953 259.956C191.957 251.12 135.222 227.987 78.6584 192.642C22.0948 157.297 -23.5684 116.445 -51.6731 78.9773C-65.7259 60.2426 -75.3806 42.3649 -79.8192 26.4537C-84.2576 10.5426 -83.4739 -3.37519 -76.6929 -14.2271C-69.9119 -25.079 -57.7462 -31.8846 -41.4997 -34.8702C-25.2531 -37.8559 -4.95237 -37.0154 18.0466 -32.5975C64.0429 -23.7621 120.778 -0.628626 177.342 34.7162Z"
				stroke="url(#paint70_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M180.066 35.7386C235.362 73.0359 279.572 115.457 306.352 153.883C319.742 173.096 328.767 191.3 332.647 207.357C336.528 223.413 335.259 237.295 328.103 247.904C320.948 258.512 308.552 264.889 292.211 267.306C275.87 269.723 255.611 268.174 232.78 262.956C187.121 252.521 131.227 227.422 75.9318 190.124C20.6362 152.827 -23.5735 110.406 -50.3534 71.9802C-63.7439 52.7664 -72.7688 34.5627 -76.6493 18.5062C-80.5298 2.44999 -79.2608 -11.432 -72.1052 -22.0406C-64.9496 -32.6492 -52.5538 -39.0261 -36.2131 -41.443C-19.8721 -43.8599 0.386886 -42.3114 23.2177 -37.0935C68.8776 -26.6582 124.771 -1.55881 180.066 35.7386Z"
				stroke="url(#paint71_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M182.729 36.8559C236.689 76.0603 279.391 119.998 304.814 159.335C317.526 179.005 325.91 197.512 329.227 213.694C332.545 229.876 330.793 243.706 323.271 254.058C315.75 264.41 303.139 270.351 286.724 272.196C270.308 274.041 250.116 271.786 227.481 265.775C182.213 253.753 127.23 226.718 73.2696 187.513C19.3093 148.309 -23.393 104.371 -48.8156 65.0339C-61.5274 45.3646 -69.9114 26.8569 -73.2293 10.6748C-76.547 -5.50705 -74.7943 -19.3363 -67.2728 -29.6887C-59.7514 -40.0412 -47.1406 -45.9816 -30.7254 -47.8267C-14.3101 -49.6718 5.88254 -47.4172 28.5173 -41.4057C73.7852 -29.3833 128.768 -2.34854 182.729 36.8559Z"
				stroke="url(#paint72_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M185.326 38.0661C237.885 79.1298 279.028 124.531 303.062 164.732C315.08 184.833 322.813 203.622 325.564 219.91C328.315 236.197 326.081 249.957 318.202 260.041C310.324 270.124 297.514 275.621 281.044 276.892C264.575 278.163 244.473 275.205 222.062 268.408C177.241 254.813 123.235 225.875 70.6756 184.812C18.1164 143.748 -23.0264 98.3465 -47.0607 58.1461C-59.0783 38.0451 -66.8113 19.2561 -69.5624 2.96813C-72.3134 -13.3197 -70.0791 -27.0793 -62.2009 -37.163C-54.3227 -47.2466 -41.5123 -52.7433 -25.0428 -54.0144C-8.57304 -55.2855 11.5286 -52.3276 33.9398 -45.5298C78.7606 -31.9349 132.767 -2.99769 185.326 38.0661Z"
				stroke="url(#paint73_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M187.85 39.3629C238.944 82.2359 278.478 129.045 301.094 170.06C312.403 190.568 319.476 209.616 321.656 225.99C323.837 242.364 321.124 256.037 312.899 265.84C304.674 275.642 291.679 280.688 275.175 281.384C258.671 282.079 238.685 278.422 216.525 270.846C172.206 255.695 119.242 224.891 68.1484 182.018C17.0543 139.145 -22.479 92.3352 -45.0957 51.3205C-56.4044 30.8124 -63.477 11.7649 -65.658 -4.60915C-67.8389 -20.9831 -65.1257 -34.6564 -56.9004 -44.4589C-48.6751 -54.2614 -35.6807 -59.3077 -19.1768 -60.0032C-2.67276 -60.6988 17.3134 -57.0411 39.4737 -49.4654C83.7927 -34.3145 136.756 -3.51016 187.85 39.3629Z"
				stroke="url(#paint74_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M190.304 40.75C239.87 85.38 277.746 133.541 298.917 175.32C309.503 196.21 315.907 215.493 317.515 231.933C319.123 248.373 315.935 261.943 307.372 271.453C298.81 280.962 285.647 285.552 269.129 285.671C252.611 285.79 232.765 281.437 210.882 273.093C167.119 256.405 115.263 223.771 65.6962 179.141C16.1295 134.51 -21.7461 86.3497 -42.9176 44.5706C-53.5037 23.6803 -59.9073 4.39765 -61.5154 -12.0426C-63.1236 -28.4826 -59.9349 -42.0529 -51.3725 -51.5624C-42.8101 -61.0719 -29.6475 -65.6616 -13.1294 -65.7807C3.38888 -65.8999 23.2352 -61.5469 45.1177 -53.2024C88.8809 -36.5141 140.737 -3.88011 190.304 40.75Z"
				stroke="url(#paint75_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M192.679 42.2193C240.658 88.552 276.83 138.005 296.53 180.498C306.381 201.745 312.108 221.239 313.141 237.725C314.175 254.212 310.514 267.662 301.625 276.867C292.736 286.072 279.421 290.2 262.909 289.742C246.397 289.285 226.714 284.242 205.137 275.139C161.982 256.933 111.297 222.509 63.318 176.177C15.3391 129.844 -20.8327 80.3908 -40.5332 37.8983C-50.3838 16.6512 -56.1105 -2.84314 -57.1439 -19.3295C-58.1773 -35.8156 -54.517 -49.2664 -45.628 -58.4713C-36.7389 -67.6762 -23.4241 -71.8037 -6.91193 -71.3462C9.60044 -70.8888 29.2828 -65.8459 50.8607 -56.7428C94.0148 -38.5373 144.7 -4.11348 192.679 42.2193Z"
				stroke="url(#paint76_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M194.978 43.7695C241.311 91.7485 275.735 142.434 293.94 185.588C303.044 207.166 308.086 226.848 308.544 243.361C309.001 259.873 304.874 273.188 295.669 282.077C286.464 290.966 273.013 294.626 256.527 293.593C240.041 292.559 220.546 286.833 199.299 276.982C156.807 257.281 107.354 221.11 61.0208 173.131C14.6881 125.152 -19.7357 74.4664 -37.9413 31.3122C-47.0444 9.73433 -52.0873 -9.94802 -52.5447 -26.4604C-53.0021 -42.9726 -48.8746 -56.2874 -39.6697 -65.1764C-30.4648 -74.0655 -17.0141 -77.7258 -0.527909 -76.6924C15.9584 -75.659 35.4528 -69.9323 56.6998 -60.0816C99.1923 -40.3811 148.646 -4.2094 194.978 43.7695Z"
				stroke="url(#paint77_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M197.195 45.3997C241.825 94.9664 274.459 146.822 291.147 190.586C299.492 212.468 303.844 232.314 303.725 248.833C303.606 265.351 299.017 278.513 289.507 287.076C279.997 295.638 266.427 298.827 249.987 297.219C233.547 295.611 214.264 289.207 193.374 278.621C151.595 257.449 103.434 219.574 58.8041 170.007C14.174 120.44 -18.4599 68.5844 -35.1483 24.8212C-43.4928 2.93875 -47.8457 -16.9076 -47.7266 -33.4259C-47.6075 -49.944 -43.0178 -63.1066 -33.5083 -71.669C-23.9988 -80.2314 -10.4285 -83.4201 6.01155 -81.8119C22.4518 -80.2038 41.7344 -73.8002 62.6247 -63.2141C104.404 -42.0426 152.565 -4.16701 197.195 45.3997Z"
				stroke="url(#paint78_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M199.328 47.1067C242.201 98.2007 273.006 151.164 288.157 195.483C295.732 217.643 299.39 237.63 298.694 254.134C297.999 270.637 292.953 283.632 283.15 291.857C273.348 300.083 259.674 302.796 243.3 300.615C226.926 298.434 207.879 291.361 187.371 280.052C146.356 257.436 99.5464 217.902 56.6733 166.808C13.8003 115.714 -17.004 62.751 -32.1549 18.432C-39.7306 -3.72829 -43.3883 -23.7145 -42.6927 -40.2185C-41.9972 -56.7224 -36.951 -69.7168 -27.1484 -77.9421C-17.3459 -86.1674 -3.67259 -88.8806 12.7013 -86.6997C29.0754 -84.5188 48.1228 -77.4461 68.631 -66.1374C109.646 -43.5207 156.455 -3.98741 199.328 47.1067Z"
				stroke="url(#paint79_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M201.372 48.8846C242.436 101.444 271.373 155.45 284.968 200.271C291.766 222.682 294.724 242.783 293.453 259.253C292.182 275.723 286.685 288.533 276.601 296.411C266.518 304.29 252.758 306.524 236.47 303.773C220.182 301.022 201.393 293.289 181.292 281.271C141.092 257.237 95.6903 216.094 54.6266 163.535C13.5628 110.976 -15.3743 56.9695 -28.9693 12.1487C-35.767 -10.2625 -38.7249 -30.3641 -37.4538 -46.8339C-36.1827 -63.3034 -30.6861 -76.1138 -20.6024 -83.992C-10.5188 -91.8702 3.24084 -94.1045 19.5287 -91.3535C35.8167 -88.6024 54.6057 -80.8693 74.7066 -68.8518C114.907 -44.8175 160.308 -3.67465 201.372 48.8846Z"
				stroke="url(#paint80_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M203.327 50.7334C242.532 104.694 269.566 159.677 281.589 204.945C287.6 227.579 289.855 247.772 288.01 264.187C286.165 280.603 280.224 293.213 269.872 300.735C259.519 308.256 245.69 310.009 229.508 306.691C213.326 303.373 194.819 294.989 175.149 282.278C135.812 256.855 91.8743 214.153 52.6698 160.192C13.4654 106.232 -13.5693 51.2491 -25.5918 5.98118C-31.6033 -16.6536 -33.8578 -36.8463 -32.0127 -53.2616C-30.1676 -69.6767 -24.2272 -82.2875 -13.8748 -89.809C-3.52237 -97.3305 10.3069 -99.0832 26.4888 -95.7654C42.6709 -92.4476 61.1785 -84.0635 80.8478 -71.3517C120.185 -45.9291 164.123 -3.22686 203.327 50.7334Z"
				stroke="url(#paint81_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M205.193 52.6476C242.491 107.943 267.59 163.836 278.025 209.496C283.243 232.327 284.792 252.586 282.375 268.927C279.958 285.268 273.581 297.664 262.972 304.819C252.364 311.975 238.482 313.244 222.425 309.363C206.369 305.483 188.165 296.458 168.952 283.067C130.526 256.287 88.1048 212.078 50.8074 156.782C13.51 101.486 -11.5894 45.5933 -22.0247 -0.0665442C-27.2425 -22.8973 -28.791 -43.1564 -26.3741 -59.4973C-23.9573 -75.8381 -17.5804 -88.2338 -6.97178 -95.3894C3.63684 -102.545 17.5188 -103.814 33.5751 -99.9336C49.6315 -96.053 67.8353 -87.0281 87.049 -73.6377C125.475 -46.8577 167.896 -2.64804 205.193 52.6476Z"
				stroke="url(#paint82_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M206.964 54.6284C242.309 111.192 265.442 167.927 274.278 213.923C278.695 236.922 279.536 257.223 276.55 273.47C273.565 289.716 266.759 301.882 255.907 308.663C245.055 315.444 231.137 316.228 215.226 311.789C199.315 307.35 181.437 297.696 162.703 283.643C125.235 255.538 84.3828 209.875 49.0379 153.311C13.693 96.7478 -9.44043 40.0128 -18.2759 -5.98346C-22.6938 -28.9825 -23.5343 -49.2832 -20.5486 -65.5298C-17.5629 -81.7763 -10.7573 -93.9419 0.0945759 -100.723C10.9465 -107.504 24.8643 -108.288 40.7753 -103.849C56.6865 -99.4107 74.5643 -89.756 93.2989 -75.7031C130.767 -47.5985 171.619 -1.93525 206.964 54.6284Z"
				stroke="url(#paint83_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M208.636 56.6664C241.985 114.429 263.124 171.937 270.349 218.213C273.962 241.353 274.093 261.67 270.542 277.803C266.992 293.935 259.766 305.856 248.684 312.254C237.602 318.652 223.665 318.95 207.919 313.959C192.172 308.968 174.642 298.695 156.409 283.997C119.945 254.602 80.7114 207.54 47.3621 149.778C14.0128 92.0152 -7.12649 34.5073 -14.3513 -11.7693C-17.9639 -34.9084 -18.0954 -55.2261 -14.5445 -71.3587C-10.9937 -87.491 -3.76762 -99.4118 7.31431 -105.81C18.3962 -112.208 32.3329 -112.506 48.0794 -107.515C63.826 -102.523 81.3559 -92.2507 99.5887 -77.5526C136.053 -48.1574 175.287 -1.09628 208.636 56.6664Z"
				stroke="url(#paint84_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M210.212 58.7623C241.525 117.654 260.645 175.864 266.25 222.365C269.053 245.616 268.475 265.926 264.363 281.925C260.252 297.923 252.614 309.584 241.316 315.592C230.017 321.599 216.079 321.41 200.516 315.873C184.953 310.335 167.792 299.457 150.084 284.132C114.667 253.482 77.1001 205.08 45.787 146.189C14.4739 87.2973 -4.64557 29.0868 -10.251 -17.4138C-13.0538 -40.6649 -12.4761 -60.9749 -8.36438 -76.9736C-4.2527 -92.9722 3.38497 -104.634 14.6834 -110.641C25.9819 -116.649 39.9205 -116.46 55.4832 -110.922C71.046 -105.384 88.2067 -94.5061 105.915 -79.1806C141.332 -48.5307 178.899 -0.129056 210.212 58.7623Z"
				stroke="url(#paint85_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M211.688 60.91C240.926 120.858 258.003 179.701 261.982 226.368C263.971 249.703 262.685 269.981 258.018 285.826C253.35 301.672 245.31 313.059 233.809 318.669C222.308 324.278 208.384 323.603 193.024 317.526C177.664 311.448 160.894 299.978 143.731 284.043C109.406 252.176 73.5504 202.493 44.3116 142.545C15.0729 82.5965 -2.0034 23.7541 -5.98252 -22.9137C-7.97217 -46.2485 -6.68607 -66.5259 -2.01849 -82.3714C2.64903 -98.2168 10.689 -109.604 22.1903 -115.214C33.6915 -120.824 47.615 -120.148 62.9749 -114.071C78.3351 -107.993 95.1056 -96.5229 112.269 -80.5887C146.594 -48.7215 182.449 0.961736 211.688 60.91Z"
				stroke="url(#paint86_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M213.06 63.1095C240.189 124.042 255.202 183.444 257.55 230.222C258.724 253.612 256.731 273.833 251.513 289.506C246.295 305.178 237.863 316.279 226.173 321.483C214.483 326.688 200.591 325.527 185.453 318.917C170.314 312.308 153.954 300.259 137.358 283.735C104.166 250.69 70.0662 199.785 42.9374 138.853C15.8087 77.921 0.796352 18.5186 -1.55167 -28.2597C-2.72572 -51.6497 -0.732737 -71.8699 4.485 -87.5429C9.70267 -103.216 18.1352 -114.316 29.8252 -119.521C41.5152 -124.725 55.4066 -123.564 70.5451 -116.955C85.6838 -110.345 102.044 -98.2961 118.64 -81.7727C151.832 -48.727 185.932 2.17736 213.06 63.1095Z"
				stroke="url(#paint87_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M214.331 65.3552C239.316 127.197 252.246 187.087 252.961 233.919C253.318 257.336 250.62 277.474 244.859 292.955C239.097 308.437 230.282 319.236 218.418 324.029C206.553 328.823 192.711 327.178 177.812 320.044C162.913 312.91 146.984 300.297 130.974 283.205C98.9557 249.021 66.6536 196.957 41.6678 135.116C16.6821 73.2737 3.75204 13.3835 3.03799 -33.4482C2.68095 -56.865 5.3784 -77.0032 11.1399 -92.4846C16.9014 -107.966 25.7162 -118.765 37.5807 -123.558C49.4452 -128.352 63.2877 -126.707 78.1863 -119.573C93.085 -112.439 109.015 -99.8266 125.024 -82.734C157.043 -48.55 189.345 3.51338 214.331 65.3552Z"
				stroke="url(#paint88_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M215.495 67.6416C238.307 130.318 249.139 190.623 248.218 237.451C247.758 260.866 244.359 280.898 238.061 296.168C231.763 311.439 222.576 321.924 210.552 326.301C198.527 330.677 184.751 328.55 170.11 320.901C155.469 313.251 139.99 300.09 124.586 282.449C93.7802 247.169 63.3148 194.01 40.5025 131.334C17.6903 68.6574 6.85821 8.35242 7.779 -38.4757C8.23941 -61.8906 11.638 -81.9225 17.9363 -97.1933C24.2346 -112.464 33.4209 -122.949 45.4455 -127.325C57.4701 -131.702 71.2467 -129.575 85.8872 -121.925C100.528 -114.276 116.008 -101.115 131.411 -83.4741C162.217 -48.1936 192.683 4.9654 215.495 67.6416Z"
				stroke="url(#paint89_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M216.556 69.9673C237.167 133.401 245.888 194.048 243.333 240.815C242.056 264.2 237.96 284.101 231.133 299.142C224.305 314.184 214.759 324.342 202.589 328.296C190.419 332.251 176.725 329.644 162.36 321.488C147.995 313.332 132.984 299.639 118.206 281.471C88.6498 245.137 60.0582 190.947 39.4472 127.513C18.8362 64.0792 10.1153 3.43294 12.6699 -43.3345C13.9472 -66.7191 18.0428 -86.6202 24.8702 -101.662C31.6976 -116.703 41.2442 -126.861 53.4142 -130.816C65.5842 -134.77 79.2782 -132.163 93.6429 -124.007C108.008 -115.852 123.019 -102.159 137.797 -83.9908C167.353 -47.6567 195.945 6.53317 216.556 69.9673Z"
				stroke="url(#paint90_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M217.505 72.3283C235.889 136.443 242.488 197.357 238.303 244.007C236.211 267.332 231.423 287.078 224.075 301.873C216.727 316.667 206.831 326.485 194.531 330.012C182.23 333.54 168.635 330.457 154.564 321.805C140.493 313.152 125.969 298.944 111.833 280.271C83.5636 242.928 56.8805 187.773 38.4959 123.658C20.1113 59.5435 13.5123 -1.37013 17.6974 -48.02C19.79 -71.3457 24.5777 -91.0917 31.9259 -105.886C39.274 -120.68 49.1693 -130.499 61.4699 -134.026C73.7705 -137.553 87.3652 -134.47 101.436 -125.818C115.508 -117.166 130.032 -102.957 144.167 -84.2846C172.437 -46.9411 199.12 8.21355 217.505 72.3283Z"
				stroke="url(#paint91_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M218.346 74.721C234.482 139.438 238.951 200.545 233.14 247.021C230.235 270.259 224.761 289.826 216.901 304.355C209.041 318.883 198.809 328.351 186.393 331.446C173.977 334.542 160.498 330.987 146.737 321.849C132.976 312.71 118.957 298.004 105.482 278.849C78.5323 240.542 53.7904 184.49 37.6546 119.772C21.5187 55.0551 17.0496 -6.0518 22.8602 -52.5271C25.7656 -75.7657 31.2395 -95.3326 39.0996 -109.861C46.9595 -124.39 57.1915 -133.857 69.6077 -136.953C82.0239 -140.049 95.5027 -136.493 109.263 -127.355C123.024 -118.217 137.044 -103.51 150.519 -84.356C177.468 -46.0486 202.21 10.0037 218.346 74.721Z"
				stroke="url(#paint92_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M219.077 77.1408C232.944 142.382 235.278 203.607 227.849 249.852C224.134 272.975 217.981 292.339 209.619 306.584C201.256 320.83 190.7 329.934 178.184 332.595C165.667 335.255 152.32 331.231 138.887 321.619C125.453 312.006 111.956 296.819 99.1575 277.206C73.5615 237.981 50.7908 181.1 36.9234 115.859C23.056 50.6177 20.7222 -10.6079 28.1512 -56.8522C31.8659 -79.9752 38.0193 -99.3391 46.3816 -113.585C54.7438 -127.83 65.2999 -136.935 77.8166 -139.595C90.3333 -142.256 103.68 -138.232 117.113 -128.619C130.547 -119.006 144.044 -103.819 156.843 -84.2064C182.439 -44.9818 205.209 11.8998 219.077 77.1408Z"
				stroke="url(#paint93_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M219.696 79.5836C231.278 145.269 231.474 206.539 222.436 252.495C217.916 275.475 211.091 294.612 202.236 308.557C193.382 322.502 182.515 331.233 169.913 333.455C157.311 335.677 144.113 331.19 131.023 321.114C117.933 311.038 104.974 295.389 92.8678 275.342C68.6563 235.248 47.8847 177.606 36.3026 111.921C24.7205 46.2356 24.5248 -15.0342 33.5633 -60.991C38.0826 -83.9702 44.9081 -103.108 53.7625 -117.053C62.6168 -130.998 73.4842 -139.728 86.0861 -141.95C98.688 -144.172 111.886 -139.685 124.976 -129.61C138.066 -119.534 151.025 -103.885 163.131 -83.8373C187.343 -43.7433 208.114 13.8983 219.696 79.5836Z"
				stroke="url(#paint94_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M220.205 82.0474C229.488 148.097 227.545 209.336 216.908 254.95C211.59 277.757 204.1 296.645 194.765 310.272C185.429 323.9 174.264 332.246 161.592 334.026C148.92 335.807 135.887 330.862 123.157 320.336C110.426 309.81 98.021 293.718 86.6219 273.26C63.8244 232.346 45.0771 174.014 35.7944 107.965C26.5118 41.9151 28.4545 -19.3241 39.0913 -64.9375C44.4099 -87.745 51.8991 -106.633 61.2348 -120.26C70.5703 -133.888 81.7358 -142.233 94.4076 -144.014C107.079 -145.795 120.113 -140.85 132.843 -130.324C145.573 -119.798 157.978 -103.706 169.378 -83.248C192.175 -42.3335 210.922 15.9979 220.205 82.0474Z"
				stroke="url(#paint95_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M220.602 84.5267C227.574 150.86 223.495 211.994 211.273 257.208C205.162 279.816 197.018 298.431 187.212 311.725C177.407 325.018 165.957 332.969 153.231 334.307C140.504 335.644 127.652 330.247 115.297 319.283C102.941 308.319 91.1053 291.804 80.427 270.961C59.0713 229.276 42.3712 170.325 35.3993 103.992C28.4274 37.6591 32.5062 -23.4751 44.7283 -68.6894C50.8397 -91.2975 58.9835 -109.912 68.7891 -123.206C78.5945 -136.499 90.0444 -144.45 102.771 -145.788C115.497 -147.125 128.35 -141.728 140.705 -130.764C153.06 -119.8 164.896 -103.285 175.574 -82.4419C196.93 -40.7567 213.63 18.1935 220.602 84.5267Z"
				stroke="url(#paint96_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M220.883 87.0182C225.536 153.554 219.326 214.509 205.533 259.269C198.636 281.65 189.848 299.969 179.584 312.912C169.321 325.856 157.601 333.402 144.835 334.295C132.07 335.187 119.414 329.345 107.449 317.957C95.4837 306.568 84.2312 289.65 74.2869 268.447C54.399 226.042 39.7663 166.545 35.1137 100.008C30.461 33.4724 36.6709 -27.4822 50.4636 -72.2425C57.3602 -94.6234 66.1487 -112.943 76.4122 -125.886C86.6756 -138.829 98.3961 -146.375 111.161 -147.268C123.926 -148.161 136.583 -142.319 148.548 -130.93C160.513 -119.541 171.765 -102.623 181.71 -81.4201C201.598 -39.015 216.23 20.4821 220.883 87.0182Z"
				stroke="url(#paint97_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M221.055 89.5171C223.383 156.175 215.05 216.876 199.703 261.127C192.03 283.254 182.607 301.255 171.898 313.832C161.189 326.409 149.213 333.542 136.424 333.989C123.636 334.436 111.191 328.155 99.6306 316.356C88.0703 304.557 77.4151 287.257 68.2169 265.719C49.821 222.646 37.2737 162.674 34.9459 96.0162C32.6182 29.3583 40.9515 -31.3424 56.2979 -75.5941C63.9714 -97.7208 73.3939 -115.722 84.1029 -128.299C94.8118 -140.876 106.788 -148.009 119.577 -148.456C132.365 -148.902 144.811 -142.622 156.371 -130.823C167.931 -119.023 178.586 -101.723 187.784 -80.1857C206.18 -37.1124 218.728 22.8592 221.055 89.5171Z"
				stroke="url(#paint98_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<path
				d="M221.11 92.0198C221.11 158.718 210.663 219.091 193.781 262.78C185.34 284.626 175.295 302.287 164.154 314.483C153.013 326.678 140.794 333.389 127.998 333.389C115.202 333.389 102.984 326.678 91.8422 314.483C80.7008 302.287 70.6559 284.626 62.2148 262.78C45.3334 219.091 34.8867 158.718 34.8867 92.0198C34.8867 25.3212 45.3334 -35.0517 62.2148 -78.7408C70.6559 -100.586 80.7008 -118.248 91.8422 -130.443C102.984 -142.639 115.202 -149.35 127.998 -149.35C140.794 -149.35 153.013 -142.639 164.154 -130.443C175.295 -118.248 185.34 -100.586 193.781 -78.7408C210.663 -35.0517 221.11 25.3212 221.11 92.0198Z"
				stroke="url(#paint99_linear_5690_81197)"
				strokeWidth="0.5"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_5690_81197"
					x1="216.791"
					y1="137.112"
					x2="39.2066"
					y2="194.812"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_5690_81197"
					x1="217.744"
					y1="139.48"
					x2="38.2549"
					y2="190.948"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_5690_81197"
					x1="218.588"
					y1="141.882"
					x2="37.4116"
					y2="187.054"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_5690_81197"
					x1="219.321"
					y1="144.31"
					x2="36.6786"
					y2="183.132"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_5690_81197"
					x1="219.944"
					y1="146.761"
					x2="36.0575"
					y2="179.185"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_5690_81197"
					x1="220.453"
					y1="149.233"
					x2="35.5475"
					y2="175.22"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_5690_81197"
					x1="220.849"
					y1="151.721"
					x2="35.1494"
					y2="171.239"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_5690_81197"
					x1="221.134"
					y1="154.22"
					x2="34.8657"
					y2="167.245"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_5690_81197"
					x1="221.306"
					y1="156.728"
					x2="34.6968"
					y2="163.245"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_5690_81197"
					x1="221.359"
					y1="159.24"
					x2="34.6365"
					y2="159.24"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint10_linear_5690_81197"
					x1="221.305"
					y1="161.75"
					x2="34.6963"
					y2="155.234"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint11_linear_5690_81197"
					x1="221.134"
					y1="164.258"
					x2="34.8658"
					y2="151.233"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint12_linear_5690_81197"
					x1="220.848"
					y1="166.757"
					x2="35.1479"
					y2="147.239"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint13_linear_5690_81197"
					x1="220.453"
					y1="169.246"
					x2="35.5474"
					y2="143.259"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint14_linear_5690_81197"
					x1="219.943"
					y1="171.716"
					x2="36.057"
					y2="139.292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint15_linear_5690_81197"
					x1="219.321"
					y1="174.169"
					x2="36.6789"
					y2="135.347"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint16_linear_5690_81197"
					x1="218.588"
					y1="176.597"
					x2="37.4114"
					y2="131.424"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint17_linear_5690_81197"
					x1="217.746"
					y1="178.997"
					x2="38.2563"
					y2="127.53"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint18_linear_5690_81197"
					x1="216.791"
					y1="181.366"
					x2="39.2074"
					y2="123.666"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint19_linear_5690_81197"
					x1="215.73"
					y1="183.701"
					x2="40.2684"
					y2="119.838"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint20_linear_5690_81197"
					x1="214.563"
					y1="185.997"
					x2="41.4366"
					y2="116.049"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint21_linear_5690_81197"
					x1="213.289"
					y1="188.249"
					x2="42.7093"
					y2="112.302"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint22_linear_5690_81197"
					x1="211.913"
					y1="190.457"
					x2="44.0877"
					y2="108.603"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint23_linear_5690_81197"
					x1="210.433"
					y1="192.613"
					x2="45.5669"
					y2="104.952"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint24_linear_5690_81197"
					x1="208.853"
					y1="194.716"
					x2="47.1459"
					y2="101.355"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint25_linear_5690_81197"
					x1="207.174"
					y1="196.762"
					x2="48.8236"
					y2="97.8141"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint26_linear_5690_81197"
					x1="205.397"
					y1="198.75"
					x2="50.5967"
					y2="94.3359"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint27_linear_5690_81197"
					x1="203.53"
					y1="200.671"
					x2="52.4681"
					y2="90.9184"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_5690_81197"
					x1="201.57"
					y1="202.526"
					x2="54.4306"
					y2="87.568"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint29_linear_5690_81197"
					x1="199.519"
					y1="204.313"
					x2="56.4809"
					y2="84.29"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint30_linear_5690_81197"
					x1="197.38"
					y1="206.026"
					x2="58.6179"
					y2="81.0838"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint31_linear_5690_81197"
					x1="195.157"
					y1="207.662"
					x2="60.8402"
					y2="77.9537"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint32_linear_5690_81197"
					x1="192.855"
					y1="209.22"
					x2="63.1467"
					y2="74.9024"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint33_linear_5690_81197"
					x1="190.472"
					y1="210.695"
					x2="65.5303"
					y2="71.9331"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint34_linear_5690_81197"
					x1="188.011"
					y1="212.086"
					x2="67.988"
					y2="69.0475"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint35_linear_5690_81197"
					x1="185.477"
					y1="213.39"
					x2="70.5187"
					y2="66.2501"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint36_linear_5690_81197"
					x1="182.877"
					y1="214.605"
					x2="73.1236"
					y2="63.5427"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint37_linear_5690_81197"
					x1="180.208"
					y1="215.726"
					x2="75.7939"
					y2="60.9258"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint38_linear_5690_81197"
					x1="177.473"
					y1="216.754"
					x2="78.5252"
					y2="58.4042"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint39_linear_5690_81197"
					x1="174.68"
					y1="217.685"
					x2="81.3187"
					y2="55.9787"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint40_linear_5690_81197"
					x1="171.829"
					y1="218.519"
					x2="84.1684"
					y2="53.6522"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint41_linear_5690_81197"
					x1="168.926"
					y1="219.251"
					x2="87.0719"
					y2="51.4255"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint42_linear_5690_81197"
					x1="165.974"
					y1="219.882"
					x2="90.0274"
					y2="49.3019"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint43_linear_5690_81197"
					x1="162.975"
					y1="220.408"
					x2="93.0272"
					y2="47.2813"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint44_linear_5690_81197"
					x1="159.931"
					y1="220.829"
					x2="96.0678"
					y2="45.367"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint45_linear_5690_81197"
					x1="156.85"
					y1="221.142"
					x2="99.1498"
					y2="43.5581"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint46_linear_5690_81197"
					x1="153.732"
					y1="221.349"
					x2="102.264"
					y2="41.8594"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint47_linear_5690_81197"
					x1="150.585"
					y1="221.444"
					x2="105.412"
					y2="40.268"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint48_linear_5690_81197"
					x1="147.412"
					y1="221.432"
					x2="108.59"
					y2="38.7899"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint49_linear_5690_81197"
					x1="144.21"
					y1="221.307"
					x2="111.786"
					y2="37.4208"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint50_linear_5690_81197"
					x1="140.993"
					y1="221.069"
					x2="115.006"
					y2="36.1637"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint51_linear_5690_81197"
					x1="137.761"
					y1="220.719"
					x2="118.243"
					y2="35.0195"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint52_linear_5690_81197"
					x1="134.513"
					y1="220.256"
					x2="121.488"
					y2="33.9878"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint53_linear_5690_81197"
					x1="131.257"
					y1="219.68"
					x2="124.741"
					y2="33.0708"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint54_linear_5690_81197"
					x1="128"
					y1="218.99"
					x2="128"
					y2="32.2674"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint55_linear_5690_81197"
					x1="124.742"
					y1="218.187"
					x2="131.258"
					y2="31.5782"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint56_linear_5690_81197"
					x1="121.486"
					y1="217.27"
					x2="134.511"
					y2="31.0015"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint57_linear_5690_81197"
					x1="118.239"
					y1="216.238"
					x2="137.757"
					y2="30.5376"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint58_linear_5690_81197"
					x1="115.006"
					y1="215.095"
					x2="140.993"
					y2="30.189"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint59_linear_5690_81197"
					x1="111.787"
					y1="213.837"
					x2="144.211"
					y2="29.9505"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint60_linear_5690_81197"
					x1="108.587"
					y1="212.469"
					x2="147.409"
					y2="29.8264"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint61_linear_5690_81197"
					x1="105.415"
					y1="210.989"
					x2="150.587"
					y2="29.8128"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint62_linear_5690_81197"
					x1="102.265"
					y1="209.399"
					x2="153.733"
					y2="29.9096"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint63_linear_5690_81197"
					x1="99.1492"
					y1="207.699"
					x2="156.85"
					y2="30.1147"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint64_linear_5690_81197"
					x1="96.0694"
					y1="205.891"
					x2="159.932"
					y2="30.4286"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint65_linear_5690_81197"
					x1="93.0256"
					y1="203.976"
					x2="162.973"
					y2="30.8496"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint66_linear_5690_81197"
					x1="90.0272"
					y1="201.956"
					x2="165.974"
					y2="31.3763"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint67_linear_5690_81197"
					x1="87.0743"
					y1="199.831"
					x2="168.928"
					y2="32.0057"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint68_linear_5690_81197"
					x1="84.1693"
					y1="197.605"
					x2="171.83"
					y2="32.7388"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint69_linear_5690_81197"
					x1="81.3188"
					y1="195.279"
					x2="174.68"
					y2="33.5727"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint70_linear_5690_81197"
					x1="78.526"
					y1="192.854"
					x2="177.474"
					y2="34.5042"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint71_linear_5690_81197"
					x1="75.792"
					y1="190.332"
					x2="180.206"
					y2="35.5313"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint72_linear_5690_81197"
					x1="73.1226"
					y1="187.716"
					x2="182.876"
					y2="36.6536"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint73_linear_5690_81197"
					x1="70.5217"
					y1="185.009"
					x2="185.48"
					y2="37.869"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint74_linear_5690_81197"
					x1="67.9877"
					y1="182.209"
					x2="188.011"
					y2="39.1714"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint75_linear_5690_81197"
					x1="65.5289"
					y1="179.326"
					x2="190.471"
					y2="40.5642"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint76_linear_5690_81197"
					x1="63.1443"
					y1="176.357"
					x2="192.853"
					y2="42.0394"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint77_linear_5690_81197"
					x1="60.841"
					y1="173.304"
					x2="195.158"
					y2="43.5959"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint78_linear_5690_81197"
					x1="58.6183"
					y1="170.174"
					x2="197.38"
					y2="45.2324"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint79_linear_5690_81197"
					x1="56.4818"
					y1="166.969"
					x2="199.52"
					y2="46.946"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint80_linear_5690_81197"
					x1="54.4296"
					y1="163.689"
					x2="201.569"
					y2="48.7306"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint81_linear_5690_81197"
					x1="52.4676"
					y1="160.339"
					x2="203.53"
					y2="50.5865"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint82_linear_5690_81197"
					x1="50.6001"
					y1="156.922"
					x2="205.4"
					y2="52.5078"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint83_linear_5690_81197"
					x1="48.8259"
					y1="153.444"
					x2="207.176"
					y2="54.4959"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint84_linear_5690_81197"
					x1="47.1456"
					y1="149.903"
					x2="208.852"
					y2="56.5414"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint85_linear_5690_81197"
					x1="45.5663"
					y1="146.306"
					x2="210.433"
					y2="58.6449"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint86_linear_5690_81197"
					x1="44.0869"
					y1="142.654"
					x2="211.912"
					y2="60.8004"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint87_linear_5690_81197"
					x1="42.7091"
					y1="138.955"
					x2="213.289"
					y2="63.0079"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint88_linear_5690_81197"
					x1="41.436"
					y1="135.209"
					x2="214.562"
					y2="65.2616"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint89_linear_5690_81197"
					x1="40.2676"
					y1="131.419"
					x2="215.73"
					y2="67.5561"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint90_linear_5690_81197"
					x1="39.2094"
					y1="127.591"
					x2="216.793"
					y2="69.89"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint91_linear_5690_81197"
					x1="38.2556"
					y1="123.727"
					x2="217.745"
					y2="72.2594"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint92_linear_5690_81197"
					x1="37.412"
					y1="119.833"
					x2="218.588"
					y2="74.6605"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint93_linear_5690_81197"
					x1="36.6789"
					y1="115.911"
					x2="219.321"
					y2="77.0888"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint94_linear_5690_81197"
					x1="36.0564"
					y1="111.964"
					x2="219.943"
					y2="79.5402"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint95_linear_5690_81197"
					x1="35.5468"
					y1="107.999"
					x2="220.453"
					y2="82.0126"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint96_linear_5690_81197"
					x1="35.1506"
					y1="104.018"
					x2="220.851"
					y2="84.5006"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint97_linear_5690_81197"
					x1="34.8643"
					y1="100.026"
					x2="221.132"
					y2="87.0008"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint98_linear_5690_81197"
					x1="34.6961"
					y1="96.0249"
					x2="221.305"
					y2="89.5084"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint99_linear_5690_81197"
					x1="34.6367"
					y1="92.0198"
					x2="221.36"
					y2="92.0198"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#ED1EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
			</defs>
		</Icon>
	);
};
